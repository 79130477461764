import React, { createContext, useContext, useReducer } from "react";
import axios from "axios";
import { API_BASE_URL } from "@config/config";
import { ACTION_TYPES } from "@reducers/actionTypes";
import ticketsReducer, { INITIAL_STATE } from "@reducers/ticketsReducer";

const TicketsContext = createContext(INITIAL_STATE);

export const TicketsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ticketsReducer, INITIAL_STATE);


    const reSendTicketEmail = (uuid) => {

        dispatch({ type: ACTION_TYPES.FETCH_START, uuid });

        axios
            .post(`${API_BASE_URL}/ticket/resend-email/${uuid}`, {})
            .then(function (response) {
                dispatch({
                    type: ACTION_TYPES.FETCH_SUCCESS,
                    payload: {
                        uuid,
                        message: response.data.message,
                        success: response.data.success,
                    }
                });

            })
            .catch(() => {
                dispatch({ type: ACTION_TYPES.FETCH_ERROR });
            });
    };

    const value = {
        id: state.id,
        error: state.error,
        processing: state.processing,
        message: state.message,
        errorMessage: state.errorMessage,
        loading: state.loading,
        success: state.success,
        reSendTicketEmail,
    };

    return (
        <TicketsContext.Provider value={value}>
            {children}
        </TicketsContext.Provider>
    );
};

const useTickets = () => {
    const context = useContext(TicketsContext);

    if (context === undefined) {
        throw new Error("useTickets must be used within TicketsContext");
    }

    return context;
};

export default useTickets;
