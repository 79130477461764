import React, { useContext } from "react";
import { SearchContext } from "@context/SearchContext";
import "./style.scss";

const experiencesColours = [
    { id: "GOLD", name: "Gold", description: "Gold | Lux | Titan Luxe" },
    { id: "STND", name: "Standard", description: "Standard Seating" },
    { id: "PREM", name: "Premium Standard", description: "VMAX | Premium | Xtreme | Dbox" },
];

const Experiences = ({ experienceData, handleExperienceClick }) => {
    const { selectedExperiences, setSelectedExperiences } = useContext(SearchContext);

    if (!experienceData || experienceData.length === 0) {
        return null;
    }

    const uniqueExperiencesMap = new Map();
    experienceData.forEach((exp) => {
        if (!uniqueExperiencesMap.has(exp.experience_type)) {
            uniqueExperiencesMap.set(exp.experience_type, exp);
        }
    });

    const uniqueExperiences = Array.from(uniqueExperiencesMap.values());
    const mergedExperiences = uniqueExperiences.map((experience) => {
        const matchedExperience = experiencesColours.find((exp) => exp.id === experience.experience_type);
        return {
            ...experience,
            id: matchedExperience?.id || experience.experience_type,
            name: matchedExperience?.name || "Unknown Experience",
            description: matchedExperience?.description || "No description available",
        };
    });

    const handleCheckboxChange = (experienceType) => {
        setSelectedExperiences((prevSelected = []) => {
            const isSelected = prevSelected.includes(experienceType);
            const updatedSelections = isSelected
                ? prevSelected.filter((type) => type !== experienceType)
                : [...prevSelected, experienceType];

            handleExperienceClick(updatedSelections);
            return updatedSelections;
        });
    };


    const handleKeyDown = (event, experienceType) => {
        if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            handleCheckboxChange(experienceType);
        }
    };

    return (
        <div className="experience">
            <h2 id="experience-filter-title" className="experience__title">Experience Filter</h2>
            <div
                className="experience__wrapper"
                role="group"
                aria-labelledby="experience-filter-title"
            >
                {mergedExperiences.map((experience) => {
                    const isChecked = selectedExperiences.includes(experience.experience_type);

                    return (
                        <label
                            key={experience.experience_type}
                            className={`experience__item experience__item--${experience.id} ${isChecked ? "experience__item--selected" : ""}`}
                            tabIndex="0"
                            role="checkbox"
                            aria-checked={isChecked}
                            onKeyDown={(event) => handleKeyDown(event, experience.experience_type)}
                        >
                            <div className="experience__content">
                                <span className="experience__label">{experience.name}</span>
                                <span className="experience__description">{experience.description}</span>
                            </div>
                            <input
                                type="checkbox"
                                name="experience"
                                value={experience.experience_type}
                                checked={isChecked}
                                onChange={() => handleCheckboxChange(experience.experience_type)}
                                className="experience__checkbox"
                            />
                            <span
                                className={`experience__custom-checkbox ${isChecked ? "experience__custom-checkbox--checked" : ""}`}
                                role="checkbox"
                                aria-checked={isChecked}
                                tabIndex="-1"
                            ></span>
                        </label>
                    );
                })}
            </div>
        </div>
    );
};

export default Experiences;
