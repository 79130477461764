import React from "react";
import AboriginalFlag from "@components/Icons/AboriginalFlag";
import TorresStraitFlag from "@components/Icons/TorresStraitFlag";
import "./style.scss";

const AcknowledgementOfCountry = () => {
	return (
    <div className="acknowledgement__wrapper">
        <div className="acknowledgement__text">
            Choovie Group acknowledges the Aboriginal and Torres Strait Islander people as the Traditional Owners of Country throughout Australia. We celebrate their stories, culture and tradition and pay our respect to elders past, present and emerging.
        </div>

        <div className="acknowledgement__flag-wrapper">
            <AboriginalFlag className="acknowledgement__flag" />
            <TorresStraitFlag className="acknowledgement__flag" />
        </div>
    </div>
	);
};

export default AcknowledgementOfCountry;
