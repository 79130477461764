import React, { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { API_BASE_URL } from "@config/config";
import { AuthContext } from "@context/AuthContext";
import Button from "@components/Ui/Button";
import MoviesComingSoon from "@components/V3/Movies/MoviesComingSoon";
import MoviesNowShowing from "@components/V3/Movies/MoviesNowShowing";
import "./style.scss";

const MoviesList = () => {
    const [showCurrent, setShowCurrent] = useState(true);
    
    const [{ data, loading, error }] = useAxios(`${API_BASE_URL}/movie/now-showing`);

    const { setNowShowing, setComingSoon, setIsMoviesLoading, setErrorMessage } = useContext(AuthContext);

    useEffect(() => {
        if (data) {
            setNowShowing(data.data?.nowShowing ?? []);
            setComingSoon(data.data?.comingSoon ?? []);
        }
    },[data]);

    useEffect(() => {
        setIsMoviesLoading(loading);
    },[loading]);
    
    useEffect(() => {
        if (error) {
            setErrorMessage(error);
        }
    }, [error]);

    return (
        <div className="movie-list">
            <div className="movie-list__header">
                <ul className="movie-list__buttons">
                    <li className="movie-list__item">
                        <Button
                            additionalClasses={`button movie-list__button ${showCurrent ? "movie-list__button-selected" : ""}`}
                            onClick={() => setShowCurrent(true)}>
                            Now Showing
                        </Button>
                    </li>
                    <li className="movie-list__item">
                        <Button
                            additionalClasses={`button movie-list__button ${!showCurrent ? "movie-list__button-selected" : ""}`}
                            onClick={() => setShowCurrent(false)}>
                            Coming Soon
                        </Button>
                    </li>
                </ul>
            </div>

            {showCurrent && (
                <MoviesNowShowing />
            )}
            {!showCurrent && (
                <MoviesComingSoon />
            )}
        </div>
    );
};

export default MoviesList;
