import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ApplicationContext } from "@context/ApplicationContext";

const NewsItem = ({ item }) => {
    const { setNewsItem } = useContext(ApplicationContext);
    const { id, title, image, text, link, date } = item;

    const handleNewsItem = (id) => {
        setNewsItem({
            id: item.id,
            link: item.link,
            title: item.title,
            text: item.text,
            image: item.image,
            content: item.content,
            date: item.date,
        });
    };


    return (
        <Link onClick={() => handleNewsItem(id)} to={{ pathname: `${link}` }} className="news__item-link">
            <div className="news__item">
                <div className="news__title-container">
                    <h2 className="news__title">{title}</h2>
                </div>
                <div className="news__image-container">
                    <img className="news__image" src={image} alt={title} />
                </div>
                <p className="news__text">{text}</p>
                <p className="news__date">{date}</p>
                <p className="news__link">Read more</p>
            </div>
        </Link>
    );
};

export default NewsItem;
