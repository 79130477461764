import React, { useContext } from "react";
import { ApplicationContext } from "@context/ApplicationContext";

const BrandName = () => {
    const { whiteLabel } = useContext(ApplicationContext);

    return (
        <>
            {whiteLabel?.brand_name}
        </>
    );
};

export default BrandName;
