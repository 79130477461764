import React from "react";

const PlayIcon = ({ className = "" }) => {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="11" fill="black" fillOpacity="0.5" stroke="currentColor" strokeWidth="2" />
            <path d="M15.9278 11.429C16.6831 11.7924 16.6831 12.8679 15.9278 13.2313L10.2343 15.9705C9.57043 16.29 8.80078 15.8062 8.80078 15.0694L8.80078 9.59085C8.80078 8.8541 9.57043 8.3703 10.2343 8.68972L15.9278 11.429Z" fill="currentColor" />
        </svg>
    );
};

export default PlayIcon;
