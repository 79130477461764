import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { SITE_V3 } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import MovieClub from "@components/MovieClub";
import LayoutV3 from "@components/V3/Layout";
import Layout from "@components/Wrappers/Layout";

const MovieClubPage = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const { siteVersion } = useContext(AuthContext);
    const brand = whiteLabel?.brand_name;
    const LayoutComponent = siteVersion && siteVersion === SITE_V3 ? LayoutV3 : Layout;
    
    return (
        <LayoutComponent>
            <Helmet>
                <title>{`Movie Club Membership | Exclusive Offers | ${brand}`}</title>
                <meta name="description" content="Discover the perks of our membership for movies. Enjoy cheaper tickets, free movie sessions & more at nearby independent or regional cinema. Sign up today!" />
                <link rel="canonical" href={`${window.location.protocol}//${window.location.host}/movie-club-membership`} />
            </Helmet>
            <MovieClub />
        </LayoutComponent>
    );
};

export default MovieClubPage;
