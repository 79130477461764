import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ApplicationContext } from "@context/ApplicationContext";
import Layout from "@components/V3/Layout";
import MoviesList from "@components/V3/Movies/MoviesList";
import V3Carousel from "@components/V3/V3Carousel/index";

const MoviesPage = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;

    return (
        <Layout>
            <Helmet>
                <title>{`${brand} | ${whiteLabel?.brand_title}`}</title>
                <meta name="keywords" content={`Now Showing | Movies in Cinemas Now | ${brand}`} />
                <meta name="description" content={`Discover movies now showing and coming soon in regional cinemas across Australia.
                Get information on movie tickets, screening locations,
                showtimes & more. Only at ${brand}!`} />
                <link rel="canonical" href={`${window.location.protocol}//${window.location.host}`} />
            </Helmet>
            <V3Carousel />
            <MoviesList />
        </Layout>
    );
};

export default MoviesPage;
