import React from "react";
import CreatePasswordForm from "@components/Form/SetPasswordPostSignUpForm";
import FormWrapper from "@components/Wrappers/FormWrapper";
import MinimalLayout from "@components/Wrappers/MinimalLayout";

const CreatePasswordPage = () => {

    return (
        <MinimalLayout>
            <FormWrapper pageTitle="Create Password">
                <CreatePasswordForm />
            </FormWrapper>
        </MinimalLayout>
    );
};

export default CreatePasswordPage;
