import React, { createContext, useState, useContext } from "react";
import axios from "axios";
import { API_BASE_URL } from "@config/config";
import { AuthContext } from "@context/AuthContext";

export const MembershipContext = createContext({});

const MembershipContextProvider = ({ children }) => {
    const { setUserAuth } = useContext(AuthContext);
    const [selectedMembershipId, setSelectedMembershipId] = useState(0);
    const [isMembershipLoading, setIsMembershipLoading] = useState(false);
    const [membershipOptions, setMembershipOptions] = useState(null);
    const [memberData, setMemberData] = useState(null);
    const [isMembershipExpired, setIsMembershipExpired] = useState(false);

    const fetchMembershipInformation = (id) => {
        setIsMembershipLoading(true);
        setIsMembershipExpired(false);
        axios
            .get(`${API_BASE_URL}/movie-club-purchase/${id}`)
            .then(function(response) {
                setIsMembershipLoading(false);
                setMemberData(response?.data?.data?.membershipData);
                setIsMembershipExpired(response?.data?.data?.isMembershipExpired);

                if (response?.data?.data?.subscriber) {
                    setUserAuth((prevState) => ({
                        ...prevState,
                        user: response.data.data.subscriber,
                    }));
                }
            })
            .catch((error) => {
                setIsMembershipLoading(false);
                console.log(error);
            });
    };

    const fetchMembershipOptions = (subscriberGroupId) => {
        setIsMembershipLoading(true);
        axios
            .get(`${API_BASE_URL}/movie-club/${subscriberGroupId}`)
            .then(function(response) {
                setIsMembershipLoading(false);
                setMembershipOptions(response.data);
            })
            .catch((error) => {
                setIsMembershipLoading(false);
            });
    };

    return (
        <MembershipContext.Provider
            value={{
                fetchMembershipOptions,
                fetchMembershipInformation,
                isMembershipExpired,
                isMembershipLoading,
                memberData,
                membershipOptions,
                selectedMembershipId,
                setIsMembershipExpired,
                setSelectedMembershipId,
            }}
        >
            {children}
        </MembershipContext.Provider>
    );
};

export default MembershipContextProvider;
