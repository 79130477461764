import React, { useContext, useEffect } from "react";
import { SITE_V3 } from "@config/config";
import { getSiteConfig } from "@config/siteConfig";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import Spinner from "@components/Ui/Spinner";

const AutoLogin = ({
                       brandCode,
                       subscriberGroupId,
                       productTypeId,
                       publicSubscriberGroup,
                       cdnUrl,
                       gaCode,
                       children,
                   }) => {

    const { user, isLoggedIn, loginByToken, siteVersion } = useContext(AuthContext);

    const {
        setBrandCode,
        setSubscriberGroupId,
        setSiteConfig,
        setPublicSubscriberGroup,
        setCdnUrl,
        setGaCode,
        fetchWhiteLabel,
        setProductTypeId,
        fetchSeasonalRoutes,
        setDisplayNewsLink,
        // getLocation,
    } = useContext(ApplicationContext);

    useEffect(() => {
        if (isLoggedIn === null) {
            loginByToken();
        }
    }, [isLoggedIn]);

    // useEffect(() => {
    //     getLocation();
    // },[]);

    useEffect(() => {
        if (user?.subscriber_group_id) {
            setSubscriberGroupId(user.subscriber_group_id);
            fetchWhiteLabel(user.subscriber_group_id);
        } else {
            fetchWhiteLabel(subscriberGroupId);
            setSubscriberGroupId(subscriberGroupId);
        }
        if (siteVersion !== SITE_V3) {
            fetchSeasonalRoutes();
        }
    }, []);

    useEffect(() => {
        if (brandCode) {
            const siteConfig = getSiteConfig(brandCode);
            setBrandCode(brandCode);
            setSiteConfig(siteConfig);
        }
        setCdnUrl(cdnUrl);
        if (productTypeId) {
            setProductTypeId(productTypeId);
        }
        if (brandCode === "brand_cinema_near_me") {
            setDisplayNewsLink(true);
        }
    }, [brandCode, productTypeId]);

    useEffect(() => {
        if (publicSubscriberGroup !== null) {
            setPublicSubscriberGroup(publicSubscriberGroup);
        }
    }, [publicSubscriberGroup]);


    useEffect(() => {
        setGaCode(gaCode);
    }, [gaCode]);


    return (
        <>
            {isLoggedIn === null ? (
                <Spinner text="loading..." center={true} />
            ) : (
                children
            )}
        </>
    );
};

export default AutoLogin;
