import React, { useContext } from "react";
import { MembershipContext } from "@context/MembershipContext";
import AvatarIcon from "@components/Icons/AvatarIcon";
import "./style.scss";

const MembershipOptions = ({ membershipOptions }) => {
    const {
        selectedMembershipId,
        setSelectedMembershipId
    } = useContext(MembershipContext);


    const handleRadioChange = (event) => {
        const { id } = event.target;
        setSelectedMembershipId(parseInt(id));
    };



    return (
        <div className="membership-options__container">
            <div className="membership-options__intro">
                <h2 className="membership-options__intro-title">Types of Memberships Available Under Movie Club</h2>
                <h3 className="membership-options__text-title">Step 1: Select your membership</h3>
            </div>
            <div className="membership-options__content">
                {membershipOptions.data && membershipOptions.data.map((item, i) => {
                        return (
                            <div className="membership-options__item" key={i}>
                                <AvatarIcon className="membership-options__icon" type={item.id}/>
                                <h3 className="membership-options__item-title">{item.name}</h3>
                                <p className="paragraph">{item.description}</p>
                                <div className="membership-options__list">
                                    <div className="radio">
                                        <input
                                            id={item.id}
                                            type="radio"
                                            name="membershipId"
                                            className="radio-input"
                                            value={item.id}
                                            checked={selectedMembershipId ===  item.id }
                                            onChange={handleRadioChange}
                                        />
                                        <label
                                            htmlFor="radio"
                                            className="radio-label"
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default MembershipOptions;
