import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { TYPE_VOUCHER_ADULT } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import BookingDetails from "@components/Bookings/BookingDetails";
import StarIcon from "@components/Icons/StarIcon";
import MoviePoster from "@components/MoviePoster";
import SessionDetails from "@components/SessionDetails";
import TicketClarification from "@components/Tickets/TicketClarification";
import "./style.scss";

const ConfirmationMessage = ({ purchasedTicket, purchasedSession, tempAccountCreation }) => {
    const { user } = useContext(AuthContext);
    const { whiteLabel } = useContext(ApplicationContext);

    return (
        <>
            {purchasedSession && (
                <div className="confirmation">
                    <div className="confirmation__header">
                        <div className="confirmation__details">
                            <h1 className="confirmation__title">
                                {whiteLabel?.brand_code === "brand_lexus"
                                    ? <>Thank you for your purchase {user.first_name}.
                                        Your premium movie experience awaits</>
                                    : <>Congratulations {user.first_name}! You&apos;re off to the movies!</>}
                            </h1>

                            <p className="paragraph">
                                You should already have received an email with details of your purchase.
                            </p>

                            {tempAccountCreation && (
                                <p className="paragraph">
                                    A secondary email will be sent to complete the account creation process.
                                </p>
                            )}

                            {purchasedSession?.ticket_type_id === TYPE_VOUCHER_ADULT && (
                                <>
                                    <TicketClarification />
                                    {purchasedTicket?.booking_url && (
                                        <p className="paragraph">
                                            Click <a className="link" href={purchasedTicket.booking_url} target="_blank" rel="noreferrer"> here</a> to confirm your seat , please note cinema booking fees may apply
                                        </p>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="confirmation__buttons">
                            <Link to="/user/current-tickets" className="button button__primary">
                                <StarIcon />
                                View Purchases
                            </Link>
                        </div>
                    </div>

                    <div className="confirmation__body">
                        {purchasedSession ? (
                            <div className="confirmation__image">
                                <MoviePoster
                                    movie={purchasedSession}
                                    size="medium"
                                />
                            </div>) : null}

                        <div className="confirmation__details">
                            {purchasedSession ? (
                                <SessionDetails session={purchasedSession} />) : null}
                            {purchasedTicket ? (
                                <div className="confirmation__movie-price-details">
                                    <BookingDetails ticket={purchasedTicket} />
                                </div>
                            ) : null}

                        </div>
                    </div>

                </div>
            )}
        </>
    );
};

export default ConfirmationMessage;
