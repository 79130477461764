import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "@config/config";
import { AuthContext } from "@context/AuthContext";
import { SessionContext } from "@context/SessionContext";
import { UserContext } from "@context/UserContext";

export const CheckoutComplimentaryContext = createContext({});

const CheckoutComplimentaryContextProvider = (props) => {
    const { isLoggedIn } = useContext(AuthContext);
    const { setPurchasedTicket, setPurchasedSession } = useContext(UserContext);
    const { session } = useContext(SessionContext);
    const [error, setError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const { children } = props;

    const checkoutComplementary = (id, bookingSessionId, freeTicketToken) => {
        setIsSubmitting(true);
        axios
            .post(`${API_BASE_URL}/ticket/promo-result/${freeTicketToken}`)
            .then(function (response) {
                if (response.status === 201) {
                    setIsSubmitting(false);
                    setPaymentSuccess(true);
                    setPurchasedSession(session);
                    setPurchasedTicket(response.data.data.ticket);
                } else {
                    const message = response.data.errors
                        ? response.data.errors[0].desc
                        : "Payment failed, Please try again";
                    setError(message);
                }
            })
            .catch((error) => {
                if (isLoggedIn && error?.response?.status === 401) {
                    window.location = "/";
                }

                setIsSubmitting(false);
                setPaymentSuccess(false);
                const message = error?.response?.data?.errors
                    ? error?.response?.data?.errors[0]?.desc
                    : error?.response?.data?.message;
                setError(message);
            });
    };

    return (
        <CheckoutComplimentaryContext.Provider
            value={{
                isSubmitting,
                paymentSuccess,
                error,
                checkoutComplementary,
                setPaymentSuccess,
            }}
        >
            {children}
        </CheckoutComplimentaryContext.Provider>
    );
};

export default CheckoutComplimentaryContextProvider;

