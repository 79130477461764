import React, { createContext, useState } from "react";
import axios from "axios";
import { API_BASE_URL, SITE_V3 } from "@config/config";
import Button from "@components/Ui/Button";

export const AuthContext = createContext({});

const AuthContextProvider = (props) => {
    const [userAuth, setUserAuth] = useState({
        user: null,
        token: "",
        isLoggedIn: null,
    });
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorMessagesStart, setErrorMessagesStart] = useState(null);
    const [isSignupSubmitting, setIsSignupSubmitting] = useState(false);
    const [isLoginSubmitting, setIsLoginSubmitting] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const [letsStartLoginError, setLetsStartLoginError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [tempAccountLogin, setTempAccountLogin] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const { user, token, isLoggedIn } = userAuth;
    const [loggedInByToken, setLoggedInByToken] = useState(false);
    const [memberId, setMemberId] = useState("");
    const [isVenueOptionsLoading, setIsVenueOptionsLoading] = useState(false);
    const [isMovieOptionsLoading, setIsMovieOptionsLoading] = useState(false);
    const [venueOptions, setVenueOptions] = useState([]);
    const [movieOptions, setMovieOptions] = useState([]);
    const [isReadytoCheckout, setIsReadyToCheckout] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [emailToken, setEmailToken] = useState(null);
    const [emailTokenExpired, setEmailTokenExpired] = useState(false);
    const [isEmailTokenExpiredLoading, setIsEmailTokenExpiredLoading] = useState(false);
    const [nowShowing, setNowShowing] = useState([]);
    const [comingSoon, setComingSoon] = useState([]);
    const [isMoviesLoading, setIsMoviesLoading] = useState(false);
    const [siteVersion, setSiteVersion] = useState(null);
    const [drawerState, setDrawerState] = useState({
        venuesDrawer: false,
        venueSelectorDrawer: false,
        experienceDrawer: false,
        loginSignUpDrawer: false,
        complimentaryCheckoutDrawer: false,
        creditCardCheckoutDrawer: false,
        fastAuthDrawer: false,
    });

    const [drawerContent, setDrawerContent] = useState(null);

    const openDrawer = (drawerName, content = null) => {
        setDrawerState((prev) => ({
            ...prev,
            [drawerName]: true,
        }));
        if (content) {
            setDrawerContent(content);
        }
    };


    const closeDrawer = (drawerName) => {
        setDrawerState((prev) => ({
            ...prev,
            [drawerName]: false,
        }));
        setDrawerContent(null);
    };
    
    const { children } = props;
    
    const login = ({ username, password } = {}) => {
        setIsLoginSubmitting(true);
        axios
            .post(`${API_BASE_URL}/user/login`, { username, password })
            .then(function(response) {
                setIsLoginSubmitting(false);
                setLoginError(null);
                setMovieOptions([]);
                setVenueOptions([]);
                setIsReadyToCheckout(true);
                setLoggedIn(true);

                setUserAuth((prevState) => ({
                    ...prevState,
                    user: response.data.data.user,
                    token: response.data.data.token,
                    isLoggedIn: true,
                }));

                return window.location = "/";
            })
            .catch(({ response }) => {
                const errorMessage =
                    response?.status === 403 && response?.data?.message
                        ? response?.data?.message
                        : "Unknown error occurred";
                const user = response?.data?.user;

                setIsLoginSubmitting(false);
                setIsReadyToCheckout(false);
                setSuccessMessage(null);

                if (response?.data?.email_verified === false) {
                    setLoginError(<>
                        {errorMessage} 
                        &nbsp;Click the link in the email we sent you to complete sign up. Still can&apos;t find the email, click&nbsp;
                        <Button 
                            additionalClasses="link link__button link__clickable" 
                            onClick={ () => 
                                response?.data?.password_created 
                                    ? resendAccountCreation(user?.email_token)
                                    : resendAccountCreation(user?.email_token)
                            }
                        >
                            here
                        </Button> to
                        resend.</>);
                } else {
                    setLoginError(errorMessage);
                }

                setUserAuth((prevState) => ({
                    ...prevState,
                    user: null,
                    token: "",
                    // isLoggedIn: false,
                }));
            });
    };

    const resendAccountCreation = (token) => {
        axios
            .get(`${API_BASE_URL}/user/account-creation-email/${token}`)
            .then((response) => {
                if (response?.data?.message) {
                    setLoginError(null);
                    setSuccessMessage(response.data.message);
                } else {
                    setSuccessMessage(null);
                    setLoginError("Error: No account creation mail was sent");
                    setErrorMessage("Error: No message received");
                }
            })
            .catch((error) => {
                setSuccessMessage(null);
                if (error.response && error.response.status === 500) {
                    setLoginError(error.response.data.error);
                    setErrorMessage(error.response.data.error);
                } else if (error.response && error.response.status === 400) { // if token is expired
                    setLoginError(error.response.data.message);
                    setErrorMessage(error.response.data.message);
                } else {
                    setLoginError("Unknown error occurred; please try again later.");
                    setErrorMessage("Unknown error occurred; please try again later.");
                }
            });
    };


    const createUserAccount = (data) => {
        setIsReadyToCheckout(false);
        axios
            .post(`${API_BASE_URL}/user/create-temp-account`, data)
            .then((response) => {
                setIsLoginSubmitting(false);
                setIsSignupSubmitting(false);
                setLoginError(null);
                setIsReadyToCheckout(true);
                
                if (siteVersion && siteVersion === SITE_V3) {
                    closeDrawer("fastAuthDrawer");
                }
                return setUserAuth((prevState) => ({
                    ...prevState,
                    user: response.data.data.user,
                    token: response.data.data.token,
                    isLoggedIn: true,
                }));
            })
            .catch((error) => {
                setIsSignupSubmitting(false);
                if (error.response.status === 500) {
                    setErrorMessages("Unknown error occurred");
                }
                if (error.response.status === 400) {
                    setErrorMessages(error.response.data.errors);
                } else {
                    setErrorMessages({
                        unknown_field: { unknown_error: "Unknown error occurred" },
                    });
                }
            });
    };

    const loginCheckout = ({ username, password } = {}) => {
        setIsLoginSubmitting(true);
        setIsReadyToCheckout(false);
        axios
            .post(`${API_BASE_URL}/user/login`, { username, password })
            .then(function(response) {
                setIsLoginSubmitting(false);
                setLoginError(null);
                setMovieOptions([]);
                setVenueOptions([]);
                setIsReadyToCheckout(true);
                setLoggedIn(true);

                if (siteVersion && siteVersion === SITE_V3) {
                    closeDrawer("loginSignUpDrawer");
                }

                return setUserAuth((prevState) => ({
                    ...prevState,
                    user: response.data.data.user,
                    token: response.data.data.token,
                    isLoggedIn: true,
                }));
            })
            .catch(({ response }) => {
                const errorMessage =
                    response?.status === 403 && response?.data?.message
                        ? response?.data?.message
                        : "Unknown error occurred";
                setIsLoginSubmitting(false);
                setIsReadyToCheckout(false);

                if (response?.data?.email_verified === false) {
                    setLoginError(<>
                        Please verify your email, click the link in the email we sent you to complete signup.
                        Still can&apos;t find the email, click&nbsp; 
                        <Button 
                            additionalClasses="link link__button link__clickable"
                            onClick={() => resendAccountCreation(response?.data?.user?.email_token)}
                        >
                            here
                        </Button> to resend.
                    </>);

                } else {
                    setLoginError(errorMessage);
                }
            });
    };

    const loginByToken = () => {
        axios
            .post(`${API_BASE_URL}/user/login-token`, {})
            .then((response) => {
                setIsReadyToCheckout(true);
                setLoggedInByToken(true);
                setUserAuth((prevState) => ({
                    ...prevState,
                    user: response.data.data.user,
                    token: response.data.data.token,
                    isLoggedIn: true,
                }));
            })
            .catch(({ response }) => {
                const errorMessage =
                    response?.status === 401 && response?.data?.message
                        ? response?.data?.message
                        : "Unknown error occurred";
                setLoginError(errorMessage);
                setUserAuth((prevState) => ({
                    ...prevState,
                    user: null,
                    token: null,
                    isLoggedIn: false,
                }));
            });
    };

    const loginMovieClub = ({ username, password, membershipId } = {}) => {
        setIsLoginSubmitting(true);
        axios
            .post(`${API_BASE_URL}/movie-club/login`, { username, password, membershipId })
            .then(function(response) {
                setIsLoginSubmitting(false);
                setLoginError(null);
                setUserAuth((prevState) => ({
                    ...prevState,
                    user: response.data.data.user,
                    token: response.data.data.token,
                    isLoggedIn: true,
                }));
                window.open(response.data.data.action_url, "_self");
            })
            .catch(({ response }) => {
                const errorMessage = (response?.status === 403 || response?.status === 400) && response?.data?.message ? response?.data?.message : "Unknown error occurred";
                setIsLoginSubmitting(false);
                setLoginError(errorMessage);
            });
    };

    const createMovieClubUserAccount = (data) => {
        axios
            .post(`${API_BASE_URL}/movie-club/signup`, data)
            .then((response) => {
                setIsLoginSubmitting(false);
                setIsSignupSubmitting(false);
                setLoginError(null);
                setUserAuth((prevState) => ({
                    ...prevState,
                    user: response.data.data.user,
                    token: response.data.data.token,
                    isLoggedIn: true,
                }));
                window.open(response.data.data.action_url, "_self");
            })
            .catch((error) => {
                setIsSignupSubmitting(false);
                if (error.response.status === 500) {
                    setErrorMessages("Unknown error occurred");
                }
                if (error.response.status === 400 || error.response.status === 403) {
                    setErrorMessages(error.response.data.errors);
                } else {
                    setErrorMessages({
                        unknown_field: { unknown_error: "Unknown error occurred" },
                    });
                }
            });
    };

    const existingUserMovieClubSignup = (data) => {
        axios
            .post(`${API_BASE_URL}/movie-club/purchase`, data)
            .then((response) => {
                window.open(response.data.data.action_url, "_self");
            })
            .catch((error) => {
                setIsSignupSubmitting(false);
                if (error.response.status === 500) {
                    setErrorMessages("Unknown error occurred");
                }
                if (error.response.status === 400 || error.response.status === 403) {
                    setErrorMessages(error.response.data.errors);
                } else {
                    setErrorMessages({
                        unknown_field: { unknown_error: "Unknown error occurred" },
                    });
                }
            });
    };

    const logout = () => {
        axios
            .post(`${API_BASE_URL}/user/logout`)
            .then(function() {
                setUserAuth(() => ({
                    user: null,
                    token: null,
                    isLoggedIn: false,
                }));
                setIsReadyToCheckout(false);
                window.location = "/";
            })
            .catch(() => {
                setUserAuth((prevState) => ({
                    ...prevState,
                    user: null,
                    token: null,
                    isLoggedIn: false,
                }));
            });
    };

    const updateUser = (user) => {
        setUserAuth((prevState) => ({
            ...prevState,
            user,
        }));
    };

    const fetchMovieOptions = () => {
        setIsMovieOptionsLoading(true);
        axios
            .get(`${API_BASE_URL}/movie/get-movies?limit=1000`)
            .then(function(response) {
                const movies = response.data.data.map((item) => ({
                    label: item.movie_title,
                    value: item.movie_title,
                }));
                setMovieOptions(movies);
                setIsMovieOptionsLoading(false);
            })
            .catch(({ response }) => {
                if (isLoggedIn && response?.status === 401) {
                    window.location = "/";
                }
            });
    };

    const fetchVenueOptions = () => {
        setIsVenueOptionsLoading(true);
        axios
            .get(`${API_BASE_URL}/venue/get-venues?limit=1000`)
            .then(function(response) {
                const venues = response.data.data.map((item) => ({
                    ...item,
                    label: item.name,
                    value: item.name,
                }));
                setVenueOptions(venues);
                setIsVenueOptionsLoading(false);
            })
            .catch(({ response }) => {
                if (isLoggedIn && response?.status === 401) {
                    window.location = "/";
                }
            });
    };

    const checkUserVerification = (token) => {
        setErrorMessage(null);
        setIsEmailTokenExpiredLoading(true);
        axios
            .get(`${API_BASE_URL}/user/get-user/${token}`)
            .then((response) => {
                const user = response?.data?.data?.user;
                const dateNow = new Date();

                if (user) {
                    if (user.sub_email_verified && user.verify_email_verified ) {
                        // User is already verified
                        setEmailToken(null);
                        setEmailTokenExpired(false);
                    } else if (user.sub_email_token ) {
                        if (new Date(user.sub_email_token_expires) >= dateNow) {
                            // User has email token that has NOT expired
                            setEmailToken(user?.sub_email_token);
                            setEmailTokenExpired(false);
                        } else {
                            // User has email token that IS expired
                            setEmailToken(user?.sub_email_token);
                            setEmailTokenExpired(true);
                        }
                    } else {
                        // User does not have a token
                        setEmailToken(null);
                        setEmailTokenExpired(true);
                    }
                } else {
                    // User was not returned
                    setEmailToken(null);
                    setEmailTokenExpired(true);
                }
                setIsEmailTokenExpiredLoading(false);
            })
            .catch((error) => {
                if (error.response && error.response.status === 500) {
                    setErrorMessage(error.response.data.error);
                } else {
                    setErrorMessage("Unknown error occurred; please try again later.");
                }
                setIsEmailTokenExpiredLoading(false);
            });
    };

    const addAccountMemberId = (data) => {
        setIsLoginSubmitting(true);
        axios
            .post(`${API_BASE_URL}/user/create-account-by-member-id`, data)
            .then(function(response) {

                setLetsStartLoginError(null);
                setMovieOptions([]);
                setVenueOptions([]);
                setIsReadyToCheckout(true);
                setLoggedIn(true);
                setIsLoginSubmitting(false);
                setUserAuth((prevState) => ({
                    ...prevState,
                    user: response.data.data.user,
                    token: response.data.data.token,
                    isLoggedIn: true,
                }));
                if (response.data.data.venue_name && response.data.data.venue_name !== "" && response.data.data.movie_url && response.data.data.movie_url !== "") {
                    window.location = `/movie/${  response.data.data.movie_url  }?venue_name=${  response.data.data.venue_name}`;
                }
                else
                {
                    window.location = "/";
                }
            })
            .catch((error) => {
                const errorMessages = error?.response?.data?.message;
                const user = error?.user;
                setIsLoginSubmitting(false);
                setIsReadyToCheckout(false);
                setSuccessMessage(null);
                setLetsStartLoginError(errorMessages);
                setUserAuth((prevState) => ({
                    ...prevState,
                    user: null,
                    token: "",
                    isLoggedIn: false,
                }));
            });
    };

    return (
        <AuthContext.Provider
            value={{
                errorMessage,
                fetchMovieOptions,
                fetchVenueOptions,
                isLoggedIn,
                isLoginSubmitting,
                login,
                loginCheckout,
                loginMovieClub,
                loginByToken,
                loginError,
                logout,
                loggedIn,
                movieOptions,
                processing,
                setErrorMessage,
                setLoginError,
                setProcessing,
                setSuccessMessage,
                successMessage,
                tempAccountLogin,
                setTempAccountLogin,
                token,
                updateUser,
                user,
                memberId,
                setMemberId,
                venueOptions,
                isVenueOptionsLoading,
                isMovieOptionsLoading,
                isReadytoCheckout,
                createUserAccount,
                createMovieClubUserAccount,
                existingUserMovieClubSignup,
                setIsReadyToCheckout,
                errorMessages,
                setErrorMessages,
                isSignupSubmitting,
                setIsSignupSubmitting,
                loggedInByToken,
                emailToken,
                setEmailToken,
                checkUserVerification,
                emailTokenExpired,
                isEmailTokenExpiredLoading,
                setUserAuth,
                addAccountMemberId,
                errorMessagesStart,
                setErrorMessagesStart,
                nowShowing,
                setNowShowing,
                comingSoon, 
                setComingSoon,
                setLetsStartLoginError,
                letsStartLoginError,
                siteVersion,
                setSiteVersion,
                resendAccountCreation,
                isMoviesLoading,
                setIsMoviesLoading,
                drawerState,
                setDrawerState,
                openDrawer,
                closeDrawer,
                drawerContent, 
                setDrawerContent
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
