import React, { useState } from "react";
import { Link } from "react-router-dom";
import useTickets from "@context/TicketsContext";
import BookingDetails from "@components/Bookings/BookingDetails";
import InlineMessage from "@components/InlineMessage";
import MoviePoster from "@components/MoviePoster";
import SessionDetails from "@components/SessionDetails";
import VoucherList from "@components/Tickets/VoucherList";
import Button from "@components/Ui/Button";
import Spinner from "@components/Ui/Spinner";
import VoucherLogo from "@components/VoucherManager/VoucherPurchases/VoucherLogo";
import "./style.scss";

const Ticket = ({ ticket }) => {
    const {
        id,
        error,
        errorMessage,
        processing,
        message,
        loading,
        reSendTicketEmail,
    } = useTickets();

    const defaultDisplayLimit = 8;
    const [emailSent, setEmailSent] = useState(false);
    const sendTicketEmail = (uuid) => {
        reSendTicketEmail(uuid);
        setEmailSent(!emailSent);
    };

    return (
        <>
            <div key={ticket.ticket_id} className="ticket-history__item">
                {ticket.ticket_id && ticket.ticket_type_id === null ? (
                    <div className="ticket-history__img">
                        <MoviePoster
                            movie={ticket}
                            size="small"
                            key={`ticket_img_${ticket.ticket_id}`}
                        />
                    </div>
                ) : (
                    <VoucherLogo item={ticket.items[0]} />
                )}

                <>
                    {ticket.ticket_id && ticket.ticket_type_id === null ? (
                        <div className="ticket-history__content">
                            <div className="ticket-history__title">
                                <Link
                                    className="ticket-history__link"
                                    key={ticket.ticket_id}
                                    to={{
                                        pathname: `/movie/${
                                            ticket.movie_id
                                        }/${ticket.movie_title.replace(/\s+/g, "-")}`,
                                    }}
                                >
                                    {ticket.movie_title}
                                </Link>
                            </div>
                            <div className="ticket-history__block">
                                <SessionDetails
                                    session={{
                                        session_date: ticket.sessionDate,
                                        venue_name: ticket.venue_name,
                                        venue_address: ticket.venue_address,
                                        venue_suburb: ticket.venue_suburb,
                                        venue_state: ticket.venue_state,
                                    }}
                                />
                            </div>
                            <div className="ticket-history__block">
                                <BookingDetails ticket={ticket} />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="ticket-history__content-wrapper">
                                <div className="ticket-history__block">
                                    <div className="ticket-history__text">
                                        {new Date(ticket.purchase_date).toLocaleDateString()}
                                    </div>
                                    <div className="ticket-history__text">
                                        {ticket.items[0].cinema_group_name}
                                    </div>
                                    <BookingDetails ticket={ticket} />
                                </div>
                            </div>
                        </>
                    )}
                </>


                <div className="ticket-history__vouchers-container">
                    <Button
                        additionalClasses="button button__primary ticket-history__btn"
                        onClick={() => sendTicketEmail(ticket.uuid)}
                        disabled={processing === true && ticket.ticket_id === id}
                    >
                        {loading === true && ticket.ticket_id === id ? (
                            <Spinner text="loading..." />
                        ) : (
                            <span>Resend Email</span>
                        )}
                    </Button>
                    {error && ticket.ticket_id === id && (
                        <InlineMessage message={errorMessage} messageType="error" />
                    )}
                    {emailSent === true && (
                        <InlineMessage message={message} messageType="success" />
                    )}

                    {ticket.vouchers.length > 0 ? (
                        <VoucherList
                            vouchers={ticket.vouchers}
                            defaultDisplayLimit={defaultDisplayLimit}
                        />
                    ) : ticket.qr_code_url ? (
                        <div className="ticket-history__code">
                            <img
                                className="img ticket-history__img"
                                alt="image of qr code"
                                src={ticket.qr_code_url}
                            />
                        </div>
                    ) : (
                        <div className="ticket-history__code">
                            <img
                                className="img ticket-history__img"
                                alt="image of bar code"
                                src={ticket.bar_code_url}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Ticket;
