import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { ApplicationContext } from "@context/ApplicationContext";
import LoginForm from "@components/Form/LoginForm";
import FormWrapper from "@components/Wrappers/FormWrapper";
import Layout from "@components/Wrappers/Layout";

const LoginPage = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;

    return (
        <Layout>
            <Helmet>
                <title>{ `Login to Your Account Profile | ${brand}` }</title>
                <meta name="keywords" content={ `Login to Your Account Profile | ${brand}` } />
                <meta name="description" content={ `Log into your ${brand} account to access
                your membership offers & benefits. Explore tickets to movie showtimes at your nearest
                regional cinema today!` } />
                <link rel="canonical" href={`${window.location.protocol }//${ window.location.host}/login`} />
            </Helmet>
            <FormWrapper pageTitle={`Log in to ${brand}`}>
                <LoginForm />
            </FormWrapper>
            <div className="login__signup">
                <span className="link__text">New to {brand}?</span>
                <Link className="link link__primary" to="/signup">
                    Sign up
                </Link>
            </div>
        </Layout>
    );
};

export default LoginPage;
