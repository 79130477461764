import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import toSeoUrl from "@functions/toSeoUrl";
import useViewport from "@hooks/useViewport";
import { ApplicationContext } from "@context/ApplicationContext";
import PlayIcon from "@components/Icons/PlayIcon";
import TicketIcon from "@components/Icons/TicketIcon";
import TrailerButton from "@components/TrailerButton";
import "./style.scss";

const NowShowingTile = ({ movie }) => {
    const { width } = useViewport();
    const blurbBreakpoint = 640;
    const { cdnUrl } = useContext(ApplicationContext);

    const [isActive, setIsActive] = useState(false);
    const fallbackImage = `${cdnUrl}asset/img/placeholder.jpeg`;

    const onImageError = (e) => {
        e.target.src = fallbackImage;
    };

    const handleClick = () => {
        setIsActive(!isActive);
    };

    return (
        <div className="now-showing-tile__item" >
            <Link
                className="now-showing-tile__image-wrapper"
                to={{ pathname: `/movie/${movie.movie_id}/${toSeoUrl(movie.movie_title,)}` }}
            >
                <img
                    className="now-showing-tile__image"
                    src={movie.movie_image || fallbackImage}
                    alt={movie.movie_title}
                    onError={onImageError}
                />
            </Link>

            <div className="now-showing-tile__content">
                <Link
                    className="now-showing-tile__title"
                    to={{ pathname: `/movie/${movie.movie_id}/${toSeoUrl(movie.movie_title,)}` }}
                >
                    {movie.movie_title}
                </Link>

                <div className="now-showing-tile__meta">
                    
                    {movie.rating ? (
                        <>
                            <span
                                className={`now-showing-tile__movie-rating now-showing-tile__movie-rating--${movie?.rating.replace(
                                        "+",
                                        "",
                                    )}`}
                                role="status"
                                aria-label="Movie Rating"
                                >
                                {movie?.rating}
                            </span>
                            <span> · {" "}</span>
                        </>
                        ): null}
                    {movie.running_time ? (
                        <span>{movie.running_time}mins · {" "}</span>
                        ): null}
                    {movie.release_year ? (
                        <span>{movie.release_year} · {" "}</span>
                        ): null}
                    {movie.genre_name ? (
                        <span>{movie.genre_name}</span>
                        ): null}
                   
                </div>

                { width >= blurbBreakpoint && (
                    <button 
                        className={`now-showing-tile__description ${isActive ? "active" : ""}`}
                        onClick={handleClick}
                    >
                        {movie.movie_blurb}
                    </button>
                )}

                <div className="now-showing-tile__actions">
                    <Link
                        className="now-showing-tile__trailer"
                        to={{ pathname: `/movie/${movie.movie_id}/${toSeoUrl(movie.movie_title,)}` }}
                    >
                        <TicketIcon className="now-showing-tile__icon-svg" />
                        <span className="now-showing-tile__trailer-text">
                            Book Now
                        </span>
                    </Link>

                    {movie.trailer_url ? (
                        <TrailerButton
                            extraClass="now-showing-tile__trailer"
                            type="mp4"
                            trailerLink={movie.trailer_url}
                        >
                            <PlayIcon className="now-showing-tile__icon-svg" />

                            <span className="now-showing-tile__trailer-text">
                                Trailer
                            </span>
                        </TrailerButton>
                    ) : null}

                </div>
            </div>
        </div>
    );
};

export default NowShowingTile;
