import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import flattenErrorMessages from "@functions/flattenErrorMessages";
import { AuthContext } from "@context/AuthContext";
import { ProfileContext } from "@context/ProfileContext";
import AlertMessage from "@components/AlertMessage";
import BrandName from "@components/BrandName";
import PasswordStrength from "@components/PasswordStrength";
import Button from "@components/Ui/Button";
import Loader from "@components/Ui/Loader";
import Spinner from "@components/Ui/Spinner";
import TextInputLabel from "@components/Ui/TextInputLabel";


const ResetForgottenPasswordForm = () => {
    const { passwordToken } = useParams();
    const {
        emailToken,
        errorMessage,
        successMessage,
        setEmailToken,
        checkUserVerification,
        emailTokenExpired,
        isEmailTokenExpiredLoading,
        resendAccountCreation
    } = useContext(AuthContext);

    const {
        errorMessages,
        processing,
        setErrorMessages,
        setProcessing,
        resetForgottenPassword,
        success
    } = useContext(ProfileContext);

    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");

    useEffect(() => {
        if (passwordToken) {
            checkUserVerification(passwordToken);
        }
    }, [passwordToken]);

    useEffect(() => {
        setEmailToken(emailToken);
    }, [emailToken]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);
        setErrorMessages({});
        const data = {
            password,
            password_repeat: passwordRepeat,
        };
        await resetForgottenPassword(passwordToken, data);
        await checkUserVerification(passwordToken);
    };

    const errorsFlattened = flattenErrorMessages(errorMessages);

    if (isEmailTokenExpiredLoading === true) {
        return (
            <Loader isLoading={isEmailTokenExpiredLoading} />
        );
    }
    
    const message = success
    ? "You have successfully reset your password."
    : "Your password has already been reset with this link. Please request another.";

    if (success || (!emailToken && errorsFlattened.length <= 0 && !emailTokenExpired)) {
        return <p className="paragraph">{message}</p>;
    }

    if (emailTokenExpired) {
        if (emailToken) {
            return (
                <>
                    <p className="paragraph">
                        This link has expired. Click&nbsp;
                        <Button 
                            additionalClasses="link link__button link__button--form-link link__clickable"
                            onClick={() => resendAccountCreation(emailToken)}
                        >
                            here
                        </Button> &nbsp;to complete email verification process.
                    </p>

                    {successMessage && (
                        <AlertMessage
                            message={successMessage}
                            type="success" />
                    )}

                    {errorMessage && (
                        <AlertMessage displayEmail={true} message={errorMessage} type="error" />
                    )}
                </>
            );
        }
        return (
            <>
                <p className="paragraph">
                    An email verification link could not be found. Please try requesting a new link through the log in page.
                </p>
            </>
        );
    }

    return (
        <>
            <form onSubmit={handleSubmit} className="form__content">
                <TextInputLabel
                    type="password"
                    name="password"
                    labelName="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    disabled={processing === true}
                    required={true}
                />

                <div className="form__range">
                    <PasswordStrength password={password} />
                </div>

                <TextInputLabel
                    type="password"
                    name="password_repeat"
                    labelName="Password Repeat"
                    onChange={(e) => setPasswordRepeat(e.target.value)}
                    value={passwordRepeat}
                    disabled={processing === true}
                    required={true}
                />
                <Button
                    additionalClasses="button button__primary">
                    {processing ? (
                        <Spinner text="loading..." />
                    ) : (
                        <span>Save</span>
                    )}
                </Button>
            </form>

            {errorsFlattened.length > 0 && (
                <AlertMessage
                    message="The following errors occurred:"
                    type="error"
                    messageList={errorsFlattened}
                />
            )}
        </>
    );
};

export default ResetForgottenPasswordForm;
