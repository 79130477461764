import React, { useContext } from "react";
import { SessionContext } from "@context/SessionContext";
import Loader from "@components/Ui/Loader";
import SearchResult from "./SearchResult";

const SearchResults = ({ items }) => {
    const { sessions, isLoadedSessions, isLoadingSessions } =
        useContext(SessionContext);

    if (isLoadingSessions === true) {
        return <Loader isLoading={isLoadingSessions} />;
    }


    return (
        <>
            {sessions.length > 0 && isLoadedSessions === true ? (
                <div className="search-result__list">
                    {items.map((item) => (
                        <SearchResult key={item.key} item={item} />
                    ))}
                </div>
            ) : (
                <div className="search-result__list">
                    <p className="paragraph">There are no sessions currently available</p>
                </div>
            )}
        </>
    );
};

export default SearchResults;
