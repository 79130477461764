import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "@context/AuthContext";
import { SearchContext } from "@context/SearchContext";
import SearchLensIcon from "@components/Icons/SearchLensIcon";
import SearchSelect from "@components/SearchSelect";
import Loader from "@components/Ui/Loader";
import "./style.scss";

const Venues = () => {
    const { closeDrawer } = useContext(AuthContext);
    const { participatingCinemas, setVenueName } = useContext(SearchContext);
    const [selectedVenue, setSelectedVenue] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (participatingCinemas && participatingCinemas.length > 0) {
            setLoading(false);
        }
    }, [participatingCinemas]);


    const searchOptions = participatingCinemas.map((item) => ({
        ...item,
        label: item.name,
        value: item.name,
    }));

    const filterOptions = (candidate, input) => {
        if (!candidate.value || typeof candidate.value !== "string") {
            return false;
        }

        const candidateStr = candidate.value.toLowerCase();
        const candidateTerms = candidateStr.split(" ");
        const searchStr = input.toLowerCase();

        return searchStr && searchStr.includes(" ")
            ? candidateStr.includes(searchStr)
            : searchStr
                ? candidateTerms.some((str) => str.startsWith(searchStr))
                : false;
    };

    const handleOnChangeVenue = (option, { action }) => {
        if (action === "clear") {
            setSelectedVenue(null);
            setVenueName("");
        } else if (action === "select-option") {
            setSelectedVenue(option);
            setVenueName(option.label);
            closeDrawer("venueSelectorDrawer");
        }
    };

    const customNoOptionsMessage = () => (
        <span className="venue-finder__no-results">
            We did not find any venues
        </span>
    );

    if (loading) {
        return <Loader isLoading={loading} />;
    }

    if (!participatingCinemas || participatingCinemas.length === 0) {
        return null;
    }

    return (
        <div className="venue-selector__container">
            <p className="venue-selector__title">Find a cinema</p>
            <div className="venue-selector__input-wrapper">
                <span className="venue-selector__search-icon">
                    <SearchLensIcon className="venue-selector__search-svg" />
                </span>
                <SearchSelect
                    placeholder="Enter your cinema"
                    aria-label="Enter your cinema"
                    value={selectedVenue}
                    options={searchOptions}
                    filterOption={filterOptions}
                    onChange={handleOnChangeVenue}
                    isClearable={true}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                    openMenuOnClick={false}
                    noOptionsMessage={customNoOptionsMessage}
                />
            </div>
        </div>
    );
};

export default Venues;
