import React, { createContext, useReducer } from "react";
import axios from "axios";
import { API_BASE_URL } from "@config/config";
import reducer from "@reducers/checkoutCreditCardReducer";


export const CheckoutCreditCardContext = createContext({});

const CheckoutCreditCardContextProvider = (props) => {
    const initialState = {
        isTermsChecked: false,
        isSaveCardChecked: false,
        isUseSavedCardChecked: false,
        isSubmitting: false,
        errors: [],
        name: "",
        number: "",
        member_id: "",
        expiryMonth: "",
        expiryYear: "",
        cvn: "",
        ewayRedirectUrl: "",
        ewayTransactionStatus: false,
        cardNumber: "",
        username: "",
        firstName: "",
        lastName: "",
        isTempLogin: false,
    };

    const [cardState, cardDispatch] = useReducer(reducer, initialState);
    const { children } = props;

    const processPaymentGateway = (e) => {
        e.preventDefault();
        cardDispatch({
            type: "is_submitting",
            isSubmitting: true,
        });

        /**
         *  eWAY var included globally via script tag in root template.
         *  Please see: https://api.ewaypayments.com/JSONP/v3/js
         */

        /* global eWAY */
        /* eslint no-undef: "error" */

        eWAY.process(document.getElementById("eway_payment_form"), {
            autoRedirect: false,
            onComplete(data) {
                console.log("eWAY.process.onComplete");
                cardDispatch({
                    type: "is_submitting",
                    isSubmitting: false,
                });
                cardDispatch({
                    type: "eway_redirect_url",
                    ewayRedirectUrl: data.RedirectUrl,
                });
            },
            onError (error) {
                console.error("eWAY Process Error:", error);
                cardDispatch({
                    type: "is_submitting",
                    isSubmitting: false,
                });
            },
            onTimeout() {
                cardDispatch({
                    type: "is_submitting",
                    isSubmitting: false,
                });
            },
        });
    };
    
    const fetchEwayToken = () => {
        axios
            .get(`${API_BASE_URL}/user/eway-token-customer`)
            .then(function (response) {
                if (response.data.data !== "") {
                    cardDispatch({
                        type: "credit_card_number",
                        cardNumber: response.data.data,
                    });
                    cardDispatch({
                        type: "is_use_saved_card_checked",
                        isUseSavedCardChecked: true,
                    });
                }
            });
    };

    const fetchPaymentResult = (
        accessCode,
        isSaveCardChecked,
        isUseSavedCardChecked,
        isTempLogin
    ) => {
        cardDispatch({
            type: "is_submitting",
            isSubmitting: true,
        });
        axios
            .post(`${API_BASE_URL}/ticket/payment-result/${accessCode}`, {
                isSaveCardChecked,
                isUseSavedCardChecked,
                isTempLogin,
            })
            .then(function (response) {
                cardDispatch({
                    type: "is_submitting",
                    isSubmitting: false,
                });
                cardDispatch({
                    type: "eway_transaction_status",
                    ewayTransactionStatus: response.data.data.transaction.status,
                });
            })
            .catch(({ response }) => {
                cardDispatch({
                    type: "is_submitting",
                    isSubmitting: false,
                });
                cardDispatch({
                    type: "errors",
                    errors: response?.data?.errors,
                });
            });
    };

    return (
        <CheckoutCreditCardContext.Provider
            value={{
                cardState,
                cardDispatch,
                fetchPaymentResult,
                processPaymentGateway,
                fetchEwayToken
            }}
        >
            {children}
        </CheckoutCreditCardContext.Provider>
    );
};

export default CheckoutCreditCardContextProvider;
