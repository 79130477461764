import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "@components/Modal";

const useModal = () => {
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState(false);
    const [modalClass, setModalClass] = useState(null);
    const [portal, setPortal] = useState(null);

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === "Escape") {
                event.preventDefault();
                closeModal();
            }
        };
        document.addEventListener("keydown", keyDownHandler);
        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, []);

    const closeModal = () => {
        setOpen(false);
        setContent(null);
    };

    const openModalWithContent = (content, modalClass) => {
        setContent(content);
        setModalClass(modalClass);
        setOpen(true);
    };

    useEffect(() => {
        setPortal(
            ReactDOM.createPortal(
                <Modal closeModal={closeModal} content={content} open={open} modalClass={modalClass} />,
                document.getElementById("modal-root")
            )
        );
    }, [content, open]);

    return { openModalWithContent, portal };
};

export default useModal;
