import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { SearchContext } from "@context/SearchContext";
import SearchSelect from "@components/SearchSelect";
import Loader from "@components/Ui/Loader";
import SearchLens from "@components/V3/Icons/SearchLens";
import "./style.scss";

const SearchBar = () => {
    const {
        setActiveSearchItem,
        fetchSearchOptions,
        isSearchOptionsLoading,
        searchOptions,
    } = useContext(SearchContext);

    useEffect(() => {
        if (searchOptions.length === 0 && isSearchOptionsLoading === false) {
            fetchSearchOptions();
        }
        return () => {
            document.body.classList.remove("select-focused");
        };
    }, []);

    const handleFocus = () => {
        document.body.classList.add("select-focused");
    };

    const handleBlur = () => {
        document.body.classList.remove("select-focused");
    };


    const handleOnChangeSearch = (option, { action }) => {
        if (action === "clear") {
            setActiveSearchItem("");
        } else if (action === "select-option") {
            setActiveSearchItem(option);
        }
    };


    const filterOptions = (candidate, input) => {
        const candidateStr = candidate.value.toLowerCase();
        const candidateTerms = candidateStr.split(" ");
        const searchStr = input.toLowerCase();

        return searchStr && searchStr.includes(" ")
            ? candidateStr.includes(searchStr)
            : searchStr
                ? candidateTerms.some((str) => str.startsWith(searchStr))
                : false;
    };

    const customNoOptionsMessage = () => (
        <>
            {(isSearchOptionsLoading) ? (
                <Loader isLoading={isSearchOptionsLoading} text="Searching" />
            ) : (
                <span className="search-bar__no-results">
                    We did not find any results
                </span>
            )}
        </>
    );

    const CustomOption = (props) => {
        const { data, innerRef, innerProps } = props;
        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Link to={data.url} className="search-bar__link dropdown-item react-select__option" ref={innerRef} {...innerProps}>
                {data.type === "movie" ? (
                    <div className="search-bar__item">
                        <div className="search-bar__image-wrapper">
                            <img src={data.image_url} className="search-bar__image" alt={data.name} />
                        </div>
                        <div className="search-bar__text-wrapper">
                            <div className="search-bar__title">{data.name}</div>
                            <div className="search-bar__text">{data.info_description}</div>
                        </div>
                    </div>
                ) : (
                    <div className="search-bar__item--cinema">
                        <div className="search-bar__title">{data.name}</div>
                        <div className="search-bar__text">{data.venue_description}</div>
                    </div>
                )}
            </Link>
        );
    };

    return (
        <div className="search-bar">
            <div className="search-bar__container">
                <span className="search-bar__icon">
                    <SearchLens className="svg-icon" />
                </span>
                <SearchSelect
                    className="search-bar"
                    placeholder="type in a movie or cinema"
                    aria-label="Select a search input"
                    options={searchOptions}
                    filterOption={filterOptions}
                    onChange={handleOnChangeSearch}
                    blurInputOnSelect={true}
                    closeMenuOnSelect={true}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    isClearable={true}
                    components={{
                        Option: CustomOption,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                    openMenuOnClick={false}
                    noOptionsMessage={customNoOptionsMessage}
                    value={setActiveSearchItem}
                />
            </div>
        </div>
    );
};

export default SearchBar;
