import React from "react";

const UserIcon = ({ className = "" }) => {
    return (

        <svg className={className} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 8.5C6.9 8.5 5.95833 8.10833 5.175 7.325C4.39167 6.54167 4 5.6 4 4.5C4 3.4 4.39167 2.45833 5.175 1.675C5.95833 0.891667 6.9 0.5 8 0.5C9.1 0.5 10.0417 0.891667 10.825 1.675C11.6083 2.45833 12 3.4 12 4.5C12 5.6 11.6083 6.54167 10.825 7.325C10.0417 8.10833 9.1 8.5 8 8.5ZM0 16.5V13.7C0 13.1333 0.145833 12.6125 0.4375 12.1375C0.729167 11.6625 1.11667 11.3 1.6 11.05C2.63333 10.5333 3.68333 10.1458 4.75 9.8875C5.81667 9.62917 6.9 9.5 8 9.5C9.1 9.5 10.1833 9.62917 11.25 9.8875C12.3167 10.1458 13.3667 10.5333 14.4 11.05C14.8833 11.3 15.2708 11.6625 15.5625 12.1375C15.8542 12.6125 16 13.1333 16 13.7V16.5H0Z"
                fill="currentColor" />
        </svg>
    );
};

export default UserIcon;



