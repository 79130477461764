import React, { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { API_BASE_URL } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import Loader from "@components/Ui/Loader";
import "./style.scss";

const Privacy = () => {
    const [privacyPolicy, setPrivacyPolicy] = useState("");
    const { subscriberGroupId } = useContext(ApplicationContext);
    const [{ data, loading, error }] = useAxios(`${API_BASE_URL}/subscriber-group-policy/${subscriberGroupId}`);

    useEffect(() => {
        if (data) {
            setPrivacyPolicy(data.data.privacy_policy);
        }
    }, [data]);


    if (loading) {
        return <Loader isLoading={loading} />;
    }


    return (
        <div className="privacy__wrapper">
            {privacyPolicy ? (
                // eslint-disable-next-line react/no-danger
                <div className="privacy__content" dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
            ) : (<p>Privacy policy content to follow</p>)}
        </div>

    );
};

export default Privacy;
