import React, { useContext } from "react";
import { SITE_V3 } from "@config/config";
import { AuthContext } from "@context/AuthContext";
import MovieClubSuccess from "@components/MovieClubSuccess";
import LayoutV3 from "@components/V3/Layout";
import Layout from "@components/Wrappers/Layout";

const MovieClubSuccessPage = () => {
    const { siteVersion } = useContext(AuthContext);
    const LayoutComponent = siteVersion && siteVersion === SITE_V3 ? LayoutV3 : Layout;

    return (
        <LayoutComponent>
            <MovieClubSuccess />
        </LayoutComponent>
    );
};

export default MovieClubSuccessPage;
