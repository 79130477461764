import React from "react";
import VoucherHistory from "@components/VoucherManager/VoucherPurchases/VoucherHistory";
import Layout from "@components/Wrappers/Layout";

const VoucherHistoryPage = () => {
    return (
        <Layout>
            <VoucherHistory />
        </Layout>
    );
};

export default VoucherHistoryPage;
