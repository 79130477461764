import React from "react";
import "./style.scss";

const TicketIcon2 = ({ className = "" }) => {
    return (
        <svg
            className={className}
            width="67"
            height="26"
            viewBox="0 0 67 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.00069394 0.000181198H30.9989L31 26H0.00108417L0 22.7759C1.34791 22.7753 2.43833 21.6368 2.43833 20.2332C2.43833 18.8296 1.34791 17.6911 0.00111019 17.6911V15.5434C1.34791 15.5434 2.43833 14.4043 2.43833 13.0007C2.43725 11.596 1.34791 10.4597 0.00111019 10.4586L0.00219436 8.31039C1.34685 8.31039 2.43726 7.17242 2.4383 5.76885C2.4383 4.36578 1.34685 3.22673 0.00108417 3.22781V0.00140381L0.00069394 0.000181198Z"
                fill="currentColor"
            />
            <path
                d="M10.0007 0.000181198H40.9989L41 26H10.0011L10 22.7759C11.3479 22.7753 12.4383 21.6368 12.4383 20.2332C12.4383 18.8296 11.3479 17.6911 10.0011 17.6911V15.5434C11.3479 15.5434 12.4383 14.4043 12.4383 13.0007C12.4372 11.596 11.3479 10.4597 10.0011 10.4586L10.0022 8.31039C11.3468 8.31039 12.4373 7.17242 12.4383 5.76885C12.4383 4.36578 11.3468 3.22673 10.0011 3.22781V0.00140381L10.0014 0L10.0007 0.000181198Z"
                fill="currentColor"
            />
            <path
                d="M66.9993 0.000181198H37.001L37 26H66.999L67 22.7759C65.6956 22.7753 64.6403 21.6368 64.6403 20.2332C64.6403 18.8296 65.6956 17.6911 66.9989 17.6911V15.5434C65.6956 15.5434 64.6403 14.4043 64.6403 13.0007C64.6414 11.596 65.6956 10.4597 66.9989 10.4586L66.9979 8.31039C65.6966 8.31039 64.6414 7.17242 64.6404 5.76885C64.6404 4.36578 65.6966 3.22673 66.999 3.22781V0.00140381L66.9987 0L66.9993 0.000181198Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default TicketIcon2;
