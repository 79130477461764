import React, { useContext, useEffect } from "react";
import useAxios from "axios-hooks";
import Slider from "react-slick";
import { API_BASE_URL } from "@config/config";
import toSeoUrl from "@functions/toSeoUrl";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import PlayIcon from "@components/Icons/PlayIcon";
import TrailerButton from "@components/TrailerButton";
import "./style.scss";


const FeatureFilm = () => {
    const { siteVersion } = useContext(AuthContext);
    const { subscriberGroupId, featureFilm, setFeatureFilm } = useContext(ApplicationContext);

    const [{ data, loading, error }] = useAxios(
        `${API_BASE_URL}/media/feature-films/${subscriberGroupId}`,
    );

    useEffect(() => {
        if (data) {
            const filmList = data.data?.feature_films;
            const trailerList = data.data?.feature_films;
            if (filmList) {
                filmList.map(film => {
                    return film.feature_url = film.feature_film_flag ?
                        `${film.ad_category_url}/${film.bo_movie_id}/${toSeoUrl(film.bo_movie_title)}` :
                        film.ad_category_url;
                });
                setFeatureFilm(filmList);
            }
            if (trailerList) {
                trailerList.map(trailer => {
                    return trailer.trailer_url = trailer.trailer_available ?
                        `${trailer.trailer_url}` : "";
                });
            }
        }
    }, [data]);

    const settings = {
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: true,
        speed: 600,
        initialSlide: 0,
        autoplaySpeed: 7000,
        cssEase: "ease-in-out",
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    if (!featureFilm) {
        return null;
    }

    if (featureFilm.length === 1) {
        return (
            <div className="feature-film__container">
                <div className="feature-film__list">
                    {featureFilm?.map((film, i) =>
                        <div key={`${film.id}-${i}`}  className="feature-film__wrapper">
                            <a className="feature-film__link" href={film?.feature_url} target="_blank" rel="noreferrer">
                                <picture>
                                    <source media="(min-width: 1025px)" srcSet={featureFilm[0]?.image_url} />
                                    <img className="feature-film__image" src={featureFilm[0]?.mobile_image_url} alt={featureFilm[0]?.description} />
                                </picture>
                            </a>
                            {film?.trailer_url && (
                                <TrailerButton extraClass="trailer-button" type="youtube" key={`${film.trailer_url}-${i}`} trailerLink={film.trailer_url}>
                                    <PlayIcon className="trailer-button__svg" />
                                    <span className="trailer-button__button-text">Official Trailer</span>
                                </TrailerButton>
                            )}
                        </div>,
                    )}
                </div>
            </div>
        );
    }
    return (
        <div className="feature-film__container">
            <div className="feature-film__list">
                <Slider {...settings}>
                    {featureFilm.map((film, i) =>
                        <div key={`${film.id}-${i}`} className="feature-film__wrapper">
                            <a
                                href={film?.feature_url}
                                target="_blank"
                                rel="noreferrer"
                                className="feature-film__link"
                            >
                                <picture>
                                    <source media="(min-width: 1025px)" srcSet={film?.image_url} />
                                    <img className="feature-film__image" src={film?.mobile_image_url} alt={film?.description} />
                                </picture>
                            </a>
                            {film?.trailer_url &&(
                                (<TrailerButton extraClass="trailer-button trailer-button--official-trailer" type="youtube" key={`${film.trailer_url}-${i}`} trailerLink={film.trailer_url}>
                                    <PlayIcon className="trailer-button__svg" />
                                    <span className="trailer-button__button-text">Official Trailer</span>
                                </TrailerButton>)
                            )}
                        </div>,
                    )}
                </Slider>
            </div>
        </div>
    );
};
export default FeatureFilm;
