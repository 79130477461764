import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "@context/AuthContext";
import { BookSessionContext } from "@context/BookSessionContext";
import AlertMessage from "@components/AlertMessage";
import Button from "@components/Ui/Button";
import Spinner from "@components/Ui/Spinner";
import TextInputLabel from "@components/Ui/TextInputLabel";
import "./style.scss";

const LoginForm = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const {
        loggedIn,
        login,
        isLoginSubmitting,
        loginError,
        setLoginError,
        setTempAccountLogin,
        successMessage,
        setSuccessMessage,
    } = useContext(AuthContext);
    const { setIsTempAccount } = useContext(BookSessionContext);
    const history = useHistory();
    const handleSubmit = (evt) => {
        evt.preventDefault();
        setLoginError(null);
        setIsTempAccount(false);
        setTempAccountLogin(true);
        login({ username, password });

    };
    const handleUsernameOnchange = (e) => {
        setUsername(e.target.value);
        setLoginError(null);
    };
    const handlePasswordOnchange = (e) => {
        setPassword(e.target.value);
        setLoginError(null);
    };


    useEffect(() => {
        setLoginError(null);
        setSuccessMessage(null);
        return () => {
            setLoginError(null);
            setSuccessMessage(null);
        };
    }, []);


    return (
        <>
            <form onSubmit={handleSubmit} className="form__content">
                <TextInputLabel
                    type="email"
                    name="email"
                    labelName="Email"
                    required={true}
                    value={username}
                    onChange={handleUsernameOnchange}
                />
                <TextInputLabel
                    type="password"
                    name="password"
                    labelName="Password"
                    autoComplete="true"
                    required={true}
                    value={password}
                    onChange={handlePasswordOnchange}
                />
                <Link className="link link__primary" to="/request-password-reset">
                    Forgot password?
                </Link>
                <Button additionalClasses="button button__primary">
                    {isLoginSubmitting ? (
                        <Spinner text="loading..." />
                    ) : (
                        <span>Login</span>
                    )}
                </Button>
            </form>

            {successMessage && (
                <AlertMessage
                    message={successMessage}
                    type="success" />
            )}

            {loginError && (
                <AlertMessage displayEmail={true} message={loginError} type="error" />
            )}
        </>
    );
};

export default LoginForm;

