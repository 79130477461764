import React from "react";


const TicketClarificationIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="ticket-clarification-icon">
            <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37256 24 0 18.6274 0 12C0 5.37256 5.37256 0 12 0C18.6274 0 24 5.37256 24 12ZM13.0646 4.65555C12.0511 4.65555 11.2296 5.4535 11.2296 6.43789C11.2296 7.42222 12.0511 8.22024 13.0646 8.22024C14.0781 8.22024 14.8996 7.42229 14.8996 6.43789C14.8996 5.4535 14.0781 4.65555 13.0646 4.65555ZM14.4782 18.6508L14.6713 17.8255C13.3192 18.1943 12.4412 18.2469 12.4763 16.9299C12.5115 15.6129 13.8285 12.5399 13.688 11.3634C13.5475 10.1869 12.6168 9.73033 11.493 9.62497C10.3691 9.51961 8.61312 10.3625 8.61312 10.3625L8.4024 11.2054C8.4024 11.2054 9.13993 10.8015 9.96525 10.9771C10.7906 11.1527 10.615 12.1009 10.5447 12.417C10.4745 12.7331 9.49113 15.9466 9.38577 16.8246C9.28041 17.7026 9.47357 18.9318 11.0364 19.2654C12.5993 19.5991 14.4782 18.6508 14.4782 18.6508Z" fill="#30C0FF"/>
        </svg>
    );
};

export default TicketClarificationIcon;
