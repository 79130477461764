import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";

export const VoucherManagerContext = createContext({});

const VoucherManagerContextProvider = ({ children }) => {
    const { whiteLabel } = useContext(ApplicationContext);
    const [cinemaGroups, setCinemaGroups] = useState(null);
    const [ticketClasses, setTicketClasses] = useState(null);
    const [ticketTypes, setTicketTypes] = useState(null);
    const [selectedCinemaGroup, setSelectedCinemaGroup] = useState(null);
    const [selectedTicketClass, setSelectedTicketClass] = useState(null);
    const [selectedTicketType, setSelectedTicketType] = useState(null);
    const [isVoucherBucketSearchLoading, setIsVoucherBucketSearchLoading] = useState(false);
    const [initialSearchVouchers, setInitialSearchVouchers] = useState(null);
    const [vouchers, setVouchers] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [voucherQuantity, setVoucherQuantity] = useState(0);


    const uniqueCinemaGroups = useMemo(() => {
        if (!vouchers) return [];
        const uniqueCinemas = new Map();
        vouchers.forEach(({ cinema_group: { name } }) => {
            uniqueCinemas.set(name, { label: name, value: name });
        });
        return [...uniqueCinemas.values()];
    }, [vouchers]);

    const uniqueTicketClasses = useMemo(() => {
        if (!vouchers) return [];
        const uniqueClasses = new Map();
        vouchers.forEach(({ voucher_time_periods: { ticket_clas } }) => {
            if (ticket_clas?.name) {
                uniqueClasses.set(ticket_clas.name, {
                    label: ticket_clas.name,
                    value: ticket_clas.name,
                });
            }
        });
        return [...uniqueClasses.values()];
    }, [vouchers]);

    const uniqueTicketTypes = useMemo(() => {
        if (!vouchers) return [];
        const uniqueTypes = new Map();
        vouchers.forEach(({ voucher_time_periods: { ticket_type } }) => {
            if (ticket_type?.name) {
                uniqueTypes.set(ticket_type.name, {
                    label: ticket_type.name,
                    value: ticket_type.name,
                });
            }
        });
        return [...uniqueTypes.values()];
    }, [vouchers]);


    useEffect(() => {
        setCinemaGroups(uniqueCinemaGroups);
        setTicketClasses(uniqueTicketClasses);
        setTicketTypes(uniqueTicketTypes);
    }, [uniqueCinemaGroups, uniqueTicketClasses, uniqueTicketTypes]);

    // Filtering vouchers based on selected options
    useEffect(() => {
        if (!initialSearchVouchers) return;

        let filteredVouchers = [...initialSearchVouchers];
        if (selectedCinemaGroup) {
            filteredVouchers = filteredVouchers.filter(({ cinema_group }) =>
                cinema_group.name === selectedCinemaGroup,
            );
        }
        if (selectedTicketClass) {
            filteredVouchers = filteredVouchers.filter(
                ({ ticket_clas }) => ticket_clas?.name === selectedTicketClass || !ticket_clas,
            );
        }
        if (selectedTicketType) {
            filteredVouchers = filteredVouchers.filter(
                ({ ticket_type }) => ticket_type?.name === selectedTicketType || !ticket_type,
            );
        }

        setVouchers(filteredVouchers);
    }, [selectedCinemaGroup, selectedTicketClass, selectedTicketType, initialSearchVouchers]);


    const fetchVoucherBucketSearch = useCallback(async () => {
        setIsVoucherBucketSearchLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/voucher-manager/voucher-search`);
            const voucherData = response.data.data.voucher_buckets.map((item) => {
                const { cinema_group, ticket_type, ticket_clas, voucher_code_bucket, voucher_category } = item.voucher_time_periods[0];
                return {
                    id: item.slug,
                    slug: item.slug,
                    cinema_group,
                    ticket_type,
                    ticket_clas,
                    voucher_category,
                    description: voucher_code_bucket.short_description,
                    price_cents: whiteLabel?.subscriber_group.cashback_flag
                        ? item.voucher_time_periods[0].rrp_price_cents
                        : item.voucher_time_periods[0].price_cents,
                    rrp_price_cents: item.voucher_time_periods[0].rrp_price_cents,
                    voucher_time_periods: item.voucher_time_periods[0],
                    voucher_code_bucket,
                    tile_class: voucher_code_bucket.name,
                };
            });

            setInitialSearchVouchers(voucherData);
            setVouchers(voucherData);
        } catch (error) {
            setInitialSearchVouchers(null);
            setVouchers(null);
            console.error(error);
        } finally {
            setIsVoucherBucketSearchLoading(false);
        }
    }, [whiteLabel]);

    return (
        <VoucherManagerContext.Provider
            value={{
                selectedCinemaGroup,
                setSelectedCinemaGroup,
                cinemaGroups,
                ticketClasses,
                ticketTypes,
                fetchVoucherBucketSearch,
                isVoucherBucketSearchLoading,
                vouchers,
                selectedTicketType,
                setSelectedTicketType,
                selectedTicketClass,
                setSelectedTicketClass,
                selectedItems,
                setSelectedItems,
                voucherQuantity,
                setVoucherQuantity,
            }}
        >
            {children}
        </VoucherManagerContext.Provider>
    );
};

export default VoucherManagerContextProvider;
