import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ACCOUNT_TYPE_NORMAL, TYPE_VOUCHER_ADULT } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { BookSessionContext } from "@context/BookSessionContext";
import { CheckoutCreditCardContext } from "@context/CheckoutCreditCardContext";
import { SessionContext } from "@context/SessionContext";
import { UserContext } from "@context/UserContext";
import AlertMessage from "@components/AlertMessage";
import BookedTicketWarning from "@components/Bookings/BookedTicketWarning";
import BookingTotal from "@components/Bookings/BookingTotal";
import CheckoutComplimentary from "@components/CheckoutComplimentary";
import CheckoutCreditCard from "@components/CheckoutCreditCard";
import MoviePoster from "@components/MoviePoster";
import PromoCodeInput from "@components/PromoCodeInput";
import PurchasableItems from "@components/PurchasableItems";
import RedemptionDetails from "@components/RedemptionDetails";
import ReferralCodeInput from "@components/ReferralCodeInput";
import SessionDetails from "@components/SessionDetails";
import TicketClarification from "@components/Tickets/TicketClarification";
import Button from "@components/Ui/Button";
import Loader from "@components/Ui/Loader";
import { isMessageRequired } from "@utils/ticketUtils";
import "./style.scss";

const BookSession = () => {
    const { id } = useParams();

    const {
        isTempAccount,
        bookingSessionId,
        uuid,
        setBookingSessionId,
        promo,
        setPromo,
        checkout,
        setCheckout,
        setTicketQuantity,
        selectedItems,
        setSelectedItems,
        promoCode,
        setPromoCode,
        promoCodeSuccess,
        setPromoCodeSuccess,
        promoCodeMessage,
        error,
        setError,
        checkoutBookingSession,
        setPromoCodeMessage,
        ewayAccessCode,
        ewayFormActionUrl,
        isSubmitting,
        success,
        referralCode,
        setReferralCode,
        referralSuccess,
        setReferralSuccess,

    } = useContext(BookSessionContext);

    const {
        session,
        setSession,
        fetchPurchasable,
        isLoadingSession,
        purchasable,
    } = useContext(SessionContext);

    const {
        bookedTicketForSession,
        fetchBookedTicketForSession,
        setBookedTicketForSession,
        fetchApplyPromoAvailability,
        promoDetails,
        isAdvancedPromo,
        setIsAdvancedPromo,
        setIsRegularPromo,
        isRegularPromo,
        reUseLimitReached,
        setReuseLimitReached,
    } = useContext(UserContext);
    const { promoUniqueCode, redemptionCount, redemptionLimit, promoTicketClasses, promoCategory, cap_purchases } = promoDetails;
    const { cardState } = useContext(CheckoutCreditCardContext);
    const { token, user, isLoggedIn, isReadytoCheckout } = useContext(AuthContext);
    const [showCheckout, setShowCheckout] = useState(false);
    const { whiteLabel } = useContext(ApplicationContext);
    const redemptionCountLeft = redemptionLimit - redemptionCount;

    useEffect(() => {
        setIsRegularPromo(false);
        setIsAdvancedPromo(false);
        setPromoCodeSuccess(null);
        setReferralSuccess(null);
        setPromo(null);
        setPromoCodeMessage("");
        setPromoCode("");
    }, []);

    useEffect(() => {
        // Check if user.id is available before calling fetchApplyPromoAvailability
        if (user?.id) {
            fetchApplyPromoAvailability();
            if (cap_purchases === 1 && redemptionCountLeft === 0) {
                setReuseLimitReached(true);
            }
        }
    }, [user?.id]);


    useEffect(() => {
        handleApplyPromo();
    }, [session, promoDetails, promoUniqueCode]);


    useEffect(() => {
        if (isLoggedIn === true && isReadytoCheckout && totalTicketQuantity > 0) {
            handleFastLogIn();
        }
    }, [isLoggedIn, isReadytoCheckout]);

    useEffect(() => {
        fetchPurchasable(id);
        if (token) {
            fetchBookedTicketForSession(id);
        }
        return () => {
            setBookingSessionId(null);
            setSession(null);
            setError(null);
            setSelectedItems([]);
            setBookedTicketForSession([]);
        };
    }, [id]);

    useEffect(() => {
        setError(null);
        // setPromo(null);
        setPromoCodeMessage("");
        redemptionLimitReached(totalTicketQuantity);
    }, [selectedItems]);

    const redemptionLimitReached = (quantity) => {
        if (cap_purchases === 1 && quantity > redemptionCountLeft || cap_purchases === 1 && redemptionCountLeft === 0) {
            setReuseLimitReached(true);
        } else {
            setReuseLimitReached(false);
        }
    };

    const checkoutText = promoCategory ? "Continue" : "Checkout";
    const standardPromoCheckout = checkout ? "Update Tickets" : "Checkout";
    const addTicketButtonText = checkout ? "Update Tickets" : checkoutText;
    const promoCheckoutMessage = `Click '${checkoutText}' to proceed with purchase`;
    const totalTicketQuantity = selectedItems.reduce((acc, cur) => {
        return cur.type === "ticket" ? acc + cur.quantity : acc;
    }, 0);

    const handleApplyPromo = () => {
        if (promoTicketClasses?.includes(Number(session?.ticket_class_id))) {
            setIsAdvancedPromo(true);
            setIsRegularPromo(false);
            setPromoCode(promoUniqueCode);
            setPromoCodeMessage("");
            setReferralCode(referralCode);
        }
    };

    const handleFastLogIn = () => {
        fetchApplyPromoAvailability();
        handleApplyPromo();
        handleCheckout();
    };

    const handleCheckout = () => {
        if (totalTicketQuantity === 0) {
            setPromoCodeMessage("");
            setError("Please enter the quantity of tickets needed");
        } else {
            checkoutBookingSession(totalTicketQuantity, "session");
        }
    };

    useEffect(() => {
        // Calculate the total value of selected items
        const totalValue = selectedItems.reduce((total, item) => {
            const itemValue = item.price_cents * item.quantity;
            return total + itemValue;
        }, 0);
        setShowCheckout(totalValue - (promo?.discount || 0) > 0);
    }, [promo, selectedItems]);

    const handleGuestCheckout = (e) => {
        e.preventDefault();
        if (totalTicketQuantity === 0) {
            setPromoCodeMessage("");
            setError("Please enter the quantity of tickets needed");
        } else {
            checkoutBookingSession(totalTicketQuantity, "session");
        }
    };

    const purchasableItemKey = (item) => {
        return item.id && item.ticket_type_id
            ? `ticket-${item.id}-${item.ticket_type_id}`
            : `candy-bar-${item.bucket_id}`;
    };

    const handleChangeItemQuantity = (selection, idKey, quantity, isSession) => {
        setCheckout(false);
        const type = isSession ? "ticket" : "candy-bar";
        const newSelection = [...selection];
        const index = newSelection.findIndex((item) => item.key === idKey);
        setError("");

        if (index === -1) {
            const choice = purchasable.find(
                (item) => idKey === purchasableItemKey(item),
            );
            if (isSession) {
                newSelection.push({
                    key: idKey,
                    type,
                    promo: false,
                    session_id: choice.id,
                    session_inventory_id: choice.session_inventory_id,
                    price_cents: choice.ticket_price_cents,
                    rrp_pricing_cents: choice.rrp_pricing_cents,
                    saving: choice.saving,
                    quantity: 1,
                });
                setTicketQuantity(1);
            } else {
                newSelection.push({
                    key: idKey,
                    type,
                    promo: false,
                    bucket_id: choice.bucket_id,
                    candy_bar: choice.candy_bar,
                    candy_bar_description: choice.candy_bar_description,
                    price_cents: choice.candy_bar_price,
                    candy_bar_rrpPricing: choice.candy_bar_rrpPricing,
                    rrp_pricing_cents: choice.rrp_pricing_cents,
                    saving: choice.saving,
                    quantity: 1,
                });
            }
        } else {
            if (newSelection[index] && quantity > 10 && quantity >= newSelection[index].quantity && !isSession) {
                setError("The maximum quantity of candy bars you can purchase is 10");
                return false;
            }
            newSelection[index].quantity = quantity;

            if (isSession) {
                setTicketQuantity(quantity);
            }
        }

        setSelectedItems(newSelection);
    };

    if (isLoadingSession || !session) {
        return <Loader isLoading={isLoadingSession} />;
    }

    const movie = {
        movie_id: session?.movie_id,
        movie_image: session?.movie_image,
        movie_title: session?.movie_title,
    };

    const paidTicketQuantity =
        totalTicketQuantity >= promo?.num_free_tickets
            ? totalTicketQuantity - promo?.num_free_tickets
            : totalTicketQuantity;


    const hasCandyBarItems = selectedItems.some(item => item.type === "candy-bar" && item.quantity > 0);

    return (
        <>
            <div className="booking__container">
                <div className="booking__image">
                    <MoviePoster key={1} movie={movie} size="large" />
                </div>
                <div className="booking__info">
                    <SessionDetails session={session} />

                    {isMessageRequired(session) && (
                        <AlertMessage
                            message={isMessageRequired(session)}
                            type="error"
                        />
                    )}
                    
                    {reUseLimitReached === true && (
                        <AlertMessage
                            message={`Only ${redemptionLimit} voucher${redemptionLimit === 1 ? "" : "s"} can be purchased each calendar month.`}
                            type="error"
                        />
                    )}

                    
                    {(redemptionCount <= redemptionLimit) ? (
                        <>
                            {isLoggedIn && user.account_type_id === ACCOUNT_TYPE_NORMAL && isAdvancedPromo && (
                                <RedemptionDetails
                                    redemptionCount={redemptionCount}
                                    redemptionLimit={redemptionLimit}
                                    brandName={whiteLabel?.brand_name}
                                />
                            )}
                        </>
                    ) : null}

                    {bookedTicketForSession.length > 0 && <BookedTicketWarning />}
                    {session.session_inventory_id == null ? (
                        <div>
                            Sorry there is no inventory available to purchase for this
                            session.
                        </div>
                    ) : (
                        <div className="booking__inner">
                            <div className="booking__inputs">
                                <PurchasableItems
                                    items={purchasable}
                                    selectedItems={selectedItems}
                                    setQuantity={handleChangeItemQuantity}
                                    purchasableItemKey={purchasableItemKey}
                                    promo={promo}
                                />
                                <BookingTotal items={selectedItems} promo={promo} />

                                {whiteLabel?.referral_code_flag && (
                                    <ReferralCodeInput
                                        referralCode={referralCode}
                                        setReferralCode={setReferralCode}
                                        success={referralSuccess}
                                    />
                                )}

                                <div className={`form__group-wrapper ${!isAdvancedPromo ? "form__group-wrapper--row" : ""}`}>
                                    {!isAdvancedPromo && (
                                        <PromoCodeInput
                                            promoCode={promoCode}
                                            setPromoCode={setPromoCode}
                                            success={promoCodeSuccess}
                                            promoUniqueCode={promoUniqueCode}
                                        />
                                    )}

                                    {isLoggedIn && (redemptionCount < redemptionLimit) && isAdvancedPromo ? (
                                        <Button
                                            additionalClasses="button button__secondary button--right"
                                            onClick={handleCheckout}
                                            disabled={
                                                cardState.isSubmitting || reUseLimitReached === true
                                                    ? "disabled"
                                                    : ""
                                            }
                                        >
                                            {addTicketButtonText}
                                        </Button>
                                    ) : (
                                        <Button
                                            additionalClasses="button button__secondary button--right"
                                            onClick={handleGuestCheckout}
                                            disabled={
                                                cardState.isSubmitting || reUseLimitReached === true
                                                    ? "disabled"
                                                    : ""
                                            }
                                        > {standardPromoCheckout}
                                        </Button>
                                    )}

                                </div>

                                {promoUniqueCode && isAdvancedPromo && !success && totalTicketQuantity > 0 && (
                                    <div className="form__group-wrapper">
                                        <div className="booking__promo-code-msg booking__promo-code-msg__success">
                                            {promoCheckoutMessage}
                                        </div>
                                    </div>
                                )}
                                {success && <div className="booking__promo-code-msg booking__promo-code-msg__success">
                                    {promoCodeMessage}
                                </div>}
                                <div className="form__group-wrapper">
                                    {error && <AlertMessage message={error} type="error" />}
                                </div>
                                <div className="form__group-wrapper">
                                    {session?.ticket_type_id === TYPE_VOUCHER_ADULT && (
                                        <TicketClarification />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="booking__checkout">
                    {isSubmitting && (
                        <Loader isLoading={isSubmitting} />
                    )}
                    {checkout && (
                        <>
                            {promo?.free_ticket_token &&
                            paidTicketQuantity === 0 &&
                            hasCandyBarItems === false &&
                            showCheckout === false &&
                            isLoggedIn &&
                            user.account_type_id === ACCOUNT_TYPE_NORMAL ? (
                                <CheckoutComplimentary
                                    uuid={uuid}
                                    bookingSessionId={bookingSessionId}
                                    freeTicketToken={promo.free_ticket_token}
                                />
                            ) : (
                                <>
                                    {reUseLimitReached === false ? (
                                        <CheckoutCreditCard
                                            isTempAccount={isTempAccount}
                                            uuid={uuid}
                                            ewayAccessCode={ewayAccessCode}
                                            ewayFormActionUrl={ewayFormActionUrl}
                                        />
                                    ) : null}
                                </>

                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default BookSession;
