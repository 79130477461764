import React from "react";
import "./style.scss";

const UserIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="41.973" height="41.973" viewBox="0 0 41.973 41.973" className="user-icon">
            <defs>
            </defs>
            <g transform="translate(1 1)">
                <circle className="outer" cx="19.987" cy="19.987" r="19.987"/>
                <g transform="translate(10.118 8.03)">
                    <circle className="inner" cx="7.286" cy="7.286" r="7.286" transform="translate(2.582)"/>
                    <path className="inner"
                        d="M579.15,365.507l-.6-2.69a6.224,6.224,0,0,1,6.074-7.577h7.284a6.225,6.225,0,0,1,6.075,7.577l-.6,2.69"
                        transform="translate(-578.4 -336.343)"/>
                </g>
            </g>
        </svg>
    );
};

export default UserIcon;
