import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PRODUCT_TYPE_MOVIE_REWARDS, PRODUCT_TYPE_VOUCHER_MANAGER, SITE_V3, TYPE_SEASONAL_VOUCHER } from "@config/config";
import usePurchaseTracking from "@hooks/usePurchaseTracking";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { CheckoutComplimentaryContext } from "@context/CheckoutComplimentaryContext";
import { TicketContext } from "@context/TicketContext";
import AlertMessage from "@components/AlertMessage";
import ConfirmationMessage from "@components/ConfirmationMessage";
import FeatureFilm from "@components/FeatureFilm";
import Vouchercon from "@components/Icons/VoucherIcon";
import Loader from "@components/Ui/Loader";
import LayoutV3 from "@components/V3/Layout";
import Layout from "@components/Wrappers/Layout";

const SuccessPage = () => {
    const { uuid } = useParams();
    const { siteVersion } = useContext(AuthContext);
    const { productTypeId } = useContext(ApplicationContext);
    const { setPaymentSuccess } = useContext(CheckoutComplimentaryContext);

    const {
        isLoading,
        errorMessage,
        fetchTicket,
        purchasedTicket,
        setPurchasedTicket,
        purchasedSession,
        setPurchasedSession,
        tempAccountCreation,
        setTempAccountCreation,
        purchasedType,
    } = useContext(TicketContext);
    

    useEffect(() => {
        fetchTicket(uuid);
        
        return () => {
            setPurchasedTicket(null);
            setPurchasedSession(null);
            setTempAccountCreation(false);
            setPaymentSuccess(false);
        };
    }, [uuid]);

    // Google Analytics tracking for purchases
    usePurchaseTracking(purchasedTicket, purchasedSession);

    const LayoutComponent = siteVersion && siteVersion === SITE_V3 ? LayoutV3 : Layout;

    if (isLoading === true) {
        return <Loader isLoading={isLoading} />;
    }

    return (
        <LayoutComponent>
            <FeatureFilm />
            {(productTypeId === PRODUCT_TYPE_MOVIE_REWARDS) ? (
                <>
                    {(purchasedTicket && purchasedSession && purchasedType !==  TYPE_SEASONAL_VOUCHER) ? (
                        <ConfirmationMessage
                            purchasedTicket={purchasedTicket}
                            purchasedSession={purchasedSession}
                            tempAccountCreation={tempAccountCreation}
                        />
                    ):(
                        <div className="voucher-search__success">
                            <Vouchercon />
                            <h2 className="voucher-search__success-title">Thank you and enjoy your movie :)</h2>
                            <h3 className="voucher-search__success-subtitle">Your email voucher has now been sent</h3>
                        </div>
                    )}
                </>
            ): null}
            {(productTypeId === PRODUCT_TYPE_VOUCHER_MANAGER) ? (
                <div className="voucher-search__success">
                    <Vouchercon />
                    <h2 className="voucher-search__success-title">Thank you and enjoy your movie :)</h2>
                    <h3 className="voucher-search__success-subtitle">Your email voucher has now been sent</h3>
                </div>

            ): null}
            {errorMessage ? (
                <AlertMessage
                    type="error"
                    message="Error Occurred"
                    messageList={errorMessage} />
            ): null}
        </LayoutComponent>
    );
};

export default SuccessPage;
