import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { PRODUCT_TYPE_MOVIE_REWARDS } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import "./style.scss";

const BookedTicketItems = ({ ticket }) => {
    const { user } = useContext(AuthContext);
    const { productTypeId } = useContext(ApplicationContext);
    const { total_price_cents } = ticket;

    return (
        <>
            {ticket?.items?.length > 0 && ticket.items.map((item, i) => {
                const priceElements = [];
                if (item.quantity > 0) {
                    const basePrice = (typeof item.rrp_pricing_cents !== "undefined" && item.rrp_pricing_cents > 0 && user?.cashback_flag !== "1")
                        ? (item.rrp_pricing_cents / 100) * item.quantity
                        : (productTypeId === PRODUCT_TYPE_MOVIE_REWARDS)
                            ? (item.price_cents / 100) * item.quantity
                            : null;

                    if (basePrice !== null) {
                        priceElements.push(
                            <span key={`${item.id}-base`} className="booked-ticket-items__price">
                                ${basePrice.toFixed(2)}
                            </span>
                        );
                    }
                    if (item.promo_discount_cents > 0) {
                        priceElements.push(
                            <span key={`${item.id}-promo`} className="booked-ticket-items__price booked-ticket-items__price--promo-price">
                                ${(total_price_cents / 100).toFixed(2)}
                            </span>
                        );
                    }
                }
                return (
                    <Fragment key={item.id}>
                        {item.quantity > 0 ? (
                            <div key={`${item.id}`} className="booked-ticket-items">
                                <div className="booked-ticket-items__item">
                                    <span className="booked-ticket-items__item-name">{item.quantity} x {item.name}</span>
                                    {priceElements.map((element, index) =>
                                        React.cloneElement(element, {
                                            className: `${element.props.className} ${index !== priceElements.length - 1 ? "booked-ticket-items__price--savings" : ""}`
                                        })
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </Fragment>
                );
            })}
        </>
    );
};

BookedTicketItems.propTypes = {
    ticket: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
                price_cents: PropTypes.number.isRequired,
            }),
        ).isRequired,
    }),
};

export default BookedTicketItems;
