import { useEffect } from "react";

const usePurchaseTracking = (purchasedTicket=null, purchasedSession=null) => {

    useEffect(() => {
        if (purchasedTicket && purchasedSession) {
            const gaEvent = {
                event: "purchase",
                ecommerce: {
                    currency: "AUD",
                    value: (purchasedTicket.total_price_cents / 100).toFixed(2),
                    items: [{
                        item_id: `session-id-${purchasedTicket.id}`,
                        item_name: `${purchasedSession.venue_name} - ${purchasedSession.movie_title} - ${purchasedSession.session_date}`,
                        price: (purchasedTicket.ticket_price_cents / 100).toFixed(2),
                        quantity: purchasedTicket.total_tickets
                    }],
                }
            };
            if (Array.isArray(purchasedTicket.ticket_addons)) {
                purchasedTicket.ticket_addons.map((addon) => {
                    gaEvent.ecommerce.items.push({
                        item_id: `voucher-category-id-${addon.voucher_category_id}`,
                        item_name: addon.name,
                        price: (addon.price / 100).toFixed(2),
                        quantity: addon.quantity
                    });
                });
            }

            // assumes window.dataLayer is initialized in usePageTracking()
            if (Array.isArray(window.dataLayer)) {
                window.dataLayer.push(gaEvent);
            }
        }
    }, [purchasedTicket, purchasedSession]);
};

export default usePurchaseTracking;
