import React, { useContext, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { useParams } from "react-router-dom";
import {
    EVENT_CINEMAS,
    HOYTS_CINEMAS,
    LUNA_CINEMAS,
    MOVING_STORY,
    ORANA_CINEMAS,
    PALACE_CINEMAS,
    READING_CINEMAS,
    VILLAGE_CINEMAS
} from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { SearchContext } from "@context/SearchContext";
import BrandName from "@components/BrandName";
import EventLogo from "@components/CinemaGroupLogo/EventLogo";
import HoytsLogo from "@components/CinemaGroupLogo/HoytsLogo";
import LunaLogo from "@components/CinemaGroupLogo/LunaLogo";
import MovingStoryLogo from "@components/CinemaGroupLogo/MovingStoryLogo";
import OranaLogo from "@components/CinemaGroupLogo/OranaLogo";
import PalaceLogo from "@components/CinemaGroupLogo/PalaceLogo";
import ReadingsLogo from "@components/CinemaGroupLogo/ReadingsLogo";
import VillageLogo from "@components/CinemaGroupLogo/VillageLogo";
import MapsMarkerIcon from "@components/Icons/MapsMarkerIcon";
import MarkerIcon from "@components/Icons/MarkerIcon";
import LinkTabs from "@components/LinkTabs";
import VenueSessionSelector from "@components/V3/VenueSessionSelector";
import "./style.scss";

const SearchDetailsVenue = () => {
    const { id, venue_name } = useParams();
    const { fetchVenue, venue } = useContext(SearchContext);
    const { publicSubscriberGroup } = useContext(ApplicationContext);

    const {
        address,
        cinema_group_id,
        city,
        description,
        email,
        latitude,
        longitude,
        name,
        phone,
        postcode,
        state,
        website,
    } = venue;
    const venueAddress = `${address}, ${city}, ${state}, ${postcode}`;
    useEffect(() => {
        if (id && venue_name) {
            fetchVenue(id, venue_name);
        }
    }, [id, venue_name]);
    

    let CinemaGroupLogo;

    switch (cinema_group_id) {
        case HOYTS_CINEMAS:
            CinemaGroupLogo = HoytsLogo;
            break;
        case VILLAGE_CINEMAS:
            CinemaGroupLogo = VillageLogo;
            break;
        case EVENT_CINEMAS:
            CinemaGroupLogo = EventLogo;
            break;
        case LUNA_CINEMAS:
            CinemaGroupLogo = LunaLogo;
            break;
        case PALACE_CINEMAS:
            CinemaGroupLogo = PalaceLogo;
            break;
        case READING_CINEMAS:
            CinemaGroupLogo = ReadingsLogo;
            break;
        case ORANA_CINEMAS:
            CinemaGroupLogo = OranaLogo;
            break;
        case MOVING_STORY:
            CinemaGroupLogo = MovingStoryLogo;
            break;
        default:
            CinemaGroupLogo = () => null;
            break;
    }


    return (
        <>
            {publicSubscriberGroup !== 0 ? (
                <div className="search-details-venue">
                    <div className="search-details-venue__wrapper">
                        <picture className="search-details-venue__picture">
                            <source media="(min-width: 768px)" srcSet={venue.large_image_url} />
                            <img
                                className="search-details-venue__image"
                                src={venue.large_image_url}
                                alt={venue.name} />
                        </picture>
                        <div className="search-details-venue__text-container">
                            <div className="search-details-venue__logo">
                                <CinemaGroupLogo />
                            </div>
                            <div className="search-details-venue__text-content">
                                <h1 className="search-details-venue__title">{venue.name}</h1>
                                <p className="search-details-venue__text">In partnership with <BrandName />, the home of independent cinema in Australia.</p>
                                <p className="search-details-venue__text">Your one-stop-shop for great value tickets at Indie cinemas Australia-wide.</p>
                                <h2 className="search-details-venue__text">{venue.address}</h2>
                                <div className="search-details-venue__group">
                                    <MarkerIcon className="search-details-venue__icon" />
                                    <a
                                        className="search-details-venue__directions"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={encodeURI(
                                            `https://www.google.com/maps/place/${venueAddress}`,
                                        )}
                                    >Get directions
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    ): (
                        <div className="search-details-venue">
                            <div className="search-details-venue__text-container">
                                <h1 className="search-details-venue__title">{venue.name}</h1>
                                <h2 className="search-details-venue__text">{venue.address}</h2>
                                <div className="search-details-venue__group">
                                    <MarkerIcon className="search-details-venue__icon" />
                                    <a
                                        className="search-details-venue__directions"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={encodeURI(
                                            `https://www.google.com/maps/place/${venueAddress}`,
                                        )}
                                    >Get directions
                                    </a>
                                </div>
                            </div>
                            <div className="search-details-venue__wrapper">
                                <picture className="search-details-venue__picture">
                                    <source media="(min-width: 768px)" srcSet={venue.large_image_url} />
                                    <img
                                        className="search-details-venue__image"
                                        src={venue.large_image_url}
                                        alt={venue.name} />
                                </picture>
                            </div>
                            <span className="search-details-venue__background"></span>
                        </div>
                    )}

            <div className="search-details-venue__content">
                <LinkTabs>
                    <div label="Sessions">
                        <VenueSessionSelector />
                    </div>
                    <div label="About">
                        <div className="search-details-venue__tab-wrapper">
                            <div className="search-details-venue__detail">
                                <h2 className="search-details-venue__title">{name}</h2>
                                <h3 className="search-details-venue__text">
                                    {description}
                                </h3>

                                {website && <div className="search-details-venue__text">Website: <a href={website} target="_blank"
                                    rel="noopener noreferrer"
                                    className="search-details-venue__link">{website}</a>
                                </div>}
                                {phone && <div className="search-details-venue__text">Phone: <span
                                    className="search-details-venue__text--field">{phone}</span></div>}
                                {email && <div className="search-details-venue__text">Email: <span
                                    className="search-details-venue__text--field">{email}</span></div>}
                            </div>

                            <div className="search-details-venue__map-wrap">
                                {latitude && longitude && (
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyC4N1BHxxWohTt7moat_Mq3x5Gw5U6Cvqw" }}
                                        className="map"
                                        yesIWantToUseGoogleMapApiInternals
                                        region="au"
                                        zoom={11}
                                        center={{ lat: Number(latitude), lng: Number(longitude) }}
                                    >
                                        <MapsMarkerIcon lat={latitude} lng={longitude} className="venue__marker" />
                                    </GoogleMapReact>
                                )}
                            </div>
                        </div>
                    </div>
                </LinkTabs>
            </div>

        </>

    );
};

export default SearchDetailsVenue;
