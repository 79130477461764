import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ProfileContext } from "@context/ProfileContext";
import AlertMessage from "@components/AlertMessage";
import Button from "@components/Ui/Button";
import Spinner from "@components/Ui/Spinner";
import TextInputLabel from "@components/Ui/TextInputLabel";

const RequestPasswordResetForm = () => {

    useEffect(() => {
        setError(null);
        setErrorMessages({});
        setEmail("");
    }, []);

    const {
        done,
        error,
        email,
        errorMessages,
        processing,
        requestPasswordReset,
        setDone,
        setEmail,
        setErrorMessages,
        setError,
        setProcessing,

    } = useContext(ProfileContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessages({});
        requestPasswordReset(email);
    };
    const handleEmailOnchange = (e) => {
        setEmail(e.target.value);
        setError(null);
        setErrorMessages({});
    };

    if (done) {
        return (
            <>
                <p className="paragraph">The password reset email has been sent to you.</p>
                <p className="paragraph">To continue browsing movies <Link className="inline-link" to="/">Click Here</Link></p>
            </>
        );
    }

    const errorsFlattened = Object.keys(errorMessages).reduce(
        (acc, cur) => [
            ...acc,
            ...Object.keys(errorMessages[cur]).map(
                (key) => errorMessages[cur][key]
            ),
        ],
        []
    );

    return (
        <>
            <form onSubmit={handleSubmit} className="form__content">
                <TextInputLabel
                    type="email"
                    name="Email"
                    labelName="Enter email to reset your password"
                    value={email}
                    onChange={handleEmailOnchange}
                    required
                />
                <Button
                    additionalClasses="button button__primary">
                    {processing ? (
                        <Spinner text="loading..." />
                    ) : (
                        <span>Send</span>
                    )}
                </Button>
            </form>
            {errorsFlattened.length > 0 && (
                <AlertMessage
                    message="The following errors occurred:"
                    type="error"
                    messageList={errorsFlattened}
                />
            )}
            {error && (
                <AlertMessage
                    message={error}
                    type="error"
                />
            )}
        </>
    );
};

export default RequestPasswordResetForm;
