import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import useModal from "@hooks/useModal";
import { ApplicationContext } from "@context/ApplicationContext";
import { BookSessionContext } from "@context/BookSessionContext";
import { VoucherManagerContext } from "@context/VoucherManagerContext";
import AlertMessage from "@components/AlertMessage";
import CheckoutCreditCard from "@components/CheckoutCreditCard";
import Button from "@components/Ui/Button";
import Spinner from "@components/Ui/Spinner";
import "./style.scss";

const calcTotalPrice = (carry, item) => {
    return carry + (item.price_cents * item.quantity);
};
const calcTotalRrpPrice = (carry, item) => {
    return carry + (item.rrp_price_cents * item.quantity);
};
const calcTotalQuantity = (carry, item) => {
    return carry + item.quantity;
};

const VoucherSummary = ({ items, type }) => {
    const {
        error,
        isSubmitting,
        checkoutBookingSession,
        setSelectedItems,
        checkout,
        ewayAccessCode,
        ewayFormActionUrl,
        uuid,
        isTempAccount
    } = useContext(BookSessionContext);

    const {
        selectedItems,
    } = useContext(VoucherManagerContext);

    const { whiteLabel } = useContext(ApplicationContext);

    const { openModalWithContent, portal } = useModal();

    const addTicketButtonText = checkout ? "Update Purchase" : "Checkout";

    useEffect(() => {
        if (selectedItems) {
            setSelectedItems(selectedItems);
        }
    }, [selectedItems]);

    useEffect(() => {
        if (!error && checkout && totalQuantity > 0 && ewayAccessCode && ewayFormActionUrl) {
            openModalWithContent(<CheckoutCreditCard
                isTempAccount={isTempAccount}
                uuid={uuid}
                ewayAccessCode={ewayAccessCode}
                ewayFormActionUrl={ewayFormActionUrl}
            />, "small");
        }
        else {
            return false;
        }
    }, [isTempAccount, checkout, uuid, ewayAccessCode, ewayFormActionUrl]);

    const totalPrice = whiteLabel?.subscriber_group?.display_price === true ? items.reduce(calcTotalRrpPrice, 0) : items.reduce(calcTotalPrice, 0);
    const totalQuantity = items.reduce(calcTotalQuantity, 0);

    const handleCheckout = () => {
        if (selectedItems) {
            setSelectedItems(selectedItems);
        }
        if (totalQuantity > 0) {
            checkoutBookingSession(totalQuantity, type.type);
        }
    };

        return (
            <>
                {(totalPrice > 0) ? (
                    <>
                        <div className="voucher-search__summary">
                            <div className={`booking__price ${totalQuantity === 0 ? "booking__quantity-none" : ""}`}>
                                Total Price: ${(totalPrice / 100).toFixed(2)}
                            </div>
                            <Button
                                onClick={handleCheckout}
                                additionalClasses="button button__primary"
                                disabled={isSubmitting === true}
                            >
                                {isSubmitting ? (
                                    <Spinner text="loading..."/>
                                )  : (
                                    addTicketButtonText
                                )}
                            </Button>
                        </div>

                        <div className="form__group-wrapper">
                            {error  && (
                                <AlertMessage message={error} type="error" />
                            )}
                        </div>
                    </>
                ) : ""}
                {portal}
            </>
    );
};

VoucherSummary.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            quantity: PropTypes.number.isRequired
        })
    ).isRequired,
};

export default VoucherSummary;
