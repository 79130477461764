import React, { useContext, useState } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import toSeoUrl from "@functions/toSeoUrl";
import useViewport from "@hooks/useViewport";
import { ApplicationContext } from "@context/ApplicationContext";
import PlayIcon from "@components/Icons/PlayIcon";
import TrailerButton from "@components/TrailerButton";
import V3SessionButton from "@components/V3/V3SessionButton";
import { sortVenuesByDistance } from "@utils/sessionUtils";
import "./style.scss";

const FilteredExperience = ({ groupedVenues, selection, onImageError }) => {
    const { cdnUrl } = useContext(ApplicationContext);
    const fallbackImage = `${cdnUrl}asset/img/placeholder.jpeg`;
    const { width } = useViewport();
    const blurbBreakpoint = 640;
    const [isActive, setIsActive] = useState(false);
    const handleClick = () => setIsActive(!isActive);

    const groupSessionsByMovie = (sessions) => {
        const movieMap = {};
        sessions.forEach((session) => {
            if (!movieMap[session.movie_id]) {
                movieMap[session.movie_id] = [];
            }
            movieMap[session.movie_id].push(session);
        });
        return movieMap;
    };

    return (
        <div className="experiences__list">
            {Object.keys(groupedVenues).length > 0 ? (
                <div className="experiences__groups">
                    {sortVenuesByDistance(groupedVenues).map(([venue, sessions]) => {
                        const moviesGrouped = groupSessionsByMovie(sessions);
                        const firstSession = sessions[0];
                        return (
                            <div key={venue} className="experiences__group">
                                <Link className="experiences__venue-name" to={`/venue/${firstSession.venue_id}/${toSeoUrl(venue)}`}>
                                    {firstSession.venue_name} - {firstSession.venue_distance} km
                                </Link>
                                <address className="experiences__address">
                                    {[firstSession.venue_address, firstSession.venue_suburb, firstSession.venue_postcode, firstSession.venue_state]
                                        .filter(Boolean)
                                        .join(", ")}
                                </address>
                                {Object.values(moviesGrouped).map((movieSessions) => {
                                    const movie = movieSessions[0];
                                    return (
                                        <React.Fragment key={movie.movie_id}>
                                            <div className="experiences__movie-item">
                                                <Link
                                                    className="experiences__movie-item-link"
                                                    to={`/movie/${movie.movie_id}/${toSeoUrl(movie.movie_title)}`}
                                            >
                                                    <img
                                                        className="experiences__movie-item-image"
                                                        src={movie.movie_image || fallbackImage}
                                                        alt={movie.movie_title}
                                                        onError={onImageError}
                                                />
                                                </Link>
                                                <div className="experiences__movie-item-content">
                                                    <Link
                                                        className="experiences__movie-item-title"
                                                        to={`/movie/${movie.movie_id}/${toSeoUrl(movie.movie_title)}`}
                                                >
                                                        {movie.movie_title}
                                                    </Link>
                                                    {width >= blurbBreakpoint && (
                                                    <button
                                                        className={`experiences__movie-item-description ${isActive ? "active" : ""}`}
                                                        onClick={handleClick}
                                                    >
                                                        {movie.movie_blurb}
                                                    </button>
                                                )}
                                                    <div className="experiences__meta">
                                                        {movie.movie_rating && (
                                                        <span className="experiences__meta-text">{movie.movie_rating}</span>
                                                    )}
                                                        {movie.movie_run_time && (
                                                        <span className="experiences__meta-text">{movie.movie_run_time} mins</span>
                                                    )}
                                                        {movie.movie_genre && (
                                                        <span className="experiences__meta-text">{movie.movie_genre}</span>
                                                    )}
                                                    </div>
                                                    {movie.trailer_url && (
                                                    <TrailerButton
                                                        extraClass="experiences__movie-item-trailer"
                                                        type="mp4"
                                                        trailerLink={movie.trailer_url}
                                                    >
                                                        <PlayIcon className="experiences__movie-item-icon-svg" />
                                                        <span className="experiences__movie-item-trailer-text">Official Trailer</span>
                                                    </TrailerButton>
                                                )}
                                                </div>
                                            </div>
                                            <div className="experiences__sessions">
                                                <div className="experiences__date-text">
                                                    {selection.date && <>{format(new Date(selection.date), "EEE d MMM")}</>}
                                                </div>
                                                <div className="experiences__movie-item-sessions">
                                                    {movieSessions.map((session) => (
                                                        <V3SessionButton key={session.id} session={session} />
                                                ))}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="experiences__message">
                    <p className="experiences__message-title">Sorry, we couldn&apos;t find any results for your chosen cinemas</p>
                    <p className="experiences__message-text">Please select from one of the nearby sessions below, or select a different date</p>
                </div>
            )}
        </div>
    );
};

export default FilteredExperience;
