import React from "react";
import NoPublicAccess from "@components/NoPublicAccess";
import Layout from "@components/Wrappers/Layout";

const NoPublicAccessPage = () => {

    return (
        <Layout>
            <NoPublicAccess />
        </Layout>
    );
};

export default NoPublicAccessPage;
