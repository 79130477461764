import React, { useContext } from "react";
import { ApplicationContext } from "@context/ApplicationContext";
import { CheckoutComplimentaryContext } from "@context/CheckoutComplimentaryContext";
import { CheckoutCreditCardContext } from "@context/CheckoutCreditCardContext";
import AcknowledgementOfCountry from "@components/AcknowledgementOfCountry";
import PoweredBy from "@components/PoweredBy";
import "./style.scss";


const Legal = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const { cardState } = useContext(CheckoutCreditCardContext);
    const { isSubmitting } = useContext(CheckoutComplimentaryContext);

    let termsLink = null;
    let participatingCinemaLink = null;
    let privacyLink = null;
    let faqLink = null;
    let privacyCollectionNotice = null;

    const formSubmitting = cardState.isSubmitting || isSubmitting === true ? "legal__container--disabled" : "";

    if (whiteLabel?.terms_use_api === true) {
        termsLink = (
            <a className="legal__link" href="/terms">
                Terms &amp; Conditions
            </a>
        );
    }

    if (whiteLabel?.privacy_use_api === true) {
        privacyLink = (
            <a className="legal__link" href="/privacy">
                Privacy Policy
            </a>
        );
    }

    if (whiteLabel?.show_participating_cinemas === true) {
        participatingCinemaLink = (
            <a className="legal__link" href="/participating-cinemas">
                Participating Cinemas
            </a>
        );
    }

    if (whiteLabel?.faq_use_api === true) {
        faqLink = (
            <a className="legal__link" href="/faqs">
                FAQs
            </a>
        );
    }

    if (whiteLabel?.data_collection_use_api === true) {
        privacyCollectionNotice = (
            <a className="legal__link" href="/privacy-collection-notice">
                Privacy Collection Notice
            </a>
        );
    }


    return (
        <>
            <div className={`legal__container ${formSubmitting}`}>
                {termsLink}
                {privacyLink}
                <a className="legal__link" href={`mailto:${whiteLabel?.support_email}`}>Contact Us</a>
                {participatingCinemaLink}
                {faqLink}
                {privacyCollectionNotice}
            </div>
            <PoweredBy />
            <AcknowledgementOfCountry />
        </>
    );
};

export default Legal;
