import React from "react";
import useTrackingViewItem from "@hooks/useTrackingViewItem";
import Layout from "@components/V3/Layout";
import V2V3BookSession from "../components/V3/V3BookSession";

const V2V3TicketPurchasePage = () => {
    // Google Analytics tracking for viewing items
    useTrackingViewItem();
    return (
        <Layout>
            <V2V3BookSession />
        </Layout>
    );
};

export default V2V3TicketPurchasePage;
