import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApplicationContext } from "@context/ApplicationContext";
import { SearchContext } from "@context/SearchContext";
import SearchSelect from "@components/SearchSelect";
import "./style.scss";
import Loader from "@components/Ui/Loader";

const V3ParticipatingCinemas = () => {
    const { cdnUrl } = useContext(ApplicationContext);
    const { participatingCinemas, setVenueName } = useContext(SearchContext);
    const [selectedVenue, setSelectedVenue] = useState(null);
    const [loading, setLoading] = useState(true);
    const fallbackImage = `${cdnUrl}asset/img/default-movie-poster-small.webp`;

    useEffect(() => {
        if (participatingCinemas && participatingCinemas.length > 0) {
            setLoading(false);
        }
    }, [participatingCinemas]);

    const onImageError = (e) => {
        e.target.src = fallbackImage;
    };

    const searchOptions = participatingCinemas.map((item) => ({
        ...item,
        label: item.name,
        value: item.name,
    }));

    const filterOptions = (candidate, input) => {
        if (!candidate.value || typeof candidate.value !== "string") {
            return false;
        }

        const candidateStr = candidate.value.toLowerCase();
        const candidateTerms = candidateStr.split(" ");
        const searchStr = input.toLowerCase();

        return searchStr && searchStr.includes(" ")
            ? candidateStr.includes(searchStr)
            : searchStr
                ? candidateTerms.some((str) => str.startsWith(searchStr))
                : false;
    };

    const handleOnChangeVenue = (option, { action }) => {
        if (action === "clear") {
            setSelectedVenue(null);
            setVenueName("");
        } else if (action === "select-option") {
            setSelectedVenue(option);
            setVenueName(option.label);
        }
    };

    const customNoOptionsMessage = () => (
        <span className="venue-finder__no-results">
            We did not find any venues
        </span>
    );

    
    const filteredCinemas = selectedVenue
        ? participatingCinemas.filter(
            (cinema) => cinema.name === selectedVenue.label,
        )
        : participatingCinemas;

    if (loading) {
        return <Loader isLoading={loading} />; // Show loading text while loading is true
    }

    if (!participatingCinemas || participatingCinemas.length === 0) {
        return null;
    }

    return (
        <div className="participating-cinemas__container">
            <h1 className="main-heading">Cinemas</h1>
            <div className="participating-cinemas__input">
                <SearchSelect
                    placeholder="Enter your cinema"
                    aria-label="Enter your cinema"
                    value={selectedVenue}
                    options={searchOptions}
                    filterOption={filterOptions}
                    onChange={handleOnChangeVenue}
                    isClearable={true}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                    openMenuOnClick={false}
                    noOptionsMessage={customNoOptionsMessage}
                />
            </div>

            <div className="participating-cinemas__list">
                {filteredCinemas.map((cinema, index) => (
                    <Link key={index} className="participating-cinemas__card" to={{ pathname: cinema.link_url }}>
                        <div className="participating-cinemas__image-wrapper">
                            <img
                                className="participating-cinemas__image"
                                onError={onImageError}
                                src={cinema.thumbnail_url ?? cinema.large_image_url}
                                alt={cinema.name}
                            />
                            
                        </div>
                        <div className="participating-cinemas__info">
                            <h2 className="participating-cinemas__title">
                                {cinema.name}
                            </h2>
                            <address className="participating-cinemas__address">
                                {[cinema.info_address, cinema.info_state, cinema.info_postcode]
                                    .filter(Boolean)
                                    .join(", ")}
                            </address>
                        </div>
                        <span className="participating-cinemas__background"></span>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default V3ParticipatingCinemas;
