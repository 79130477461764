import React, { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { API_BASE_URL } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import Loader from "@components/Ui/Loader";
import "./style.scss";


const Terms = () => {
    const { whiteLabel, brandCode, subscriberGroupId } = useContext(ApplicationContext);
    const [terms, setTerms] = useState("");
    const [{ data, loading }] = useAxios(`${API_BASE_URL}/subscriber-group-terms/${subscriberGroupId}`);

    useEffect(() => {
        if (data) {
            setTerms(data.data.terms);
        }
    }, [data]);


    if (loading) {
        return (
            <Loader isLoading={loading} />
        );
    }

    return (
        <div className="terms__wrapper">
            {terms ? (
                // eslint-disable-next-line react/no-danger
                <div className="terms__content" dangerouslySetInnerHTML={{ __html: terms }} />
            ) : (<p>Terms and conditions content to follow</p>)}
        </div>
    );
};

export default Terms;
