import React from "react";

const CvnInput = ({ children }) => {
    return (
        <div className="checkout-card__input-container">
            <label className="form__label">CCV</label>
            <div className="checkout-card__input">{children}</div>
        </div>
    );
};

export default CvnInput;
