import React, { useContext, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { PRODUCT_TYPE_MOVIE_REWARDS } from "@config/config";
import groupSessionsByVenueMovie from "@functions/groupSessionsByVenueMovie";
import { ApplicationContext } from "@context/ApplicationContext";
import { MovieContext } from "@context/MovieContext";
import { SessionContext } from "@context/SessionContext";
import PlayIcon from "@components/Icons/PlayIcon";
import LinkTabs from "@components/LinkTabs";
import MovieResults from "@components/SearchResults/MovieResults";
import TrailerButton from "@components/TrailerButton";
import Button from "@components/Ui/Button";
import Loader from "@components/Ui/Loader";
import "./style.scss";

function minToHour(mins = 0) {
    const hours = mins / 60;
    const rHours = Math.floor(hours);
    const minutes = (hours - rHours) * 60;
    const rMinutes = Math.round(minutes);
    return `${rHours}hrs ${rMinutes}min.`;
}

const MovieDetails = () => {

    const { id, movieName } = useParams();
    const history = useHistory();
    const location = useLocation();
    const { movie, fetchMovie, isLoading, movieUrl, movieImage, movieTitle } = useContext(MovieContext);
    const { sessions, fetchMovieSessions, isLoadingSessions, setIsLoadingSessions } = useContext(SessionContext);
    const { cdnUrl, whiteLabel, productTypeId  } = useContext(ApplicationContext);
    const fallbackImage = `${cdnUrl}asset/img/default-movie-poster-large.webp`;
    const imageUrl = movieImage || fallbackImage;
    const brand = whiteLabel?.brand_name;
    const { title } = movie;
    const onImageError = (e) => {
        e.target.src = fallbackImage;
    };

    useEffect(() => {
        fetchMovie(id,movieName);
    }, [id,movieName]);

    useEffect(() => {
        setIsLoadingSessions(true);
        if (movie && title) {
            setIsLoadingSessions(true);
            if (!navigator.geolocation) {
                fetchMovieSessions({
                    movie_title: title,
                    lat: null,
                    lng: null
                });
            } else {
                navigator.geolocation.getCurrentPosition((position) => {
                    fetchMovieSessions({
                        movie_title: title,
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                }, () => {
                    fetchMovieSessions({
                        movie_title: title,
                        lat: null,
                        lng: null
                    });
                });
            }

        }
        history.replace({
            pathname: location.pathname,
        });
    }, [movie]);


    const sessionsGrouped = sessions
        .reduce(groupSessionsByVenueMovie, [])
        .sort((a, b) => a.venue_distance - b.venue_distance);

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    if (isLoading === true) {
        return <Loader isLoading={isLoading}/>;
    }

    return (
        <>
            <Helmet>
                <title>{ `${movie?.title} | Movie Tickets & Showtimes | ${brand}` }</title>
                <meta name="keywords" content={ `${movie?.title} | Movie Tickets & Showtimes | ${brand}` } />
                <meta name="description" content={ `Immerse yourself in the movie screening of ${movie?.title}
                at your nearest independent or regional cinema. Buy discounted movie tickets from ${brand} today!` } />
                <link rel="canonical" />
            </Helmet>
            <div className="movie__container">
                <div className="movie__image-wrapper">
                    {movieUrl ? (
                        <TrailerButton extraClass="trailer-button__button" type="mp4" trailerLink={movieUrl}>
                            <span className="trailer-button--play-icon"><PlayIcon className="trailer-button--play-icon-svg" /></span>
                        </TrailerButton>
                    ):null}
                    {!isLoading && (
                        <img
                            className="movie__image"
                            src={imageUrl}
                            style={{
                                width: "100%",
                                height: "auto",
                            }}
                            alt={movieTitle}
                            onError={onImageError}
                        />
                    )}
                    {(productTypeId === PRODUCT_TYPE_MOVIE_REWARDS && sessionsGrouped.length) ? (
                        <Button additionalClasses="button button__primary button__small movie__button--buy-now"  onClick={handleClick}>Buy Now</Button>): null}
                </div>
            </div>
            <div className="movie__detail">
                <h1 className="movie__title">{movie?.title}</h1>
                <LinkTabs>
                    <div label="Story">
                        <div className="movie__tab">
                            {movie?.review && (
                                <div className="movie__description">
                                    <p className="movie__description-text" key={movie.review.id}>
                                        {movie.review.review_description}
                                    </p>
                                </div>
                            )}
                            {movie?.genres?.length > 0 && (
                                <ul className="movie__genres">
                                    {movie.genres.map((genre, i) => (
                                        <li className="movie__genre" key={i}>
                                            {genre.genre_name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div label="Cast & Directors">
                        <div className="movie__tab">
                            <div className="movie__info">
                                <span>{movie?.release_year}</span>
                                {movie?.rating && <span>{movie.rating}</span>}
                                {movie && <span>{minToHour(movie?.running_time)}</span>}
                            </div>
                            {movie?.cast?.length > 0 && (
                                <div className="movie__starring">
                                    <ul className="movie__items">
                                        <li className="movie__item">Starring:</li>
                                        {movie.cast.map((actor, i) => (
                                            <li className="movie__item" key={i}>
                                                {actor.actor_name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {movie?.directors?.length > 0 && (
                                <div className="movie__director">
                                    <ul className="movie__items">
                                        <li>Directors:</li>
                                        {movie.directors.map((director, i) => (
                                            <li key={i}>{director.director_name}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </LinkTabs>
            </div>



            {(productTypeId === PRODUCT_TYPE_MOVIE_REWARDS && isLoadingSessions === true) ? (
                <Loader isLoading={isLoadingSessions}/>
            ): (
                <>
                    {(productTypeId === PRODUCT_TYPE_MOVIE_REWARDS && sessionsGrouped.length ) ?
                        (
                            <div className="movie__lisings-wrapper">
                                <h2 className="movie__sub-title">Now Showing</h2>
                                <div className="movie__listings" ref={ref}>
                                    <MovieResults items={sessionsGrouped} sessions={sessions}/>
                                </div>

                            </div>
                        ): (
                            <div className="movie__lisings-wrapper">
                                <p className="paragraph">no sessions currently available</p>
                            </div>
                        )}
                </>
                )}
        </>
    );
};

export default MovieDetails;
