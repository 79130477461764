import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ApplicationContext } from "@context/ApplicationContext";
import Footer from "@components/Footer";
import LetsGetStarted from "@components/LetsGetStarted";


const LetsGetStartedPage = () => {

    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;

    return (
        <div className="refresh__wrapper">
            <Helmet>
                <title>{ `Lets get started | Movies in Cinemas Now | ${brand}` }</title>
                <meta name="keywords" content={ `Lets get started | Movies in Cinemas Now | ${brand}` } />
                <meta name="description" content={ `Discover movies now showing in regional cinemas across Australia.
                Get information on movie tickets, screening locations,
                showtimes & more. Only at ${brand}!` } />
                <link rel="canonical" href={`${window.location.protocol }//${ window.location.host}/lets-get-started`} />
            </Helmet>
            <LetsGetStarted />
            <Footer />
        </div>


    );
};

export default LetsGetStartedPage;
