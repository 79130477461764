import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ApplicationContext } from "@context/ApplicationContext";
import Layout from "@components/Wrappers/Layout";
import SubscriberGroupNotLaunchedPage from "@pages/SubscriberGroupNotLaunchedPage";

const SubscriberGroupLandingPage = () => {
    const { groupCode } = useParams();
    const {
        subscriberGroup,
        fetchSubscriberGroup,
        isLoading,
    } = useContext(ApplicationContext);

    useEffect(() => {
        if (groupCode) {
            fetchSubscriberGroup(groupCode);
        }
    }, [groupCode]);


    if (subscriberGroup?.is_launched === true) {
        window.location = "/";
    }

    return (
        <>
            {isLoading === false && subscriberGroup?.is_launched === false && (
                <Layout>
                    <SubscriberGroupNotLaunchedPage
                        subscriberGroup={subscriberGroup}
                    />
                </Layout>
            )}
        </>
    );
};

export default SubscriberGroupLandingPage;
