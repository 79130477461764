import React from "react";
import useModal from "@hooks/useModal";
import Button from "@components/Ui/Button";
import VideoPlayer from "@components/Video";
import "./style.scss";

const TrailerButton = ({ trailerLink, type, children, extraClass }) => {
    const { openModalWithContent, portal } = useModal();


    
    return (
        <>
            <Button
                onClick={() => {
                    openModalWithContent(<VideoPlayer type={type} src={trailerLink}  />, "video");
                }}
                additionalClasses={extraClass}
                >
                {children}
            </Button>
            {portal}
        </>
    );
};

export default TrailerButton;
