import React from "react";
import { Link } from "react-router-dom";
import toSeoUrl from "@functions/toSeoUrl";
import MoviePoster from "@components/MoviePoster";
import Sessions from "@components/Sessions";
import "./style.scss";

const SearchResult = ({ item }) => {

    return (
        <div className="search-result__item" key={item.id}>
            <div className="search-result__thumbnail">
                <MoviePoster movie={item} size="small" key={item.movie_id}/>
            </div>
            <div className="search-result__content">
                <Link
                    key={item.id}
                    className="search-result__link"
                    to={{
                        pathname: `/movie/${item.movie_id}/${toSeoUrl(item.movie_title)}`,
                    }}
                >
                    {item.movie_title}
                </Link>
                <Link
                    key={item.id}
                    className="search-result__venue-link"
                    to={{
                        pathname: `/venue/${item.venue_id}/${toSeoUrl(item.venue_name)}`,
                    }}
                >
                    {item.venue_name}
                </Link>
                <p
                    className="search-result__address"
                >
                    <span>{item.venue_address}, {item.venue_suburb}, {item.venue_state.toUpperCase()}</span>

                    <span
                        className="search-result__distance">{item.venue_distance}km</span>
                </p>
            </div>
            <Sessions sessions={item.sessions} sessionLimit={9}/>
        </div>
    );
};

export default SearchResult;
