import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "@context/AuthContext";
import { MembershipContext } from "@context/MembershipContext";
import PlusIcon from "@components/Icons/PlusIcon";
import Button from "@components/Ui/Button";
import Logo from "@components/Ui/Logo";
import Drawer from "@components/V3/Drawer";
import Venues from "@components/V3/VenueFinder/venues";
import "./style.scss";

const MovieClubRenewal = () => {
    const { drawerState, openDrawer, closeDrawer, user } = useContext(AuthContext);
    const { memberData, isMembershipExpired, setIsMembershipExpired } = useContext(MembershipContext);

    useEffect(() => {
        if (isMembershipExpired) {
            openDrawer("movieClubRenewalDrawer");
            setIsMembershipExpired(false);
        }
    }, [isMembershipExpired]);

    return (
        <>
            <Drawer isOpen={drawerState.movieClubRenewalDrawer} onClose={() => closeDrawer("movieClubRenewalDrawer")} closeButtonAlignment="right">
                <div className="drawer__menu">
                    <div className="drawer__logo">
                        <Logo />
                    </div>
                </div>
                
                <p className="membership-renewal__title">
                    Movie Club Membership
                </p>

                <p className="membership-renewal__message">
                    {memberData?.expiry_date 
                        ? `Your Movie Club Membership expired on the ${memberData?.expiry_date}.`
                        : "Your Movie Club Membership has expired."
                    }
                </p>

                <p className="membership-renewal__question">Do you wish to renew?</p>

                <div className="membership-renewal__buttons--wrapper">
                    <NavLink
                        to="/movie-club-membership#renew"
                        activeClassName="active"
                        onClick={() => closeDrawer("movieClubRenewalDrawer")}
                        // className="button button__primary membership-renewal__buttons">Yes
                        className="button membership-renewal__buttons">Yes
                    </NavLink>

                    {/* <Button additionalClasses="button button__primary membership-renewal__buttons" onClick={() => closeDrawer("movieClubRenewalDrawer")}> */}
                    <Button additionalClasses="button membership-renewal__buttons" onClick={() => closeDrawer("movieClubRenewalDrawer")}>
                        No
                    </Button>
                </div>
            </Drawer>
        </>
    );
};

export default MovieClubRenewal;
