import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const TextInput = ({
                       value = "",
                       onChange,
                       isValueErrored = false,
                       disabled = false,
                       required = false,
                       autoComplete = "off",
                       additionalClasses = "",
                       name,
                       id,
                       placeholder = "",
                       type = "text",
                       maxLength = "",
                   }) => {

    const className = `text-input__field ${isValueErrored ? "text-input__field__error" : ""} ${additionalClasses}`;

    return (
        <>
            <div className="text-input">
                <input
                    aria-label={`${placeholder} input`}
                    id={name ? "" : id}
                    name={id || name}
                    type={type}
                    required={required}
                    autoComplete={autoComplete}
                    className={className}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    placeholder={placeholder}
                    maxLength={maxLength}
                />
            </div>
        </>
    );
};

TextInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    isValueErrored: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    autoComplete: PropTypes.string,
    additionalClasses: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
};

export default TextInput;
