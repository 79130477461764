import React, { useContext } from "react";
import { AuthContext } from "@context/AuthContext";
import CheckoutCreditCard from "@components/CheckoutCreditCard";
import Drawer from "@components/V3/Drawer";

const V3CheckoutCreditCardDrawer = ({ uuid, isTempAccount, ewayAccessCode, ewayFormActionUrl }) => {
    const { drawerState, closeDrawer } = useContext(AuthContext);

    return (
        <>
            <Drawer
                isOpen={drawerState.creditCardCheckoutDrawer}
                onClose={() => closeDrawer("creditCardCheckoutDrawer")}
                closeButtonAlignment="right"
            >
                <CheckoutCreditCard
                    isTempAccount={isTempAccount}
                    uuid={uuid}
                    ewayAccessCode={ewayAccessCode}
                    ewayFormActionUrl={ewayFormActionUrl}
                />
            </Drawer>
        </>
    );
};

export default V3CheckoutCreditCardDrawer;
