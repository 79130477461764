import React, { useEffect, useRef } from "react";
import CloseIcon from "@components/Icons/CloseIcon";
import Button from "@components/Ui/Button";
import "./style.scss";

const Modal = ({ open, closeModal, content, modalClass }) => {
    const node = useRef(null);
    const handleClick = (e) => {
        if (node.current === e.target) {
            closeModal();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [open, node]);

    return (
        <>

            {open ? (
                <>
                    <div className={`${open ? "modal__background--is-open" : ""} modal__background`}></div>
                    <div ref={node} className={`${open ? "modal__container--is-open" : ""} modal__container`}>
                        <div className={`${modalClass ? (`modal__wrapper--${modalClass}`) : ""} modal__wrapper`}>
                            <Button
                                additionalClasses="modal__close"
                                onClick={closeModal}
                            >
                                <CloseIcon className="modal__svg"/>
                            </Button>
                            <div className={`${modalClass ? (`modal__content--${modalClass}`) : ""} modal__content`}>
                                <div className="modal__body">
                                    {content}
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            ) : null }
        </>
    );
};

export default Modal;
