import React, { useContext, useState } from "react";
import useViewport from "@hooks/useViewport";
import { ApplicationContext } from "@context/ApplicationContext";
import Footer from "@components/Footer";
import CloseIcon from "@components/Icons/CloseIcon";
import Button from "@components/Ui/Button";
import LeftSidebarMenu from "@components/V3/LeftSidebarMenu";
import Logo from "@components/V3/Logo";
import MovieClubRenewal from "@components/V3/MovieClubRenewal";
import SearchBar from "@components/V3/SearchBar";
import UserMenu from "@components/V3/UserMenu";
import "./style.scss";

const Layout = ({ children }) => {
    const { cdnUrl } = useContext(ApplicationContext);
    const [isNavCollapsed, setIsNavCollapsed] = useState(false);
    const { width } = useViewport();
    const breakpoint = 1219;
    const handleNavCollapse = () => {
        setIsNavCollapsed(!isNavCollapsed);
    };
    return (
        <>
            <div className="layout">
                <div className="layout__container">
                    <nav className={`layout__nav ${!isNavCollapsed ? "closed" : "open"}`}>
                        {width > breakpoint ? (
                            <div className="layout__logo larger-screens">
                                <Logo />
                            </div>
                        ) : null}

                        {width < breakpoint ? (
                            <>
                                <div className="layout__nav--close"><Button additionalClasses="drawer__close" onClick={handleNavCollapse}><CloseIcon className="drawer__svg" /></Button></div>
                                <div className={`layout__search--mobile ${!isNavCollapsed ? "closed" : "open"}`}>
                                    <SearchBar />
                                </div>
                                <LeftSidebarMenu isOpen={isNavCollapsed} />
                            </>
                        ) : (
                            <LeftSidebarMenu />
                        )}

                    </nav>
                    <div className="layout__content">
                        <div className="layout__header">
                            <div className="layout__hamburger">
                                <button
                                    className={`layout__hamburger-button ${!isNavCollapsed ? "closed" : "open"}`}
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#v3mobilemenu"
                                    aria-controls="v3mobilemenu"
                                    aria-expanded={!!isNavCollapsed}
                                    aria-label="Toggle navigation"
                                    onClick={handleNavCollapse}
                                    style={{
                                        mask: isNavCollapsed
                                            ? `url('${cdnUrl}asset/img/close.svg') no-repeat 50% 50%`
                                            : `url('${cdnUrl}asset/img/hamburger.svg') no-repeat 50% 50%`,
                                        WebkitMask: isNavCollapsed
                                            ? `url('${cdnUrl}asset/img/close.svg') no-repeat 50% 50%`
                                            : `url('${cdnUrl}asset/img/hamburger.svg') no-repeat 50% 50%`,
                                    }}
                                >
                                    <span className="menu__icon"></span>
                                </button>
                            </div>
                            {width < breakpoint ? (
                                <div className={`layout__header-logo ${!isNavCollapsed ? "closed" : "open"}`}>
                                    <Logo />
                                </div>
                            ) : null}

                            <div className="layout__search--desktop">
                                <SearchBar />
                            </div>
                            <UserMenu />
                            <MovieClubRenewal />
                        </div>
                        <div className="layout__inner">
                            {children}
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Layout;
