import React, { useContext } from "react";
import { AuthContext } from "@context/AuthContext";
import { SearchContext } from "@context/SearchContext";
import PlusIcon from "@components/Icons/PlusIcon";
import Button from "@components/Ui/Button";
import Drawer from "@components/V3/Drawer";
import Venues from "@components/V3/VenueSelector/venues";
import "./style.scss";

const VenueSelector = () => {
    const { venueName, participatingCinemas } = useContext(SearchContext);
    const { drawerState, openDrawer, closeDrawer } = useContext(AuthContext);

    return (
        <>
            <div className="venue-selector">
                {venueName ? (
                    <Button additionalClasses="venue-selector__button" onClick={() => openDrawer("venueSelectorDrawer")}>
                        {venueName} <PlusIcon className="venue-selector__icon" />
                    </Button>
                ) : (
                    <Button additionalClasses="venue-selector__button" onClick={() => openDrawer("venueSelectorDrawer")}>
                        Add a cinema <PlusIcon className="venue-selector__icon" />
                    </Button>
                )}
            </div>
            <Drawer
                isOpen={drawerState.venueSelectorDrawer}
                onClose={() => closeDrawer("venueSelectorDrawer")}
                aria-label="Select your local cinema"
                role="dialog"
                closeButtonAlignment="right"
            >
                <Venues venues={participatingCinemas} />
                <Button additionalClasses="button button__primary" onClick={() => closeDrawer("venueSelectorDrawer")}>
                    Save and browse
                </Button>
            </Drawer>
        </>
    );
};

export default VenueSelector;
