import React from "react";
import FormWrapper from "@components/Wrappers/FormWrapper";

const SubscriberGroupNotLaunchedPage = ({ subscriberGroup }) => {
    return (
        <FormWrapper pageTitle="Launching Soon">
            <p className="paragraph">{subscriberGroup.pre_launch_message}</p>
        </FormWrapper>
        
    );
};

export default SubscriberGroupNotLaunchedPage;
