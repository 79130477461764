import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ApplicationContext } from "@context/ApplicationContext";
import ResetForgottenPasswordForm from "@components/Form/ResetForgottenPasswordForm";
import FormWrapper from "@components/Wrappers/FormWrapper";
import MinimalLayout from "@components/Wrappers/MinimalLayout";

const ResetForgottenPasswordPage = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;

    return (
        <MinimalLayout>
            <Helmet>
                <title>{ `Reset Forgotten Password | ${brand}` }</title>
                <meta name="keywords" content={ `Reset Forgotten Password | ${brand}` } />
                <meta name="description" content={ `Forgot your password? Not to worry,
                simply request a password reset to re-access your ${brand} account &
                continue enjoying member benefits.` } />
                <link rel="canonical" />
            </Helmet>
            <FormWrapper pageTitle="Reset Password">
                <ResetForgottenPasswordForm />
            </FormWrapper>
        </MinimalLayout>
    );
};

export default ResetForgottenPasswordPage;
