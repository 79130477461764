import React, { createContext, useState } from "react";
import axios from "axios";
import { API_BASE_URL, AUTH_JWT, AUTH_PARTNER_INTEGRATION } from "@config/config";

export const ApplicationContext = createContext({});

const ApplicationContextProvider = (props) => {
    const [brandCode, setBrandCode] = useState("brand_choovie");
    const [brandName, setBrandName] = useState("");
    const [subscriberGroupId, setSubscriberGroupId] = useState(null);
    const [siteConfig, setSiteConfig] = useState({});
    const [publicSubscriberGroup, setPublicSubscriberGroup] = useState(null);
    const [showWelcomePopup, setShowWelcomePopup] = useState(true);
    const [cdnUrl, setCdnUrl] = useState(null);
    const [showCarousel, setShowCarousel] = useState(false);
    const [gaCode, setGaCode] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [subscriberGroupTerms, setSubscriberGroupTerms] = useState("Could not fetch Terms & Conditions.");
    const [subscriberGroup, setSubscriberGroup] = useState({});
    const [cinemas, setCinemas] = useState([]);
    const [templateTheme, setTemplateTheme] = useState(null);
    const [movies, setMovies] = useState([]);
    const [whiteLabel, setWhiteLabel] = useState(null);
    const [productTypeId, setProductTypeId] = useState(null);
    const [seasonalRoutes, setSeasonalRoutes] = useState(null);
    const [topBanner, setTopBanner] = useState(null);
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [status, setStatus] = useState(null);
    const [news, setNews] = useState({
        id: null,
        title: "",
        text: "",
        image: null,
        link: null,
        content: null,
    });
    const [newsItem, setNewsItem] = useState({});
    const [displayNewsLink, setDisplayNewsLink] = useState(false);
    const [appSecurityLogIn, setAppSecurityLogIn] = useState(false);
    const [featureFilm, setFeatureFilm] = useState(null);
    const { children } = props;

    const fetchSubscriberGroup = (groupCode) => {
        setIsLoading(true);
        axios
            .get(`${API_BASE_URL}/subscriber-group/${groupCode}`)
            .then(function(response) {
                setSubscriberGroup(response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchSubscriberCinemas = () => {
        axios
            .get(`${API_BASE_URL}/venue/get-subscriber-cinemas`)
            .then(function(response) {
                if (response.status === 200) {
                    setCinemas(response.data.data);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };

    const fetchWhiteLabel = (subscriberGroupId) => {
        setIsLoading(true);
        axios
            .get(`${API_BASE_URL}/subscriber-group-white-label/${subscriberGroupId}`)
            .then(function(response) {
                setIsLoading(false);
                const whiteLabelData = response?.data?.data?.white_label;
                setWhiteLabel(whiteLabelData);
                setAppSecurityLogIn(true);
                if (
                    whiteLabelData?.subscriber_group?.authentication_type === AUTH_JWT ||
                    whiteLabelData?.subscriber_group?.authentication_type === AUTH_PARTNER_INTEGRATION
                ) {
                    setAppSecurityLogIn(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    };

    const fetchSeasonalRoutes = () => {
        setIsLoading(true);
        axios
            .get(`${API_BASE_URL}/seasonal-voucher-manager`)
            .then(function(response) {
                setIsLoading(false);
                setSeasonalRoutes(response?.data?.data?.seasonalRoutes);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    };

    return (
        <ApplicationContext.Provider
            value={{
                brandCode,
                cdnUrl,
                cinemas,
                fetchSubscriberCinemas,
                fetchSubscriberGroup,
                gaCode,
                isLoading,
                movies,
                publicSubscriberGroup,
                setBrandCode,
                setCdnUrl,
                setCinemas,
                setGaCode,
                brandName,
                setBrandName,
                setMovies,
                setPublicSubscriberGroup,
                setShowCarousel,
                setShowWelcomePopup,
                setSiteConfig,
                setSubscriberGroupId,
                setSubscriberGroupTerms,
                showCarousel,
                showWelcomePopup,
                siteConfig,
                subscriberGroup,
                subscriberGroupId,
                subscriberGroupTerms,
                templateTheme,
                whiteLabel,
                fetchWhiteLabel,
                productTypeId,
                setProductTypeId,
                fetchSeasonalRoutes,
                seasonalRoutes,
                topBanner,
                setTopBanner,
                news,
                setNews,
                newsItem,
                setNewsItem,
                displayNewsLink,
                setDisplayNewsLink,
                appSecurityLogIn,
                featureFilm,
                setFeatureFilm,
                lat,
                lng
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );
};

export default ApplicationContextProvider;
