import React, { useContext } from "react";
import { ApplicationContext } from "@context/ApplicationContext";
import "./style.scss";


const LetsGetStartedContent = () => {

    const { cdnUrl, whiteLabel } = useContext(ApplicationContext);
    const getStarted = whiteLabel?.subscriber_group?.subscriber_group_get_started;
    const image1 = "asset/img/lets-get-started-1.webp";
    const image2 = "asset/img/lets-get-started-2.webp";
    const image3 = "asset/img/lets-get-started-3.webp";
    const image1Url = cdnUrl ? cdnUrl + image1 : `/${image1}`;
    const image2Url = cdnUrl ? cdnUrl + image2 : `/${image2}`;
    const image3Url = cdnUrl ? cdnUrl + image3 : `/${image3}`;

    return (
        <div className="lets-get-started-content__container">
            <div className="lets-get-started__content">
                <div className="lets-get-started-content__block lets-get-started-content__block--alt">
                    <div className="lets-get-started-content__img-wrapper">
                        <img
                            className="lets-get-started-content__img"
                            src={image1Url}
                            alt="Lets Gets Started Image 1"
                        />
                    </div>
                </div>
                <div className="lets-get-started-content__block">
                    <h2 className="lets-get-started__title">
                        {getStarted?.header_one ?? "Anywhere, Anytime"}
                    </h2>
                    <p className="lets-get-started__text">
                        {getStarted?.paragraph_one ?? "Over 200,000 movie sessions across 300 cinemas every single week. Whatever you want to see, wherever you are, we've got you covered."}
                    </p>
                </div>
            </div>
            <div className="lets-get-started__content">
                <div className="lets-get-started-content__block">
                    <div className="lets-get-started-content__img-wrapper">
                        <img
                            className="lets-get-started-content__img"
                            src={image2Url}
                            alt="Lets Gets Started Image 2"
                        />
                    </div>
                </div>
                <div className="lets-get-started-content__block">
                    <h2 className="lets-get-started__title">
                        {getStarted?.header_two ?? "Australia's largest Cinema Network"}
                    </h2>
                    <p className="lets-get-started__text">
                        {getStarted?.paragraph_two ?? "Our network of more than 300 cinemas includes all the majors and your favourite independents. We've got locations in every major town and city across the country."}
                    </p>
                </div>
            </div>
            <div className="lets-get-started__content">
                <div className="lets-get-started-content__block  lets-get-started-content__block--alt">
                    <div className="lets-get-started-content__img-wrapper">
                        <img
                            className="lets-get-started-content__img"
                            src={image3Url}
                            alt="Lets Gets Started Image 3"
                        />
                    </div>
                </div>
                <div className="lets-get-started-content__block">
                    <h2 className="lets-get-started__title">
                        {getStarted?.header_three ?? "Best Value"}
                    </h2>
                    <p className="lets-get-started__text">
                        {getStarted?.paragraph_three ?? "With discounts of up to 50% available every single day of the year, including premium sessions and candy bar items, there's no better and cheaper place to get your movie fix!"}
                    </p>
                </div>
            </div>
        </div>

    );
};

export default LetsGetStartedContent;
