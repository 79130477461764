import React, { useContext, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { ACCOUNT_TYPE_NORMAL, ACCOUNT_TYPE_TEMPORARY } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { MembershipContext } from "@context/MembershipContext";
import MovieClubLoginComponent from "@components/Form/MovieClubLoginComponent";
import MovieClubSignupComponent from "@components/Form/MovieClubSignupComponent";
import CartIcon from "@components/Icons/CartIcon";
import DiaryIcon from "@components/Icons/DiaryIcon";
import JoinIcon from "@components/Icons/JoinIcon";
import MembershipIcon from "@components/Icons/MembershipIcon";
import SearchIcon from "@components/Icons/SearchIcon";
import SignupIcon from "@components/Icons/SignupIcon";
import MembershipOptions from "@components/MembershipOptions";
import Tabs from "@components/Tabs";
import Button from "@components/Ui/Button";
import "./style.scss";

const MovieClub = () => {
    const { user, isLoggedIn, existingUserMovieClubSignup } = useContext(AuthContext);
    const { cdnUrl, whiteLabel } = useContext(ApplicationContext);
    const { membershipOptions, selectedMembershipId } = useContext(MembershipContext);
    const image1 = "asset/img/movie-club1.webp";
    const image2 = "asset/img/movie-club2.webp";
    const image3 = "asset/img/movie-club3.webp";
    const image1Url = cdnUrl ? cdnUrl + image1 : `/${image1}`;
    const image2Url = cdnUrl ? cdnUrl + image2 : `/${image2}`;
    const image3Url = cdnUrl ? cdnUrl + image3 : `/${image3}`;

    const brand = whiteLabel?.brand_name;
    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleSubmit = () => {
        const data = {
            membershipId: selectedMembershipId,
        };
        existingUserMovieClubSignup(data);
    };

    useEffect(() => {
        if (location.hash === "#renew" && ref.current) {
            ref.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [location]);

    return (
        <div className="movie-club__main-wrapper">
            <div className="movie-club__intro">
                <h1 className="movie-club__intro-title">
                    Movie Club Membership
                </h1>
                <p className="movie-club__intro-sub-title">
                    Movie tickets for less than two cups of coffee?
                </p>
                {!isLoggedIn || user?.membership_status === false ? (
                    <Button
                        additionalClasses="button button__primary"
                        onClick={handleClick}
                    >
                        Join Movie Club
                    </Button>
                ) : null}
            </div>
            <div className="movie-club__content">
                <div className="movie-club__block">
                    <h2 className="movie-club__sub-title">
                        {brand} Movie Club: Your Limited Edition Pass to Monthly
                        Cinematic Bliss!
                    </h2>
                    <p className="movie-club__text">
                        Immerse yourself in the enchanting realm of independent
                        cinema with cinemanearme Movie Club. Secure your spot in
                        this exclusive club where each member receives a
                        complimentary movie ticket every month. Hurry, as passes
                        are limited!{" "}
                    </p>
                    <h2 className="movie-club__sub-title">
                        Your Limited Edition Pass to Monthly Cinematic Bliss!
                    </h2>
                    <p className="movie-club__text">
                        Join {brand} Movie Club and every month for a whole
                        year, enjoy your free movie. There&apos;s nothing for
                        you to do; we will make sure your ticket allocation is
                        in your account each month, so when you go to the
                        checkout, just click &quot;apply promo&quot;, and your
                        Movie Club allocation (Single, Double or Family) will be
                        applied to your purchase. It&apos;s the cheapest and
                        easiest way to become the most happening movie fan in
                        your crew!
                    </p>
                </div>
                <div className="movie-club__block  movie-club__block--alt">
                    <div className="movie-club__img-wrapper">
                        <img
                            className="movie-club__img"
                            src={image1Url}
                            alt="Palace Cinema Balwyn"
                        />
                    </div>
                </div>
            </div>
            <div className="movie-club__content">
                <div className="movie-club__block">
                    <div className="movie-club__img-wrapper movie-club__img-wrapper--alt">
                        <img
                            className="movie-club__img"
                            src={image2Url}
                            alt="Group of images"
                        />
                    </div>
                </div>
                <div className="movie-club__block">
                    <h2 className="movie-club__sub-title">
                        What is the {brand} Movie Club?
                    </h2>
                    <p className="movie-club__text">
                        It&apos;s your exclusive pass to over 100 independent
                        cinemas across Australia, including the{" "}
                        <Link
                            className="link link__primary"
                            to="/venue/363/regent-cinema"
                        >
                            Regent Cinema
                        </Link>
                        ,{" "}
                        <Link
                            className="link link__primary"
                            to="/venue/359/Thornbury-Picture-House"
                        >
                            Thornbury Picture House
                        </Link>{" "}
                        &{" "}
                        <Link
                            className="link link__primary"
                            to="/venue/78/majestic-cinemas-port-macquarie"
                        >
                            Majestic Cinema&apos;s Port Macquarie
                        </Link>
                        . As supporters of Australia&apos;s home of independent
                        cinema, we invite you to become a part of our vibrant
                        community.
                    </p>
                    <p className="movie-club__text">
                        Choose from single, double, or family options and savour
                        the pleasure of a monthly cinematic escape for less than
                        the cost of two cups of coffee. Join now and make each
                        movie night extraordinary!
                    </p>
                </div>
            </div>

            {!isLoggedIn || user?.membership_status === false ? (
                <div className="membership-options" ref={ref}>
                    {membershipOptions && (
                        <MembershipOptions
                            membershipOptions={membershipOptions}
                        />
                    )}
                </div>
            ) : null}

            {!isLoggedIn || (isLoggedIn && user.account_type_id === ACCOUNT_TYPE_TEMPORARY) ? (
                <div className="movie-club__wrapper">
                    <div className="movie-club__text-intro">
                        <h3 className="movie-club__text-intro-title">
                            Step 2: Login or Sign Up
                        </h3>
                    </div>
                    <div className="movie-club__form-wrapper">
                        <div className="movie-club__form">
                            <div className="movie-club__tabs">
                                <Tabs>
                                    <div label="Login">
                                        <MovieClubLoginComponent
                                            membershipId={selectedMembershipId}
                                        />
                                    </div>
                                    <div label="Sign Up">
                                        <MovieClubSignupComponent
                                            membershipId={selectedMembershipId}
                                        />
                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {isLoggedIn && user?.membership_status === false && user.account_type_id === ACCOUNT_TYPE_NORMAL ? (
                <div className="movie-club__wrapper">
                    <div className="movie-club__text-intro">
                        <h3 className="movie-club__text-intro-title">
                            Step 2: Sign Up.
                        </h3>
                    </div>
                    <div className="movie-club__button-container">
                        <Button
                            additionalClasses="button button__primary"
                            disabled={selectedMembershipId === 0}
                            onClick={handleSubmit}
                        >
                            Join Movie Club
                        </Button>
                    </div>
                </div>
            ) : null}
            <div className="movie-club__wrapper">
                <h2 className="movie-club__section-title">
                    Benefits of Joining
                </h2>
                <div className="movie-club__benefits-wrapper">
                    <div className="movie-club__block">
                        <h3 className="movie-club__text-title">
                            Monthly Movie Magic
                        </h3>
                        <p className="paragraph">
                            Enjoy one fantastic film every month for a year at
                            any of our participating independent cinemas.
                            Immerse yourself in diverse stories on the big
                            screen. Your monthly movie ticket is free at your
                            choice of cinema, and you can also purchase
                            additional tickets at cinemanearme’s everyday low
                            prices.
                        </p>
                    </div>
                    <div className="movie-club__block">
                        <h3 className="movie-club__text-title">
                            Unbeatable Savings
                        </h3>
                        <p className="paragraph">
                            Affordable single, double, and family passes mean
                            limitless cinematic adventures. Say goodbye to
                            budget constraints and hello to unforgettable
                            experiences. A cinemanearme membership saves you up
                            to 65% off retail prices, meaning family or date
                            nights can be any night, or your solo cinema treats
                            are not so few and far between.
                        </p>
                    </div>
                    <div className="movie-club__block">
                        <h3 className="movie-club__text-title">
                            Discover Local Independent Cinemas
                        </h3>
                        <p className="paragraph">
                            Local independent cinemas are more than just
                            theatres. They are community hubs where neighbours
                            share stories, laughter, and unforgettable cinematic
                            moments. Often located right in the heart of the
                            community, your cinema experience can be much more
                            than just a visit to the movies.
                        </p>
                    </div>
                    <div className="movie-club__block">
                        <h3 className="movie-club__text-title">
                            Diverse Content
                        </h3>
                        <p className="paragraph">
                            Experience many films, from action-packed
                            blockbusters to thought-provoking documentaries and
                            heartwarming indie stories. Independent cinemas
                            showcase the full spectrum of storytelling. Free
                            from the usual cinema budget constraints,
                            cinemanearme members can expand their movie-going
                            horizons and discover new and exciting content that
                            wouldn’t be on their usual movie menu.
                        </p>
                    </div>
                    <div className="movie-club__block">
                        <h3 className="movie-club__text-title">
                            Unique Experiences
                        </h3>
                        <p className="paragraph">
                            Each independent cinema has its personality and
                            style. From vintage theatres with history and charm
                            to modern spaces with all the latest in cinematic
                            technology, enjoy a personal and immersive
                            movie-watching experience. Independent cinema owners
                            are passionate about movies and their communities
                            and want to meet you.
                        </p>
                    </div>
                </div>
            </div>
            {!isLoggedIn || user?.membership_status === false ? (
                <div className="movie-club__content">
                    <div className="movie-club__join-wrapper">
                        <h3 className="movie-club__cta-title">
                            A limited number of memberships are available so get
                            in fast if you don’t want to miss out.
                        </h3>
                        <div className="movie-club__btn">
                            <Button
                                additionalClasses="button button__primary"
                                onClick={handleClick}
                            >
                                Join Movie Club
                            </Button>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="movie-club__wrapper">
                <h2 className="movie-club__section-title">How it works</h2>
                <div className="movie-club__works-wrapper">
                    <div className="movie-club__works-block">
                        <div className="movie-club__icon">
                            <SignupIcon className="movie-club__svg" />
                        </div>
                        <div className="movie-club__content-wrap">
                            <h3 className="movie-club__text-title">
                                1. Sign up
                            </h3>
                            <p className="movie-club__text">
                                Login to your cinemanearme account or create an
                                account if you don’t have one yet; make sure you
                                verify your new account
                            </p>
                        </div>
                    </div>
                    <div className="movie-club__works-block">
                        <div className="movie-club__icon">
                            <JoinIcon className="movie-club__svg" />
                        </div>
                        <div className="movie-club__content-wrap">
                            <h3 className="movie-club__text-title">
                                2. Click to join
                            </h3>
                            <p className="movie-club__text">
                                Navigate to the memberships page or click on a
                                membership banner
                            </p>
                        </div>
                    </div>
                    <div className="movie-club__works-block">
                        <div className="movie-club__icon">
                            <MembershipIcon className="movie-club__svg" />
                        </div>
                        <div className="movie-club__content-wrap">
                            <h3 className="movie-club__text-title">
                                3. Choose your membership
                            </h3>
                            <p className="movie-club__text">
                                Decide which membership suits you best, and
                                complete the secure payment; your membership
                                will automatically be added to your account
                            </p>
                        </div>
                    </div>
                    <div className="movie-club__works-block">
                        <div className="movie-club__icon">
                            <DiaryIcon />
                        </div>
                        <div className="movie-club__content-wrap">
                            <h3 className="movie-club__text-title">
                                4. Receive Your monthly allocation
                            </h3>
                            <p className="movie-club__text">
                                Each month, your account will be automatically
                                updated with your free tickets
                            </p>
                        </div>
                    </div>
                    <div className="movie-club__works-block">
                        <div className="movie-club__icon">
                            <SearchIcon className="movie-club__svg" />
                        </div>
                        <div className="movie-club__content-wrap">
                            <h3 className="movie-club__text-title">
                                5. Start browsing
                            </h3>
                            <p className="movie-club__text">
                                Find the movie, sessions and cinema you want to
                                attend, select the session
                            </p>
                        </div>
                    </div>
                    <div className="movie-club__works-block">
                        <div className="movie-club__icon">
                            <CartIcon className="movie-club__svg" />
                        </div>
                        <div className="movie-club__content-wrap">
                            <h3 className="movie-club__text-title">
                                6. Complete for free
                            </h3>
                            <p className="movie-club__text">
                                After adding tickets to your cart, click apply
                                promo and your free ticket allocation will be
                                deducted from the total
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="movie-club__content">
                <div className="movie-club__block">
                    <h4 className="movie-club__sub-title">
                        Join {brand} Movie Club today and embrace a world of
                        enchanting cinema that celebrates diverse storytelling,
                        community warmth, and unique experiences.
                    </h4>
                    <p className="movie-club__text">
                        Rediscover the magic of cinema in the heart of your
                        community. Movie Club - Where Great Stories and
                        Community Unite!
                    </p>
                    {!isLoggedIn || user?.membership_status === false ? (
                        <Button
                            additionalClasses="button button__primary"
                            onClick={handleClick}
                        >
                            Join Movie Club
                        </Button>
                    ) : null}
                </div>
                <div className="movie-club__block">
                    <div className="movie-club__img-wrapper">
                        <img
                            className="movie-club__img"
                            src={image3Url}
                            alt="Group of images"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MovieClub;
