import React from "react";
import CloseIcon from "@components/Icons/CloseIcon";
import "./style.scss";

const Drawer = ({ isOpen, onClose, children, closeButtonAlignment }) => {
    return (
        <>
            <div className={`drawer ${isOpen ? "open" : ""}`}>
                <div className="drawer__content">
                    <button
                        className={`drawer__close drawer__close--${closeButtonAlignment}`}
                        onClick={onClose}
                    >
                        <CloseIcon className="drawer__svg" />
                    </button>
                    {children}
                </div>
            </div>
            {isOpen && (
                <div
                    role="presentation"
                    className="backdrop open"
                    onClick={onClose}
                ></div>
            )}
        </>
    );
};

export default Drawer;
