import React, { useContext } from "react";
import { SessionContext } from "@context/SessionContext";
import SearchResultsByMovie from "./SearchResultsByMovie";
import "./movie-results.scss";

const MovieResults = ({ items }) => {
    const { session, isLoadingSessions } = useContext(SessionContext);

    return (
        <>{!isLoadingSessions && (
            <div className="movie-results__list">
                {items.map((item) => (
                    <SearchResultsByMovie key={item.key} session={session} item={item}/>
                ))}
            </div>
        )}
        </>
    );
};

export default MovieResults;
