import React from "react";

import ApplicationContextProvider from "@context/ApplicationContext";
import AuthContextProvider from "@context/AuthContext";
import BookSessionContextProvider from "@context/BookSessionContext";
import CheckoutComplimentaryContextProvider from "@context/CheckoutComplimentaryContext";
import CheckoutCreditCardContextProvider from "@context/CheckoutCreditCardContext";
import MembershipContextProvider from "@context/MembershipContext";
import MovieContextProvider from "@context/MovieContext";
import ProfileContextProvider from "@context/ProfileContext";
import SearchContextProvider from "@context/SearchContext";
import SessionContextProvider from "@context/SessionContext";
import TicketContextProvider from "@context/TicketContext";
import { TicketsProvider } from "@context/TicketsContext";
import UserContextProvider from "@context/UserContext";
import VenueContextProvider from "@context/VenueContext";
import VoucherManagerContextProvider from "@context/VoucherManagerContext";
import AutoLogin from "@components/AutoLogin";
import RouteCollection from "@components/RouteCollection";
import "@components/App.scss";

const App = ({
                 brandCode,
                 subscriberGroupId,
                 productTypeId,
                 publicSubscriberGroup,
                 cdnUrl,
                 gaCode,
             }) => {

    return (
        <AuthContextProvider>
            <TicketsProvider>
                <VoucherManagerContextProvider>
                    <MembershipContextProvider>
                        <UserContextProvider>
                            <ProfileContextProvider>
                                <ApplicationContextProvider>
                                    <SearchContextProvider>
                                        <SessionContextProvider>
                                            <MovieContextProvider>
                                                <VenueContextProvider>
                                                    <BookSessionContextProvider>
                                                        <CheckoutComplimentaryContextProvider>
                                                            <CheckoutCreditCardContextProvider>
                                                                <TicketContextProvider>
                                                                    <AutoLogin
                                                                        brandCode={brandCode}
                                                                        subscriberGroupId={subscriberGroupId}
                                                                        productTypeId={productTypeId}
                                                                        publicSubscriberGroup={publicSubscriberGroup}
                                                                        cdnUrl={cdnUrl}
                                                                        gaCode={gaCode}>
                                                                        <RouteCollection />
                                                                    </AutoLogin>
                                                                </TicketContextProvider>
                                                            </CheckoutCreditCardContextProvider>
                                                        </CheckoutComplimentaryContextProvider>
                                                    </BookSessionContextProvider>
                                                </VenueContextProvider>
                                            </MovieContextProvider>
                                        </SessionContextProvider>
                                    </SearchContextProvider>
                                </ApplicationContextProvider>
                            </ProfileContextProvider>
                        </UserContextProvider>
                    </MembershipContextProvider>
                </VoucherManagerContextProvider>
            </TicketsProvider>
        </AuthContextProvider>

    );
};

export default App;
