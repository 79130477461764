import React from "react";

const Button = ({
                    onClick,
                    additionalClasses = "",
                    disabled = false,
                    children,
                }) => {

    return (
        <button
            className={additionalClasses}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;
