import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ApplicationContext } from "@context/ApplicationContext";
import Membership from "@components/Membership";
import Layout from "@components/Wrappers/Layout";

const UserMembershipsPage = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;


    return (
        <Layout>
            <Helmet>
                <title>{`Memberships | ${brand}`}</title>
                <meta name="keywords" content={`Memberships | ${brand}`} />
                <meta
                    name="description"
                    content={`Keep track of your membership
                information & history through your ${brand} membership page.`}
                />
                <link rel="canonical" href={`${window.location.protocol}//${window.location.host}/user/membership`} />
            </Helmet>
            <h1 className="movie-club__section-title">Memberships</h1>
            <Membership />
        </Layout>
    );
};

export default UserMembershipsPage;
