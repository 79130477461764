import React, { useContext, useEffect, useState } from "react";
import { ACCOUNT_TYPE_TEMPORARY } from "@config/config";
import flattenErrorMessages from "@functions/flattenErrorMessages";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { ProfileContext } from "@context/ProfileContext";
import AlertMessage from "@components/AlertMessage";
import PasswordStrength from "@components/PasswordStrength";
import Button from "@components/Ui/Button";
import Spinner from "@components/Ui/Spinner";
import TextInputLabel from "@components/Ui/TextInputLabel";

const SignupForm = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const { whiteLabel } = useContext(ApplicationContext);
    const { user, memberId, setMemberId } = useContext(AuthContext);

    const {
        success,
        email,
        isSignupSubmitting,
        setIsSignupSubmitting,
        signup,
        setEmail,
        errorMessages,
        setErrorMessages,
    } = useContext(ProfileContext);

    useEffect(() => {
        return () => {
            setErrorMessages({});
        };
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setIsSignupSubmitting(true);
        setErrorMessages({});
        const data = {
            email_address: email,
            first_name: firstName,
            last_name: lastName,
            password,
            password_repeat: passwordRepeat,
            subscriber_group_code: null,
            referrer_subscriber_id: null,
            member_id: "",
        };

        if (
            user?.account_type_id === ACCOUNT_TYPE_TEMPORARY &&
            user?.subscriber_group_code
        ) {
            data.subscriber_group_code = user.subscriber_group_code;
        }
        if (user?.id) {
            data.referrer_subscriber_id = user.id;
        }

        if (memberId) {
            data.member_id = memberId;
            setMemberId(memberId);
        }

        signup(data);
    };

    if (success) {
        return (
            <div className="body-primary">
                <p className="paragraph">Registration completed successfully.</p>
                <p className="paragraph">
                    Please check your email and click the verify link to activate the
                    account.
                </p>
            </div>
        );
    }

    const errorsFlattened = flattenErrorMessages(errorMessages);

    return (
        <>
            <form onSubmit={onSubmit} className="form__content">
                {whiteLabel?.show_member_no === true ? (
                    <TextInputLabel
                        id="member_id"
                        name="member_id"
                        labelName="Member Number"
                        required={true}
                        value={memberId}
                        onChange={(e) => setMemberId(e.target.value)}
                        // isValueErrored={errorMessages.member_id != ""}
                        placeholder=""
                    />
                ) : null}

                <TextInputLabel
                    id="Email"
                    name="Email"
                    type="email"
                    labelName="Email"
                    required={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isValueErrored={errorMessages.email_address != null}
                    placeholder="Email"
                />
                <TextInputLabel
                    id="Firstname"
                    name="Firstname"
                    labelName="First Name"
                    required={true}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    isValueErrored={errorMessages.first_name != null}
                    placeholder="First Name"
                />
                <TextInputLabel
                    name="Lastname"
                    labelName="Last Name"
                    required={true}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    isValueErrored={errorMessages.last_name != null}
                    placeholder="Last Name"
                />

                <TextInputLabel
                    name="password"
                    type="password"
                    labelName="Password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    isValueErrored={errorMessages.password != null}
                />
                <PasswordStrength password={password} />
                <TextInputLabel
                    name="passwordRepeat"
                    type="password"
                    labelName="Confirm Password"
                    additionalClasses="required mt-2 mb-2"
                    placeholder="Password Repeat"
                    value={passwordRepeat}
                    onChange={(e) => setPasswordRepeat(e.target.value)}
                />
                <Button additionalClasses="button button__primary">
                    {isSignupSubmitting ? (
                        <Spinner text="loading..." />
                    ) : (
                        <span>Sign Up</span>
                    )}
                </Button>
            </form>

            {errorsFlattened.length > 0 && (
                <AlertMessage
                    message="The following errors occurred:"
                    type="error"
                    messageList={errorsFlattened}
                />
            )}
        </>
    );
};

export default SignupForm;
