import React, { useRef } from "react";
import { useDetectOutsideClick } from "@hooks/useDetectOutsideClick";
import UserIcon from "@components/Icons/UserIcon";
import Button from "@components/Ui/Button";
import "./style.scss";

const DropdownMenu = ({ children }) => {
  const dropDownRef = useRef(null);
  const [isOpen, setIsOpen] = useDetectOutsideClick(dropDownRef, false);
    return (
        <div className={`dropdown ${isOpen ? "show" : ""}`}>
            <Button
                additionalClasses="dropdown-menu__button"
                onClick={() => setIsOpen(!isOpen)}>
                <UserIcon />
            </Button>
            <div
                className={`dropdown-menu ${isOpen ? "show" : ""}`}
                ref={dropDownRef}
        >
                {children}
            </div>
        </div>
    );
};

export default DropdownMenu;
