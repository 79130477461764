const API_BASE_URL = "/api";
const ACCOUNT_TYPE_NORMAL = 1;
const ACCOUNT_TYPE_TEMPORARY = 2;
const TYPE_TICKET_ADULT = 1;
const TYPE_VOUCHER_ADULT = 2;
const TYPE_TICKET_MANUAL = 3;
const TYPE_UNUSED = 4;
const TYPE_VOUCHER_KIDS = 5;
const TYPE_VOUCHER_MANAGER = 6;
const TYPE_SEASONAL_VOUCHER = 7;
const CLASS_STANDARD = 1;
const CLASS_GOLD = 2;
const CLASS_PREMIUM = 4;
const PRODUCT_TYPE_MOVIE_REWARDS = 1;
const PRODUCT_TYPE_VOUCHER_MANAGER = 2;
const VILLAGE_CINEMAS = 50;
const EVENT_CINEMAS = 51;
const HOYTS_CINEMAS = 52;
const LUNA_CINEMAS = 70;
const READING_CINEMAS = 72;
const ORANA_CINEMAS = 69;
const PALACE_CINEMAS = 53;
const MOVING_STORY = 76;
const AUTH_APPLICATION_SECURITY = 1;
const AUTH_JWT = 2;
const AUTH_PARTNER_INTEGRATION = 3;
const AUTH_JWT_EMAIL = 1;
const AUTH_JWT_MEMBER_ID = 0;
const AUTH_PARTNER_INTEGRATION_EMAIL = 1;
const AUTH_PARTNER_INTEGRATION_MEMBER_ID = 0;
const TYPE_MOVIE_DETAILS = 1;
const SOURCE_LOCAL_IMAGE = 0;
const SOURCE_BO_IMAGE = 1;
const SOURCE_BO_TRAILER = 2;
const SOURCE_LOCAL_TRAILER = 3;

const SITE_V2 = 1;
const SITE_V3 = 2;

export {
    API_BASE_URL,
    ACCOUNT_TYPE_NORMAL,
    ACCOUNT_TYPE_TEMPORARY,
    TYPE_TICKET_ADULT,
    TYPE_VOUCHER_ADULT,
    TYPE_TICKET_MANUAL,
    TYPE_UNUSED,
    TYPE_VOUCHER_KIDS,
    CLASS_STANDARD,
    CLASS_GOLD,
    CLASS_PREMIUM,
    PRODUCT_TYPE_MOVIE_REWARDS,
    PRODUCT_TYPE_VOUCHER_MANAGER,
    TYPE_VOUCHER_MANAGER,
    TYPE_SEASONAL_VOUCHER,
    VILLAGE_CINEMAS,
    EVENT_CINEMAS,
    HOYTS_CINEMAS,
    LUNA_CINEMAS,
    READING_CINEMAS,
    PALACE_CINEMAS,
    ORANA_CINEMAS,
    MOVING_STORY,
    AUTH_APPLICATION_SECURITY,
    AUTH_JWT,
    AUTH_PARTNER_INTEGRATION,
    AUTH_JWT_EMAIL,
    AUTH_JWT_MEMBER_ID,
    AUTH_PARTNER_INTEGRATION_EMAIL,
    AUTH_PARTNER_INTEGRATION_MEMBER_ID,
    SITE_V2,
    SITE_V3,
    TYPE_MOVIE_DETAILS,
    SOURCE_LOCAL_IMAGE,
    SOURCE_BO_IMAGE,
    SOURCE_BO_TRAILER,
    SOURCE_LOCAL_TRAILER,
};
