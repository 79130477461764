import React from "react";

const ExpiryInput = ({ children }) => {
 return (
     <div className="checkout-card__input-container">
         <label className="form__label">
             Expiry
         </label>
         <div className="checkout-card__input--sub-fields">
             {children}
         </div>
     </div>
 );
};

export default ExpiryInput;
