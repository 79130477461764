import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BookSessionContext } from "@context/BookSessionContext";

const PaymentStatus = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { setError } = useContext(BookSessionContext);
    const errors = queryParams.get("errors");

    useEffect(() => {
        if (errors && errors.length > 0) {
            setError(errors);
        }
    }, [errors]);

    return null;
};

export default PaymentStatus;
