import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { ViewportContext } from "@context/ViewportContext"; // Import ViewportContext
import InlineMessage from "@components/InlineMessage";
import LetsGetStartedContent from "@components/LetsGetStartedContent";
import LogoBar from "@components/LogoBar";
import Button from "@components/Ui/Button";
import Spinner from "@components/Ui/Spinner";
import TextInput from "@components/Ui/TextInput";
import "./style.scss";

const LetsGetStarted = () => {
    const { cdnUrl, whiteLabel } = useContext(ApplicationContext);
    const { width } = useContext(ViewportContext);
    const [username, setUsername] = useState("");

    const {
        addAccountMemberId,
        isLoginSubmitting,
        letsStartLoginError,
        setSuccessMessage,
        errorMessagesStart,
        setErrorMessagesStart,
    } = useContext(AuthContext);

    useEffect(() => {
        return () => {
            setErrorMessagesStart({});
            setSuccessMessage(null);
        };
    }, []);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        setErrorMessagesStart({});
        setSuccessMessage(null);
        const data = {
            email_address: username,
        };
        addAccountMemberId(data);
    };

    const handleUsernameOnchange = (e) => {
        setUsername(e.target.value);
    };

    const getStarted = whiteLabel?.subscriber_group?.subscriber_group_get_started;
    const getStartedBackgroundImage = whiteLabel?.subscriber_group?.subscriber_group_get_started?.background_image;
    const getStartedMobileBackgroundImage = whiteLabel?.subscriber_group?.subscriber_group_get_started?.background_mobile_image;
    const defaultBackgroundImageMobile = `${cdnUrl}asset/img/lets-get-started-mobile.webp`;
    const defaultBackgroundImage = `${cdnUrl}asset/img/lets-get-started.webp`;
    
    const isMobile = width <= 767;
    const backgroundImage = isMobile
        ? getStartedMobileBackgroundImage || defaultBackgroundImageMobile
        : getStartedBackgroundImage || defaultBackgroundImage;

    return (
        <div
            className="lets-get-started__wrapper"
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            }}
        >
            <div className="container">
                <LogoBar />
            </div>
            <div className="container">
                <div className="lets-get-started__container">
                    <div className="lets-get-started__intro">
                        <h1 className="lets-get-started__main-title">
                            {getStarted?.page_title ?? "Unlock unbeatable deals at cinemas nationwide"}
                        </h1>
                        <p className="lets-get-started__text">
                            {getStarted?.page_subtitle ?? "All the major chains and beloved local independents"}
                        </p>
                    </div>
                    <div className="lets-get-started__inner">
                        <div className="lets-get-started__form-wrapper">
                            <p className="lets-get-started__form-title">
                                {getStarted?.where_to_message ?? "Where would you like us to send your purchase?"}
                            </p>
                            <form onSubmit={handleSubmit} className="lets-get-started__form">
                                <TextInput
                                    type="email"
                                    name="username"
                                    placeholder="Enter email address"
                                    additionalClasses={`lets-get-started__input ${
                                        letsStartLoginError ? "lets-get-started__input--error" : ""
                                    } `}
                                    required={true}
                                    value={username}
                                    onChange={handleUsernameOnchange}
                                />
                                <Button additionalClasses="button button__primary lets-get-started__button">
                                    {isLoginSubmitting ? <Spinner text="loading..." /> : <span>Get Started</span>}
                                </Button>
                            </form>

                            {letsStartLoginError && <InlineMessage message={letsStartLoginError} type="error" />}
                        </div>

                        {getStarted?.desktop_banner_url && getStarted?.mobile_banner_url && (
                            <div className="lets-get-started__banner-wrapper">
                                <picture>
                                    <source media="(min-width: 768px)" srcSet={getStarted.desktop_banner_url} />
                                    <img
                                        className="lets-get-started__banner-image"
                                        src={getStarted.mobile_banner_url}
                                        alt={`${whiteLabel?.brand_name || "cinemanearme"} middle banner`}
                                        loading="lazy"
                                    />
                                </picture>
                            </div>
                        )}
                        <LetsGetStartedContent />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LetsGetStarted;
