import React from "react";
import CheckboxIcon from "@components/Icons/CheckboxIcon";
import "./style.scss";

const Checkbox = ({ id, checked, onChange, children }) => {
    return (
        <div className="form__group-wrapper">
            <label className="checkbox">
                <div className="checkbox__input-wrapper">
                    <input
                        id={id}
                        className="checkbox__input"
                        type="checkbox"
                        checked={checked}
                        onChange={onChange}
                    />
                    <span className="checkbox__control">
                        <CheckboxIcon />
                    </span>
                </div>
                <div className="checkbox__label">{children}</div>
            </label>
        </div>
    );
};
export default Checkbox;
