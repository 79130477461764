import React, { useContext } from "react";
import { ApplicationContext } from "@context/ApplicationContext";
import CinemaNearMeAboutUs from "@components/AboutUsContent/CinemaNearMeAboutUs";
import "./style.scss";

const AboutUsContent = () => {
    const { cdnUrl, whiteLabel } = useContext(ApplicationContext);
    const mobileImage = "asset/img/brands/cinemanearme/about-us-mobile.webp";
    const desktopImage = "asset/img/brands/cinemanearme/about-us-desktop.webp";

    const founderImage = "asset/img/photo/choovie-founders.webp";
    const foundersImageUrl = cdnUrl
        ? cdnUrl + founderImage
        : `/${founderImage}`;

    if (whiteLabel?.brand_code === "brand_cinema_near_me") {
        return (
            <>
                <CinemaNearMeAboutUs
                    mobileImage={mobileImage}
                    desktopImage={desktopImage}
                    whiteLabel={whiteLabel}>
                </CinemaNearMeAboutUs>
            </>
        );
    }
    if (whiteLabel?.brand_code !== "brand_cinema_near_me") {
        return (
            <div className="about-us__content">
                <h1 className="main-heading">About Choovie</h1>
                <p className="paragraph">
                    At Choovie, we love the movies. Not just what we see on the screen, but the whole experience. We love
                    the sticky carpet, the popcorn, and the freedom to switch-off and zone out. There is also something special
                    about laughing, crying, and jumping out of your seat with a bunch of strangers. The shared emotion
                    enhances the experience for everyone.
                </p>
                <p className="paragraph">
                    However, a few years ago we noticed that whilst cinemas were busy during certain times on the weekends,
                    all other times, they were relatively dead. We sensed an opportunity. An opportunity to make every night
                    movie night.
                </p>
                <p className="paragraph">
                    So that’s our mission - to make every night movie night.
                </p>
                <p className="paragraph">
                    Choovie is Australia’s home of cheap cinema tickets. With Australia’s largest cinema network, and a unique
                    airline style pricing system, Choovie has a discount cinema ticket anywhere and any day of the week!
                </p>
                <p className="paragraph">
                    And the name, Choovie? It’s simple, mash together Choose Movie….and hey presto, you’ve got, Choovie!
                </p>

                <h2 className="heading">How It Works</h2>
                <p className="paragraph">
                    You no longer have to wait until “Cheap Tuesday” to get a great movie deal. On Choovie, every unpopular
                    session is cheap. So you can get a discount movie ticket any day of the week. Our unique pricing algorithm
                    predicts which sessions will be the least popular and prices them accordingly. Even more popular sessions
                    are usually much cheaper than at the cinemas.
                </p>

                <h2 className="heading">How To Get Cheap Movie Tickets</h2>
                <div className="movie__trailer">
                    <video
                        className="movie__video"
                        src="https://choovie-movie-image.s3-ap-southeast-2.amazonaws.com/videos/Choovie-Sub-Walk-Through.mp4"
                        controls
                        width="100%"
                    >
                        Sorry, your browser doesn&apos;t support embedded videos.
                    </video>
                </div>

                <h2 className="heading">The Founders</h2>
                <p className="paragraph">
                    Sonya and Shane are movie fans and also life partners. In 2016 they went along to a Wednesday evening
                    session at their local multiplex and discovered 35 people in the entire venue. Shane, an Economist by
                    trade, suggested a demand-based pricing approach would be great for cinemas and customers alike. One year,
                    one baby, and a lot of nagging later, Sonya and Shane quit their jobs and Choovie was born.
                </p>
                <div className="img about-us__img">
                    <img alt="image of founders" src={foundersImageUrl} />
                </div>
            </div>
        );
    }
};

export default AboutUsContent;
