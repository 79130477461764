import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ApplicationContext } from "@context/ApplicationContext";
import Privacy from "@components/Privacy";
import Layout from "@components/Wrappers/Layout";


const PrivacyPolicyPage = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;

    return (
        <Layout>
            <Helmet>
                <title>{`${brand} | ${whiteLabel?.brand_title}`}</title>
                <meta name="keywords" content={`${brand} | ${whiteLabel?.brand_title}`} />
                <link rel="canonical" href={`${window.location.protocol}//${window.location.host}/privacy`} />
            </Helmet>
            <Privacy />
        </Layout>
    );
};

export default PrivacyPolicyPage;
