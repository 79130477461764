import React, { useContext, useEffect, useRef, useState } from "react";
import useAxios from "axios-hooks";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "@config/config";
import toSeoUrl from "@functions/toSeoUrl";
import { AuthContext } from "@context/AuthContext";
import Loader from "@components/Ui/Loader";
import LeftArrowIcon from "@components/V3/Icons/LeftArrowIcon";
import RightArrowIcon from "@components/V3/Icons/RightArrowIcon";
import VenueSelector from "@components/V3/VenueSelector";
import "./style.scss";

const CurrentlyShowing = () => {
    const [{ data, loading }] = useAxios(`${API_BASE_URL}/movie/now-showing`);
    const { nowShowing, setNowShowing, setComingSoon } = useContext(AuthContext);
    const containerRef = useRef(null);

    const [scrollState, setScrollState] = useState({
        isOverflowing: false,
        canScrollBack: false,
        canScrollForward: true,
    });

    useEffect(() => {
        if (data) {
            setComingSoon(data.data?.comingSoon);
            setNowShowing(data.data?.nowShowing);
        }
    }, [data]);


    const handleScrollForward = () => {
        const container = containerRef.current;
        if (!container) return;

        const maxScrollLeft = container.scrollWidth - container.clientWidth;
        const newScrollPosition = Math.min(container.scrollLeft + 200, maxScrollLeft);
        container.scrollTo({ left: newScrollPosition, behavior: "smooth" });

        setTimeout(() => {
            setScrollState({
                isOverflowing: maxScrollLeft > 0,
                canScrollBack: newScrollPosition > 0,
                canScrollForward: newScrollPosition < maxScrollLeft,
            });
        }, 300);
    };

    const handleScrollBack = () => {
        const container = containerRef.current;
        if (!container) return;

        const newScrollPosition = Math.max(container.scrollLeft - 200, 0);
        container.scrollTo({ left: newScrollPosition, behavior: "smooth" });

        setTimeout(() => {
            setScrollState({
                isOverflowing: container.scrollWidth > container.clientWidth,
                canScrollBack: newScrollPosition > 0,
                canScrollForward: newScrollPosition < container.scrollWidth - container.clientWidth,
            });

        }, 300);
    };

    useEffect(() => {
        if (nowShowing && nowShowing.length > 3 && containerRef.current) {
            const container = containerRef.current;
            const isOverflow = container.scrollWidth > container.clientWidth;
            setScrollState({
                isOverflowing: isOverflow,
                canScrollForward: isOverflow,
                canScrollBack: false,
            });
        }
    }, [nowShowing]);


    if (loading) {
        return <Loader isLoading={loading} text="Fetching Movies" />;
    }

    if (data.data.length === 0) {
        return (
            <div className="search__container">
                <p className="paragraph">Sorry there are no movies currently showing near you.</p>
            </div>
        );
    }
    
    return (
        <div className="currently-showing">
            <h2 className="currently-showing__title">Now Showing</h2>
            <VenueSelector />
            <div className="currently-showing__carousel">
                {scrollState.canScrollBack && (
                    <button className="currently-showing__arrow currently-showing__arrow--left" aria-label="Previous movies" onClick={handleScrollBack}>
                        <LeftArrowIcon className="currently-showing__arrow--prev" />
                    </button>
                )}

                <div className={scrollState.canScrollBack ? "currently-showing__list active" : "currently-showing__list"} ref={containerRef}>
                    {nowShowing && nowShowing.map && nowShowing.map((movie, index) =>
                        movie.movie_title ? (
                            <div className="currently-showing__wrapper" key={movie.movie_id}>
                                <Link className="currently-showing__link" key={movie.movie_id} to={{ pathname: `/movie/${movie.movie_id}/${toSeoUrl(movie.movie_title)}` }}>
                                    <img
                                        className="currently-showing__image"
                                        src={movie?.movie_image}
                                        alt={movie?.movie_title} />
                                </Link>
                                <p className="currently-showing__movie-title">{movie?.movie_title}</p>
                                <div className="currently-showing__info">
                                    {movie?.rating ? (
                                        <div
                                            className={`currently-showing__movie-rating currently-showing__movie-rating--${movie?.rating.replace(
                                                "+",
                                                "",
                                            )}`}
                                        >
                                            {movie?.rating}
                                        </div>
                                    ) : null}
                                    {movie?.running_time ? (
                                        <div className="currently-showing__movie-time">
                                            {movie?.running_time} min
                                        </div>
                                    ) : null}
                                </div>
                            </div>


                        ) : null,
                    )}
                </div>

                {scrollState.canScrollForward && scrollState.isOverflowing && (
                    <button className="currently-showing__arrow currently-showing__arrow--right" aria-label="Next movies" onClick={handleScrollForward}>
                        <RightArrowIcon className="currently-showing__arrow--next" />
                    </button>
                )}
            </div>

        </div>
    );
};


export default CurrentlyShowing;
