import React, { useState } from "react";
import Button from "@components/Ui/Button";
import "./style.scss";

const Tab = ({ label, activeTab, onClick }) => {
    return (
        <Button
            additionalClasses={`button tab__button ${activeTab === label ? "tab__button--active" : ""}`}
            onClick={() => onClick(label)}
      >
            {label}
        </Button>
    );
  };

  const Tabs = ({ children }) => {
    const [activeTab, setActiveTab] = useState(children[0].props.label);

    const onClickTab = (tab) => {
      setActiveTab(tab);
    };

    return (
        <div className="tab">
            <div className="tab__buttons">
                {children.map((child) => {
            const { label } = child.props;
            return (
                <Tab
                    key={label}
                    label={label}
                    activeTab={activeTab}
                    onClick={onClickTab}
              />
            );
          })}
            </div>
            <div className="checkout-card__wrapper">
                {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
            </div>
        </div>
    );
  };

  export default Tabs;

