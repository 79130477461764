import React from "react";
import useTrackingViewItem from "@hooks/useTrackingViewItem";
import BookSession from "@components/Bookings/BookSession";
import Layout from "@components/Wrappers/Layout";

const TicketPurchasePage = () => {
    // Google Analytics tracking for viewing items
    useTrackingViewItem();
    return (
        <Layout>
            <BookSession />
        </Layout>
    );
};

export default TicketPurchasePage;
