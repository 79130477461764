import React from "react";
import VoucherSearch from "@components/VoucherManager/VoucherSearch";
import Layout from "@components/Wrappers/Layout";

const VoucherManagerSearchPage = () => {


    return (
        <Layout>
            <VoucherSearch type="voucherManager"/>
        </Layout>
    );
};

export default VoucherManagerSearchPage;
