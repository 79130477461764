import React from "react";
import BrandName from "@components/BrandName";
import TicketClarificationIcon  from "@components/Icons/TicketClarificationIcon";
import "./style.scss";

const TicketClarification = () => {
    return (
        <div className="ticket-clarification">
            <div className="ticket-clarification__icon">
                <TicketClarificationIcon />
            </div>
            <div className="ticket-clarification__message">
                <p className="ticket-clarification__text">
                    PLEASE NOTE: Not all purchases made via <BrandName /> represent a secured seat.
                </p>
                <p className="ticket-clarification__text">
                    Please download your voucher. Visit the cinema&apos;s website and use the voucher number and PIN to secure your seat.
                </p>
                <p className="ticket-clarification__text">
                    This transaction will appear as CHOOVIE on your bank statement.
                </p>
            </div>
        </div>
    );
};

export default TicketClarification;
