import React from "react";
import Button from "@components/Ui/Button";
import "./style.scss";

const VoucherButtons = ({ value, setValue }) => {
    const displayValue = Number.isInteger(value) && value > 0
        ? value
        : "";
    const localValue = Number.isInteger(value) && value > 0
        ? value
        : 0;
    return (
        <div className="voucher-search__buttons">
            <Button
                additionalClasses="voucher-search__button voucher-search__button--decrement"
                onClick={() => {
                    const val = parseInt(localValue) - 1;
                    setValue(val > 0 ? val : 0);
                }}>
                -
            </Button>
            <input
                type="text"
                aria-label="quantity input"
                value={displayValue}
                className="number-input__text"
                placeholder="0"
                onChange={(e) => setValue(parseInt(e.target.value) || 1)}
            />
            <Button
                additionalClasses="voucher-search__button voucher-search__button--increment"
                onClick={() => setValue(parseInt(localValue) + 1)}>
                +
            </Button>
        </div>
    );
};

export default VoucherButtons;
