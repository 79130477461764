import React from "react";

const TorresStraitFlag = ({ className="" }) => {
    return (
        <svg className={className} width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40 0H0V24H40V0Z" fill="#00CA00"/>
            <path d="M40 5.14258H0V18.8567H40V5.14258Z" fill="#000100"/>
            <path d="M40 6H0V18H40V6Z" fill="#0001FD"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.7998 16.6341L16.5046 14.8137C16.5046 14.8137 15.3113 13.1582 15.3113 11.0066C15.3113 8.85499 17.0161 7.19995 19.062 7.19995L19.9146 8.5238L20.7668 7.19995C22.4721 7.19995 24.518 8.5238 24.518 10.8412C24.518 13.1582 23.3242 14.8137 23.3242 14.8137L25.1998 16.8L21.6194 15.9722V10.6759C21.6194 10.3447 20.9376 9.68275 19.9146 9.68275C19.062 9.68275 18.3802 10.3447 18.3802 10.6759V15.8068L14.7998 16.6341Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.2225 12.7856L19.4675 12.0256L18.8184 11.5384L19.6388 11.5295L19.9154 10.7798L20.1777 11.5342L20.9976 11.558L20.3393 12.0331L20.5698 12.7978L19.9005 12.3367L19.2225 12.7856Z" fill="white"/>
        </svg>
    );
};

export default TorresStraitFlag;
