import React, { useContext, useEffect } from "react";
import { VoucherManagerContext } from "@context/VoucherManagerContext";
import FeatureFilm from "@components/FeatureFilm";
import Loader from "@components/Ui/Loader";
import VoucherItems from "@components/VoucherManager/VoucherItems";
import VoucherSearchBar from "@components/VoucherManager/VoucherSearchBar";
import VoucherSummary from "@components/VoucherManager/VoucherSummary";
import "./style.scss";

const VoucherSearch = ( type ) => {
    const {
        isVoucherBucketSearchLoading,
        selectedItems,
        vouchers,
        selectedCinemaGroup,
        selectedTicketClass,
        selectedTicketType,
        fetchVoucherBucketSearch,
        setVoucherQuantity,
        setSelectedItems
    } = useContext(VoucherManagerContext);


    useEffect(() => {
        fetchVoucherBucketSearch();
    }, []);


    const purchasableItemKey = (item) => {
        return item.id;
    };

    const handleChangeItemQuantity = (selection, idKey, quantity ) => {
        const newSelection = [...selection];
        const index = newSelection.findIndex((item) => item.key === idKey);

        if (index === -1) {
            const choice = vouchers.find(
                (item) => idKey === purchasableItemKey(item)
            );
            newSelection.push({
                id: idKey,
                key: idKey,
                type,
                bucket_id: choice.voucher_code_bucket.id,
                session_inventory_id: choice.session_inventory_id,
                price_cents: choice.price_cents,
                rrp_price_cents: choice.rrp_price_cents,
                quantity: 1,
            });
            setVoucherQuantity(1);
        }
        else {
            newSelection[index].quantity = quantity;
            setVoucherQuantity(quantity);
        }

        const finalSelection= newSelection.filter(function (item) {
            return item.quantity > 0;
        });

        setSelectedItems(finalSelection);
    };
    
    return (
        <>
            <h1 className="voucher-search__title">Select your discount movie vouchers</h1>
            <FeatureFilm />
            <div className="voucher-search">
                <>
                    {isVoucherBucketSearchLoading? (
                        <Loader isLoading={isVoucherBucketSearchLoading} />
                    ): (
                        <>
                            <VoucherSearchBar />
                        </>
                    )}
                </>
                <div className="voucher-search__results">
                    <VoucherItems
                        items={vouchers}
                        selectedItems={selectedItems}
                        setQuantity={handleChangeItemQuantity}
                        purchasableItemKey={purchasableItemKey} />
                </div>
                <VoucherSummary items={selectedItems} ticket_type={selectedTicketType} ticket_clas={selectedTicketClass} cinema_group={selectedCinemaGroup} type={type}/>
            </div>
        </>
    );
};

export default VoucherSearch;
