import React from "react";
import CreatePasswordForm from "@components/Form/SetPasswordPostSignUpForm";
import Layout from "@components/V3/Layout";
import FormWrapper from "@components/Wrappers/FormWrapper";

const V3CreatePasswordPage = () => {

    return (
        <Layout>
            <FormWrapper pageTitle="Create Password">
                <CreatePasswordForm />
            </FormWrapper>
        </Layout>
    );
};

export default V3CreatePasswordPage;
