import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ApplicationContext } from "@context/ApplicationContext";
import { SessionContext } from "@context/SessionContext";
import TicketIcon2 from "@components/Icons/TicketIcon2";
import NumberInput from "@components/NumberInput";
import { getSessionTextFull } from "@utils/ticketUtils";
import "./style.scss";


const PurchasableItems = ({
                              items = [],
                              selectedItems = [],
                              setQuantity = () => {
                              },
                              purchasableItemKey = () => {
                              },
                              promo,
                          }) => {

    const { cdnUrl, whiteLabel } = useContext(ApplicationContext);
    const { hasCandyBarInventory } = useContext(SessionContext);

    return (
        <>
            <div className="purchasable__list" key="tickets">
                <div className="purchasable__desc">Vouchers</div>
                {items && items.map((item, i) => {
                    const isSession = !!item.session_inventory_id;
                    const idKey = purchasableItemKey(item);
                    let icon = item.ticket_type_name || item.candy_bar;
                    icon = icon.replace(/\s+/g, "-").toLowerCase();
                    const purchasableItemIconUrl = (
                        <TicketIcon2 className="purchasable__svg" />
                    );

                    const price = item.ticket_price_cents;
                    const index = selectedItems.findIndex((selectedItem) => selectedItem.key === idKey);
                    const quantity = index !== -1 ? selectedItems[index].quantity : 0;
                    let itemTotal = quantity === 0 ? price : price * quantity;
                    let discounted = false;
                    let promoIdKey = "";
                    const promoItems = {};

                    if (promo?.item_uid) {
                        promoIdKey = promo.item_uid
                            .split("/")
                            .map(function(item) {
                                const val = item.substr(
                                    0,
                                    item.lastIndexOf("-"),
                                );
                                promoItems[val] = item.substr(
                                    item.lastIndexOf("-") + 1,
                                    item.length,
                                );
                                return val;
                            });
                    }

                    if (promo?.applied && promo?.discount && promoIdKey.indexOf(idKey) > -1) {
                        itemTotal -= promoItems[idKey];
                        discounted = true;
                    }

                    const priceClassNames = `purchasable__price${quantity === 0 ? " purchasable__quantity-none" : ""}${discounted ? " purchasable__discounted" : ""}`;

                    if (item?.ticket_type_name) {
                        return (
                            <div
                                key={`ticket__item-${i}`}
                                className={`purchasable__item purchasable__item-${i}`}
                                data-idkey={idKey}
                            >
                                <div className="purchasable__group">
                                    <div className="purchasable__icon">
                                        {purchasableItemIconUrl}
                                        {item.ticket_type_id &&
                                        item.ticket_type_id === "5" ? (
                                            <p className="purchasable__svg-label">
                                                CHILD
                                            </p>
                                        ) : (
                                            <p className="purchasable__svg-label">
                                                ADULT
                                            </p>
                                        )}
                                    </div>

                                    {whiteLabel && whiteLabel?.show_ticket_class === true ? (
                                        <div className={`purchasable__label ${discounted ? " purchasable__discounted" : ""}`}>
                                            {getSessionTextFull(item)}
                                        </div>

                                    ) : (
                                        <div className={`purchasable__label ${discounted ? " purchasable__discounted" : ""}`}>{item.ticket_type_name}</div>
                                    )}

                                </div>

                                <div className="purchasable__price-group">
                                    {(item.rrp_pricing_cents !== null && item.rrp_pricing_cents !== 0) ? (
                                        <div className="purchasable__rrp">
                                            ${(item.rrp_pricing_cents / 100).toFixed(2)}
                                        </div>
                                    ) : null}

                                    <div className={priceClassNames}>
                                        ${(itemTotal / 100).toFixed(2)}
                                    </div>

                                    <NumberInput
                                        value={quantity}
                                        setValue={(newQuantity) => {
                                            setQuantity(
                                                selectedItems,
                                                idKey,
                                                newQuantity,
                                                isSession,
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    }
                })}
            </div>

            {hasCandyBarInventory.length > 0 ? (
                <div className="purchasable__list" key="confectionary">
                    <div className="purchasable__desc">Confectionary</div>
                    {items && items.map((item, i) => {
                        const isSession = !!item.session_inventory_id;
                        const idKey = purchasableItemKey(item);
                        let icon = item.ticket_type_name || item.candy_bar;
                        icon = icon.replace(/\s+/g, "-").toLowerCase();
                        const purchasableItemIconUrl = `${cdnUrl}asset/img/purchasableItemsIcons/${icon}.png`;
                        const purchasableItemDefaultIconUrl = `${cdnUrl}asset/img/purchasableItemsIcons/choc-top-icecream.png`;

                        const price = item.candy_bar_price;
                        const index = selectedItems.findIndex((selectedItem) => selectedItem.key === idKey);
                        const quantity = index !== -1 ? selectedItems[index].quantity : 0;
                        let itemTotal = quantity === 0 ? price : price * quantity;
                        let discounted = false;
                        let promoIdKey = "";
                        const promoItems = {};

                        if (promo?.item_uid) {
                            promoIdKey = promo.item_uid
                                .split("/")
                                .map(function(item) {
                                    const val = item.substr(
                                        0,
                                        item.lastIndexOf("-"),
                                    );
                                    promoItems[val] = item.substr(
                                        item.lastIndexOf("-") + 1,
                                        item.length,
                                    );
                                    return val;
                                });
                        }
                        if (
                            promo?.applied &&
                            promo?.discount &&
                            promoIdKey.indexOf(idKey) > -1
                        ) {
                            itemTotal -= promoItems[idKey];
                            discounted = true;
                        }

                        const priceClassNames = `purchasable__price ${quantity === 0 ? " purchasable__quantity-none" : ""}`;

                        if (item?.candy_bar) {
                            return (
                                <div
                                    key={`confectionary__item-${i}`}
                                    className={`purchasable__item purchasable__item-${i}`}
                                    data-idkey={idKey}
                                >
                                    <div className="purchasable__group">
                                        <div className="purchasable__icon">
                                            <img
                                                src={
                                                    purchasableItemIconUrl ||
                                                    purchasableItemDefaultIconUrl
                                                }
                                                alt={icon}
                                                className="purchasable__img"
                                            />
                                        </div>
                                        <div className="purchasable__label">{item.candy_bar}</div>
                                    </div>
                                    <div className="purchasable__price-group">
                                        <div className="purchasable__rrp">
                                            {item?.candy_bar_rrpPricing !== null ? (
                                                <>
                                                    ${(item?.candy_bar_rrpPricing / 100).toFixed(2)}
                                                </>
                                            ) : null}
                                        </div>
                                        <div className={priceClassNames}>
                                            ${(itemTotal / 100).toFixed(2)}
                                        </div>
                                        <NumberInput
                                            value={quantity}
                                            setValue={(newQuantity) => {
                                                setQuantity(
                                                    selectedItems,
                                                    idKey,
                                                    newQuantity,
                                                    isSession,
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            ) : null}
        </>
    );
};

PurchasableItems.propTypes = {
    items: PropTypes.array.isRequired,
    selectedItems: PropTypes.array.isRequired,
    setQuantity: PropTypes.func,
};

export default PurchasableItems;
