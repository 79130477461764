import React from "react";

const CalenderIcon = () => {
    return (
        <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1111 1.9H14.1667V0H12.2778V1.9H4.72222V0H2.83333V1.9H1.88889C0.840556 1.9 0.00944444 2.755 0.00944444 3.8L0 17.1C0 18.145 0.840556 19 1.88889 19H15.1111C16.15 19 17 18.145 17 17.1V3.8C17 2.755 16.15 1.9 15.1111 1.9ZM15.1111 17.1H1.88889V6.65H15.1111V17.1ZM3.77778 8.55H8.5V13.3H3.77778V8.55Z" fill="currentColor"/>
        </svg>
    );
};

export default CalenderIcon;
