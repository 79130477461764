import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { UserContext } from "@context/UserContext";
import { ViewportContext } from "@context/ViewportContext";
import AlertMessage from "@components/AlertMessage";
import LogoBar from "@components/LogoBar";
import RedemptionDetails from "@components/RedemptionDetails";
import "./style.scss";

const Welcome = () => {
    const { cdnUrl, whiteLabel } = useContext(ApplicationContext);
    const { width } = useContext(ViewportContext);
    const { promoDetails, fetchApplyPromoAvailability } = useContext(UserContext);
    const { user } = useContext(AuthContext);
    const { redemptionCount, redemptionLimit, cap_purchases } = promoDetails;
    const welcomePage = whiteLabel?.subscriber_group?.subscriber_group_welcome_page;
    const welcomeBackgroundImage = whiteLabel?.subscriber_group?.subscriber_group_welcome_page?.background_image;
    const welcomeMobileBackgroundImage = whiteLabel?.subscriber_group?.subscriber_group_welcome_page?.background_mobile_image;
    const defaultBackgroundImageMobile = `${cdnUrl}asset/img/lets-get-started-mobile.webp`;
    const defaultBackgroundImage = `${cdnUrl}asset/img/lets-get-started.webp`;
    const isMobile = width <= 767;
    const backgroundImage = isMobile
        ? welcomeMobileBackgroundImage || defaultBackgroundImageMobile
        : welcomeBackgroundImage || defaultBackgroundImage;
    

    useEffect(() => {
        if (user?.id && promoDetails) {
            fetchApplyPromoAvailability();
        }
    }, [user?.id]);
    
    return (
        <div className="welcome__wrapper"
            style={{
                 backgroundImage: `url(${backgroundImage})`,
                 backgroundSize: "cover",
                 backgroundPosition: "center",
                 backgroundRepeat: "no-repeat",
             }}>
            <div className="container">
                <LogoBar />
            </div>
            <div className="welcome__content">
                <h1 className="welcome__title">{welcomePage?.page_title ?? "Welcome"}</h1>
                <h2 className="welcome__subtitle">{welcomePage?.page_subtitle}</h2>
                {redemptionCount >= 0 && redemptionLimit ? (
                    <RedemptionDetails
                        redemptionCount={redemptionCount}
                        redemptionLimit={redemptionLimit}
                        brandName={whiteLabel?.brand_name}
                    />
                ) : null}
                <div className="welcome__cta-wrapper">
                    <p className="welcome__text">{welcomePage?.page_message ?? "Just follow the link below, find your local cinema, pick a session and checkout."}</p>
                    <div className="welcome__cta">
                        <Link className="button button__primary" to="/">
                            Find a session
                        </Link>
                    </div>
                </div>
                {(cap_purchases === 1 && redemptionCount >= redemptionLimit) ? (
                    <div className="welcome__error-wrapper">
                        <AlertMessage
                            message="Monthly allocation exhausted, next allocation available on the 1st of next month."
                            type="error"
                        />
                    </div>
                ) : null}
                {welcomePage?.desktop_banner_url && (
                    <div className="welcome__banner-wrapper">
                        <picture>
                            <source media="(min-width: 768px)" srcSet={welcomePage?.desktop_banner_url} />
                            <img className="welcome__banner-image" src={welcomePage?.mobile_banner_url} alt={`${whiteLabel?.brand_name} middle banner`} />
                        </picture>
                    </div>
                )}
            </div>
        </div>
    );
};


export default Welcome;
