import React from "react";

const AboriginalFlag = ({ className="" }) => {
	return (
    <svg className={className} width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="flag">
            <path id="Vector" d="M0 0H40V24H0V0Z" fill="black"/>
            <path id="Vector_2" d="M0 12H40V24H0V12Z" fill="#CC0000"/>
            <path id="Vector_3" d="M20 18C23.3137 18 26 15.3137 26 12C26 8.68629 23.3137 6 20 6C16.6863 6 14 8.68629 14 12C14 15.3137 16.6863 18 20 18Z" fill="#FFFF00"/>
        </g>
    </svg>
	);
};

export default AboriginalFlag;
