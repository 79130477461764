import React from "react";
import MovieDetails from "@components/MovieDetails";
import Layout from "@components/Wrappers/Layout";

const MovieDetailPage = () => {
    return (
        <Layout>
            <MovieDetails />
        </Layout>
    );
};

export default MovieDetailPage;
