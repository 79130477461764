import { format, isToday, isTomorrow, parseISO } from "date-fns";

export const renderDate = (date) => {
    const parsedDate = parseISO(date);
    if (isToday(parsedDate)) return "Today";
    if (isTomorrow(parsedDate)) return "Tomorrow";
    return format(parsedDate, "EEE d MMM");
};

export const getTime = (sessionDate) => {
    if (!sessionDate) {
        return "";
    }
    const date = new Date(sessionDate);
    if (Number.isNaN(date.getTime())) {
        return "";
    }
    return date.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
    }).toLowerCase().replace(/\s/g, " ");
};
