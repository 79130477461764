import React, { useContext, useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import { ApplicationContext } from "@context/ApplicationContext";
import { SearchContext } from "@context/SearchContext";
import Loader from "@components/Ui/Loader";
import DateSelector from "@components/V3/DateSelector";
import FilteredExperience from "@components/V3/Experiences/filteredExperience";
import SelectExperienceSessions from "@components/V3/Experiences/selectExperience";
import { groupSessionsByVenue } from "@utils/sessionUtils";
import "./style.scss";

const Experiences = ({ defaultExperience = "" }) => {
    const location = useLocation();
    const { cdnUrl } = useContext(ApplicationContext);
    const { allSessions, fetchAllSessions, setMovie, getLocation, setGetLocation, isLoadingSessions } = useContext(SearchContext);
    const fallbackImage = `${cdnUrl}asset/img/placeholder.jpeg`;
    const [groupedVenues, setGroupedVenues] = useState({});
    const [selection, setSelection] = useState({ date: "", experience: defaultExperience });
    const locationFetched = useRef(false);
    const [uniqueDates, setUniqueDates] = useState([]);

    useEffect(() => {
        const handleFetchSessions = (lat = null, lng = null) => {
            setMovie(null);
            setGetLocation(false);
            fetchAllSessions({ lat, lng });
        };

        if (!navigator.geolocation) {
            handleFetchSessions();
        } else {
            setGetLocation(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (!locationFetched.current) {
                        locationFetched.current = true;
                        handleFetchSessions(position.coords.latitude, position.coords.longitude);
                    } else {
                        setGetLocation(false);
                    }
                },
                () => handleFetchSessions(),
                { enableHighAccuracy: true, timeout: 10000, maximumAge: 60000 }
            );
        }
    }, []);

    useEffect(() => {
        const dates = Array.from(
            new Set(allSessions.map((session) => format(new Date(session.session_date), "yyyy-MM-dd")))
        ).sort((a, b) => new Date(a) - new Date(b));
        setUniqueDates(dates);
    }, [allSessions]);

    const filterSessions = (date, experience) => {
        const filtered = allSessions.filter((session) => {
            const sessionDate = format(new Date(session.session_date), "yyyy-MM-dd");
            const matchesDate = sessionDate === date;
            const matchesExperience = experience ? session.experience_type === experience : true;
            return matchesDate && matchesExperience;
        });
        setGroupedVenues(groupSessionsByVenue(filtered));
    };

    useEffect(() => {
        const experienceMap = {
            standard: "STND",
            premium: "PREM",
            gold: "GOLD",
        };
        const pathParts = location.pathname.split("/");
        const expFromUrl = experienceMap[pathParts[2]] || defaultExperience;
        setSelection((prev) => ({
            ...prev,
            experience: expFromUrl,
        }));
    }, [location.pathname]);

    useEffect(() => {
        if (!selection.date && uniqueDates.length > 0) {
            setSelection((prev) => {
                const updatedSelection = { ...prev, date: uniqueDates[0] };
                filterSessions(updatedSelection.date, updatedSelection.experience);
                return updatedSelection;
            });
        }
    }, [uniqueDates]);

    useEffect(() => {
        if (selection.date && selection.experience !== undefined) {
            filterSessions(selection.date, selection.experience);
        }
    }, [selection.date, selection.experience]);

    const bannerImages = {
        "/experiences": "experiences-banner.jpg",
        "/experiences/standard": "STND-banner.png",
        "/experiences/premium": "PREM-banner.png",
        "/experiences/gold": "GOLD-banner.png",
    };

    const currentPath = location.pathname;
    const bannerImage = bannerImages[currentPath] || "STND-banner.png";
    const isMainExperiencesPage = currentPath === "/experiences";


    return (
        <div className="experiences">
            <div className="experiences__wrapper">
                <picture className="experiences__picture">
                    <source media="(min-width: 768px)" srcSet={`${cdnUrl}asset/img/${bannerImage}`} />
                    <img className="experiences__image" src={`${cdnUrl}asset/img/${bannerImage}`} alt="Experiences Image" />
                </picture>
                {!isMainExperiencesPage && (
                    <>
                        <div className="experiences__text-container">
                            <h1 className="experiences__title">Experiences</h1>
                            {!isMainExperiencesPage && (
                                <p className="experiences__text">
                                    Experience type:{" "}
                                    {location.pathname.split("/").pop().charAt(0).toUpperCase() +
                                        location.pathname.split("/").pop().slice(1)}
                                </p>
                            )}
                        </div>
                        <div className={`experiences__triangle experiences__triangle--${selection.experience}`}></div>
                    </>
                )}
            </div>
            {isMainExperiencesPage && (
                <>
                    <div className="experiences__intro">
                        <h1 className="experiences__main-title">Experiences</h1>
                        <p className="paragraph">Discover the cinema experience that fits your vibe — with our fabulous cinema partners bringing you everything from luxe Gold Class to cosy Standard, and all points in between.</p>
                    </div>
                    <SelectExperienceSessions
                        experience={selection.experience}
                        setExperience={(exp) => setSelection((prev) => ({ ...prev, experience: exp }))}
                    />
                </>
            )}
            {!isMainExperiencesPage && (
                <div className="experiences__main-list">
                    <DateSelector
                        uniqueDates={uniqueDates}
                        handleClickDate={(date) => setSelection((prev) => ({ ...prev, date }))}
                        selectedDate={selection.date}
                    />
                    {getLocation || isLoadingSessions ? (
                        <Loader isLoading={getLocation || isLoadingSessions} text="Fetching Movies" />
                    ) : Object.keys(groupedVenues).length > 0 ? (
                        <FilteredExperience
                            groupedVenues={groupedVenues}
                            selection={selection}
                            onImageError={(e) => (e.target.src = fallbackImage)}
                        />
                    ) : (
                        <div className="experiences__message">
                            <p className="experiences__message-title">Sorry, we couldn&apos;t find any results for your chosen cinemas</p>
                            <p className="experiences__message-text">Please select another experience type, or select a different date</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Experiences;
