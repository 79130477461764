import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ACCOUNT_TYPE_NORMAL, AUTH_APPLICATION_SECURITY, AUTH_JWT, AUTH_PARTNER_INTEGRATION, PRODUCT_TYPE_MOVIE_REWARDS } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import "./style.scss";

const UserProfile = () => {
    const { whiteLabel, productTypeId } = useContext(ApplicationContext);
    const { user, isLoggedIn, logout, closeDrawer } = useContext(AuthContext);
    const partnerAuthFlag = whiteLabel?.subscriber_group?.authentication_type === AUTH_PARTNER_INTEGRATION;
    const jwtAuthFlag = whiteLabel?.subscriber_group?.authentication_type === AUTH_JWT;
    const appAuthFlag = whiteLabel?.subscriber_group?.authentication_type === AUTH_APPLICATION_SECURITY;

    const handleCloseDrawer = () => {
        closeDrawer("loginSignUpDrawer");
    };

    return (
        <div className="user-profile">
            {isLoggedIn && user?.account_type_id === ACCOUNT_TYPE_NORMAL && (
                <>
                    {(appAuthFlag || partnerAuthFlag) && (
                        <div className="user-profile__container">
                            <div className="user-profile__name">
                                {`${user.first_name} ${user.last_name}`}
                            </div>
                            <div className="user-profile__menu">
                                <Link className="user-profile__link" to="/user/profile" onClick={handleCloseDrawer}>My Profile</Link>
                                {user?.membership_status === "true" ? (
                                    <Link to="/user/membership" className="dropdown-menu__link" onClick={handleCloseDrawer}>Membership</Link>
                                ) : null}
                                <Link className="user-profile__link" to="/user/email-add" onClick={handleCloseDrawer}>Manage Email</Link>
                                {!partnerAuthFlag && (
                                    <Link className="user-profile__link" to="/user/change-password" onClick={handleCloseDrawer}>
                                        Change Password
                                    </Link>
                                )}
                                {productTypeId === PRODUCT_TYPE_MOVIE_REWARDS ? (
                                    <Link className="user-profile__link" to="/user/current-tickets" onClick={handleCloseDrawer}>
                                        Purchases
                                    </Link>
                                ) : (
                                    <Link className="user-profile__link" to="/user/current-vouchers" onClick={handleCloseDrawer}>
                                        Purchases
                                    </Link>
                                )}
                                <Link to="/" onClick={() => { logout(); handleCloseDrawer(); }} className="user-profile__link">Logout</Link>
                            </div>
                        </div>
                    )}
                    {jwtAuthFlag && (
                        <div className="user-profile__container">
                            <div className="user-profile__menu">
                                <Link className="user-profile__link" to="/user/email-add" onClick={handleCloseDrawer}>Account Email</Link>
                                {productTypeId === PRODUCT_TYPE_MOVIE_REWARDS ? (
                                    <Link className="user-profile__link" to="/user/current-tickets" onClick={handleCloseDrawer}>
                                        Purchases
                                    </Link>
                                ) : (
                                    <Link className="user-profile__link" to="/user/current-vouchers" onClick={handleCloseDrawer}>
                                        Purchases
                                    </Link>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default UserProfile;
