import React, { useContext } from "react";
import { AuthContext } from "@context/AuthContext";
import Loader from "@components/Ui/Loader";
import ComingSoonTile from "@components/V3/Movies/ComingSoonTile";
import "./style.scss";

const MoviesComingSoon = () => {
    const { comingSoon, isMoviesLoading } = useContext(AuthContext);

    if (isMoviesLoading) {
        return (
            <Loader isLoading={isMoviesLoading} />
        );
    }
    if (comingSoon.length === 0) {
        return (
            <div className="movie-list__movies">
                <p className="movie-list__no-movies">There are currently no movies Coming Soon. Please check back again later.</p>
            </div>
        );
    }

    return (
        <>
            {comingSoon.length > 0 && (
                <div className="movie-list__movies">
                    {comingSoon.map((movie) => (
                        <div className="movie-list__movie-wrapper" key={movie.id} >
                            <ComingSoonTile movie={movie} />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default MoviesComingSoon;
