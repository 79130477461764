import React from "react";
import "./style.scss";

const PoweredBy = () => {
    return (
        <a href="https://www.chooviegroup.com" className="powered__link" target="_blank" rel="noreferrer">
            <span className="powered__text">powered by</span>
            <span className="powered__text powered__text--large">CHOOVIE</span>
            <span className="powered__text">GROUP</span>
        </a>
    );
};

export default PoweredBy;
