import React from "react";
import useViewport from "@hooks/useViewport";
import DesktopMenu from "@components/Navs/DesktopMenu";
import MobileMenu from "@components/Navs/MobileMenu";
import "./style.scss";

const Menu = () => {
    const { width } = useViewport();
    const breakpoint = 768;
    return (
        <>
            {width < breakpoint ? (
                <MobileMenu />
            ) : (
                <DesktopMenu />
            )}
        </>
    );
};

export default Menu;
