import React from "react";

const ScreenIcon = ({ className="" }) => {
    return (
        <svg className={className} width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.6364 0H2.36364C1.06364 0 0 1.1 0 2.44444V17.1111C0 18.4556 1.06364 19.5556 2.36364 19.5556H8.27273V22H17.7273V19.5556H23.6364C24.9364 19.5556 25.9882 18.4556 25.9882 17.1111L26 2.44444C26 1.1 24.9364 0 23.6364 0ZM22.75 16.5H3.25V3.3H22.75V16.5Z" fill="currentColor"/>
        </svg>


    );
};

export default ScreenIcon;
