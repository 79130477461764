import React from "react";

const SearchLensIcon = ({ className = "" }) => {
    return (
        <svg className={className} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.2611 20.3848L11.3568 13.4781C10.8168 13.8964 10.1941 14.2283 9.48858 14.4737C8.7831 14.7191 8.029 14.8418 7.22626 14.8418C5.20926 14.8418 3.50063 14.1415 2.10038 12.7408C0.700126 11.3399 0 9.63074 0 7.61329C0 5.59584 0.700126 3.88667 2.10038 2.4858C3.50063 1.08511 5.20926 0.384766 7.22626 0.384766C9.24308 0.384766 10.9516 1.08511 12.3519 2.4858C13.7523 3.88667 14.4525 5.59584 14.4525 7.61329C14.4525 8.42063 14.3299 9.17498 14.0845 9.87632C13.839 10.5777 13.5073 11.1962 13.0892 11.732L20 18.6516L18.2611 20.3848ZM7.22626 12.3642C8.54795 12.3642 9.66994 11.9029 10.5923 10.9803C11.5146 10.0579 11.9757 8.93557 11.9757 7.61329C11.9757 6.29101 11.5146 5.16866 10.5923 4.24624C9.66994 3.32364 8.54795 2.86234 7.22626 2.86234C5.90439 2.86234 4.78231 3.32364 3.85999 4.24624C2.93786 5.16866 2.4768 6.29101 2.4768 7.61329C2.4768 8.93557 2.93786 10.0579 3.85999 10.9803C4.78231 11.9029 5.90439 12.3642 7.22626 12.3642Z"
                fill="currentColor" />
        </svg>
    );
};

export default SearchLensIcon;
