import React, { useContext, useEffect, useState } from "react";
import flattenErrorMessages from "@functions/flattenErrorMessages";
import { ProfileContext } from "@context/ProfileContext";
import AlertMessage from "@components/AlertMessage";
import PasswordStrength from "@components/PasswordStrength";
import Button from "@components/Ui/Button";
import Spinner from "@components/Ui/Spinner";
import TextInputLabel from "@components/Ui/TextInputLabel";

const ChangePasswordForm = () => {
    const {
        errorMessages,
        processing,
        setErrorMessages,
        setProcessing,
        setSuccess,
        success,
        updatePassword,
    } = useContext(ProfileContext);

    const [passwordCurrent, setPasswordCurrent] = useState("");
    const [passwordNew, setPasswordNew] = useState("");
    const [passwordNewRepeat, setPasswordNewRepeat] = useState("");

    useEffect(() => {
        setErrorMessages({});
        setSuccess(false);
        return () => {
            setErrorMessages({});
            setSuccess(false);
        };
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setProcessing(true);
        setErrorMessages({});
        setSuccess(false);
        const data = {
            password_current: passwordCurrent,
            password: passwordNew,
            password_repeat: passwordNewRepeat,
        };
        updatePassword(data);
    };

    if (success) {
        return (
            <AlertMessage
                message="Password reset completed successfully."
                type="success"
            />
        );
    }

    const errorsFlattened = flattenErrorMessages(errorMessages);

    return (
        <>
            <form onSubmit={handleSubmit} className="form__content">
                <TextInputLabel
                    id="password_current"
                    name="password_current"
                    labelName="Current Password"
                    type="password"
                    required={true}
                    value={passwordCurrent}
                    onChange={(e) => setPasswordCurrent(e.target.value)}
                    disabled={processing === true}
                />
                <TextInputLabel
                    id="password"
                    name="password"
                    labelName="New Password"
                    type="password"
                    required={true}
                    value={passwordNew}
                    onChange={(e) => setPasswordNew(e.target.value)}
                    disabled={processing === true}
                />
                <div className="form__range">
                    <PasswordStrength password={passwordNew} />
                </div>
                <TextInputLabel
                    id="password_repeat"
                    name="password_repeat"
                    labelName="New Password Repeat"
                    type="password"
                    required={true}
                    value={passwordNewRepeat}
                    onChange={(e) => setPasswordNewRepeat(e.target.value)}
                    disabled={processing === true}
                />
                <Button
                    additionalClasses="button button__primary">
                    {processing ? (
                        <Spinner text="loading..." />
                    ) : (
                        <span>Save</span>
                    )}
                </Button>
            </form>
            {errorsFlattened.length > 0 && (
                <AlertMessage
                    message="The following errors occurred:"
                    type="error"
                    messageList={errorsFlattened}
                />
            )}
        </>
    );
};

export default ChangePasswordForm;
