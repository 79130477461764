import React from "react";
import FeatureFilm from "@components/FeatureFilm";
import CurrentlyShowing from "@components/V3/CurrentlyShowing";
import Layout from "@components/V3/Layout";
import V3Carousel from "@components/V3/V3Carousel/index";

const V3Page = () => {
    
    return (
        <Layout>
            <V3Carousel />
            <CurrentlyShowing />
            <FeatureFilm />
        </Layout>

    );
};

export default V3Page;
