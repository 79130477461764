import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import getPageTitleFromPath from "@functions/getPageTitleFromPath";

const enabledDomains = [
    "www.choovie.com.au",
    "stage2.choovie.com.au",
    "www.mytoyotamovies.com.au",
    "www.budgetdirectmovies.com.au",
    "www.lexusencoremovies.com.au",
];

const usePageTracking = () => {
    const [initialized, setInitialized] = useState(false);

    const location = useLocation();
    const { host } = window.location;
    const siteUrl = `${window.location.protocol }//${ window.location.host}`;

    useEffect(() => {
        if (enabledDomains.includes(host)) {
            window.dataLayer = window.dataLayer || [];
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            const currentUrl =  siteUrl + location.pathname + location.search;
            const event = {
                "event": "virtualPageview",
                "pageUrl": currentUrl,
                "pageTitle": getPageTitleFromPath(location.pathname + location.search).title,
            };
            window.dataLayer.push(event);
        }
    }, [initialized, location]);
};

export default usePageTracking;
