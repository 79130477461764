import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const BookedTicketTotal = ({ ticket }) => {

    return (
        <div className="booked-ticket-total">
            <div key={ticket.id} className="booked-ticket-total__item booking-details__item--total">
                Total Price: ${(ticket.total_price_cents / 100).toFixed(2)}
            </div>
        </div>
    );
};

BookedTicketTotal.propTypes = {
    ticket: PropTypes.shape({
        total_price_cents: PropTypes.number
    })
};

export default BookedTicketTotal;
