import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AUTH_APPLICATION_SECURITY, AUTH_JWT, AUTH_PARTNER_INTEGRATION } from "@config/config";

import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { MembershipContext } from "@context/MembershipContext";
import "./style.scss";

const LeftSidebarMenu = ({ isOpen }) => {

    const { publicSubscriberGroup, productTypeId, whiteLabel, displayNewsLink } = useContext(ApplicationContext);
    const { user, isLoggedIn } = useContext(AuthContext);
    const { membershipOptions, fetchMembershipInformation, isMembershipLoading } = useContext(MembershipContext);
    const [isNavCollapsed, setIsNavCollapsed] = useState(false);
    const partnerAuthFlag = whiteLabel?.subscriber_group?.authentication_type === AUTH_PARTNER_INTEGRATION;
    const jwtAuthFlag = whiteLabel?.subscriber_group?.authentication_type === AUTH_JWT;
    const appAuthFlag = whiteLabel?.subscriber_group?.authentication_type === AUTH_APPLICATION_SECURITY;

    useEffect(() => {
        if (isLoggedIn === true && user?.id) {
            fetchMembershipInformation(user?.id);
        }
    }, [user?.id]);

    const handleNavCollapse = () => {
        setIsNavCollapsed(!isNavCollapsed);
    };


    return (
        <div className={`left-sidebar-menu ${isOpen ? "open" : "closed"}`}>
            <NavLink
                to="/movies"
                activeClassName="active"
                onClick={handleNavCollapse}
                className="left-sidebar-menu__link">Movies
            </NavLink>
            <NavLink
                to="/cinemas"
                activeClassName="active"
                onClick={handleNavCollapse}
                className="left-sidebar-menu__link">Cinemas
            </NavLink>
            <NavLink
                to="/experiences"
                activeClassName="active"
                onClick={handleNavCollapse}
                className="left-sidebar-menu__link">Experiences
            </NavLink>
            {membershipOptions ? (
                <NavLink
                    to="/movie-club-membership"
                    activeClassName="active"
                    onClick={handleNavCollapse}
                    className="left-sidebar-menu__link">Movie Club
                </NavLink>
            ) : null}
            {publicSubscriberGroup !== 0 ? (
                <>
                    <NavLink
                        to="/about"
                        activeClassName="active"
                        onClick={handleNavCollapse}
                        className="left-sidebar-menu__link"
                    >About
                    </NavLink>
                    <NavLink
                        to="/news"
                        activeClassName="active"
                        onClick={handleNavCollapse}
                        className="left-sidebar-menu__link"
                    >News
                    </NavLink>
                </>

            ) : null}
        </div>
    );
};

export default LeftSidebarMenu;
