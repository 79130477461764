import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { SITE_V3 } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import AboutUsContent from "@components/AboutUsContent";
import LayoutV3 from "@components/V3/Layout";
import Layout from "@components/Wrappers/Layout";

const AboutUsPage = () => {
    const { siteVersion } = useContext(AuthContext);
    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;
    const LayoutComponent = siteVersion && siteVersion === SITE_V3 ? LayoutV3 : Layout;
    return (
        <LayoutComponent>
            <Helmet>
                <title>{ `About Us | Our Story | ${brand}` }</title>
                <meta name="keywords" content={ `About Us | Our Story | ${brand}` } />
                <meta name="description" content={ `Discover who we are & how we became your number
                    one supplier of independent & regional cinema movie tickets. Discover ${brand}'s
                    entire history today!` } />
                <link rel="canonical" href={`${window.location.protocol }//${ window.location.host}/about`} />
            </Helmet>
            <AboutUsContent />
        </LayoutComponent>
    );
};

export default AboutUsPage;
