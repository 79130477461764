import React from "react";
import usePageTracking from "@hooks/usePageTracking";
import Legal from "@components/Legal";
import "./style.scss";

const Footer = () => {
    // Google Analytics page tracking
    usePageTracking();
    return (
        <footer className="footer container">
            <Legal />
        </footer>
    );
};

export default Footer;
