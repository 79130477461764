import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";
import { ApplicationContext } from "@context/ApplicationContext";

const Byline = () => {
    const { whiteLabel } = useContext(ApplicationContext);

    const LinkRenderer = ({ children, href }) => {
        // Ensure href is defined and non-empty
        if (href && href.trim().length > 0) {
            return (
                <a href={href} className="main-heading__link-style">
                    {children}
                </a>
            );
        }

        return <>{children}</>; // Render children as is if href is not provided
    };

    return (
        <h1 className="main-heading">
            <ReactMarkdown  components={{ a: LinkRenderer }}>{whiteLabel?.home_page_title}</ReactMarkdown>
        </h1>

    );
};

export default Byline;
