import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ApplicationContext } from "@context/ApplicationContext";
import ChangePasswordForm from "@components/Form/ChangePasswordForm";
import FormWrapper from "@components/Wrappers/FormWrapper";
import Layout from "@components/Wrappers/Layout";

const ChangePasswordPage = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;

    return (
        <Layout>
            <Helmet>
                <title>{ `Change Login Password | ${brand}` }</title>
                <meta name="keywords" content={ `Change Login Password | ${brand}` } />
                <meta name="description" content={ `Forgot your password? Not to worry,
                simply follow the steps to create a password to secure your ${brand}
                account & continue enjoying member benefits. ` } />
                <link rel="canonical" href={`${window.location.protocol }//${ window.location.host}/user/change-password`} />
            </Helmet>

            <FormWrapper pageTitle="Change Password">
                <ChangePasswordForm />
            </FormWrapper>
        </Layout>
    );
};

export default ChangePasswordPage;
