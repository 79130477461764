import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import BookedTicketItems from "@components/Bookings/BookedTicketItems";
import BookedTicketSavings from "@components/Bookings/BookedTicketSavings";
import BookedTicketTotal from "@components/Bookings/BookedTicketTotal";
import "./style.scss";

const BookingDetails = ({ ticket }) => {
    return (
        <div className="booking-details">
            <div className="booking-details__list">
                <BookedTicketItems ticket={ticket}/>
                <BookedTicketSavings ticket={ticket} />
                <BookedTicketTotal ticket={ticket}/>
            </div>
            <ReactTooltip className="tooltip" />
        </div>
    );
};

BookingDetails.propTypes = {
    ticket: PropTypes.object
};

export default BookingDetails;
