import React, { useContext } from "react";
import { ApplicationContext } from "@context/ApplicationContext";
import FormWrapper from "@components/Wrappers/FormWrapper";
import "./style.scss";

const NoPublicAccess = () => {
    const { whiteLabel, cdnUrl } = useContext(ApplicationContext);

    const noPublicAccessImageLarge = `${cdnUrl}asset/img/no_public_access_image_large.webp`;
    
    const brand = whiteLabel ? whiteLabel.no_public_access_title : "Something Went Wrong";
    const bodyText = whiteLabel ? whiteLabel.no_public_access_body : "We encountered an error connecting you to your movie rewards. Try refreshing the page or retracing your steps.\n\n";
    const buttonValue = whiteLabel ? whiteLabel.no_public_access_link_value : "Refresh the page";
    const buttonLink = whiteLabel ? whiteLabel.no_public_access_link_url : "/";

	return (
    <>
        <div className="no-access__image-wrapper">
            <img
                className="no-access__image"
                src={noPublicAccessImageLarge}
                alt="two people eating popcorn"
            />
        </div>
        <FormWrapper pageTitle={`${brand}`}>
            <p className="no-access__text paragraph">
                {bodyText}
            </p>
            {whiteLabel?.no_public_access_link_url && (
                <a className="button button__primary" href={buttonLink} target="_blank" rel="noreferrer">
                    {buttonValue}
                </a>
            )}
        </FormWrapper>
    </>
	);
};

export default NoPublicAccess;
