import React, { useEffect, useRef, useState } from "react";
import LeftArrowIcon from "@components/V3/Icons/LeftArrowIcon";
import RightArrowIcon from "@components/V3/Icons/RightArrowIcon";
import "./style.scss";
import { renderDate } from "@utils/dateUtils";

const DateSelector = ({ uniqueDates, handleClickDate, selectedDate }) => {
    const containerRef = useRef(null);

    const [scrollState, setScrollState] = useState({
        isOverflowing: false,
        canScrollBack: false,
        canScrollForward: true,
    });

    const handleScrollForward = () => {
        const container = containerRef.current;
        if (container) {
            const maxScrollLeft = container.scrollWidth - container.clientWidth;
            const scrollAmount = Math.min(container.clientWidth, maxScrollLeft - container.scrollLeft);
            container.scrollBy({ left: scrollAmount, behavior: "smooth" });
            setScrollState((prev) => ({
                ...prev,
                canScrollBack: true,
                canScrollForward: container.scrollLeft + container.clientWidth < container.scrollWidth,
            }));
        }
    };

    const handleScrollBack = () => {
        const container = containerRef.current;
        if (container) {
            const scrollAmount = Math.min(container.clientWidth, container.scrollLeft);
            container.scrollBy({ left: -scrollAmount, behavior: "smooth" });
            setScrollState((prev) => ({
                ...prev,
                canScrollBack: container.scrollLeft - scrollAmount > 0,
                canScrollForward: true,
            }));
        }
    };

    useEffect(() => {
        const container = containerRef.current;

        const checkScrollState = () => {
            if (!container) return;
            const isOverflow = container.scrollWidth > container.clientWidth;
            setScrollState({
                isOverflowing: isOverflow,
                canScrollForward:
                    isOverflow && container.scrollLeft + container.clientWidth < container.scrollWidth,
                canScrollBack: container.scrollLeft > 0,
            });
        };

        if (container) {
            checkScrollState();
            container.addEventListener("scroll", checkScrollState);
            window.addEventListener("resize", checkScrollState);
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", checkScrollState);
            }
            window.removeEventListener("resize", checkScrollState);
        };
    }, [uniqueDates, selectedDate]);
        
    return (
        <div className="date-selector__container">
            {scrollState.canScrollBack && (
                <button
                    className={`date-selector__button date-selector__button--left ${
                        !scrollState.canScrollBack ? "date-selector__button--hidden" : ""
                    }`}
                    onClick={handleScrollBack}
                >
                    <LeftArrowIcon />
                </button>
            )}

            <div className="date-selector__date-container">
                <div
                    className={
                        scrollState.canScrollBack
                            ? "date-selector__date-list active"
                            : "date-selector__date-list"
                    }
                    ref={containerRef}
                >
                    {uniqueDates.map((date) => (
                        <div
                            key={date}
                            className={`date-selector__date-item ${
                                date === selectedDate ? "active" : ""
                            }`}
                            role="button"
                            onClick={() => handleClickDate(date)}
                        >
                            {renderDate(date)}
                        </div>
                    ))}
                </div>
            </div>
            
            {scrollState.canScrollForward && scrollState.isOverflowing && (
                <button
                    className={`date-selector__button date-selector__button--right ${
                        !scrollState.canScrollForward
                            ? "date-selector__button--hidden"
                            : ""
                    }`}
                    onClick={handleScrollForward}
                >
                    <RightArrowIcon />
                </button>
            )}
        </div>
    );
};

export default DateSelector;
