import React from "react";

const CartIcon = ({ className="" }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="38" height="40" viewBox="0 0 38 40" fill="none">
            <path d="M11.4821 40C10.5696 40 9.80066 39.6894 9.17525 39.0681C8.54981 38.4469 8.23709 37.6832 8.23709 36.7768C8.23709 35.8705 8.54981 35.1067 9.17525 34.4855C9.80066 33.8643 10.5696 33.5537 11.4821 33.5537C12.3945 33.5537 13.1635 33.8643 13.7889 34.4855C14.4143 35.1067 14.727 35.8705 14.727 36.7768C14.727 37.6832 14.4143 38.4469 13.7889 39.0681C13.1635 39.6894 12.3945 40 11.4821 40ZM31.7837 40C30.8712 40 30.1023 39.6894 29.4768 39.0681C28.8514 38.4469 28.5387 37.6832 28.5387 36.7768C28.5387 35.8705 28.8514 35.1067 29.4768 34.4855C30.1023 33.8643 30.8712 33.5537 31.7837 33.5537C32.6961 33.5537 33.4651 33.8643 34.0905 34.4855C34.7159 35.1067 35.0286 35.8705 35.0286 36.7768C35.0286 37.6832 34.7159 38.4469 34.0905 39.0681C33.4651 39.6894 32.6961 40 31.7837 40ZM8.39523 6.44628L14.1695 18.5124H28.5221C28.7717 18.5124 28.9936 18.4504 29.1877 18.3264C29.3819 18.2025 29.5483 18.0303 29.6869 17.8099L35.3447 7.60328C35.5112 7.30027 35.5251 7.03169 35.3864 6.79754C35.2477 6.56337 35.012 6.44628 34.6792 6.44628H8.39523ZM7.33852 4.29752H35.5029C36.4431 4.29752 37.1503 4.67837 37.6246 5.44006C38.0988 6.20176 38.1238 6.98621 37.6994 7.79339L31.5174 18.9835C31.204 19.5069 30.797 19.9173 30.2964 20.2149C29.7958 20.5124 29.246 20.6612 28.6469 20.6612H13.196L10.5668 25.4545C10.345 25.7851 10.338 26.1432 10.546 26.5289C10.754 26.9146 11.066 27.1074 11.4821 27.1074H35.0286V29.2562H11.4821C10.2202 29.2562 9.27857 28.728 8.65731 27.6715C8.03605 26.615 8.0208 25.5537 8.61156 24.4876L11.8648 18.7272L3.91052 2.14876H0V0H5.28339L7.33852 4.29752Z" fill="white"/>
        </svg>
    );
};

export default CartIcon;
