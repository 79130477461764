import React from "react";
import { default as ReactSelect } from "react-select";
import "./style.scss";

const Select = (props) => {
    const newProps = {
        className: "react-select-container",
        classNamePrefix: "react-select",
        ...props
    };
    return (
        <ReactSelect {...newProps} />
    );
};
export default Select;
