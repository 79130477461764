import React from "react";
import "./style.scss";

const CandyBarIcon = ({ className="" }) => {
    return (
        <svg className={className} width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.11601 0C8.79157 1.36792 7.53306 2.3912 6.03461 2.3912H4.43233C3.41894 2.3912 2.59306 3.18679 2.57405 4.17197H12.7002V3.25149C12.7002 1.45889 11.2061 0.000135452 9.37003 0.000135452L9.11601 0ZM1.85935 5.56567C0.834202 5.56567 0 6.38013 0 7.38102C0 7.65104 0.0603348 7.91226 0.175946 8.15192H15.0971C15.2129 7.91241 15.2727 7.65102 15.2727 7.38102C15.2727 6.38013 14.4391 5.56567 13.4139 5.56567H1.85935ZM0.48612 9.54611V11.6844H14.7872V9.54611H0.48612ZM2.08081 13.0784L3.79148 21H11.4815L13.1921 13.0784H2.08081Z" fill="currentColor"/>
        </svg>
    );
};

export default CandyBarIcon;
