import React, { useEffect, useState } from "react";
import "./style.scss";

const VideoPlayer = ({ type, src, width, height }) => {
    const [isPlaying, setIsPlaying] = useState(true);
    const [calcWidth, setCalcWidth] = useState(null);
    const [windowDimensions, setWindowDimensions] = useState({
        width: 0,
        height: 0,
    });

    const handlePlayToggle = () => {
        setIsPlaying((prevState) => !prevState);
    };

    const handleResize = () => {
        setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const viewportHeight = windowDimensions.height;
        const aspectRatio = 16 / 9;
        if (windowDimensions.width < viewportHeight * aspectRatio) {
            const newCalcWidth = viewportHeight * aspectRatio;
            setCalcWidth(newCalcWidth);
        } else {
            setCalcWidth(windowDimensions.width);
        }
        handleResize();
    }, [windowDimensions.width, windowDimensions.height]);

    return (
        <div className="video-js__wrapper">
            {type === "youtube" ? (
                <iframe
                    width={calcWidth}
                    height={(calcWidth / 16) * 9}
                    className={`video-js__video ${isPlaying ? "video-js__video--is-active" : ""}`}
                    src={`https://www.youtube.com/embed/${src}?autoplay=${isPlaying ? 1 : 0}`}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            ) : type === "mp4" ? (
                <video
                    className={`video-js__video ${isPlaying ? "video-js__video--is-active" : ""}`}
                    src={src}
                    width={calcWidth}
                    height={(calcWidth / 16) * 9}
                    autoPlay
                    controls
                    onClick={handlePlayToggle}
                ></video>
            ) : (
                <p>Unsupported video type</p>
            )}
        </div>
    );
};

export default VideoPlayer;
