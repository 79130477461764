import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApplicationContext } from "@context/ApplicationContext";
import { SearchContext } from "@context/SearchContext";
import PlayIcon from "@components/Icons/PlayIcon";
import TrailerButton from "@components/TrailerButton";
import MovieSessionSelector from "@components/V3/MovieSessionSelector";
import "./style.scss";

const SearchDetailsMovie = () => {
    const { id, movieName } = useParams();
    const { fetchMovie, movie } = useContext(SearchContext);
    const { cdnUrl } = useContext(ApplicationContext);
    const fallbackImage = `${cdnUrl}asset/img/default-movie-poster-large.webp`;
    const [currentImage, setCurrentImage] = useState(null);
    const [isImageReady, setIsImageReady] = useState(false);

    useEffect(() => {
        if (id && movieName) {
            fetchMovie(id, movieName);
        }
    }, [id, movieName]);

    const loadImage = (imageUrl) => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
            setCurrentImage(imageUrl);
            setIsImageReady(true);
        };
        image.onerror = () => {
            setCurrentImage(fallbackImage);
            setIsImageReady(true);
        };
    };

    useEffect(() => {
        if (!movie) return;
        const imageUrl = movie.movie_still ?? movie.images?.[0]?.local_url ?? null;
        if (imageUrl) {
            loadImage(imageUrl);
        } else {
            setCurrentImage(fallbackImage);
            setIsImageReady(true);
        }
    }, [movie]);
    

    return (
        <>
            {movie ? (
                <div className="search-details-movie">
                    <div className="search-details-movie__info">
                        <div className="search-details-movie__poster-wrapper">
                            <img
                                className="search-details-movie__poster"
                                src={movie.movie_poster}
                                alt={movie?.title}
                            />
                        </div>
                        <div className="search-details-movie__text-container">
                            <h1 className="search-details-movie__title">{movie?.title}</h1>
                            <h2 className="search-details-movie__text">{movie?.review?.review_description}</h2>
                            {movie.trailer_url ? (
                                <TrailerButton
                                    extraClass="search-details-movie__button"
                                    type="mp4"
                                    trailerLink={movie.trailer_url}
                                    aria-label={`Watch trailer for ${movie.movie_title}`}
                                >
                                    <PlayIcon className="search-details-movie__icon-svg" aria-hidden="true" />
                                    <span className="search-details-movie__icon-text">Official Trailer</span>
                                </TrailerButton>
                            ) : null}
                        </div>
                    </div>
                    <div className="search-details-movie__wrapper">
                        <picture>
                            <source media="(min-width: 768px)" srcSet={currentImage || ""} />
                            <img
                                className="search-details-movie__image"
                                src={currentImage || ""}
                                alt={movie?.title || "Movie poster"}
                                style={{
                                    visibility: isImageReady ? "visible" : "hidden",
                                    opacity: isImageReady ? 1 : 0,
                                }}
                            />
                        </picture>
                    </div>
                    <span className="search-details-movie__background"></span>
                </div>
            ) : null}
            <MovieSessionSelector />
        </>
    );
};

export default SearchDetailsMovie;
