import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ApplicationContext } from "@context/ApplicationContext";
import { ProfileContext } from "@context/ProfileContext";
import LoadingVerification from "@components/LoadingVerification";
import FormWrapper from "@components/Wrappers/FormWrapper";

const EmailVerification = () => {
    const { token } = useParams();
    const { whiteLabel } = useContext(ApplicationContext);
    const { verifySuccess, verifyEmail, isLoading } = useContext(ProfileContext);
    const brand = whiteLabel?.brand_name;
    
    useEffect(() => {
        if (token) {
            verifyEmail(token); 
        }
        
    }, [token]);


    
    return (
        <FormWrapper pageTitle={`${brand} verify email`}>
            <div className="email-verification">
                {isLoading
                    ? <LoadingVerification/>
                    : (verifySuccess)
                        ? <p className="paragraph">Success, your email address has been verified</p>
                        : <p className="paragraph">Unfortunately your email could not be verified or this link has expired.</p>
                }
                {verifySuccess && (
                        (<Link to="/" className="button button__primary">Home</Link>)
                )}
            </div>
        </FormWrapper>
    );
};

export default EmailVerification;
