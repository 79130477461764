import React from "react";

const CloseIcon = ({ className="" }) => {
    return (
        <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.923077 12L0 11.0769L5.07692 6L0 0.923077L0.923077 0L6 5.07692L11.0769 0L12 0.923077L6.92308 6L12 11.0769L11.0769 12L6 6.92308L0.923077 12Z" fill="currentColor" />
        </svg>
    );
};

export default CloseIcon;
