import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { UserContext } from "@context/UserContext";
import "./style.scss";

const calcTotalPrice = (carry, item) => {
    return item.promo
        ? carry
        : carry + (item.price_cents * item.quantity);
};
const calcTotalSavings = (carry, item) => {
    return item.promo ? carry : carry + (item.saving * item.quantity);
};


const calcTotalQuantity = (carry, item) => {
    return carry + item.quantity;
};

const BookingTotal = ({ items, promo }) => {

    const { isAdvancedPromo, isRegularPromo, setIsRegularPromo } = useContext(UserContext);
    let totalPrice = items.reduce(calcTotalPrice, 0);
    const subTotal = items.reduce(calcTotalPrice, 0);
    const totalSavings = items.reduce(calcTotalSavings, 0);
    const totalQuantity = items.reduce(calcTotalQuantity, 0);
    const discount = promo?.discount;
    const finalTotal = (subTotal - promo?.discount);
    const savingsAfterPromo = totalSavings + discount;



    useEffect(() => {
        if (promo?.applied && promo?.discount) {
            totalPrice -= promo.discount;
            if (!isAdvancedPromo) {
                setIsRegularPromo(true);
            }
        }
    },[promo]);

    
    return (
        <>
            {(isAdvancedPromo || isRegularPromo) ? (
                <>
                    {(promo?.applied && promo?.discount) && savingsAfterPromo > 0 ? (
                        <div className="form__group-wrapper">
                            <div className="booking__label booking__label--bold">Savings:</div>
                            <div className={`booking__price booking__price--bold ${totalQuantity === 0 ? "booking__quantity-none" : ""}`}>
                                ${(savingsAfterPromo / 100).toFixed(2)}
                            </div>
                        </div>
                    ): (
                        <>
                            {totalSavings > 0 ? (
                                <div className="form__group-wrapper">
                                    <div className="booking__label booking__label--bold">Savings:</div>
                                    <div className={`booking__price booking__price--bold ${totalQuantity === 0 ? "booking__quantity-none" : ""}`}>
                                        ${(totalSavings / 100).toFixed(2)}
                                    </div>
                                </div>
                            ): null}
                        </>

                    )}
                    
                    {(totalPrice > 0 && !promo?.discount) ? (
                        <div className="form__group-wrapper">
                            <div className="booking__label">Total:</div>
                            <div className={`booking__price ${totalQuantity === 0 ? "booking__quantity-none" : ""}`}>
                                ${(totalPrice / 100).toFixed(2)}
                            </div>
                        </div>
                    ): null}
                    {promo?.discount ? (
                        <div className="form__group-wrapper">
                            <div className="booking__label booking__label--bold">Total:</div>
                            <div className={`booking__price booking__price--bold ${totalQuantity === 0 ? "booking__quantity-none" : ""}`}>
                                ${(finalTotal / 100).toFixed(2)}
                            </div>
                        </div>
                    ): null}

                </>
            ): (
                <>
                    {totalSavings > 0 ? (
                        <div className="form__group-wrapper">
                            <div className="booking__label booking__label--bold">Savings:</div>
                            <div className={`booking__price booking__price--bold ${totalQuantity === 0 ? "booking__quantity-none" : ""}`}>
                                ${(totalSavings / 100).toFixed(2)}
                            </div>
                        </div>
                    ): null}
                    <div className="form__group-wrapper">
                        <div className="booking__label">Total</div>
                        <div className={`booking__price ${totalQuantity === 0 ? "booking__quantity-none" : ""}`}>
                            ${(totalPrice / 100).toFixed(2)}
                        </div>
                    </div>
                </>
            )}
        </>

    );
};

BookingTotal.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            promo: PropTypes.bool.isRequired,
            price_cents: PropTypes.number.isRequired,
            quantity: PropTypes.number.isRequired
        })
    ).isRequired,
};

export default BookingTotal;
