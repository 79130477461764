import React, { useContext } from "react";
import { AuthContext } from "@context/AuthContext";
import PlusIcon from "@components/Icons/PlusIcon";
import Button from "@components/Ui/Button";
import Drawer from "@components/V3/Drawer";
import Venues from "@components/V3/VenueFinder/venues";
import "./style.scss";

const VenueFinder = ({ selectedVenue, venues, handleVenueClick }) => {
    const { drawerState, openDrawer, closeDrawer } = useContext(AuthContext);

    return (
        <>
            {selectedVenue ? (
                <Button additionalClasses="venue-finder__button" onClick={() => openDrawer("venuesDrawer")}>
                    {selectedVenue} <PlusIcon className="venue-finder__icon" />
                </Button>
            ) : (
                <Button additionalClasses="venue-finder__button" onClick={() => openDrawer("venuesDrawer")}>
                    Find a cinema <PlusIcon className="venue-finder__icon" />
                </Button>
            )}
            <Drawer isOpen={drawerState.venuesDrawer} onClose={() => closeDrawer("venuesDrawer")} closeButtonAlignment="right">
                <Venues handleVenueClick={handleVenueClick} venues={venues} />
                <Button additionalClasses="button button__primary" onClick={() => closeDrawer("venuesDrawer")}>
                    Save and browse
                </Button>
            </Drawer>
        </>
    );
};

export default VenueFinder;
