import React from "react";

const SearchLensIcon = ({ className = "" }) => {
    return (
        <svg className={className} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.2611 20.5L11.3568 13.5933C10.8168 14.0117 10.1941 14.3435 9.48858 14.5889C8.7831 14.8343 8.029 14.957 7.22626 14.957C5.20926 14.957 3.50063 14.2567 2.10038 12.856C0.700126 11.4551 0 9.74597 0 7.72852C0 5.71107 0.700126 4.00191 2.10038 2.60104C3.50063 1.20034 5.20926 0.5 7.22626 0.5C9.24308 0.5 10.9516 1.20034 12.3519 2.60104C13.7523 4.00191 14.4525 5.71107 14.4525 7.72852C14.4525 8.53587 14.3299 9.29021 14.0845 9.99155C13.839 10.6929 13.5073 11.3115 13.0892 11.8473L20 18.7668L18.2611 20.5ZM7.22626 12.4795C8.54795 12.4795 9.66994 12.0182 10.5923 11.0956C11.5146 10.1732 11.9757 9.0508 11.9757 7.72852C11.9757 6.40624 11.5146 5.28389 10.5923 4.36147C9.66994 3.43887 8.54795 2.97757 7.22626 2.97757C5.90439 2.97757 4.78231 3.43887 3.85999 4.36147C2.93786 5.28389 2.4768 6.40624 2.4768 7.72852C2.4768 9.0508 2.93786 10.1732 3.85999 11.0956C4.78231 12.0182 5.90439 12.4795 7.22626 12.4795Z"
                fill="currentColor" />
        </svg>
    );
};

export default SearchLensIcon;
