const groupSessionsByVenueMovie = (acc, cur) => {
    const key = `${cur.venue_id}-${cur.movie_id}`;
    const index = acc.findIndex((i) => i.key === key);
    if (index !== -1) {
        acc[index].sessions = [
            ...acc[index].sessions,
            {
                id: cur.id,
                ticket_class_id: cur.ticket_class_id,
                session_type: cur.session_type,
                ticket_price_cents: cur.ticket_price_cents,
                rrp_pricing_cents: cur.rrp_pricing_cents,
                saving: cur.saving,
                session_date: cur.session_date,
                cinema_group_id: parseInt(cur.cinema_group_id),
                date: cur.session_date.split("T")[0],
                d: new Date(cur.session_date),
            },
        ];
        return acc;
    }
    const item = {
        key,
        cinema_group_id: parseInt(cur.cinema_group_id),
        movie_id: cur.movie_id,
        movie_image: cur.movie_image,
        movie_title: cur.movie_title,
        movie_run_time: cur.movie_run_time,
        movie_rating: cur.movie_rating,
        movie_genre: cur.movie_genre,
        movie_blurb: cur.movie_blurb,
        trailer_url: cur.trailer_url,
        venue_id: cur.venue_id,
        venue_name: cur.venue_name,
        venue_address: cur.venue_address,
        venue_suburb: cur.venue_suburb,
        venue_postcode: cur.venue_postcode,
        venue_state: cur.venue_state,
        venue_distance: cur.venue_distance,
        venue_image: cur.venue_image,
        sessions: [
            {
                id: cur.id,
                ticket_class_id: cur.ticket_class_id,
                session_type: cur.session_type,
                ticket_price_cents: cur.ticket_price_cents,
                rrp_pricing_cents: cur.rrp_pricing_cents,
                saving: cur.saving,
                session_date: cur.session_date,
                cinema_group_id: parseInt(cur.cinema_group_id),
                date: cur.session_date.split("T")[0],
                d: new Date(cur.session_date),
            },
        ],
    };
    return [...acc, item];

};

export default groupSessionsByVenueMovie;
