import React from "react";
import { Link } from "react-router-dom";

const MovieClubSuccess = () => {

    return (
        <div className="movie-club__intro">
            <h1 className="heading">Congratulations on becoming a Movie Club Member</h1>
            <Link to="/" className="button button__primary button--inline">Start exploring movies</Link>
        </div>
    );
};
export default MovieClubSuccess;
