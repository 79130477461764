import React, { useContext, useState, useEffect } from "react";
import flattenErrorMessages from "@functions/flattenErrorMessages";
import { AuthContext } from "@context/AuthContext";
import { ProfileContext } from "@context/ProfileContext";
import AlertMessage from "@components/AlertMessage";
import Button from "@components/Ui/Button";
import Spinner from "@components/Ui/Spinner";
import TextInputLabel from "@components/Ui/TextInputLabel";

const UserProfileForm = () => {
    const { user } = useContext(AuthContext);

    const {
        updateUserProfile,
        successMessage,
        setSuccessMessage,
        errorMessages,
        setErrorMessages,
        processing,
        setProcessing,
    } = useContext(ProfileContext);

    const { first_name, last_name } = user;
    const [firstName, setFirstName] = useState(first_name);
    const [lastName, setLastName] = useState(last_name);

    useEffect(() => {
        setErrorMessages({});
        setSuccessMessage(null);
        return () => {
            setErrorMessages({});
            setSuccessMessage(null);
        };
      }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setProcessing(true);
        setErrorMessages({});
        setSuccessMessage(null);
        const data = {
            first_name: firstName,
            last_name: lastName,
        };
        updateUserProfile(data);
    };

    const errorsFlattened = flattenErrorMessages(errorMessages);

    return (
        <>
            <form className="form__content" onSubmit={(e) => handleSubmit(e)}>
                <TextInputLabel
                    id="first_name"
                    name="first_name"
                    labelName="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    isValueErrored={errorMessages.first_name != null}
                    disabled={processing === true}
                />

                <TextInputLabel
                    id="last_name"
                    name="last_name"
                    labelName="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    isValueErrored={errorMessages.last_name != null}
                    disabled={processing === true}
                />
                <Button
                    additionalClasses="button button__primary">
                    {processing ? (
                        <Spinner text="loading..." />
                    ) : (
                        <span>Save</span>
                    )}
                </Button>
            </form>

            {errorsFlattened.length > 0 && (
                <AlertMessage
                    message="The following errors occurred:"
                    type="error"
                    messageList={errorsFlattened}
                />
            )}
            {successMessage && (
                <AlertMessage message={successMessage} type="success" />
            )}
        </>
    );
};

export default UserProfileForm;
