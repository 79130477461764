import React, { useContext, useEffect } from "react";
import { SITE_V3 } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import LayoutV3 from "@components/V3/Layout";
import FormWrapper from "@components/Wrappers/FormWrapper";
import Layout from "@components/Wrappers/Layout";

const UnsuccessfulSignInPage = () => {
    const { whiteLabel, fetchWhiteLabel, subscriberGroupId } = useContext(ApplicationContext);
    const { siteVersion } = useContext(AuthContext);
    const LayoutComponent = siteVersion && siteVersion === SITE_V3 ? LayoutV3 : Layout;


    useEffect(() => {
        fetchWhiteLabel(subscriberGroupId);
    }, []);

    return (
        <LayoutComponent>
            <FormWrapper pageTitle={`${whiteLabel?.no_public_access_title}`}>
                <p className="paragraph">
                    {whiteLabel?.no_public_access_body}
                    {whiteLabel?.no_public_access_link_url &&
                        whiteLabel?.no_public_access_link_value && (
                            <a
                                href={whiteLabel?.no_public_access_link_url}
                                className="link"
                            >
                                {whiteLabel?.no_public_access_link_value}
                            </a>
                        )}
                </p>
                <a className="button button__primary" href={`${whiteLabel?.no_public_access_link_url}`}>
                    {whiteLabel?.no_public_access_link_value}
                </a>
            </FormWrapper>
        </LayoutComponent>
    );
};

export default UnsuccessfulSignInPage;
