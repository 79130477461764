import React from "react";
import Spinner from "@components/Ui/Spinner";
import "./style.scss";

const Loader = ({ text = "Loading...", isLoading }) => {
    const loaderStatus = () => {
        if (isLoading === true) {
            return (
                <div className="loading__container">
                    <span className="loading__text">{text}</span>
                    <Spinner text="loading..." />
                </div>
            );
        }
    };
    return <>{loaderStatus()}</>;
};

export default Loader;
