import React, { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { API_BASE_URL, SOURCE_BO_TRAILER, SOURCE_LOCAL_TRAILER, TYPE_MOVIE_DETAILS } from "@config/config";
import toSeoUrl from "@functions/toSeoUrl";
import { ApplicationContext } from "@context/ApplicationContext";
import PlayIcon from "@components/Icons/PlayIcon";
import TrailerButton from "@components/TrailerButton";
import Loader from "@components/Ui/Loader";
import LeftArrowIcon from "@components/V3/Icons/LeftArrowIcon";
import RightArrowIcon from "@components/V3/Icons/RightArrowIcon";
import "./style.scss";

const CustomPrevArrow = ({ onClick, activeSlide, carouselImages }) => {
    if (activeSlide === 0 || carouselImages?.length < 1) return null;
    return (
        <button className="v3-carousel__arrow v3-carousel__arrow--left" onClick={onClick}>
            <LeftArrowIcon />
        </button>
    );
};

const CustomNextArrow = ({ onClick, carouselImages }) => {
    if (carouselImages?.length <= 1) return null;
    return (
        <button className="v3-carousel__arrow v3-carousel__arrow--right" onClick={onClick}>
            <RightArrowIcon />
        </button>
    );
};

const V3Carousel = () => {
    const { cdnUrl, subscriberGroupId } = useContext(ApplicationContext);

    const [carouselImages, setCarouselImages] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0);

    const sliderRef = React.useRef(null);
    const containerRef = React.useRef(null);

    const [{ data, loading }] = useAxios(`${API_BASE_URL}/media/banner-carousel/${subscriberGroupId}`);
    const fallbackImage = `${cdnUrl}asset/img/default-movie-poster-large.webp`;

    useEffect(() => {
        if (data) {
            const imageList = data.data?.bannerCarousel;
            if (imageList) {
                imageList.forEach((film) => {
                    film.feature_url = film.feature_film_flag
                        ? `${film.ad_category_url}/${film.bo_movie_id}/${toSeoUrl(film.bo_movie_title)}`
                        : film.ad_category_url;

                    film.trailer_url = film.trailer_available ? `${film.trailer_url}` : "";
                    return film;
                });
                setCarouselImages(imageList);
            }
        }
    }, [data]);

    const handleAfterChange = (current) => {
        setActiveSlide(current);
    };
    const handleBeforeChange = (current, next) => {
        const totalSlides = carouselImages?.length || 0;

        if (current === totalSlides && next === 0) {
            sliderRef.current.slickGoTo(0);
        }
    };

    const settings = {
        className: (carouselImages?.length > 1 ? "multiple-banners" : ""),
        dots: true,
        arrows: true,
        autoplay: true,
        infinite: (carouselImages?.length > 1),
        speed: 400,
        autoplaySpeed: 7000,
        cssEase: "ease-in-out",
        slidesToShow: 1.5,
        slidesToScroll: 1,
        initialSlide: 0,
        afterChange: handleAfterChange,
        beforeChange: handleBeforeChange,
        prevArrow: <CustomPrevArrow activeSlide={activeSlide} carouselImages={carouselImages}/>,
        nextArrow: <CustomNextArrow carouselImages={carouselImages}/>,
        appendDots: (dots) => (
            <div className="slick-dots">
                {dots.map((_, index) => (
                    <div
                        role="button"
                        key={index}
                        className={`slick-dot ${index === activeSlide ? "active" : ""}`}
                        onClick={() => sliderRef.current.slickGoTo(index)}
                    />
                ))}
            </div>
        ),
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    if (loading) {
        return <Loader isLoading={loading} />;
    }

    return (
        <div ref={containerRef} className="v3-carousel">
            <Slider ref={sliderRef} {...settings}>
                {carouselImages?.map((film, i) => (
                    <div className="v3-carousel__wrapper" key={i}>
                        <div className="v3-carousel__text-container">
                            <div className="v3-carousel__text-content">
                                {film?.bo_movie_title && (
                                    <a
                                        className="v3-carousel__link v3-carousel__title"
                                        href={film?.feature_url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <h2 className="v3-carousel__title">{film.bo_movie_title}</h2>
                                    </a>
                                )}
                                <p className="v3-carousel__subtitle">{film.description}</p>
                                <div className="v3-carousel__buttons">

                                    {Number(film?.ad_category_id) === TYPE_MOVIE_DETAILS && (
                                        <Link
                                            className="button button__primary v3-carousel__button"
                                            to={{ pathname: film.feature_url }}
                                        >
                                            Book Now
                                        </Link>
                                    )}
                                    {film?.trailer_url && (
                                        <TrailerButton
                                            extraClass="v3-carousel__trailer-button"
                                            type="youtube"
                                            key={`${film.trailer_url}-${i}`}
                                            trailerLink={film.trailer_url}
                                        >
                                            <PlayIcon className="v3-carousel__trailer-svg" />
                                            <span className="v3-carousel__trailer-text">Official Trailer</span>
                                        </TrailerButton>
                                    )}
                                </div>
                            </div>
                            {film?.bo_movie_rating && Number(film?.ad_category_id) === TYPE_MOVIE_DETAILS && (
                                <div className="v3-carousel__classification">{film?.bo_movie_rating}</div>
                            )}
                        </div>

                        <a
                            className="v3-carousel__link"
                            href={film?.feature_url}
                            target="_blank"
                            rel="noreferrer"
                            key={`${film?.banner_image_url}-${i}`}
                        >
                            {
                                (Number(film?.image_source) === SOURCE_BO_TRAILER ||
                                Number(film?.image_source) === SOURCE_LOCAL_TRAILER)
                            ? (
                                <video
                                    className={`v3-carousel__trailer v3-carousel__trailer--${Number(film?.image_source) === SOURCE_BO_TRAILER ? "local" : "bo"}`}
                                    muted loop autoPlay
                                    onError={(e) => {
                                        console.error("Error loading video:", e);
                                        e.target.parentNode.removeChild(e.target);
                                        const img = document.createElement("img");
                                        img.src = film?.banner_image_url || fallbackImage;
                                        img.alt = film?.description;
                                        img.className = "v3-carousel__image";
                                        e.target.parentNode.appendChild(img);
                                    }}
                                >
                                    <source src={film?.banner_trailer_url} type="video/mp4" />
                                    {/* Fallback mechanism: If the video format is not supported, display this message */}
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <img
                                    className="v3-carousel__image"
                                    src={film?.banner_image_url}
                                    alt={film?.description}
                                />
                            )}
                        </a>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default V3Carousel;
