import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ApplicationContext } from "@context/ApplicationContext";
import NowShowing from "@components/NowShowing";
import Layout from "@components/Wrappers/Layout";

const NowShowingPage = () => {

    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;

    return (
        <Layout>
            <div className="main__intro">
                <Helmet>
                    <title>{ `Now Showing | Movies in Cinemas Now | ${brand}` }</title>
                    <meta name="keywords" content={ `Now Showing | Movies in Cinemas Now | ${brand}` } />
                    <meta name="description" content={ `Discover movies now showing in regional cinemas across Australia.
                    Get information on movie tickets, screening locations,
                    showtimes & more. Only at ${brand}!` } />
                    <link rel="canonical" href={`${window.location.protocol }//${ window.location.host}/now-showing`} />
                </Helmet>
                <h1 className="main-heading">Now Showing</h1>
                <p className="paragraph">The following movies are now showing.</p>
            </div>
            <NowShowing />
        </Layout>
    );
};

export default NowShowingPage;
