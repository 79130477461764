import { ACTION_TYPES } from "@reducers/actionTypes";

export const INITIAL_STATE = {
    data: [{
        id: "",
        uuid: null,
        error: false,
        errorMessage: "",
        loading: false,
        processing: false,
        message: "",
    }, ],
};
const ticketsReducer = (state, action) => {

    const { type, payload } = action;

    switch (type) {
        case ACTION_TYPES.FETCH_START:
            return {
                ...state,
                processing: true,
                loading: true,
                uuid: action.uuid,
            };
        case ACTION_TYPES.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                processing: false,
                success: payload.success,
                message: payload.message,

            };
        case ACTION_TYPES.FETCH_ERROR:
            return {
                error: true,
                errorMessage: payload.error,
                processing: false,
                loading: false,
            };
        default:
            throw new Error(`No case for type ${type} found in ticketsReducer.`);
    }
};

export default ticketsReducer;

