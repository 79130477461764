import React, { useContext, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import useModal from "@hooks/useModal";
import { CheckoutComplimentaryContext } from "@context/CheckoutComplimentaryContext";
import AlertMessage from "@components/AlertMessage";
import Button from "@components/Ui/Button";
import Checkbox from "@components/Ui/Checkbox";
import Spinner from "@components/Ui/Spinner";
import TermsPage from "@pages/TermsPage";
import "./style.scss";

const CheckoutComplimentary = ({ uuid, bookingSessionId, freeTicketToken }) => {
    const { id } = useParams();
    const { openModalWithContent, portal } = useModal();
    const [isTermsChecked, setIsTermsChecked] = useState(false);

    const {
        isSubmitting,
        paymentSuccess,
        error,
        checkoutComplementary,
    } = useContext(CheckoutComplimentaryContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        checkoutComplementary(id, bookingSessionId, freeTicketToken);
    };

    if (paymentSuccess) {
        return <Redirect to={{ pathname: `/ticket/success/${uuid}` }} />;
    }

    return (
        <>
            <div className="checkout-comp__wrapper">
                <h2 className="checkout-comp__title">Complimentary</h2>
                <p className="paragraph">These tickets/vouchers are complimentary based on the promo code.</p>
                <div className="checkout-comp__content">
                    <form
                        onSubmit={(e) => handleSubmit(e)}
                        className="checkout-comp__form"
                    >
                        <Checkbox
                            checked={isTermsChecked}
                            onChange={() => setIsTermsChecked(!isTermsChecked)}
                            > I accept the
                            <a
                                href="#terms"
                                className="inline-link"
                                onClick={() =>
                                    openModalWithContent(<TermsPage />, "medium")
                                }>Terms &amp; Conditions</a>of purchase
                        </Checkbox>
                        <div
                            className="form__group"
                            data-tip={!isTermsChecked ? "Please accept Terms & Conditions" : null}
                        >
                            <Button
                                additionalClasses="button button__primary"
                                disabled={
                                    !isTermsChecked || isSubmitting
                                    ? "disabled"
                                    : ""
                                }>
                                {isSubmitting
                                    ? <Spinner text="loading..."/>
                                    : "Book Ticket(s)"
                                }
                            </Button>
                        </div>
                        <ReactTooltip className="tooltip" />
                        <AlertMessage message={error} type="error"/>
                    </form>
                </div>
            </div>
            {portal}
        </>
    );
};

export default CheckoutComplimentary;
