import React, { useContext, useState } from "react";
import { ApplicationContext } from "@context/ApplicationContext";
import { SessionContext } from "@context/SessionContext";
import "./style.scss";

const Logo = () => {
    const [redirectHome, setRedirectHome] = useState(false);
    const { siteConfig, cdnUrl } = useContext(ApplicationContext);
    const {
        setVenueName,
        setMovieTitle,
        setRegion,
        setSessions,
    } = useContext(SessionContext);

    const logoUrl = cdnUrl && siteConfig?.logo.startsWith("data:image") === false
        ? cdnUrl + siteConfig?.logo
        : siteConfig?.logo;

    const handleNavigateHome = () => {
        setVenueName(null);
        setMovieTitle(null);
        setRegion(25);
        setSessions([]);
        setRedirectHome(true);
    };

    if (redirectHome === true) {
        window.location = "/";
    }

    return (
    
        <a className="nav-logo__container" onClick={handleNavigateHome} role="button">
            <img src={logoUrl} alt={siteConfig?.logo_alt_text} className="nav-logo__img" />
        </a>
    );
};

export default Logo;
