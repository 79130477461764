import React, { useContext } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { ApplicationContext } from "@context/ApplicationContext";
import ErrorIcon from "@components/Icons/ErrorIcon";
import SuccessIcon from "@components/Icons/SuccessIcon";
import "./style.scss";

const AlertMessage = ({
      message=null,
      type="success",
      showIcon=true,
      messageList=null,
      link="",
      fromPathName="",
      displayEmail=false,
    }) => {
    const { whiteLabel } = useContext(ApplicationContext);


    const typeClass = type === "error"
        ? "error"
        : "success";

    const icon = type === "error"
        ? <ErrorIcon />
        : <SuccessIcon />;

    if (link) {
        message = (
            <Link className="alert-message__link" to={{ pathname: "/login", state: { from: fromPathName } }}>
                {message}
            </Link>
        );
    }

    return message && (
        <div className={`alert-message__wrapper alert-message__wrapper--${typeClass}`}>
            <div className={`alert-message alert-message__${typeClass}`}>
                {showIcon && (
                    <span className="alert-message__icon">{icon}</span>
                )}
                {message}
                {messageList && (
                    <ul className="alert-message__message-list">
                        {messageList.map((item, idx) => (
                            <li key={idx}>
                                <ReactMarkdown components={{ p: React.Fragment }}>
                                    {item}
                                </ReactMarkdown>
                            </li>
                        ))}
                    </ul>
                )}
                {displayEmail? (
                    <a className="alert-message__link" href={`mailto:${whiteLabel?.support_email}`}>{whiteLabel?.support_email}</a>
                ): null}

            </div>
        </div>
    );
};

AlertMessage.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    type: PropTypes.string,
    showIcon: PropTypes.bool,
    messageList: PropTypes.array,
    link: PropTypes.string,
    fromPathName: PropTypes.string
};

export default AlertMessage;
