import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PRODUCT_TYPE_MOVIE_REWARDS, SITE_V3 } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { MembershipContext } from "@context/MembershipContext";
import ScrollToTop from "@components/V3/ScrollToTop";
import AboutUsPage from "@pages/AboutUsPage";
import AddEmailPage from "@pages/AddEmailPage";
import ChangePasswordPage from "@pages/ChangePasswordPage";
import CreatePasswordPage from "@pages/CreatePasswordPage";
import EmailVerifyPage from "@pages/EmailVerifyPage";
import ExperiencePage from "@pages/ExperiencePage";
import FaqPage from "@pages/FaqPage";
import HistoryPage from "@pages/HistoryPage";
import LetsGetStartedPage from "@pages/LetsGetStartedPage";
import LoginPage from "@pages/LoginPage";
import MovieClubPage from "@pages/MovieClubPage";
import MovieClubSuccessPage from "@pages/MovieClubSuccessPage";
import MovieDetailPage from "@pages/MovieDetailPage";
import MovieRewardsSearchPage from "@pages/MovieRewardsSearchPage";
import MoviesPage from "@pages/MoviesPage";
import NewsItemPage from "@pages/NewsItemPage";
import NewsPage from "@pages/NewsPage";
import NoPublicAccessPage from "@pages/NoPublicAccessPage";
import NotFoundPage from "@pages/NotFoundPage";
import NowShowingPage from "@pages/NowShowingPage";
import ParticipatingCinemasPage from "@pages/ParticipatingCinemasPage";
import PrivacyCollectionNoticePage from "@pages/PrivacyCollectionNoticePage";
import PrivacyPolicyPage from "@pages/PrivacyPolicyPage";
import RequestPasswordResetPage from "@pages/RequestPasswordResetPage";
import ResetForgottenPasswordPage from "@pages/ResetForgottenPasswordPage";
import SeasonalVoucherPage from "@pages/SeasonalVoucherPage";
import SignUpPage from "@pages/SignUpPage";
import SubscriberGroupLandingPage from "@pages/SubscriberGroupLandingPage";
import SuccessPage from "@pages/SuccessPage";
import TermsPage from "@pages/TermsPage";
import TicketPurchasePage from "@pages/TicketPurchasePage";
import UnsuccessfulSignInPage from "@pages/UnsuccessfulSignInPage";
import UserMembershipsPage from "@pages/UserMembershipsPage";
import UserProfilePage from "@pages/UserProfilePage";
import V2V3TicketPurchasePage from "@pages/V2V3TicketPurchasePage";
import V3ChangePasswordPage from "@pages/V3ChangePasswordPage";
import V3CreatePasswordPage from "@pages/V3CreatePasswordPage";
import V3HistoryPage from "@pages/V3HistoryPage";
import V3MovieDetailsPage from "@pages/V3MovieDetailsPage";
import V3Page from "@pages/V3Page";
import V3ParticipatingPage from "@pages/V3ParticipatingCinemasPage";
import V3UserProfilePage from "@pages/V3UserProfilePage";
import V3VenueDetailsPage from "@pages/V3VenueDetailsPage";
import VenueDetailPage from "@pages/VenueDetailPage";
import VoucherHistoryPage from "@pages/VoucherHistoryPage";
import VoucherManagerSearchPage from "@pages/VoucherManagerSearchPage";
import WelcomePage from "@pages/WelcomePage";

const RouteCollection = () => {
    const { isLoggedIn, siteVersion, setSiteVersion } = useContext(AuthContext);
    const { publicSubscriberGroup, productTypeId, whiteLabel, seasonalRoutes, appSecurityLogIn } = useContext(ApplicationContext);
    const { fetchMembershipOptions } = useContext(MembershipContext);
    const SearchPage = productTypeId === PRODUCT_TYPE_MOVIE_REWARDS
        ? MovieRewardsSearchPage
        : VoucherManagerSearchPage;

    useEffect(() => {
        if (whiteLabel?.subscriber_group_id) {
            fetchMembershipOptions(whiteLabel.subscriber_group_id);
        }
        if (whiteLabel?.site_version) {
            setSiteVersion(whiteLabel.site_version);
        }
    }, [whiteLabel?.subscriber_group_id, whiteLabel?.site_version]);
    
    const commonRoutes = (
        <>
            <Route exact path="/faqs" component={FaqPage} />
            <Route exact path="/privacy" component={PrivacyPolicyPage} />
            <Route exact path="/terms" component={TermsPage} />
            <Route exact path="/privacy-collection-notice" component={PrivacyCollectionNoticePage} />
            <Route exact path="/news" component={NewsPage} />
            <Route exact path="/news/:id" component={NewsItemPage} />
            <Route exact path="/about" component={AboutUsPage} />
            <Route key="lets-get-started" exact path="/lets-get-started" component={LetsGetStartedPage} />
            <Route key="welcome" exact path="/welcome" component={WelcomePage} />
            <Route key="success" exact path="/ticket/success/:uuid" component={SuccessPage} />
            <Route exact path="/ticket/payment-result-eway/:uuid" component={SuccessPage} />
            <Route key="movie-club-success" exact path="/movie-club-membership/success/:uuid" component={MovieClubSuccessPage} />
            <Route key="movie-club" exact path="/movie-club-membership" component={MovieClubPage} />
            <Route exact path="/unsuccessful-login" component={UnsuccessfulSignInPage} />
        </>
    );

    return (
        <Router>
            <ScrollToTop />
            {publicSubscriberGroup === 0 && !isLoggedIn ? (
                <Switch>
                    {siteVersion && siteVersion === SITE_V3 && (
                        <Route key="v3-create-password" exact path="/user/create-password/:passwordToken"  component={V3CreatePasswordPage} />
                    )}
                    <Route exact path="/" component={NoPublicAccessPage} />
                    <Route exact path="/login" component={NoPublicAccessPage} />
                    <Route exact path="/cinemas" component={V3ParticipatingPage} />
                    <Route exact path="/participating-cinemas" component={ParticipatingCinemasPage} />
                    {seasonalRoutes?.map((path) =>
                        <Route path={path.advert_url} component={SeasonalVoucherPage} key={path.advert_url} />,
                    )}
                    <Route exact path="/signup" component={NoPublicAccessPage} />
                    <Route key="v3-experiences" exact path="/experiences" component={NoPublicAccessPage} />,
                    <Route key="experience-type" path="/experiences/:experienceType" component={NoPublicAccessPage} />
                    <Route exact path="/now-showing" component={NoPublicAccessPage} />
                    <Route exact path="/request-password-reset" component={RequestPasswordResetPage} />
                    <Route exact path="/user/forgotten-password/:passwordToken" component={ResetForgottenPasswordPage} />
                    <Route exact path="/user/create-password/:passwordToken" component={CreatePasswordPage} />
                    <Route exact path="/user/email-verify/:token" component={EmailVerifyPage} />
                    <Route exact path="/subscriber-group/:groupCode" component={SubscriberGroupLandingPage} />
                    <Route key="movie-details-view-1" exact path="/movie/:id/:movieName" component={NoPublicAccessPage} />,
                    <Route key="venue-details-view-1" exact path="/venue/:id/:venue_name" component={NoPublicAccessPage} />,
                    <Route exact path="/user/membership" component={NoPublicAccessPage} />
                    <Route exact path="/user/email-add" component={NoPublicAccessPage} />
                    <Route exact path="/user/email-verify/:token" component={NoPublicAccessPage} />
                    <Route exact path="/user/profile" component={NoPublicAccessPage} />
                    <Route exact path="/user/current-vouchers" component={NoPublicAccessPage} />
                    <Route exact path="/user/current-tickets" component={NoPublicAccessPage} />
                    <Route exact path="/ticket/purchase/:id" component={NoPublicAccessPage} />
                    <Route exact path="/user/change-password" key="data4" component={NoPublicAccessPage} />,
                    {commonRoutes}
                    <Route component={NotFoundPage} />
                </Switch>
            ) : (
                <Switch>
                    {siteVersion && siteVersion === SITE_V3 ? (
                        [
                            <Route key="v3-home" exact path="/" component={V3Page} />,
                            <Route key="v3-cinemas" exact path="/cinemas" component={V3ParticipatingPage} />,
                            <Route key="v3-venues" exact path="/movies" component={MoviesPage} />,
                            <Route key="v3-experiences" exact path="/experiences" component={ExperiencePage} />,
                            <Route key="experience-type" path="/experiences/:experienceType" component={ExperiencePage} />,
                            <Route key="movie-venue-details-view" path="/movie/:id/:movieName" component={V3MovieDetailsPage} />,
                            <Route key="new-venue-details-view" path="/venue/:id/:venue_name" component={V3VenueDetailsPage} />,
                            <Route key="v3-ticket-history" exact path="/user/current-tickets" component={V3HistoryPage} />,
                            <Route key="v3-user-profile" exact path="/user/profile" component={V3UserProfilePage} />,
                            <Route key="v3-change-password" exact path="/user/change-password" component={V3ChangePasswordPage} />,
                            <Route key="v3-create-password" exact path="/user/create-password/:passwordToken"  component={V3CreatePasswordPage} />,
                            <Route key="v2-v3-purchase-page" exact path="/ticket/purchase/:id" component={V2V3TicketPurchasePage} />,
                        ]
                    ) : (
                        <Route exact path="/" component={SearchPage} />
                    )}
                    {seasonalRoutes?.map((path, index) =>
                        <Route path={path.advert_url} component={SeasonalVoucherPage} key={index} />,
                    )}
                    <Route exact path="/user/membership" component={UserMembershipsPage} />
                    <Route exact path="/subscriber-group/:groupCode" component={SubscriberGroupLandingPage} />
                    <Route exact path="/now-showing" component={NowShowingPage} />
                    <Route exact path="/participating-cinemas" component={ParticipatingCinemasPage} />
                    <Route exact path="/user/email-add" component={AddEmailPage} />
                    <Route exact path="/user/email-verify/:token" component={EmailVerifyPage} />
                    <Route exact path="/user/current-vouchers" component={VoucherHistoryPage} />
                    <Route exact path="/user/current-tickets" component={HistoryPage} />
                    {siteVersion && siteVersion !== SITE_V3 && (
                        [
                            <Route key="movie-details-view" exact path="/movie/:id/:movieName" component={MovieDetailPage} />,
                            <Route key="venue-details-view" exact path="/venue/:id/:venue_name" component={VenueDetailPage} />,
                            <Route key="profile" exact path="/user/profile" component={UserProfilePage} />,
                            <Route key="purchase" exact path="/ticket/purchase/:id" component={TicketPurchasePage} />
                        ]
                    )}
                    {appSecurityLogIn ? (
                        [
                            <Route exact path="/signup" key="data1" component={SignUpPage} />,
                            <Route exact path="/login" key="data2" component={LoginPage} />,
                            <Route exact path="/request-password-reset" key="data3" component={RequestPasswordResetPage} />,
                            <Route exact path="/user/change-password" key="data4-2" component={ChangePasswordPage} />,
                            <Route exact path="/user/create-password/:passwordToken" key="data5" component={CreatePasswordPage} />,
                            <Route exact path="/user/forgotten-password/:passwordToken" key="data6" component={ResetForgottenPasswordPage} />,
                        ]
                    ) : (
                        [
                            <Route exact path="/signup" key="data7" component={NoPublicAccessPage} />,
                            <Route exact path="/login" key="data8" component={NoPublicAccessPage} />,
                            <Route exact path="/request-password-reset" key="data9" component={NoPublicAccessPage} />,
                            <Route exact path="/user/change-password" key="data10" component={NoPublicAccessPage} />,
                            <Route exact path="/user/create-password/:passwordToken" key="data11" component={NoPublicAccessPage} />,
                            <Route exact path="/user/forgotten-password/:passwordToken" key="data12" component={NoPublicAccessPage} />,
                        ]
                    )}
                    {commonRoutes}
                    <Route exact path="/page-not-found" component={NotFoundPage} />
                    <Route component={NotFoundPage} />
                </Switch>
            )}
        </Router>
    );
};

export default RouteCollection;
