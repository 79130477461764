import React from "react";
import PropTypes from "prop-types";

const PromoLogoAia = ({ className="" }) => {
	return (
    <>
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="15" height="21" viewBox="0 0 15 21" fill="none">
            <path d="M15 0.698653C14.5053 0.17522 8.39751 -0.516459 3.12694 15.8595C3.08888 15.9717 2.93666 15.9717 2.89861 15.8221C2.0614 11.4851 2.44195 6.54989 2.32779 5.78343C2.0614 3.82056 0.367972 4.45616 0.367972 4.45616C-0.393122 6.00776 0.0635329 18.6449 1.39545 20.3087C2.15654 20.6452 4.34469 20.533 4.78232 20.0096C4.78232 20.0096 7.84572 4.02619 15 0.698653Z" fill="currentColor" />
        </svg>
    </>
	);
};

PromoLogoAia.propTypes = {
    className: PropTypes.string,
};

export default PromoLogoAia;
