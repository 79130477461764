import React from "react";
import "./style.scss";

const TicketIcon = ({ className="" }) => {
    return (
        <svg className={className} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_684_1071)">
                <path d="M15.4495 5.55024C14.7689 4.86965 14.7689 3.75596 15.4495 3.07537L12.9746 0.600496C12.294 -0.0800939 11.1803 -0.0800935 10.4997 0.600496L0.60023 10.5C-0.0803606 11.1806 -0.0741734 12.2881 0.606417 12.9687L3.08129 15.4435C3.76188 14.763 4.86939 14.7691 5.54998 15.4497C6.23057 16.1303 6.23675 17.2378 5.54998 17.9246L8.02485 20.3995C8.70544 21.0801 9.81913 21.0801 10.4997 20.3995L20.3992 10.5C21.0798 9.8194 21.0798 8.70571 20.3992 8.02512L17.9243 5.55024C17.2438 6.23083 16.1301 6.23083 15.4495 5.55024ZM15.6846 11.2548L12.0465 12.0468L11.2546 15.6848L9.37366 12.4675L5.67372 12.8387L8.14241 10.0607L6.66367 6.66394L10.0604 8.15505L12.8385 5.68636L12.4672 9.3863L15.6846 11.2548Z" fill="currentColor"/>
            </g>
            <defs>
                <clipPath>
                    <rect width="21" height="21" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default TicketIcon;
