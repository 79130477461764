import { CLASS_GOLD, CLASS_PREMIUM, CLASS_STANDARD, HOYTS_CINEMAS, READING_CINEMAS, VILLAGE_CINEMAS } from "@config/config";

export const getSessionButtonText = (session) => {
    switch (Number(session.ticket_class_id)) {
        case CLASS_GOLD:
            switch (Number(session.cinema_group_id)) {
                case Number(HOYTS_CINEMAS):
                    return "LUX";
                case Number(READING_CINEMAS):
                    return "TITAN";
                default:
                    return "GOLD";
            }
        case CLASS_PREMIUM:
            switch (Number(session.cinema_group_id)) {
                case Number(HOYTS_CINEMAS):
                    return (session?.session_type || "").toLowerCase().includes("dbox") ? "DBOX" : "XTRM";
                case Number(VILLAGE_CINEMAS):
                    return "VMAX";
                default:
                    return "PREM";
            }
        default:
            if (Number(session.cinema_group_id) === Number(VILLAGE_CINEMAS)) {
                switch (true) {
                    case session?.session_type?.toLowerCase().includes("vpremium"):
                        return "VPRM";
                    case session?.session_type?.toLowerCase().includes("vjunior"):
                        return "VJNR";
                    default:
                        return "STND";
                }
            }
            return "STND";
    }
};

export const getSessionType = (session) => {
    const ticketClassId = Number(session.ticket_class_id);
    if (ticketClassId === CLASS_GOLD) return "GOLD";
    if (ticketClassId === CLASS_PREMIUM) return "PREM";
    return "STND";
};

export const hasCandyBarItems = (cart) => {
    if (!Array.isArray(cart)) return false;
    return cart.some((item) => item.type === "candy-bar" && item.quantity > 0);
};

export const isMessageRequired = (session) => {
    if (!session ) return false;
    if (Number(session.cinema_group_id) === Number(HOYTS_CINEMAS) && Number(session.ticket_class_id) === CLASS_PREMIUM) {
        return session?.session_type?.toLowerCase().includes("dbox")
            ? "You are buying a standard voucher. This needs to be upgraded for your selected DBox session at HOYTS for an additional $8 cost (plus standard booking fee)."
            : "You are buying a standard voucher. This needs to be upgraded for your selected Xtremescreen session at HOYTS for an additional $5 cost (plus standard booking fee).";
    } 
    if (Number(session.cinema_group_id) === Number(VILLAGE_CINEMAS) && Number(session.ticket_class_id) === CLASS_STANDARD) {
        switch (true) {
            case session?.session_type?.toLowerCase().includes("vpremium"):
                return "You are buying a standard voucher. This needs to be upgraded for your selected Vpremium session at Village for an additional $5 cost (plus standard booking fee).";
            case session?.session_type?.toLowerCase().includes("vjunior"):
                return "You are buying a standard voucher. This needs to be upgraded for your selected Vjunior session at Village for an additional $5 cost (plus standard booking fee).";
            default:
                return false;
        }
    }
    return false;
};

export const getSessionTextFull = (session) => {
    switch (Number(session.ticket_class_id)) {
        case CLASS_GOLD:
            switch (Number(session.cinema_group_id)) {
                case Number(HOYTS_CINEMAS):
                    return "LUX";
                case Number(READING_CINEMAS):
                    return "Titan Luxe";
                default:
                    return "Gold";
            }
        case CLASS_PREMIUM:
            switch (Number(session.cinema_group_id)) {
                case Number(HOYTS_CINEMAS):
                    return (session?.session_type || "").toLowerCase().includes("dbox") ? "DBox" : "Xtremescreen";
                case Number(VILLAGE_CINEMAS):
                    return "VMAX";
                default:
                    return "Premium";
            }
        default:
            if (Number(session.cinema_group_id) === Number(VILLAGE_CINEMAS)) {
                switch (true) {
                    case session?.session_type?.toLowerCase().includes("vpremium"):
                        return "Vpremium";
                    case session?.session_type?.toLowerCase().includes("vjunior"):
                        return "Vjunior";
                    default:
                        return "Standard";
                }
            }
            return "Standard";
    }
};

export const setSessionClass = (session) => {
    if (!session) return "";

    if (Number(session.cinema_group_id) === Number(VILLAGE_CINEMAS) && Number(session.ticket_class_id) === CLASS_STANDARD) {
        if (
            session?.session_type?.toLowerCase().includes("vpremium") ||
            session?.session_type?.toLowerCase().includes("vjunior")
        ) {
            return "PREM";
        }
    }
    return session.experience_type;
};
