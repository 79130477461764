import React, { useState } from "react";
import PropTypes from "prop-types";
import CandyBarIcon from "@components/Icons/CandyBarIcon";
import TicketIcon from "@components/Icons/TicketIcon";
import Button from "@components/Ui/Button";
import "./style.scss";

const VoucherList = ({ vouchers=[], defaultDisplayLimit= 8 }) => {

    const [showMore, setShowMore] = useState(false);
    const showMoreButton = vouchers.length > defaultDisplayLimit;

    const vouchersDisplayed = showMore === true
        ? vouchers
        : vouchers.filter((s, i) => i < defaultDisplayLimit);

    return (
        <>
            <div className="voucher-list__wrapper">
                {vouchersDisplayed.map((voucher, i) => (
                    <div className="voucher-list__item" key={voucher.voucher_code_url}>
                        <a href={voucher.voucher_code_url} className="button button__primary voucher-list__button"  target="_blank" rel="noreferrer">
                            {voucher.is_candy_bar === "1"
                                ? <CandyBarIcon/>
                                : <TicketIcon />}
                            {`Voucher ${i + 1}`}
                        </a>
                    </div>
                ))}

                {showMoreButton && (
                    <Button additionalClasses="button button__primary voucher-list__button" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "Show Less" : "Show More"}
                    </Button>
                )}
            </div>
        </>
    );
};

VoucherList.propTypes = {
    vouchers: PropTypes.arrayOf(
        PropTypes.shape({
            voucher_code_url: PropTypes.string.isRequired,
        })
    ).isRequired,
    defaultDisplayLimit: PropTypes.number
};

export default VoucherList;
