import format from "date-fns/format";

const getDateOptions = () => {
    return [...Array(7).keys()]
        .map((i) => {
            const date = new Date();
            date.setDate(date.getDate() + i);
            return {
                label: format(date, "EEEE, MMMM do"),
                value: format(date, "yyyy-MM-dd"),
            };
        });
};

export default getDateOptions;
