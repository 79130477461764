import React, { useContext } from "react";
import ReactTooltip from "react-tooltip";
import { CLASS_GOLD, CLASS_PREMIUM, EVENT_CINEMAS, HOYTS_CINEMAS, LUNA_CINEMAS, MOVING_STORY, ORANA_CINEMAS, PALACE_CINEMAS, READING_CINEMAS, VILLAGE_CINEMAS } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import EventLogo from "@components/CinemaGroupLogo/EventLogo";
import HoytsLogo from "@components/CinemaGroupLogo/HoytsLogo";
import LunaLogo from "@components/CinemaGroupLogo/LunaLogo";
import MovingStoryLogo from "@components/CinemaGroupLogo/MovingStoryLogo";
import OranaLogo from "@components/CinemaGroupLogo/OranaLogo";
import PalaceLogo from "@components/CinemaGroupLogo/PalaceLogo";
import ReadingsLogo from "@components/CinemaGroupLogo/ReadingsLogo";
import VillageLogo from "@components/CinemaGroupLogo/VillageLogo";
import VoucherButtons from "@components/VoucherManager/VoucherButtons";
import "./style.scss";

const VoucherItems = ({ items = [], selectedItems = [], setQuantity = () => { }, purchasableItemKey = () => { } }) => {

    const { whiteLabel } = useContext(ApplicationContext);

    return (
        <>

            {items && items.map((item, i) => {
                const consumableDescription = item.voucher_time_periods.voucher_category?.description;
                const idKey = purchasableItemKey(item);
                const cashbackFlag = whiteLabel?.subscriber_group?.cashback_flag;
                const displayPrice = whiteLabel?.subscriber_group?.display_price;
                const price = whiteLabel?.subscriber_group?.display_price === true ? item.rrp_price_cents : item.price_cents;
                const rrp = item.voucher_time_periods.rrp_price_cents;
                const { saving } = item.voucher_time_periods;
                const cashback = whiteLabel?.subscriber_group?.cashback_flag ? item.voucher_time_periods.cashback_percent : null;
                const cashbackSanitised = cashback ? cashback.slice(0, 2): null;
                const ticket_clas = item.voucher_time_periods.ticket_clas?.name;
                const ticket_type = item.voucher_time_periods.ticket_type?.name;
                const intro = item.voucher_time_periods.voucher_code_bucket.short_description;
                const voucher_category = item.voucher_time_periods.voucher_category?.name;
                const { key, slug, cinema_group, tile_class, voucher_code_bucket } = item;
                let CinemaGroupLogo;

                switch (cinema_group.id) {
                    case HOYTS_CINEMAS:
                        CinemaGroupLogo = HoytsLogo;
                        break;
                    case VILLAGE_CINEMAS:
                        CinemaGroupLogo = VillageLogo;
                        break;
                    case EVENT_CINEMAS:
                        CinemaGroupLogo = EventLogo;
                        break;
                    case LUNA_CINEMAS:
                        CinemaGroupLogo = LunaLogo;
                        break;
                    case PALACE_CINEMAS:
                        CinemaGroupLogo = PalaceLogo;
                        break;
                    case READING_CINEMAS:
                        CinemaGroupLogo = ReadingsLogo;
                        break;
                    case ORANA_CINEMAS:
                        CinemaGroupLogo = OranaLogo;
                        break;
                    case MOVING_STORY:
                        CinemaGroupLogo = MovingStoryLogo;
                        break;
                    default:
                        CinemaGroupLogo = () => null;
                        break;
                }


                const logoFillColor =
                    ticket_clas?.id === CLASS_GOLD
                        ? "gold"
                        : ticket_clas?.id === CLASS_PREMIUM
                            ? "black"
                            : "white";

                const index = selectedItems.findIndex((selectedItem) => (
                    selectedItem.key === idKey
                ));

                const quantity = index !== -1
                    ? selectedItems[index].quantity
                    : 0;

                const itemTotal = quantity === 0
                    ? 0
                    : price * quantity;

                const uniqueKey = `voucher-${key}-${i}`;

                return (
                    <div className="voucher__item" key={uniqueKey}>
                        <div className={`voucher__tile voucher__tile--${tile_class}`}>
                            <div className="voucher__logo">
                                <CinemaGroupLogo fillColour={logoFillColor}/>
                            </div>
                            <div className="voucher__title">{intro || (consumableDescription)}</div>
                        </div>
                        <div className="voucher__content">
                            <div className="voucher__group-name">
                                {cinema_group.name}
                            </div>
                            {ticket_type && ticket_clas ? (
                                <div className="voucher__details">
                                    <div className="voucher__name">{ticket_clas.name}</div>
                                    <div className="voucher__terms">
                                        <div className="voucher__description">
                                            {ticket_clas || (consumableDescription)}
                                        </div>
                                        <div
                                            className="voucher__info"
                                            data-tip
                                            data-for={`voucher__item slug__${slug}`}
                                        >
                                            i
                                        </div>
                                        <ReactTooltip
                                            className="voucher__tooltip tooltip"
                                            id={`voucher__item slug__${slug}`}
                                        >
                                            {voucher_code_bucket.description}
                                        </ReactTooltip>
                                    </div>
                                </div>
                            ) : (
                                <div className="voucher__category">{voucher_category}</div>
                            )}
                            {displayPrice === true && cashbackFlag === true ? (
                                <div className="voucher__group">
                                    <div className="voucher__price">${(rrp / 100).toFixed(2)}</div>
                                    <div className="voucher__cashback">{cashbackSanitised}% Cashback</div>
                                </div>
                            ) : displayPrice === true && cashbackFlag === false ? (
                                <div className="voucher__group">
                                    <div className="voucher__price">${(rrp / 100).toFixed(2)}</div>
                                </div>
                                )
                                : (
                                    <div className="voucher__group">
                                        <div className="voucher__price">${(price / 100).toFixed(2)}</div>
                                        {rrp? (<div className="voucher__rrp">(RRP ${(rrp / 100).toFixed(2)}</div>):null}
                                        {saving? (<div className="voucher__saving">, Saving ${(saving / 100).toFixed(2)})</div> ): null}
                                    </div>
                            )}
                        </div>
                        <div key={`voucher-${idKey}`} className={`voucher__quantity voucher__quantity--${i}`} data-idkey={idKey}>
                            <VoucherButtons
                                value={quantity}
                                setValue={(newQuantity) => {
                                    setQuantity(selectedItems, idKey, newQuantity);
                                }}
                            />
                            <div className="voucher__totals">
                                ${(itemTotal / 100).toFixed(2)}
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};



export default VoucherItems;
