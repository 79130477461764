import React from "react";
import "./style.scss";

const BookedTicketSavings = ({ ticket }) => {
    const savings = ((ticket?.total_rrp_pricing) - (ticket?.total_price_cents));

    return (
        <div className="booked-ticket-savings">
            {savings > 0? (
                <div className="booked-ticket-savings__item booked-ticket-savings__item--savings">
                    You Saved: ${(savings/100).toFixed(2)}
                </div>
            ): null}
        </div>
    );
};


export default BookedTicketSavings;
