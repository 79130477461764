import React, { useContext } from "react";
import { ApplicationContext } from "@context/ApplicationContext";
import TextInput from "@components/Ui/TextInput";
import "./style.scss";

const ReferralCodeInput = ({ referralCode, setReferralCode, success }) => {

    const { whiteLabel } = useContext(ApplicationContext);

    const inputClass = (success === true)
        ? "referral-code-input__success"
        : (success === false)
            ? "referral-code-input__error"
            : "";

    return (
        <TextInput
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
            placeholder={whiteLabel?.referral_placeholder_text}
            additionalClasses={`text-input__field--wide ${inputClass}`}
        />
    );
};

export default ReferralCodeInput;
