import React, { useContext } from "react";
import { Link } from "react-router-dom";
import toSeoUrl from "@functions/toSeoUrl";
import { ApplicationContext } from "@context/ApplicationContext";
import Sessions from "@components/Sessions";
import "./style.scss";

const SearchResultByMovie = ({ item }) => {
    const { cdnUrl } = useContext(ApplicationContext);
    const placeHolderUrl = `${cdnUrl}asset/img/placeholder.jpeg`;
    
    return (

        <div className="movie-results__item" key={item.id}>
            <div className="movie-results__thumbnail">
                <img
                    className="movie-results__image"
                    src={item.venue_image || placeHolderUrl}
                    alt={item.venue_name}/>
            </div>
            <div className="movie-results__content">
                <Link
                    key={item.id}
                    className="movie-results__venue-link"
                    to={{
                        pathname: `/venue/${item.venue_id}/${toSeoUrl(item.venue_name)}`,
                    }}
                >
                    {item.venue_name}
                </Link>
                <p
                    className="movie-results__address"
                >
                    <span
                        className="movie-results__address-item">{item.venue_address}, {item.venue_suburb}, {item.venue_state.toUpperCase()}</span>
                    <span className="movie-results__address-item">{item.venue_distance}km</span>
                </p>
            </div>
            <Sessions sessions={item.sessions} sessionLimit={9}/>
        </div>
    );
};

export default SearchResultByMovie;
