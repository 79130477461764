import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ApplicationContext } from "@context/ApplicationContext";
import UserProfileForm from "@components/Form/UserProfileForm";
import FormWrapper from "@components/Wrappers/FormWrapper";
import Layout from "@components/Wrappers/Layout";

const UserProfilePage = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;

    return (
        <Layout>
            <Helmet>
                <title>{ `Account Profile | ${brand}` }</title>
                <meta name="keywords" content={ `Account Profile | ${brand}` } />
                <meta name="description" content={ `Keep track of your membership
                information & history through your ${brand} account profile.
                Use it to shop for movie tickets, redeem offers & more!` } />
                <link rel="canonical" href={`${window.location.protocol }//${ window.location.host}/user/profile`} />
            </Helmet>
            <FormWrapper pageTitle="Account Profile">
                <UserProfileForm />
            </FormWrapper>
        </Layout>
    );
};

export default UserProfilePage;
