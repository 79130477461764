import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ApplicationContext } from "@context/ApplicationContext";
import VoucherSearch from "@components/VoucherManager/VoucherSearch";
import Layout from "@components/Wrappers/Layout";

const SeasonalVoucherPage = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;

    return (
        <Layout>
            <Helmet>
                <title>{ `${brand} | Selling Movie Vouchers for Regional Cinemas` }</title>
                <meta name="keywords" content={ `${brand} | Selling Movie Vouchers for Regional Cinemas` } />
                <meta name="description" content={ `See ${brand}'s exclusive offers on movie vouchers
                for independent & regional cinemas.` } />
                <link rel="canonical" />
            </Helmet>
            <VoucherSearch type="seasonalVoucher" />
        </Layout>
    );
};

export default SeasonalVoucherPage;
