import React, { useContext } from "react";
import { VoucherManagerContext } from "@context/VoucherManagerContext";
import SearchSelect from "@components/SearchSelect";
import "./style.scss";

const VoucherSearchBar = () => {

    const {
        isVoucherBucketSearchLoading,
        setSelectedCinemaGroup,
        setSelectedTicketType,
        setSelectedTicketClass,
        cinemaGroups,
        ticketClasses,
        ticketTypes,
    } = useContext(VoucherManagerContext);

    return (
        <>

            <div className="voucher-search__bar">
                <div className="voucher-search__input-wrapper">
                    <SearchSelect
                        placeholder="Select a cinema"
                        aria-label="Select a cinema input"
                        onChange={(selectedOption, triggeredAction) => {
                            if (triggeredAction.action === "clear") {
                                setSelectedCinemaGroup(null);
                            }
                            else {
                                setSelectedCinemaGroup(selectedOption.value);
                            }
                        }}
                        disabled={
                            isVoucherBucketSearchLoading
                                ? "disabled"
                                : ""
                        }
                        options={cinemaGroups}
                        blurInputOnSelect={true}
                        isClearable={true}
                        noOptionsMessage={ () => "There are no cinema groups" }
                        maxMenuHeight={200}
                    />
                </div>
                <div className="voucher-search__input-wrapper">
                    <SearchSelect
                        placeholder="Select an experience"
                        aria-label="Select an experience input"
                        options={ticketClasses}
                        onChange={(selectedOption, triggeredAction) => {
                            if (triggeredAction.action === "clear") {
                                setSelectedTicketClass(null);
                            }
                            else {
                                setSelectedTicketClass(selectedOption.value);
                            }
                        }}
                        disabled={
                            isVoucherBucketSearchLoading
                                ? "disabled"
                                : ""
                        }
                        isClearable={true}
                        noOptionsMessage={ () => "There are no vouchers for this ticket experience"}
                        maxMenuHeight={200}
                    />
                </div>
                <div className="voucher-search__input-wrapper">
                    <SearchSelect
                        noOptionsMessage={ () => "There are no vouchers for this ticket type"}
                        placeholder="Select ticket type"
                        aria-label="Select ticket type input"
                        options={ticketTypes}
                        onChange={(selectedOption, triggeredAction) => {
                            if (triggeredAction.action === "clear") {
                                setSelectedTicketType(null);
                            }
                            else {
                                setSelectedTicketType(selectedOption.value);
                            }
                        }}
                        disabled={
                            isVoucherBucketSearchLoading
                                ? "disabled"
                                : ""
                        }
                        isClearable={true}

                        maxMenuHeight={200}
                    />
                </div>

            </div>
        </>
    );
};

export default VoucherSearchBar;
