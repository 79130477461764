import React, { useContext, useState } from "react";
import { ACCOUNT_TYPE_TEMPORARY } from "@config/config";
import { AuthContext } from "@context/AuthContext";
import UserProfileIcon from "@components/Icons/UserProfileIcon";
import Button from "@components/Ui/Button";
import Logo from "@components/Ui/Logo";

import "./style.scss";

const V3Tab = ({ label, activeTab, onClick }) => {
    return (
        <Button
            additionalClasses={`button tab__button ${activeTab === label ? "tab__button--active" : ""}`}
            onClick={() => onClick(label)}
            role="tab"
            aria-selected={activeTab === label}
            aria-controls={`panel-${label}`}
        >
            {label}
        </Button>
    );
};

const V3Tabs = ({ children }) => {
    const { user, isLoggedIn } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState(children[0].props.label);
    
    

    const onClickTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            <div className="drawer__menu">
                {(isLoggedIn === true && user?.account_type_id === ACCOUNT_TYPE_TEMPORARY) || isLoggedIn === false ? (
                    <div className="drawer__logo">
                        <Logo />
                    </div>
                ) : (
                    <div className="drawer__avatar">
                        <UserProfileIcon className="drawer__avatar-icon" />
                    </div>
                )}
            </div>
            <div className="v3-tab">
                <div
                    className="v3-tab__buttons"
                    role="tablist"
                    aria-label="Authentication options"
            >
                    {children.map((child) => {
                    const { label } = child.props;
                    return (
                        <V3Tab
                            key={label}
                            label={label}
                            activeTab={activeTab}
                            onClick={onClickTab}
                        />
                    );
                })}
                </div>
                <div className="v3-tab__wrapper">
                
                    {children.map((child) => {
                    if (child.props.label !== activeTab) return undefined;
                    return child.props.children;
                })}
                </div>
            </div>
        </>
    );
};

export default V3Tabs;
