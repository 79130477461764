import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { ApplicationContext } from "@context/ApplicationContext";
import SignupForm from "@components/Form/SignupForm";
import FormWrapper from "@components/Wrappers/FormWrapper";
import Layout from "@components/Wrappers/Layout";

const SignUpPage = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;

    return (
        <Layout>
            <Helmet>
                <title>{ `Account Signup | ${brand}` }</title>
                <meta name="keywords" content={ `Account Signup | ${brand}` } />
                <meta name="description" content={ `Sign up for a ${brand} account
                to be granted access to various membership offers & benefits, when buying
                movie tickets for regional screenings. Join now!` } />
                <link rel="canonical" href={`${window.location.protocol }//${ window.location.host}/signup`} />
            </Helmet>
            <FormWrapper pageTitle={`Sign up to ${brand}`}>
                <SignupForm />
            </FormWrapper>
            <div className="login__signup">
                <span className="link__text">Already have an account?</span>
                <Link className="link link__primary" to="/login">
                    Log In
                </Link>
            </div>
        </Layout>
    );
};

export default SignUpPage;
