import React from "react";

const MinusIcon = ({ className="" }) => {
    return (
        <svg className={className}  width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.07692 10.25C4.66271 10.25 4.32692 10.5858 4.32692 11C4.32692 11.4142 4.66271 11.75 5.07692 11.75H16.9231C17.3373 11.75 17.6731 11.4142 17.6731 11C17.6731 10.5858 17.3373 10.25 16.9231 10.25H5.07692ZM21.25 11C21.25 16.6609 16.6609 21.25 11 21.25C5.33908 21.25 0.75 16.6609 0.75 11C0.75 5.33908 5.33908 0.75 11 0.75C16.6609 0.75 21.25 5.33908 21.25 11Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    );
};

export default MinusIcon;
