export const groupSessionsByVenue = (sessions) => {
    if (!Array.isArray(sessions)) {
        return {};
    }
    const normalizeString = (str) => str.replace(/-/g, " ").trim().toLowerCase();

    return sessions.reduce((acc, session) => {
        if (!session?.venue_name) {
            return acc;
        }
        const venueKey = normalizeString(session.venue_name);
        return { 
            ...acc,
            [venueKey]: [...(acc[venueKey] || []), session]
        };
        
    }, {});
};


export const sortVenuesByDistance = (sessions) => {
    return Object.entries(sessions).sort(([, sessionsA], [, sessionsB]) => {
        const distanceA = sessionsA[0].venue_distance ?? 0;
        const distanceB = sessionsB[0].venue_distance ?? 0;
        return distanceA - distanceB;
    });
};
