import React, { useContext, useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import toSeoUrl from "@functions/toSeoUrl";
import { AuthContext } from "@context/AuthContext";
import { SearchContext } from "@context/SearchContext";
import { UserContext } from "@context/UserContext";
import LinkTabs from "@components/LinkTabs";
import Loader from "@components/Ui/Loader";
import ExperienceFinder from "@components/V3/ExperienceFinder";
import LeftArrowIcon from "@components/V3/Icons/LeftArrowIcon";
import RightArrowIcon from "@components/V3/Icons/RightArrowIcon";
import V3SessionButton from "@components/V3/V3SessionButton";
import VenueFinder from "@components/V3/VenueFinder";
import { renderDate } from "@utils/dateUtils";
import { checkScrollState } from "@utils/scrollUtils";
import { groupSessionsByVenue, sortVenuesByDistance } from "@utils/sessionUtils";
import "./style.scss";


const MovieSessionSelector = () => {
    const { id } = useParams();
    const location = useLocation();
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const { movie, isLoadingSessions, fetchSessions, sessions, setVenueName, venueName, selectedExperiences, setSelectedExperiences, getLocation, setGetLocation  } = useContext(SearchContext);
    const { fetchApplyPromoAvailability } = useContext(UserContext);
    const [selection, setSelection] = useState({ date: null, venue: null, experience: null, session: null });
    const [filtered, setFiltered] = useState({ venues: [], experienceData: [], sessions: [] });
    const [otherVenueSessions, setOtherVenueSessions] = useState([]);
    const [scrollState, setScrollState] = useState({
        isOverflowing: false,
        canScrollBack: false,
        canScrollForward: true,
    });
    const containerRef = useRef(null);
    
    const toTitleCase = (str) => str.replace(/\b\w/g, (char) => char.toUpperCase());

    const [groupedSessions, setGroupedSessions] = useState({});

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const venueNameFromUrl = searchParams.get("venue_name");

        if (venueNameFromUrl && !venueName) {
            const decodedVenueName = decodeURIComponent(venueNameFromUrl.replace(/-/g, " "));
            const formattedVenueName = toTitleCase(decodedVenueName);
            setVenueName(formattedVenueName);
            setSelection((prev) => ({ ...prev, venue: formattedVenueName }));
        }
    }, [location.search]);

    useEffect(() => {
        if (venueName && selection.date) {
            handleDateClick(selection.date, selectedExperiences);
        }
    }, [venueName, selection.date, selectedExperiences]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const venueNameFromUrl = searchParams.get("venue_name");
        if (venueName && venueName !== venueNameFromUrl) {
            const seoVenue = toSeoUrl(venueName);
            searchParams.set("venue_name", seoVenue);
            history.replace({ pathname: location.pathname, search: searchParams.toString() });
        }
        if (venueName && selection.date) {
            handleDateClick(selection.date);
        }
    }, [venueName, selection.date]);

    useEffect(() => {
        if (Array.isArray(selectedExperiences) && selectedExperiences.length > 0 && selection.date) {
            handleDateClick(selection.date);
        }
    }, [selectedExperiences]);

    useEffect(() => {
        if (!movie?.title) return;

        const handleFetchSessions = (lat = null, lng = null) => {
            setGetLocation(false);
            fetchSessions({ movieTitle: movie.title, lat, lng });
        };

        if (!navigator.geolocation) {
            handleFetchSessions();
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => handleFetchSessions(position.coords.latitude, position.coords.longitude),
                () => handleFetchSessions()
            );
        }
    }, [id, movie?.title]);

    useEffect(() => {
        if (sessions.length > 0) {
            const uniqueDates = Array.from(new Set(sessions.map((session) => format(new Date(session.session_date), "yyyy-MM-dd"))));

            if (uniqueDates.length > 0) {
                const firstDate = uniqueDates[0];
                setSelection((prev) => ({ ...prev, date: firstDate }));
                handleDateClick(firstDate, Array.isArray(selectedExperiences) ? selectedExperiences : []);
            }
        }
        updateScrollState();
    }, [sessions, venueName]);

    useEffect(() => {
        if (user && user?.id) {
            fetchApplyPromoAvailability();
        }

    }, [user?.id]);

    const updateScrollState = () => {
        setScrollState(checkScrollState(containerRef.current));
    };

    const handleDateClick = (date, experienceSelections = selectedExperiences) => {
        if (!date) {
            return;
        }
        const normalizeString = (str) => str?.replace(/-/g, " ").trim().toLowerCase();
        const sessionsForDate = sessions.filter(
            (session) => format(new Date(session.session_date), "yyyy-MM-dd") === date
        );

        const selectedVenueSessions = venueName && typeof venueName === "string"
            ? sessionsForDate.filter((session) => normalizeString(session.venue_name) === normalizeString(venueName))
            : sessionsForDate;

        const selectedExperienceSessions =
            (Array.isArray(experienceSelections) && experienceSelections.length > 0)
                ? selectedVenueSessions.filter((session) => experienceSelections.includes(session.experience_type))
                : selectedVenueSessions;


        setFiltered({
            venues: Array.from(new Set(sessionsForDate.map((session) => session.venue_name))),
            sessions: selectedExperienceSessions,
            experienceData: Array.from(new Set(sessionsForDate.map((session) => session.experience_type))).map((expType) => ({
                experience_type: expType,
            })),
        });
        setGroupedSessions(groupSessionsByVenue(selectedExperienceSessions));
        setOtherVenueSessions(sessionsForDate.filter((session) => !selectedVenueSessions.includes(session)));
        setSelection((prev) => ({ ...prev, date }));
    };

    const handleVenueClick = (selectedVenue) => {
        if (!selectedVenue) {
            setSelection((prev) => ({ ...prev, venue: null }));
            setVenueName("");
            history.replace({ pathname: location.pathname });

            if (selection.date) {
                handleDateClick(selection.date, selectedExperiences);
            }
        } else {
            setSelection((prev) => ({ ...prev, venue: selectedVenue }));
            setVenueName(selectedVenue);
            const seoVenue = toSeoUrl(selectedVenue);
            history.replace({ pathname: location.pathname, search: `?venue_name=${seoVenue}` });

            if (selection.date) {
                handleDateClick(selection.date, selectedExperiences);
            }
        }
    };


    const handleExperienceClick = (updatedSelections) => {
        setSelectedExperiences(updatedSelections || []);
        if (selection.date) {
            handleDateClick(selection.date, Array.isArray(updatedSelections) ? updatedSelections : []);
        }
    };


    const handleScrollForward = () => {
        const container = containerRef.current;
        if (container) {
            const maxScrollLeft = container.scrollWidth - container.clientWidth;
            const scrollAmount = Math.min(container.clientWidth, maxScrollLeft - container.scrollLeft);
            container.scrollBy({ left: scrollAmount, behavior: "smooth" });
            setScrollState(checkScrollState(container));
        }
    };

    const handleScrollBack = () => {
        const container = containerRef.current;
        if (container) {
            const scrollAmount = Math.min(container.clientWidth, container.scrollLeft);
            container.scrollBy({ left: -scrollAmount, behavior: "smooth" });
            setScrollState(checkScrollState(container));
        }
    };

    const uniqueDates = Array.from(
        new Set(sessions.map((session) => format(new Date(session.session_date), "yyyy-MM-dd"))),
    ).sort((a, b) => new Date(a) - new Date(b));
    const otherGroupedSessions = groupSessionsByVenue(otherVenueSessions.sort((a, b) => a.venue_distance - b.venue_distance));



    return (
        <div className="sessions-available__inner">
            <LinkTabs>
                <div label="Dates & Sessions">
                    <div className="sessions-available__wrapper">
                        {getLocation || isLoadingSessions ? (
                            <Loader isLoading={getLocation || isLoadingSessions} text="Fetching Sessions" />
                    ): sessions.length > 0 ? (
                        <>
                            <div className="date-scroller">
                                {scrollState.canScrollBack && (
                                    <button className="scroll-button scroll-left" onClick={handleScrollBack}>
                                        <LeftArrowIcon className="v3-carousel__arrow--prev" />
                                    </button>
                                )}
                                <div className="date-scroller__container">
                                    <div
                                        className={scrollState.canScrollBack ? "date-scroller__list active" : "date-scroller__list"}
                                        ref={containerRef}
                                    >
                                        {uniqueDates.map((date) => (
                                            <div
                                                key={date}
                                                className={date === selection.date ? "date-scroller__item active" : "date-scroller__item"}
                                                role="button"
                                                onClick={() => handleDateClick(date)}
                                            >{renderDate(date)}</div>
                                        ))}
                                    </div>
                                </div>
                                {scrollState.canScrollForward && scrollState.isOverflowing && (
                                    <button className="scroll-button scroll-right" onClick={handleScrollForward}>
                                        <RightArrowIcon className="v3-carousel__arrow--next" />
                                    </button>
                                )}
                            </div>
                            {filtered.venues.length > 0 && (
                                <div className="venues-finder">
                                    <VenueFinder
                                        selectedVenue={selection.venue}
                                        handleVenueClick={(venueName) => handleVenueClick(venueName)}
                                        venues={filtered.venues}
                                    />
                                </div>
                            )}
                            {filtered.experienceData.length > 0 && (
                                <div className="experience-finder">
                                    <ExperienceFinder
                                        experienceData={filtered.experienceData}
                                        handleExperienceClick={handleExperienceClick}
                                    />
                                </div>
                            )}
                            {Object.keys(groupedSessions).length > 0 ? (
                                <div className="sessions-available">
                                    {sortVenuesByDistance(groupedSessions).map(([venue, sessions]) => (
                                        <div key={venue} className="sessions-available__venue-group">
                                            <Link className="sessions-available__venue-title" to={`/venue/${sessions[0].venue_id}/${toSeoUrl(venue)}`}>{sessions[0].venue_name} - {sessions[0].venue_distance} km</Link>
                                            <div className="sessions-available__date-title">
                                                {selection.date ? (
                                                    <> {format(new Date(selection.date), "EEE d MMM")}</>
                                                ) : null}
                                            </div>
                                            <div className="sessions-available__list">
                                                {sessions.map((session) => (
                                                    <V3SessionButton
                                                        key={session.id}
                                                        session={session}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="sessions-available__message">
                                    <p className="sessions-available__message-title">Sorry, we couldn&apos;t find any results for your chosen cinemas</p>
                                    <p className="sessions-available__message-text">Please select from one of the nearby sessions below, or select a different date</p>
                                </div>
                            )}


                            {venueName && otherVenueSessions.length > 0 && (
                                <div className="other-venues-sessions">
                                    <h2 className="date-scroller__title">Nearby cinemas also playing {movie.title}</h2>
                                    {Object.entries(otherGroupedSessions).map(([venue, sessions]) => (
                                        <div key={venue} className="sessions-available__venue-group">
                                            <Link
                                                className="sessions-available__venue-title"
                                                to={`/venue/${sessions[0].venue_id}/${toSeoUrl(venue)}`}
                                            >
                                                {sessions[0].venue_name} - {sessions[0].venue_distance} km
                                            </Link>
                                            <div className="sessions-available__list">
                                                {sessions.map((session) => (
                                                    <V3SessionButton key={session.id} session={session} />
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                        </>
                    ): sessions.length === 0 ? (
                        <p className="paragraph">There are no sessions available</p>
                    ) : null}
                    </div>
                </div>
                <div label="Details">
                    <div className="sessions-available__wrapper">
                        <div className="sessions-available__details">
                            {movie?.review && (
                                <>
                                    <p className="sessions-available__details-list-title">Synopses</p>
                                    <p className="sessions-available__details-item" key={movie.review.id}>
                                        {movie.review.review_description}
                                    </p>
                                </>
                            )}
                            {movie?.directors?.length > 0 && (
                                <ul className="sessions-available__details-list">
                                    <li className="sessions-available__details-list-title">Director:</li>
                                    {movie.directors.map((director) => (
                                        <li className="sessions-available__details-list-item" key={director.id}>
                                            {director.director_name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {movie?.cast && movie?.cast?.length > 0 ? (
                                <ul className="sessions-available__details-list">
                                    <li className="sessions-available__details-list-title">Starring:</li>
                                    {movie.cast.map((actor, i) => (
                                        <li className="sessions-available__details-list-item" key={i}>
                                            {actor.actor_name}
                                        </li>
                                    ))}
                                </ul>
                        ): null}

                            {movie?.genres && movie?.genres?.length > 0 && (
                            <ul className="sessions-available__details-list">
                                <li className="sessions-available__details-list-title">Genre:</li>
                                {movie.genres.map((genre, i) => (
                                    <li className="sessions-available__details-list-item" key={i}>
                                        {genre.genre_name}
                                    </li>
                                ))}
                            </ul>
                        )}
                            {movie?.rating ? (
                                <p className="sessions-available__details-item">Rating: {movie.rating}</p>
                        ): null}

                            {movie?.release_year ? (
                                <p className="sessions-available__details-item">Release Date: {movie.release_year}</p>
                        ): null}

                            {movie?.running_time ? (
                                <p className="sessions-available__details-item">Running Time: {movie.running_time}</p>
                            ): null}

                        </div>
                    </div>
                </div>

            </LinkTabs>

        </div>
    );
};

export default MovieSessionSelector;
