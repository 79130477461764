import React from "react";

const SingleAvatarIcon = ({ className="" }) => {
    return (
        <svg className={className}  xmlns="http://www.w3.org/2000/svg" width="42" height="40" viewBox="0 0 42 40" fill="none">
            <path d="M21 18.1396C18.525 18.1396 16.4063 17.2515 14.6438 15.4753C12.8813 13.6992 12 11.564 12 9.06982C12 6.57559 12.8813 4.4404 14.6438 2.66424C16.4063 0.888079 18.525 0 21 0C23.475 0 25.5938 0.888079 27.3563 2.66424C29.1188 4.4404 30 6.57559 30 9.06982C30 11.564 29.1188 13.6992 27.3563 15.4753C25.5938 17.2515 23.475 18.1396 21 18.1396ZM0 40V35.0232C0 33.7752 0.360575 32.6075 1.08173 31.5203C1.80288 30.4331 2.77308 29.5891 3.99233 28.9883C6.82308 27.6201 9.65575 26.594 12.4904 25.9098C15.325 25.2257 18.1616 24.8837 21 24.8837C23.8385 24.8837 26.675 25.2257 29.5096 25.9098C32.3442 26.594 35.1769 27.6201 38.0077 28.9883C39.2269 29.5891 40.1971 30.4331 40.9183 31.5203C41.6394 32.6075 42 33.7752 42 35.0232V40H0ZM3 36.9767H39V35.0232C39 34.3527 38.7856 33.7229 38.3567 33.1337C37.9279 32.5445 37.3346 32.0465 36.5769 31.6395C34.1077 30.4341 31.5618 29.5106 28.9392 28.8692C26.3167 28.2277 23.6703 27.9069 21 27.9069C18.3298 27.9069 15.6834 28.2277 13.0608 28.8692C10.4382 29.5106 7.8923 30.4341 5.4231 31.6395C4.6654 32.0465 4.07212 32.5445 3.64327 33.1337C3.21442 33.7229 3 34.3527 3 35.0232V36.9767ZM21 15.1163C22.65 15.1163 24.0625 14.5243 25.2375 13.3402C26.4125 12.1561 27 10.7326 27 9.06982C27 7.40704 26.4125 5.98359 25.2375 4.79949C24.0625 3.61538 22.65 3.02333 21 3.02333C19.35 3.02333 17.9375 3.61538 16.7625 4.79949C15.5875 5.98359 15 7.40704 15 9.06982C15 10.7326 15.5875 12.1561 16.7625 13.3402C17.9375 14.5243 19.35 15.1163 21 15.1163Z" fill="currentColor"/>
        </svg>
    );
};

export default SingleAvatarIcon;
