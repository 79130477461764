import React, { useContext, useEffect } from "react";
import { SessionContext } from "@context/SessionContext";
import MovieSearch from "@components/MovieSearch";
import NowShowing from "@components/NowShowing";
import Layout from "@components/Wrappers/Layout";

const MovieRewardsSearchPage = () => {
    const { isLoadedSessions, sessions, setSessions, setIsLoadedSessions } = useContext(SessionContext);
    
    useEffect(() => {
        setSessions([]);
        setIsLoadedSessions(false);
    }, []);

    return (
        <Layout>
            <MovieSearch />
            {(isLoadedSessions === false && sessions.length === 0) && (
                <NowShowing title="Popular Movies" />
            )}
        </Layout>
    );
};

export default MovieRewardsSearchPage;
