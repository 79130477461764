import React, { useState } from "react";
import useTickets from "@context/TicketsContext";
import BookingDetails from "@components/Bookings/BookingDetails";
import InlineMessage from "@components/InlineMessage";
import Button from "@components/Ui/Button";
import Spinner from "@components/Ui/Spinner";
import VoucherList from "@components/VoucherManager/VoucherPurchases/VoucherList";
import VoucherLogo from "@components/VoucherManager/VoucherPurchases/VoucherLogo";

const Voucher = ({ voucher }) => {
    const {
        id,
        loading,
        error,
        errorMessage,
        processing,
        message,
        success,
        reSendTicketEmail
    } = useTickets();

    const [emailSent, setEmailSent ] = useState(false);

    const sendTicketEmail = (uuid) => {
        reSendTicketEmail(uuid);
        setEmailSent(!emailSent);
    };

    return (
        <>
            {voucher.ticket_type_id  && voucher.ticket_type_id !== null ? (
                <div className="voucher-history__item" key={id}>
                    <VoucherLogo item={voucher.items[0]} />
                    <div className="voucher-history__block-wrapper">
                        <div className="voucher-history__block">
                            <div className="voucher-history__text">{new Date(voucher.purchase_date).toLocaleDateString()}</div>
                            <div className="voucher-history__text">{voucher.items[0].cinema_group_name}</div>
                            <BookingDetails ticket={voucher} />
                        </div>
                    </div>
                    <div className="voucher-history__vouchers-container">
                        <Button
                            additionalClasses="button button__primary voucher-history__btn"
                            onClick={() => sendTicketEmail(voucher.uuid)}
                            disabled={
                        processing === true && voucher.ticket_id === id
                    }
                >
                            {loading === true && voucher.ticket_id === id ? (
                                <Spinner text="loading..." />
                    ) : (
                        <span>Resend Voucher Email</span>
                    )}
                        </Button>
                        {error && voucher.ticket_id === id && (
                        <InlineMessage
                            message={errorMessage}
                            messageType="error"
                    />
                )}
                        {emailSent === true && (
                            <InlineMessage message={message} messageType="success" />
                        )}
                        {success === true && loading === false && voucher.ticket_id === id && (
                        <InlineMessage
                            message={message}
                            messageType="success"
                    />
                )}
                        {voucher.vouchers.length > 0 ? (
                            <VoucherList
                                vouchers={voucher.vouchers}
                    />
                ) : null}
                    </div>
                </div>): null}
        </>
    );
};
export default Voucher;
