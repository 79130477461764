import React from "react";

const SignupIcon = ({ className="" }) => {
    return (

        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="50" height="40" viewBox="0 0 50 40" fill="none">
            <path d="M0 36.9495V32.3523C0 30.9237 0.346781 29.7762 1.04034 28.9098C1.73391 28.0433 2.61148 27.3326 3.67305 26.7777C6.01912 25.5675 8.32804 24.633 10.5998 23.9742C12.8716 23.3154 15.7785 22.986 19.3206 22.986C20.7006 22.986 21.9223 23.029 22.9857 23.1149C24.049 23.2009 24.9894 23.3298 25.8068 23.5016L23.3121 26.0258C22.8061 25.9327 22.2054 25.8683 21.5101 25.8325C20.8148 25.7966 20.0849 25.7787 19.3206 25.7787C16.0545 25.7787 13.2634 26.1207 10.9475 26.8045C8.63147 27.4884 6.64545 28.2957 4.9894 29.2266C4.15075 29.6742 3.56955 30.1432 3.24579 30.6337C2.92198 31.1242 2.76008 31.6971 2.76008 32.3523V34.1568H18.9491L21.7092 36.9495H0ZM34.1083 40L26.4968 32.2986L28.4501 30.3222L34.1083 36.0472L48.0467 21.9441L50 23.9205L34.1083 40ZM19.3206 16.7562C17.0435 16.7562 15.0942 15.9359 13.4727 14.2952C11.8511 12.6545 11.0403 10.6821 11.0403 8.37815C11.0403 6.07413 11.8511 4.10177 13.4727 2.46106C15.0942 0.820353 17.0435 0 19.3206 0C21.5976 0 23.547 0.820353 25.1686 2.46106C26.7901 4.10177 27.6009 6.07413 27.6009 8.37815C27.6009 10.6821 26.7901 12.6545 25.1686 14.2952C23.547 15.9359 21.5976 16.7562 19.3206 16.7562ZM19.3206 13.9635C20.8386 13.9635 22.1382 13.4166 23.2192 12.3228C24.3002 11.229 24.8407 9.91413 24.8407 8.37815C24.8407 6.84217 24.3002 5.52727 23.2192 4.43347C22.1382 3.33967 20.8386 2.79276 19.3206 2.79276C17.8025 2.79276 16.503 3.33967 15.422 4.43347C14.3409 5.52727 13.8004 6.84217 13.8004 8.37815C13.8004 9.91413 14.3409 11.229 15.422 12.3228C16.503 13.4166 17.8025 13.9635 19.3206 13.9635Z" fill="white"/>
        </svg>
    );
};

export default SignupIcon;
