import React from "react";
import TicketHistory from "@components/Tickets/TicketHistory";
import Layout from "@components/V3/Layout";

const V3HistoryPage = () => {
    return (
        <Layout>
            <TicketHistory />
        </Layout>
    );
};

export default V3HistoryPage;
