import React, { useContext } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import groupSessionsByVenueMovie from "@functions/groupSessionsByVenueMovie";
import toSeoUrl from "@functions/toSeoUrl";
import { ApplicationContext } from "@context/ApplicationContext";
import { SearchContext } from "@context/SearchContext";
import PlayIcon from "@components/Icons/PlayIcon";
import TrailerButton from "@components/TrailerButton";
import V3SessionButton from "@components/V3/V3SessionButton";
import { getSessionType } from "@utils/ticketUtils";
import "./style.scss";

const SessionsByMovie = ({ selectedDate }) => {
    const { cdnUrl } = useContext(ApplicationContext);
    const { sessionsByVenue } = useContext(SearchContext);

    const sessionsGrouped = sessionsByVenue
        .filter( (session) =>
            format(new Date(session.session_date), "yyyy-MM-dd") === selectedDate,
        )
        .reduce(groupSessionsByVenueMovie, []);
    
    const fallbackImage = `${cdnUrl}asset/img/placeholder.jpeg`;

    const onImageError = (e) => {
        e.target.src = fallbackImage;
    };

    
    return (
        <>
            {sessionsGrouped.length > 0 && (
                <div className="sessions-list__movies">
                    <div className="sessions-list__movies-list">
                        {sessionsGrouped.map((movie, index) => (
                            <div className="sessions-list__movies-item-wrapper" key={index} >
                                <div className="sessions-list__movies-item" >
                                    <Link
                                        className="sessions-list__movies-link"
                                        to={{
                                            pathname: `/movie/${movie.movie_id}/${toSeoUrl(
                                                movie.movie_title,
                                            )}`,
                                        }}
                                    >
                                        <img
                                            className="sessions-list__movies-image"
                                            src={movie.movie_image || fallbackImage}
                                            alt={movie.movie_title}
                                            onError={onImageError}
                                        />
                                    </Link>

                                    <div className="sessions-list__movies-content">
                                        <Link
                                            className="sessions-list__movies-title"
                                            to={{
                                                pathname: `/movie/${movie.movie_id}/${toSeoUrl(
                                                    movie.movie_title,
                                                )}`,
                                            }}
                                        >
                                            {movie.movie_title}
                                        </Link>

                                        <p className="sessions-list__movies-meta">
                                            {movie.movie_rating ? (
                                                <span>{movie.movie_rating} . {" "}</span>
                                            ): null}

                                            {movie.movie_run_time ? (
                                                <span>{movie.movie_run_time}mins · {" "}</span>
                                            ): null}

                                            {movie.movie_genre ? (
                                                <span>{movie.movie_genre}</span>
                                            ): null}

                                        </p>

                                        <p className="sessions-list__movies-description">{movie.movie_blurb}</p>

                                        {movie.trailer_url ? (
                                            <TrailerButton
                                                extraClass="sessions-list__movies-trailer"
                                                type="mp4"
                                                trailerLink={movie.trailer_url}
                                            >
                                                <PlayIcon className="sessions-list__movies-icon-svg" />
                                                <span className="sessions-list__movies-trailer-text">
                                                    Official Trailer
                                                </span>
                                            </TrailerButton>
                                        ) : null}
                                    </div>
                                </div>

                                <h3 className="sessions-list__sessions-available-date">
                                    {movie.sessions?.length > 0 && movie.sessions[0].d
                                        ? format(
                                            new Date(movie.sessions[0].d),
                                            "EEE d MMM",
                                        )
                                        : null}
                                </h3>

                                <div className="sessions-list__sessions-available-list">
                                    {movie.sessions.map((session) => {
                                        const experience_type = getSessionType(session);

                                        return (
                                            <V3SessionButton
                                                key={session.id}
                                                session={{ ...session, experience_type }}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default SessionsByMovie;
