const flattenErrorMessages = (errorMessages) => {
    return typeof errorMessages === "object"
        ? Object
            .keys(errorMessages)
            .reduce((acc, cur) => ([
                ...acc,
                ...Object.keys(errorMessages[cur]).map((key) => errorMessages[cur][key]),
                ]),
            [])
        : [];
};

export default flattenErrorMessages;
