import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { ACCOUNT_TYPE_NORMAL, ACCOUNT_TYPE_TEMPORARY, API_BASE_URL, PRODUCT_TYPE_VOUCHER_MANAGER, SITE_V3 } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";


export const BookSessionContext = createContext({});

const BookSessionContextProvider = (props) => {
    const { user, isLoggedIn, setUserAuth, siteVersion, openDrawer, setIsReadyToCheckout } = useContext(AuthContext);
    const { whiteLabel, productTypeId } = useContext(ApplicationContext);
    const [ticketQuantity, setTicketQuantity] = useState(0);
    const [bookingSessionId, setBookingSessionId] = useState(null);
    const [uuid, setUuid] = useState(null);
    const [checkout, setCheckout] = useState(false);
    const [error, setError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isInitialising, setIsInitialising] = useState(false);
    const [promoCode, setPromoCode] = useState("");
    const [promoCodeSuccess, setPromoCodeSuccess] = useState(null);
    const [promo, setPromo] = useState(null);
    const [referralCode, setReferralCode] = useState("");
    const [referralSuccess, setReferralSuccess] = useState(null);
    const [ewayAccessCode, setEwayAccessCode] = useState(null);
    const [isTempAccount, setIsTempAccount] = useState(false);
    const [ewayFormActionUrl, setEwayFormActionUrl] = useState(null);
    const [promoCodeMessage, setPromoCodeMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [createFastSignupSubmitting, setCreateFastSignupSubmitting] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const { children } = props;

    const createFastSignupAccount = (data) => {
        setErrorMessages({});
        setCreateFastSignupSubmitting(true);
        axios
            .post(`${API_BASE_URL}/user/create-temp-account`, data)
            .then((response) => {
                setCreateFastSignupSubmitting(false);
                setIsReadyToCheckout(true);
                return setUserAuth((prevState) => ({
                    ...prevState,
                    user: response.data.data.user,
                    token: response.data.data.token,
                    isLoggedIn: true,
                }));
            })
            .catch((error) => {
                setCreateFastSignupSubmitting(false);
                if (error.response.status === 500) {
                    setErrorMessages("Unknown error occurred");
                }
                if (error.response.status === 400) {
                    setErrorMessages(error.response.data.errors);
                } else {
                    setErrorMessages({
                        unknown_field: { unknown_error: "Unknown error occurred" },
                    });
                }
            });
            
    };


    const checkoutBookingSession = (totalTicketQuantity, bookingType) => {
        if (!isLoggedIn || user.account_type_id === ACCOUNT_TYPE_TEMPORARY) {
            setIsTempAccount(true);
        }

        if (totalTicketQuantity > 0 && !isSubmitting) {
            setIsSubmitting(true);
            setCheckout(false);
            setError("");
            setSuccess(false);
            setPromoCodeMessage("");

            let data = {};
            let requestUrl = `${API_BASE_URL}/ticket`;
            

            data = {
                items: selectedItems,
                user_session_id: null,
                promo_code: promoCode,
                referral_code: referralCode,
                type: bookingType,
            };

            if (bookingSessionId) {
                data.user_session_id = bookingSessionId;
                requestUrl = `${API_BASE_URL}/ticket/${bookingSessionId}`;
            }
            axios
                .post(requestUrl, data)
                .then(function(response) {
                    if (response.status === 201) {
                        setBookingSessionId(response.data.data.user_session_id);
                        setUuid(response.data.data.uuid);
                        setPromo(response.data.data.promo);
                        setCheckout(false);
                        setSuccess(true);

                        if (promoCode && response.data.data.promo.applied) {
                            setPromoCodeSuccess(true);
                            setPromoCodeMessage("Tickets added successfully");
                        } else {
                            setPromoCodeSuccess(null);
                            setError(response.data.data.message);
                            setPromoCodeMessage(response.data.message);
                        }

                        if (!whiteLabel.enable_iframe_payment && response.data.data.promo.applied === false) {
                            // There are tickets to be paid for and eway checkout is disabled
                            if (siteVersion && siteVersion === SITE_V3) {
                                openDrawer("creditCardCheckoutDrawer");
                            }
                            
                        }

                        if (response.data.data.referral_applied) {
                            setReferralSuccess(true);
                        } else {
                            setReferralSuccess(null);
                            setError(response.data.data.message);
                        }

                        if (response.data.data.payment) {
                            if (response?.data?.data?.total_price_cents > 0 && response?.data?.data?.payment.action_url && whiteLabel?.enable_iframe_payment && (user?.account_type_id === ACCOUNT_TYPE_NORMAL || response?.data?.data?.userType === ACCOUNT_TYPE_NORMAL)) {
                                if (response.data.data.payment.action_url.includes("Email=temp-user")) {
                                    setError("Your session has been timed out, for security reasons please refresh the page");
                                    setUserAuth(() => ({
                                        user: null,
                                        token: null,
                                        isLoggedIn: false,
                                    }));
                                }
                                else {
                                    setCheckout(false);
                                    window.open(response.data.data.payment.action_url, "_self");
                                }
                            } else {
                                setCheckout(true);
                                setEwayAccessCode(response.data.data.payment.access_code);
                                setEwayFormActionUrl(response.data.data.payment.action_url);
                                if (siteVersion && siteVersion === SITE_V3) {
                                    openDrawer("complimentaryCheckoutDrawer");
                                }
                                
                            }
                        }

                        setIsSubmitting(false);
                    } else {
                        const message = response.data.errors
                            ? response.data.errors[0].desc
                            : "Fail to add ticket(s), Please try again";
                        if (message) {
                            setError(message);
                        }
                        setPromoCodeMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    if (isLoggedIn && error?.response?.status === 401) {
                        window.location = "/";
                    }

                    setIsSubmitting(false);
                    const message = error?.response?.data?.errors
                        ? error?.response?.data?.errors[0]?.desc
                        : error?.response?.data?.message;
                    if (message) {
                        setError(message);
                    }
                    setSuccess(false);
                    if (error?.response?.data?.errors?.type === "promo_code") {
                        setPromoCodeSuccess(false);
                        setError(error?.response?.data?.message);
                    }
                    if (error?.response?.data?.errors?.type === "referral_code") {
                        setReferralSuccess(false);
                        setError(error?.response?.data?.message);
                    }
                });
        } else {
            setPromoCodeMessage("");
            setError("");
        }
    };

    
    const fetchReInitialiseEway = (uuid) => {
        setEwayFormActionUrl(null);
        axios
            .get(`${API_BASE_URL}/ticket/re-initialise-eway/${uuid}`)
            .then(function(response) {
                setIsInitialising(false);
                if (response.data.data.payment) {
                    if (whiteLabel?.enable_iframe_payment && productTypeId === PRODUCT_TYPE_VOUCHER_MANAGER && response.data.data.userType === ACCOUNT_TYPE_NORMAL) {
                        window.open(response.data.data.payment.action_url, "_self");
                    } else {
                        setEwayAccessCode(response.data.data.payment.access_code);
                        setEwayFormActionUrl(response.data.data.payment.action_url);
                    }
                }
            })
            .catch((error) => {
                if (error?.response?.status === 401 && error?.response?.data?.data?.public_group === 1) {
                    setError("Your session has been timed out, for security reasons please refresh the page");
                    setUserAuth(() => ({
                        user: null,
                        token: null,
                        isLoggedIn: false,
                    }));
                }
                else if (error?.response?.status === 401 && error?.response?.data?.data?.public_group === 0)
                {
                    window.location = "/";
                }
                setIsInitialising(false);
                // setErrors(response?.data?.errors);
            });
    };

    return (
        <BookSessionContext.Provider
            value={{
                bookingSessionId,
                uuid,
                setBookingSessionId,
                isSubmitting,
                promo,
                setPromo,
                checkout,
                setCheckout,
                ticketQuantity,
                setTicketQuantity,
                selectedItems,
                setSelectedItems,
                promoCode,
                setPromoCode,
                promoCodeSuccess,
                setPromoCodeSuccess,
                promoCodeMessage,
                success,
                error,
                setError,
                checkoutBookingSession,
                setPromoCodeMessage,
                ewayAccessCode,
                ewayFormActionUrl,
                fetchReInitialiseEway,
                isTempAccount,
                setIsTempAccount,
                referralCode,
                setReferralCode,
                referralSuccess,
                setReferralSuccess,
                createFastSignupAccount,
                createFastSignupSubmitting, 
                setCreateFastSignupSubmitting,
                errorMessages,
            }}
        >
            {children}
        </BookSessionContext.Provider>
    );
};

export default BookSessionContextProvider;
