import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "@config/config";
import { AuthContext } from "@context/AuthContext";

export const ProfileContext = createContext({});

const ProfileContextProvider = (props) => {
    const { siteVersion, closeDrawer, updateUser, loginByToken, isLoggedIn, setUserAuth } = useContext(AuthContext);
    const [isSignupSubmitting, setIsSignupSubmitting] = useState(false);
    const [done, setDone] = useState(false);
    const [email, setEmail] = useState("");
    const [emailList, setEmailList] = useState([]);
    const [errorMessages, setErrorMessages] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [success, setSuccess] = useState(null);
    const [verifySuccess, setVerifySuccess] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const { children } = props;

    const signup = (data) => {
        axios
            .post(`${API_BASE_URL}/user/signup`, data)
            .then(() => {
                setIsSignupSubmitting(false);
                setSuccess(true);
            })
            .catch((error) => {
                setIsSignupSubmitting(false);
                if (error?.response?.status === 400) {
                    setErrorMessages(error?.response?.data?.errors);
                } else {
                    setErrorMessages({ unknown_field: { unknown_error: "Unknown error occurred" } });
                }
            });
    };

    const updateUserProfile = (data) => {
        axios
            .post(`${API_BASE_URL}/user/profile`, data)
            .then(function (response) {
                setProcessing(false);
                setSuccessMessage(response.data.message);
                updateUser(response.data.user);
            })
            .catch((error) => {
                if (isLoggedIn && error?.response?.status === 401) {
                    window.location = "/";
                }
                setErrorMessages(error?.response?.data?.errors);
                setProcessing(false);
            });
    };

    const addEmail = (data) => {
        axios
            .post(`${API_BASE_URL}/user/email/add`, data)
            .then(function (response) {
                setProcessing(false);
                setSuccessMessage(response.data.message);
                setEmail("");
            })
            .catch((error) => {
                if (isLoggedIn && error?.response?.status === 401) {
                    window.location = "/";
                }
                setProcessing(false);
                if (error?.response?.status === 400) {
                    setErrorMessages(error?.response?.data?.errors);
                } else {
                    setErrorMessages({ unknown_field: { unknown_error: "Unknown error occurred" } });
                }
            });
    };

    const updateEmailList = () => {
        axios
            .get(`${API_BASE_URL}/user/email/list`)
            .then(function (response) {
                setEmailList(response.data.emails);
                if (response.data.success === false) {
                    setErrorMessages(response.data.message);
                }
            })
            .catch(({ response }) => {
                if (isLoggedIn && response?.status === 401) {
                    window.location = "/";
                }
                setErrorMessages(response?.data?.errors);
            });
    };

    const verifyEmail = (token) => {
        if (!isLoading) {
            setIsLoading(true);
            axios
                .get(`${API_BASE_URL}/email/verify/${token}`)
                .then((response) => {
                    setVerifySuccess(true);
                    setIsLoading(false);
                })
                .catch(() => {
                    setSuccess(false);
                    setIsLoading(false);
                });
        }
    };

    const updatePassword = (data) => {
        axios
            .post(`${API_BASE_URL}/user/password-reset`, data)
            .then(() => {
                setProcessing(false);
                setSuccess(true);
            })
            .catch((error) => {
                setProcessing(false);
                setSuccess(false);
                if (isLoggedIn && error?.response?.status === 401) {
                    window.location = "/";
                } else if (error?.response?.status === 400) {
                    setErrorMessages(error?.response?.data?.errors);
                } else {
                    setErrorMessages({ unknown_field: { unknown_error: "Unknown error occurred" } });
                }
            });
    };

    const requestPasswordReset = (email) => {
        setProcessing(true);
        setDone(false);
        axios
            .post(`${API_BASE_URL}/user/email-password-reset`, { email })
            .then(function (response) {
                setProcessing(false);
                setDone(true);
            })
            .catch((error) => {
                setProcessing(false);
                const errorData = error?.response?.data;
                
                if (error?.response?.status) {
                    setError(errorData.errors ? errorData.errors : errorData.message);
                } else {
                    setErrorMessages({ unknown_field: { unknown_error: "Unknown error occurred" } });
                }
            });
    };

    const resetForgottenPassword = (passwordToken, data) => {
        axios
            .post(
                `${API_BASE_URL}/user/forgotten-password-reset/${passwordToken}`,
                data
            )
            .then((response) => {
                setProcessing(false);
                setSuccess(true);
                loginByToken(response.data.data.token);
            })
            .catch((error) => {
                setProcessing(false);
                if (error?.response?.status === 400) {
                    setErrorMessages(error?.response?.data?.errors);
                } else {
                    setErrorMessages({ unknown_field: { unknown_error: "Unknown error occurred" } });
                }
            });
    };

    return (
        <ProfileContext.Provider
            value={{
                addEmail,
                done,
                email,
                emailList,
                error,
                errorMessages,
                isLoading,
                isSignupSubmitting,
                processing,
                requestPasswordReset,
                resetForgottenPassword,
                setDone,
                setEmail,
                setError,
                setErrorMessages,
                setProcessing,
                setSuccess,
                setSuccessMessage,
                setIsSignupSubmitting,
                signup,
                success,
                successMessage,
                updateEmailList,
                updatePassword,
                updateUserProfile,
                verifyEmail,
                setVerifySuccess,
                verifySuccess
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
};

export default ProfileContextProvider;
