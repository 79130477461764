import React, { useContext, useState, useEffect } from "react";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { ApplicationContext } from "@context/ApplicationContext";
import { SessionContext } from "@context/SessionContext";
import Select from "@components/Select";
import SessionButton from "@components/SessionButton";
import Button from "@components/Ui/Button";
import "./style.scss";

const uniqueItems = (item) => [...new Set(item)];
const dateToday = () => {
    const today = new Date();
    return [
        today.getFullYear(),
        (today.getUTCMonth() + 1).toString().padStart(2, "0"),
        today.getUTCDate().toString().padStart(2, "0"),
    ].join("-");
};

const MovieSessions = ({ sessions, sessionLimit }) => {
    const { whiteLabel } = useContext(ApplicationContext);
    const { sessionDate } = useContext(SessionContext);

    const today = dateToday();
    const allSessions = sessions.sort(function (a, b) {
        return a.d > b.d ? 1 : a.d < b.d ? -1 : 0;
    });
    const sessionDays = uniqueItems(allSessions.map((s) => s.date));

    const options = sessionDays.map((sessionDay) => ({
        value: sessionDay,
        label: sessionDay === today
            ? "today"
            : format(parse(sessionDay, "yyyy-MM-dd", new Date()), "E dd MMM"),
    }));

    const defaultOption = options[0]?.value ?? null;
    const defaultSelectedDay = sessionDate || defaultOption;

    const [selectedDay, setSelectedDay] = useState(defaultSelectedDay);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        if (sessionDate == null || sessionDate === "") {
            setSelectedDay(defaultOption);
        }
    }, [sessionDate]);

    const handleClick = (selectedDay) => {
        setSelectedDay(selectedDay);
    };

    const sessionsForSelectedDay = allSessions.filter(
        (s) => s.date === selectedDay
    );

    const showMoreButton = sessionsForSelectedDay.length > sessionLimit;

    const sessionsDisplayed = showMore === true
        ? sessionsForSelectedDay
        : sessionsForSelectedDay.filter((s, i) => i < sessionLimit-1);

    return (
        <div className="session__container">
            <div className="session__item">
                {sessionDate == null
                    ? (
                        <Select
                            defaultValue={options[0]}
                            options={options}
                            onChange={(option) => handleClick(option.value)}
                            isMulti={false}
                            isSearchable={false}
                        />
                    ) : (
                        <div className="empty-select"></div>
                    )}
                <div className={`${whiteLabel?.show_ticket_class === true ? "session__classes" : ""}`}>
                    <div className="session__time-list">
                        {sessionsDisplayed.map((session, index) => (

                            <SessionButton
                                key={index}
                                session={session}
                            />
                        ))}
                        {showMoreButton && (
                            <Button
                                additionalClasses="button button__primary"
                                onClick={() => setShowMore(!showMore)}
                            >
                                {showMore ? "Show Less" : "Show More"}
                            </Button>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default MovieSessions;
