import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ACCOUNT_TYPE_NORMAL, ACCOUNT_TYPE_TEMPORARY } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { BookSessionContext } from "@context/BookSessionContext";
import { CheckoutCreditCardContext } from "@context/CheckoutCreditCardContext";
import { SessionContext } from "@context/SessionContext";
import { UserContext } from "@context/UserContext";
import AlertMessage from "@components/AlertMessage";
import BookedTicketWarning from "@components/Bookings/BookedTicketWarning";
import BookingTotal from "@components/Bookings/BookingTotal";
import CloseIcon from "@components/Icons/CloseIcon";
import PromoCodeInput from "@components/PromoCodeInput";
import PurchasableItems from "@components/PurchasableItems";
import RedemptionDetails from "@components/RedemptionDetails";
import ReferralCodeInput from "@components/ReferralCodeInput";
import SessionDetails from "@components/SessionDetails";
import Button from "@components/Ui/Button";
import Loader from "@components/Ui/Loader";
import PaymentStatus from "@components/V3/PaymentStatus";
import V2V3FastAuth from "@components/V3/V2V3FastAuth";
import V3CheckoutCreditCardDrawer from "@components/V3/V3CheckoutCreditCardDrawer";
import V3ComplimentaryCheckoutDrawer from "@components/V3/V3ComplimentaryCheckoutDrawer";
import { isMessageRequired } from "@utils/ticketUtils";
import "./style.scss";

const V2V3BookSession = () => {
    const { id } = useParams();
    const history = useHistory();

    const {
        isTempAccount,
        bookingSessionId,
        uuid,
        setBookingSessionId,
        promo,
        setPromo,
        checkout,
        setCheckout,
        setTicketQuantity,
        selectedItems,
        setSelectedItems,
        promoCode,
        setPromoCode,
        promoCodeSuccess,
        setPromoCodeSuccess,
        promoCodeMessage,
        error,
        setError,
        checkoutBookingSession,
        setPromoCodeMessage,
        ewayAccessCode,
        ewayFormActionUrl,
        isSubmitting,
        success,
        referralCode,
        setReferralCode,
        referralSuccess,
        setReferralSuccess,
    } = useContext(BookSessionContext);

    const {
        session,
        setSession,
        fetchPurchasable,
        isLoadingSession,
        purchasable,
    } = useContext(SessionContext);

    const {
        bookedTicketForSession,
        fetchBookedTicketForSession,
        setBookedTicketForSession,
        fetchApplyPromoAvailability,
        promoDetails,
        isRegularPromo,
        isAdvancedPromo,
        setIsAdvancedPromo,
        setIsRegularPromo,
        reUseLimitReached,
        setReuseLimitReached,
    } = useContext(UserContext);

    const { promoUniqueCode, redemptionCount, redemptionLimit, promoTicketClasses, promoCategory, cap_purchases } = promoDetails;
    const { cardState } = useContext(CheckoutCreditCardContext);
    const { token, user, isLoggedIn, isReadytoCheckout, drawerState, openDrawer, closeDrawer } = useContext(AuthContext);
    const [showCheckout, setShowCheckout] = useState(false);
    const { whiteLabel } = useContext(ApplicationContext);
    const redemptionCountLeft = redemptionLimit - redemptionCount;


    useEffect(() => {
        setIsRegularPromo(false);
        setIsAdvancedPromo(false);
        setPromoCodeSuccess(null);
        setReferralSuccess(null);
        setPromo(null);
        setPromoCodeMessage("");
        setPromoCode("");
    }, []);

    useEffect(() => {
        if (user && user?.id) {
            fetchApplyPromoAvailability();
            if (cap_purchases === 1 && redemptionCountLeft === 0) {
                setReuseLimitReached(true);
            }
        }
    }, [user?.id]);


    useEffect(() => {
        handleApplyPromo();
    }, [session, promoDetails, promoUniqueCode]);


    useEffect(() => {
        if (isLoggedIn === true && isReadytoCheckout && totalTicketQuantity > 0) {
            handleFastLogIn();
        }
    }, [isLoggedIn, isReadytoCheckout]);

    useEffect(() => {
        fetchPurchasable(id);
        if (token) {
            fetchBookedTicketForSession(id);
        }
        return () => {
            setBookingSessionId(null);
            setSession(null);
            setError(null);
            setSelectedItems([]);
            setBookedTicketForSession([]);
        };
    }, [id]);

    useEffect(() => {

        setError(null);
        // setPromo(null);
        setPromoCodeMessage("");
        redemptionLimitReached(totalTicketQuantity);
    }, [selectedItems]);


    const redemptionLimitReached = (quantity) => {
        if (cap_purchases === 1 && quantity > redemptionCountLeft || cap_purchases === 1 && redemptionCountLeft === 0) {
            setReuseLimitReached(true);
        } else {
            setReuseLimitReached(false);
        }
    };

    const checkoutText = promoCategory ? "Continue" : "Checkout";
    const standardPromoCheckout = checkout ? "Update Tickets" : "Checkout";
    const addTicketButtonText = checkout ? "Update Tickets" : checkoutText;
    const promoCheckoutMessage = `Click '${checkoutText}' to proceed with purchase`;
    const totalTicketQuantity = selectedItems.reduce((acc, cur) => {
        return cur.type === "ticket" ? acc + cur.quantity : acc;
    }, 0);



    const handleApplyPromo = () => {
        if (promoTicketClasses?.includes(Number(session?.ticket_class_id))) {
            setIsAdvancedPromo(true);
            setIsRegularPromo(false);
            setPromoCode(promoUniqueCode);
            setPromoCodeMessage("");
            setReferralCode(referralCode);
        } else {
            setIsAdvancedPromo(false);
        }
    };

    const handleFastLogIn = () => {
        fetchApplyPromoAvailability();
        handleApplyPromo();
        handleCheckout();
    };


    const handleCheckout = () => {
        if (totalTicketQuantity === 0) {
            setPromoCodeMessage("");
            setError("Please enter the quantity of tickets needed");
        } else {
            checkoutBookingSession(totalTicketQuantity, "session");
        }
    };

    useEffect(() => {
        // Calculate the total value of selected items
        const totalValue = selectedItems.reduce((total, item) => {
            const itemValue = item.price_cents * item.quantity;
            return total + itemValue;
        }, 0);

        setShowCheckout(totalValue - (promo?.discount || 0) > 0);


    }, [promo, selectedItems]);

    const handleGuestCheckout = () => {
        if (totalTicketQuantity === 0) {
            setPromoCodeMessage("");
            setError("Please enter the quantity of tickets needed");
        } else {
            checkoutBookingSession(totalTicketQuantity, "session");
            openDrawer("fastAuthDrawer");
        }
    };

    const purchasableItemKey = (item) => {
        return item.id && item.ticket_type_id
            ? `ticket-${item.id}-${item.ticket_type_id}`
            : `candy-bar-${item.bucket_id}`;
    };

    const handleChangeItemQuantity = (selection, idKey, quantity, isSession) => {
        setCheckout(false);
        const type = isSession ? "ticket" : "candy-bar";
        const newSelection = [...selection];
        const index = newSelection.findIndex((item) => item.key === idKey);
        setError("");

        if (index === -1) {
            const choice = purchasable.find(
                (item) => idKey === purchasableItemKey(item),
            );
            if (isSession) {
                newSelection.push({
                    key: idKey,
                    type,
                    promo: false,
                    session_id: choice.id,
                    session_inventory_id: choice.session_inventory_id,
                    price_cents: choice.ticket_price_cents,
                    rrp_pricing_cents: choice.rrp_pricing_cents,
                    saving: choice.saving,
                    quantity: 1,
                });
                setTicketQuantity(1);
            } else {
                newSelection.push({
                    key: idKey,
                    type,
                    promo: false,
                    bucket_id: choice.bucket_id,
                    candy_bar: choice.candy_bar,
                    candy_bar_description: choice.candy_bar_description,
                    price_cents: choice.candy_bar_price,
                    candy_bar_rrpPricing: choice.candy_bar_rrpPricing,
                    rrp_pricing_cents: choice.rrp_pricing_cents,
                    saving: choice.saving,
                    quantity: 1,
                });
            }
        } else {
            if (newSelection[index] && quantity > 10 && quantity >= newSelection[index].quantity && !isSession) {
                setError("The maximum quantity of candy bars you can purchase is 10");
                return false;
            }
            newSelection[index].quantity = quantity;

            if (isSession) {
                setTicketQuantity(quantity);
            }
        }

        setSelectedItems(newSelection);
    };

    if (isLoadingSession || !session) {
        return <Loader isLoading={isLoadingSession} />;
    }

    const movie = {
        movie_id: session?.movie_id,
        movie_image: session?.movie_image,
        movie_title: session?.movie_title,
    };

    const PosterImage = () => (
        <img
            loading="lazy"
            alt={`${movie.movie_title ? movie.movie_title : movie.title} poster image`}
            src={movie.movie_image}
            className="v2v3-booking__img"
            title={movie.movie_title ? movie.movie_title : movie.title}
        />
    );

    const paidTicketQuantity =
        totalTicketQuantity >= promo?.num_free_tickets
            ? totalTicketQuantity - promo?.num_free_tickets
            : totalTicketQuantity;


    const hasCandyBarItems = selectedItems.some(item => item.type === "candy-bar" && item.quantity > 0);

    const handleClose = () => {
        if (session?.movie_id && session?.movie_title) {
            const movieSlug = session.movie_title
                .toLowerCase()
                .replace(/\s+/g, "-")
                .replace(/[^a-z0-9-]/g, "");

            history.push(`/movie/${session.movie_id}/${movieSlug}`);
        } else {
            history.push("/");
        }
    };

    return (
        <>
            <div className="v3-booking__btn">
                <button className="v3-booking__close-btn" onClick={handleClose}><CloseIcon className="drawer__svg" /></button>
            </div>
            <div className="v3-booking__container">
                <PaymentStatus />
                <div className="v3-booking__info">
                    <div className="v3-booking__info-wrapper">
                        <div className="v3-booking__image">
                            <PosterImage />
                        </div>
                        <SessionDetails session={session} />
                    </div>


                    {isMessageRequired(session) && (
                        <AlertMessage
                            message={isMessageRequired(session)}
                            type="error"
                        />
                    )}
                    {reUseLimitReached === true && (
                        <AlertMessage
                            message={`Only ${redemptionLimit} voucher${redemptionLimit === 1 ? "" : "s"} can be purchased each calendar month.`}
                            type="error"
                        />
                    )}
                    {(redemptionCount <= redemptionLimit) ? (
                        <>
                            {isLoggedIn && user.account_type_id === ACCOUNT_TYPE_NORMAL && isAdvancedPromo ? (
                                <RedemptionDetails
                                    redemptionCount={redemptionCount}
                                    redemptionLimit={redemptionLimit}
                                    brandName={whiteLabel?.brand_name}
                                />
                            ): null}
                        </>
                    ) : null}
                    {bookedTicketForSession.length > 0 && <BookedTicketWarning />}
                    {session.session_inventory_id == null ? (
                        <div>
                            Sorry there is no inventory available to purchase for this
                            session.
                        </div>
                    ) : (
                        <div className="v3-booking__inner">
                            <div className="v3-booking__inputs">

                                <PurchasableItems
                                    items={purchasable}
                                    selectedItems={selectedItems}
                                    setQuantity={handleChangeItemQuantity}
                                    purchasableItemKey={purchasableItemKey}
                                    promo={promo}
                                />

                                <BookingTotal items={selectedItems} promo={promo} />
                                {whiteLabel?.referral_code_flag ? (
                                    <ReferralCodeInput
                                        referralCode={referralCode}
                                        setReferralCode={setReferralCode}
                                        success={referralSuccess}
                                    />
                                ): null}
                                <div className={`v3-booking__form form__group-wrapper ${isRegularPromo ? "form__group-wrapper--row" : ""}`}>
                                    {isRegularPromo || !isAdvancedPromo ? (
                                        <PromoCodeInput
                                            promoCode={promoCode}
                                            setPromoCode={setPromoCode}
                                            success={promoCodeSuccess}
                                            promoUniqueCode={promoUniqueCode}
                                        />
                                    ): null }

                                    {isLoggedIn && user.account_type_id === ACCOUNT_TYPE_NORMAL ? (
                                        <Button
                                            additionalClasses="button button__secondary button--right"
                                            onClick={handleCheckout}
                                            disabled={
                                                    cardState.isSubmitting || reUseLimitReached === true
                                                        ? "disabled"
                                                        : ""
                                                }
                                            >
                                            {addTicketButtonText}
                                        </Button>
                                        )

                                        : (
                                            <Button
                                                additionalClasses="button button__secondary button--right"
                                                onClick={handleGuestCheckout}
                                                disabled={
                                                    cardState.isSubmitting || reUseLimitReached === true
                                                        ? "disabled"
                                                        : ""
                                                }
                                            >{standardPromoCheckout}
                                            </Button>
                                    )}
                                </div>



                                {!isLoggedIn || user.account_type_id === ACCOUNT_TYPE_TEMPORARY ? (
                                    <V2V3FastAuth
                                        isDrawerOpen={drawerState.fastAuthDrawer}
                                        setDrawerOpen={() => closeDrawer("fastAuthDrawer")}
                                    />
                                ): null}

                                {promoUniqueCode && isAdvancedPromo && !success && totalTicketQuantity > 0 && (
                                    <div className="form__group-wrapper">
                                        <div className="booking__promo-code-msg booking__promo-code-msg__success">
                                            {promoCheckoutMessage}
                                        </div>
                                    </div>
                                )}

                                {success && <div className="v3-booking__promo-code-msg v3-booking__promo-code-msg--success">
                                    {promoCodeMessage}
                                </div>}
                                <div className="form__group-wrapper">
                                    {error && <AlertMessage message={error} type="error" />}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="v3-booking__checkout">
                    {isSubmitting && (
                        <Loader isLoading={isSubmitting} />
                    )}

                    {checkout ? (
                        <>
                            {promo?.free_ticket_token &&
                            paidTicketQuantity === 0 &&
                            hasCandyBarItems === false &&
                            showCheckout === false &&
                            isLoggedIn &&
                            user.account_type_id === ACCOUNT_TYPE_NORMAL ? (

                                <V3ComplimentaryCheckoutDrawer
                                    uuid={uuid}
                                    bookingSessionId={bookingSessionId}
                                    freeTicketToken={promo.free_ticket_token}
                                />

                            ) : (
                                <>
                                    {reUseLimitReached === false ? (
                                        <V3CheckoutCreditCardDrawer
                                            isTempAccount={isTempAccount}
                                            uuid={uuid}
                                            ewayAccessCode={ewayAccessCode}
                                            ewayFormActionUrl={ewayFormActionUrl}
                                        />

                                    ) : null}
                                </>
                            )}
                        </>
                    ): null}
                </div>
            </div>
        </>
    );
};

export default V2V3BookSession;
