import React from "react";

const OranaLogo = ({ fillColour="white" }) => {
    return (
        <svg className="voucher__img" width="111" height="62" viewBox="0 0 111 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.03125 20.7874C0.03125 17.5851 1.20097 14.8445 3.5404 12.5659C5.87983 10.2873 8.68099 9.11719 11.9439 9.11719C15.176 9.11719 17.9464 10.2873 20.255 12.5967C22.5637 14.9061 23.7334 17.6774 23.7334 20.9414C23.7334 24.2054 22.5637 26.9767 20.2242 29.2553C17.8848 31.5339 15.0529 32.6733 11.7592 32.6733C8.8349 32.6733 6.18765 31.6571 3.879 29.6248C1.32409 27.377 0.03125 24.4209 0.03125 20.7874ZM3.47884 20.849C3.47884 23.3432 4.30995 25.4063 6.00296 27.0383C7.66519 28.6395 9.60445 29.4709 11.79 29.4709C14.1602 29.4709 16.161 28.6395 17.8232 27.0075C19.4547 25.3447 20.2858 23.3124 20.2858 20.9106C20.2858 18.478 19.4855 16.4765 17.854 14.8138C16.2534 13.1818 14.2525 12.3504 11.8823 12.3504C9.5121 12.3504 7.54206 13.1818 5.91061 14.8138C4.30995 16.4457 3.47884 18.4472 3.47884 20.849Z" fill={fillColour} />
            <path d="M35.4303 22.572L42.4486 32.2408H38.2623L31.7981 22.9415H31.1824V32.2408H27.7656V9.54688H31.7673C34.7531 9.54688 36.9387 10.1011 38.2623 11.2404C39.7398 12.5029 40.4786 14.1349 40.4786 16.198C40.4786 17.7992 40.0169 19.1848 39.0934 20.355C38.1392 21.4943 36.9387 22.2333 35.4303 22.572ZM31.1516 19.9854H32.229C35.4611 19.9854 37.0926 18.7538 37.0926 16.2596C37.0926 13.9502 35.5227 12.7801 32.3521 12.7801H31.1209V19.9854H31.1516Z" fill={fillColour} />
            <path d="M67.752 32.2413V8.00781L84.2819 25.313V9.54742H87.6987V33.627L71.1687 16.3525V32.2413H67.752Z" fill={fillColour} />
            <path d="M104.844 26.7274H95.1477L92.6235 32.2392H88.9297L100.165 8.12891L111 32.2392H107.245L104.844 26.7274ZM103.428 23.525L100.073 15.827L96.5328 23.525H103.428Z" fill={fillColour} />
            <path d="M14.4983 42.4325V45.7273C12.8977 44.3724 11.2354 43.695 9.51164 43.695C7.60315 43.695 6.03327 44.3724 4.74043 45.7273C3.44758 47.0822 2.77038 48.7449 2.77038 50.6848C2.77038 52.6248 3.4168 54.2567 4.74043 55.5808C6.03327 56.9049 7.63393 57.5823 9.54242 57.5823C10.5274 57.5823 11.3586 57.4283 12.0358 57.1204C12.4051 56.9665 12.8053 56.7509 13.2363 56.4738C13.6364 56.1967 14.0674 55.8887 14.5291 55.4884V58.8448C12.9284 59.7378 11.2662 60.1996 9.51164 60.1996C6.86439 60.1996 4.6173 59.2759 2.77038 57.4283C0.92346 55.5808 0 53.333 0 50.7156C0 48.3754 0.769549 46.2816 2.33943 44.434C4.24791 42.1862 6.71048 41.0469 9.75789 41.0469C11.3278 41.0777 12.9284 41.5396 14.4983 42.4325Z" fill={fillColour} />
            <path d="M20.6532 41.4473V59.861H17.8828V41.4473H20.6532Z" fill={fillColour} />
            <path d="M24.6562 59.8618V40.1855L38.0772 54.2576V41.448H40.8476V61.0011L27.4266 46.9906V59.8926H24.6562V59.8618Z" fill={fillColour} />
            <path d="M55.3163 44.0651H47.9286V48.4991H55.1008V51.1165H47.9286V57.2749H55.3163V59.8923H45.1582V41.4785H55.3163V44.0651Z" fill={fillColour} />
            <path d="M58.209 59.8619L62.0875 40.0625L68.3978 54.3809L74.9544 40.0625L78.4328 59.8619H75.57L73.7847 48.7459L68.3363 60.7241L63.0725 48.7151L61.1025 59.8619H58.209Z" fill={fillColour} />
            <path d="M92.7468 55.3963H84.8666L82.8042 59.8612H79.8184L88.9298 40.2773L97.7335 59.8612H94.6861L92.7468 55.3963ZM91.6079 52.779L88.8683 46.4974L86.0055 52.779H91.6079Z" fill={fillColour} />
            <path d="M110.016 44.2189L107.769 45.543C107.338 44.804 106.938 44.3421 106.568 44.0958C106.168 43.8494 105.645 43.7263 105.029 43.7263C104.26 43.7263 103.613 43.9418 103.121 44.3729C102.597 44.804 102.351 45.3582 102.351 46.0049C102.351 46.8979 103.028 47.6369 104.352 48.1911L106.199 48.9301C107.707 49.546 108.784 50.285 109.492 51.1472C110.17 52.0094 110.539 53.0871 110.539 54.3496C110.539 56.0432 109.985 57.4596 108.846 58.5373C107.707 59.6458 106.291 60.2001 104.598 60.2001C102.997 60.2001 101.674 59.7382 100.627 58.7837C99.6114 57.8291 98.965 56.505 98.7188 54.7807L101.52 54.1648C101.643 55.2426 101.859 55.9816 102.197 56.4127C102.782 57.2133 103.613 57.6136 104.69 57.6136C105.552 57.6136 106.291 57.3364 106.845 56.7514C107.43 56.1663 107.707 55.4273 107.707 54.5343C107.707 54.1648 107.646 53.8569 107.553 53.549C107.461 53.2411 107.307 52.9639 107.091 52.7176C106.876 52.4713 106.599 52.2249 106.26 52.0094C105.922 51.7938 105.522 51.5783 105.06 51.3935L103.274 50.6545C100.75 49.5768 99.4883 48.0372 99.4883 45.9741C99.4883 44.5884 100.012 43.4183 101.089 42.4946C102.136 41.5708 103.459 41.0781 105.029 41.0781C107.153 41.1397 108.815 42.1559 110.016 44.2189Z" fill={fillColour} />
            <path d="M55.1658 38.643H100.323L104.479 35.5022H66.7398L62.5843 21.9844L59.7831 24.1706L62.5843 32.9156L55.1658 27.527L47.7473 32.9156L50.5485 24.1706L47.7473 21.9844L43.561 35.5022H5.82227L10.0086 38.643H55.1658Z" fill={fillColour} />
            <path d="M55.1028 10.5925L57.7501 18.7833H61.4439L55.1028 0L48.7617 18.7833H52.4556L55.1028 10.5925Z" fill={fillColour} />
        </svg>
    );
};

export default OranaLogo;
