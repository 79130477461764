import React from "react";
import Button from "@components/Ui/Button";
import Spinner from "@components/Ui/Spinner";

const LoadingVerification = () => {
    return (
        <>
            <p className="paragraph">You need to verify an email before it can be used.</p>
            <Button
                additionalClasses="button"
                disabled={true}>
                <Spinner text="loading..." />
                verifying email...
            </Button>
        </>
    );
};

export default LoadingVerification;
