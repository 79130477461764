import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ApplicationContext } from "@context/ApplicationContext";
import Layout from "@components/V3/Layout";
import SearchDetailsVenue from "@components/V3/SearchDetailsVenue";

const V3VenueDetailsPage = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;

    return (
        <Layout>
            <Helmet>
                <title>{`${brand} | ${whiteLabel?.brand_title}`}</title>
                <meta name="keywords" content={`${brand} | ${whiteLabel?.brand_title}`} />
                <link rel="canonical" href={`${window.location.protocol}//${window.location.host}`} />
            </Helmet>
            <SearchDetailsVenue />
        </Layout>

    );
};

export default V3VenueDetailsPage;
