import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ACCOUNT_TYPE_TEMPORARY } from "@config/config";
import { AuthContext } from "@context/AuthContext";
import { BookSessionContext } from "@context/BookSessionContext";
import AlertMessage from "@components/AlertMessage";
import UserProfileIcon from "@components/Icons/UserProfileIcon";
import Button from "@components/Ui/Button";
import Logo from "@components/Ui/Logo";
import Spinner from "@components/Ui/Spinner";
import TextInputLabel from "@components/Ui/TextInputLabel";
import "./style.scss";

const V3LoginComponent = ({ switchToSignup }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const {
        user,
        loginCheckout,
        isLoginSubmitting,
        isLoggedIn,
        loginError,
        setLoginError,
        setTempAccountLogin,
        successMessage,
        setSuccessMessage,
        closeDrawer,
    } = useContext(AuthContext);
    const { setIsTempAccount } = useContext(BookSessionContext);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        setLoginError(null);
        setIsTempAccount(false);
        setTempAccountLogin(true);
        loginCheckout({ username, password });
    };

    useEffect(() => {
        setLoginError(null);
        setSuccessMessage(null);
        return () => {
            setLoginError(null);
            setSuccessMessage(null);
        };
    }, []);

    const showError = !!(
        loginError != null &&
        username !== "" &&
        password !== ""
    );

    return (
        <>
            <div className="drawer__menu">
                {(isLoggedIn === true && user?.account_type_id === ACCOUNT_TYPE_TEMPORARY) || isLoggedIn === false ? (
                    <div className="drawer__logo">
                        <Logo />
                    </div>
                ) : (
                    <div className="drawer__avatar">
                        <UserProfileIcon className="drawer__avatar-icon" />
                    </div>
                )}
            </div>
            <form onSubmit={handleSubmit} className="form__content">
                <TextInputLabel
                    type="email"
                    name="email"
                    labelName="Email"
                    required={true}
                    value={username}
                    onChange={(e) => {
                        setUsername(e.target.value);
                        setLoginError(null);
                    }}
                />
                <TextInputLabel
                    type="password"
                    name="password"
                    labelName="Password"
                    autoComplete="true"
                    required={true}
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        setLoginError(null);
                    }}
                />
                <Button additionalClasses="button button__primary">
                    {isLoginSubmitting ? (
                        <Spinner text="loading..." />
                    ) : (
                        <span>Login</span>
                    )}
                </Button>
            </form>
            <div className="form-footer">
                <p className="form-footer__text">Having trouble signing in?</p>
                <div className="form-footer__content">
                    <Link className="form-footer__link" to="/request-password-reset" onClick={() =>closeDrawer("loginSignUpDrawer")}>Forgot password?</Link>
                    or <Button additionalClasses="form-footer__button" onClick={switchToSignup}>Sign up</Button>
                </div>
            </div>
            {successMessage && (
                <AlertMessage message={successMessage} type="success" />
            )}
            {showError && <AlertMessage message={loginError} type="error" />}
        </>
    );
};

export default V3LoginComponent;
