import React, { useContext, useEffect } from "react";
import useAxios from "axios-hooks";

import { API_BASE_URL } from "@config/config";
import toSeoUrl from "@functions/toSeoUrl";
import useViewport from "@hooks/useViewport";
import { ApplicationContext } from "@context/ApplicationContext";
import PlayIcon from "@components/Icons/PlayIcon";
import TrailerButton from "@components/TrailerButton";
import Loader from "@components/Ui/Loader";
import "./style.scss";

const TopBanner = () => {
    const { subscriberGroupId, topBanner, setTopBanner } = useContext(ApplicationContext);
    const { width } = useViewport();
    const tabletBreakpoint = 768;
    const desktopBreakpoint = 1220;
    const [{ data, loading, error }] = useAxios(`${API_BASE_URL}/media/top-banner/${subscriberGroupId}`);

    useEffect(() => {
        if (data) {
            let currentTopBanner;
            const topBannerList = data.data.top_banners;

            if (topBannerList?.length) {
                const rand = Math.floor(Math.random() * topBannerList.length);
                currentTopBanner = topBannerList[rand];
                currentTopBanner.feature_url = currentTopBanner.feature_film_flag ?
                    `${currentTopBanner.ad_category_url}/${currentTopBanner.bo_movie_id}/${toSeoUrl(currentTopBanner.bo_movie_title)}` :
                    currentTopBanner.ad_category_url;
            } else {
                currentTopBanner = null;
            }
            setTopBanner(currentTopBanner);
        }
    }, [data]);


    if (loading) {
        return <Loader isLoading={loading} />;
    }

    if (!topBanner) {
        return null;
    }
    return (
        <>
            {topBanner && width < tabletBreakpoint ? (
                <>
                    <div className="top-banner__wrapper">
                        {topBanner.enable_trailer && (
                            <TrailerButton extraClass="trailer-button" type="youtube" key={`${topBanner.trailer_url}`} trailerLink={topBanner.trailer_url}>
                                <PlayIcon className="trailer-button__svg" />
                                <span className="trailer-button__button-text">Official Trailer</span>
                            </TrailerButton>
                        )}
                        <a
                            className="top-banner__link"
                            key={topBanner?.id}
                            href={topBanner?.feature_url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className="top-banner__container">
                                <source media="(min-width: 768px)" srcSet={topBanner?.image_url} />
                                <img
                                    alt={topBanner.mobile_image_url}
                                    src={topBanner.mobile_image_url}
                                    className="top-banner__image"
                                />
                            </div>
                        </a>
                    </div>
                </>
            ) : null}
            {topBanner && width >= tabletBreakpoint && width < desktopBreakpoint ? (
                <>
                    <div className="top-banner__wrapper">
                        {topBanner.enable_trailer && (
                            <TrailerButton extraClass="trailer-button" type="youtube" key={`${topBanner.trailer_url}`} trailerLink={topBanner.trailer_url}>
                                <PlayIcon className="trailer-button__svg" />
                                <span className="trailer-button__button-text">Official Trailer</span>
                            </TrailerButton>
                        )}
                        <a
                            className="top-banner__link"
                            key={topBanner?.id}
                            href={topBanner?.feature_url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className="top-banner__container">
                                <source media="(min-width: 768px)" srcSet={topBanner?.tablet_image_url} />
                                <img
                                    alt={topBanner.tablet_image_url}
                                    src={topBanner.tablet_image_url}
                                    className="top-banner__image"
                                />
                            </div>
                        </a>
                    </div>
                </>
            ) : null}
            {topBanner && width > desktopBreakpoint ? (
                <>
                    <div className="top-banner__wrapper">
                        {topBanner.enable_trailer && (
                            <TrailerButton extraClass="trailer-button" type="youtube" key={`${topBanner.trailer_url}`} trailerLink={topBanner.trailer_url}>
                                <PlayIcon className="trailer-button__svg" />
                                <span className="trailer-button__button-text">Official Trailer</span>
                            </TrailerButton>
                        )}
                        <a
                            className="top-banner__link"
                            key={topBanner?.id}
                            href={topBanner?.feature_url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className="top-banner__container">
                                <source media="(min-width: 768px)" srcSet={topBanner?.desktop_image_url} />
                                <img
                                    alt={topBanner.desktop_image_url}
                                    src={topBanner.desktop_image_url}
                                    className="top-banner__image"
                                />
                            </div>
                        </a>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default TopBanner;
