import React from "react";

const AccessibleIcon = ({ className="" }) => {
    return (
        <svg className={className} width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4557 18.4107C12.9836 17.8844 12.1475 17.8137 11.5926 18.2615C10.6156 19.0469 9.37353 19.4789 8.09006 19.4789C5.09244 19.4789 2.65808 17.1698 2.65808 14.3265C2.65808 13.2975 2.97266 12.3078 3.57726 11.4597C3.98296 10.8864 3.82567 10.1009 3.21296 9.7159C2.60858 9.33107 1.78053 9.48027 1.3746 10.0615C0.480408 11.326 0 12.8026 0 14.3342C0 18.5597 3.62674 22 8.08172 22C9.99455 22 11.841 21.3559 13.2984 20.1856C13.8616 19.7303 13.9278 18.937 13.4557 18.4107V18.4107Z" fill="currentColor"/>
            <path d="M17.9437 18.882L15.7577 12.3548C15.6004 11.8835 15.1367 11.5693 14.6232 11.5693H7.67613V9.42521H12.3049C12.9673 9.42521 13.4972 8.92257 13.4972 8.29422C13.4972 7.66588 12.9673 7.16325 12.3049 7.16325H7.67613V4.7126C8.50417 4.2885 9.06715 3.46381 9.06715 2.51339C9.06715 1.12308 7.88314 0 6.41741 0C4.95167 0 3.76766 1.12308 3.76766 2.51339C3.76766 3.51865 4.39702 4.37496 5.29122 4.78325L5.29953 12.7004C5.29953 13.3209 5.83776 13.8314 6.49187 13.8314H13.7541L15.6752 19.5651C15.8325 20.0442 16.3046 20.3505 16.8097 20.3505C16.9256 20.3505 17.0498 20.3347 17.174 20.2954C17.7951 20.1072 18.1428 19.4709 17.944 18.8818L17.9437 18.882Z" fill="currentColor"/>
        </svg>
    );
};

export default AccessibleIcon;
