import React, { useContext, useEffect } from "react";
import { ApplicationContext } from "@context/ApplicationContext";
import NewsItem from "@components/News/NewsItems";
import NewsData from "@components/News/NewsItems/Data";
import "./style.scss";

const NewsList = () => {
    const { setNews } = useContext(ApplicationContext);

    useEffect(() => {
        if (NewsData) {
            setNews(NewsData);
        }
    }, []);

    return (
        <div className="news__wrapper">
            <h1 className="main-heading">News</h1>
            <div className="news__content">
                <div className="news__list">
                    {NewsData.map((item, i, idx) => (
                        <NewsItem key={i} item={item} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default NewsList;
