import React, { useContext } from "react";
import { ACCOUNT_TYPE_NORMAL, ACCOUNT_TYPE_TEMPORARY } from "@config/config";
import { AuthContext } from "@context/AuthContext";
import Button from "@components/Ui/Button";
import Drawer from "@components/V3/Drawer";
import UserIcon from "@components/V3/Icons/UserIcon";
import UserProfile from "@components/V3/UserProfile";
import V3LoginComponent from "@components/V3/V3LoginComponent";
import V3SignupForm from "@components/V3/V3SignupForm";
import "./style.scss";

const UserMenu = () => {
    const { user, isLoggedIn, drawerState, openDrawer, closeDrawer, drawerContent } = useContext(AuthContext);

    const openLogin = () => {
        openDrawer("loginSignUpDrawer", <V3LoginComponent switchToSignup={openSignup} />);
    };

    const openSignup = () => {
        openDrawer("loginSignUpDrawer", <V3SignupForm switchToLogin={openLogin} />);
    };

    const openProfile = () => {
        openDrawer("loginSignUpDrawer", <UserProfile />);
    };

    return (
        <>
            {(!isLoggedIn || user?.account_type_id === ACCOUNT_TYPE_TEMPORARY) && (
                <Button additionalClasses="user-menu__login-button" onClick={openLogin}>
                    <span className="user-menu__avatar-container">
                        <UserIcon className="user-menu__avatar" />
                    </span>
                    <span className="user-menu__text">Log in</span>
                </Button>
            )}
            {isLoggedIn && user?.account_type_id === ACCOUNT_TYPE_NORMAL && (
                <Button additionalClasses="user-menu__login-button" onClick={openProfile}>
                    <span className="user-menu__avatar-container">
                        <UserIcon className="user-menu__avatar" />
                    </span>
                </Button>
            )}
            <Drawer isOpen={drawerState.loginSignUpDrawer} onClose={() => closeDrawer("loginSignUpDrawer")} closeButtonAlignment="right">
                {drawerContent}
            </Drawer>
        </>
    );
};

export default UserMenu;
