import React, { useContext, useEffect, useState } from "react";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { SearchContext } from "@context/SearchContext";
import Loader from "@components/Ui/Loader";
import DateSelector from "@components/V3/DateSelector";
import SessionsByMovie from "@components/V3/SessionsByMovie";
import "./style.scss";

const VenueSessionSelector = () => {
    const { id } = useParams();
    const { fetchVenueSessions, sessionsByVenue, venue, isLoadingSessions } = useContext(SearchContext);

    const [uniqueDates, setUniqueDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        if (venue && venue.name) {
            fetchVenueSessions({ venue_name: venue.name });
        }
    }, [id, venue.name]);

    
    useEffect(() => {
        if (sessionsByVenue.length > 0) {
            const uniqueDates = Array
                .from(new Set(sessionsByVenue.map((session) => format(new Date(session.session_date), "yyyy-MM-dd"))))
                .sort((a, b) => new Date(a) - new Date(b));
            setUniqueDates(uniqueDates);
            
            setSelectedDate(uniqueDates[0]);
        }
    }, [sessionsByVenue]);

    const handleClickDate = (date) => {
        setSelectedDate(date);
    };

    return (
        <>
            {isLoadingSessions ? (
                <Loader isLoading={isLoadingSessions} text="Fetching sessions" />
            ) : sessionsByVenue.length > 0 ? (
                <div className="venue-details">
                    <DateSelector 
                        uniqueDates={uniqueDates} 
                        handleClickDate={handleClickDate} 
                        selectedDate={selectedDate}
                    />

                    <SessionsByMovie selectedDate={selectedDate}/>
                </div>
            ) : !isLoadingSessions && sessionsByVenue.length === 0 ? (
                <p className="paragraph">There are no sessions available</p>
            ) : null}
        </>
    );
};

export default VenueSessionSelector;
