import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { IMAGE_WIDTH_LARGE, IMAGE_WIDTH_MEDIUM, IMAGE_WIDTH_SMALL } from "@config/ImageSizes";
import displayImageSize from "@functions/displayImageSize";
import toSeoUrl from "@functions/toSeoUrl";
import { ApplicationContext } from "@context/ApplicationContext";
import "./style.scss";

const MoviePoster = ({ movie, size, movieLink = true, showTitle = false }) => {
    const { cdnUrl } = useContext(ApplicationContext);
    let imageSize;

    if (size === "small") {
        imageSize = IMAGE_WIDTH_SMALL;
    } else if (size === "medium") {
        imageSize = IMAGE_WIDTH_MEDIUM;
    } else if (size === "large") {
        imageSize = IMAGE_WIDTH_LARGE;
    }

    const urlMovieImage = displayImageSize(movie.movie_image, imageSize);
    const fallbackImage = `${cdnUrl}asset/img/placeholder.jpeg`;
    const imageUrl = urlMovieImage || fallbackImage;

    const onImageError = (e) => {
        e.target.src = fallbackImage;
    };

    const PosterImage = () => (
        <img
            loading="lazy"
            alt={`${movie.movie_title ? movie.movie_title : movie.title} poster image`}
            src={imageUrl}
            className="img movie-poster__img"
            onError={onImageError}
            title={movie.movie_title ? movie.movie_title : movie.title}
        />
    );

    return (
        <div className="movie-poster__container">
            <div className={`movie-poster movie-poster__${size}`}>
                {movieLink
                    ? (
                        <Link className="movie-poster__link" data-cy="movie-link" to={{ pathname: `/movie/${movie.movie_id}/${toSeoUrl(movie.movie_title)}` }}>
                            <PosterImage />
                        </Link>
                    ) : (
                        <PosterImage/>
                    )}
            </div>
            {showTitle && (
                <div className={`movie-poster__title movie-poster__title--${size}`}>
                    {movie.movie_title ? movie.movie_title : movie.title}
                </div>
            )}

        </div>
    );
};

MoviePoster.propTypes = {
    movie: PropTypes.shape({
        movie_image: PropTypes.string,
        movie_title: PropTypes.string,
        title: PropTypes.string,
        movie_id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    }),
    size: PropTypes.string,
    movieLink: PropTypes.bool,
    showTitle: PropTypes.bool
};

export default MoviePoster;
