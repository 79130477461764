import React, { useContext, useEffect, useState } from "react";
import format from "date-fns/format";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { BRAND_AIA } from "@config/siteConfig";
import { ApplicationContext } from "@context/ApplicationContext";
import { UserContext } from "@context/UserContext";
import PromoLogoAia from "@components/Icons/PromoLogoAia";
import TicketIcon from "@components/Icons/TicketIcon";
import { getSessionButtonText } from "@utils/ticketUtils";
import "./style.scss";

const SessionButton = ({ session }) => {
    const { whiteLabel } = useContext(ApplicationContext);
    const { promoDetails, setIsAdvancedPromo, isAdvancedPromo, isPromoCodeForEligibleSubscriber } = useContext(UserContext);

    const additionalClasses = isAdvancedPromo ? "session-btn--adv-promo" : "session-btn--non-promo";
    const { promoTicketClasses } = promoDetails;
    const [ticketClass, setTicketClass] = useState("");

    useEffect(() => {
        if (promoTicketClasses?.includes(Number(session?.ticket_class_id))) {
            setIsAdvancedPromo(true);
        }
    }, [promoTicketClasses]);

    useEffect(() => {
        setTicketClass(getSessionButtonText(session));
    }, [session]);

    const displaySessionBtnIcon = isAdvancedPromo && promoTicketClasses?.includes(Number(session?.ticket_class_id)) && ["0", "1"].includes(isPromoCodeForEligibleSubscriber);

    return (
        <div className="session-btn__time-item">
            <Link
                key={session.id}
                className={`button button__secondary session-btn__time-item ${additionalClasses}`}
                to={`/ticket/purchase/${session.id}`}
            >
                {displaySessionBtnIcon && (
                    whiteLabel?.brand_code === BRAND_AIA
                        ? <PromoLogoAia className="session-btn__promo-img" />
                        : <TicketIcon className="session-btn__promo-img" />
                )}
                <span className="session-btn__time">
                    {format(session.d, "h:mm a")}
                </span>
                <span className="session-btn__price">
                    ${(session.ticket_price_cents / 100).toFixed(2)}
                </span>
                {ticketClass && <span className="session-btn__class">{ticketClass}</span>}
            </Link>
        </div>
    );
};

SessionButton.propTypes = {
    session: PropTypes.shape({
        id: PropTypes.number,
        d: PropTypes.instanceOf(Date),
        ticket_price_cents: PropTypes.number,
        ticket_class_id: PropTypes.number,
    }),
    whiteLabel: PropTypes.shape({
        show_ticket_class: PropTypes.bool,
    }),
};

export default SessionButton;
