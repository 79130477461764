import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ApplicationContext } from "@context/ApplicationContext";
import UserProfileForm from "@components/Form/UserProfileForm";
import Layout from "@components/V3/Layout";
import FormWrapper from "@components/Wrappers/FormWrapper";

const V3UserProfilePage = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;

    return (
        <Layout>
            <Helmet>
                <title>{`My Profile | ${brand}`}</title>
                <meta name="keywords" content={`My Account | ${brand}`} />
                <meta name="description" content={`Keep track of your membership
                information & history through your ${brand} account profile.
                Use it to shop for movie tickets, redeem offers & more!`} />
                <link rel="canonical" href={`${window.location.protocol}//${window.location.host}/user/profile`} />
            </Helmet>
            <FormWrapper pageTitle="My Profile">
                <UserProfileForm />
            </FormWrapper>
        </Layout>
    );
};

export default V3UserProfilePage;
