import React from "react";
import TextInput from "@components/Ui/TextInput";
import "./style.scss";

const PromoCodeInput = ({ promoCode, setPromoCode, success }) => {


    const inputClass = (success === true)
        ? "promo-code-input__success"
        : (success === false)
            ? "promo-code-input__error"
            : "";

    return (
        <TextInput
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            placeholder="Enter Promo Code"
            additionalClasses={`text-input__field--wide ${inputClass}`}
        />
    );
};

export default PromoCodeInput;
