import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";

export const SessionContext = createContext({});

const SessionContextProvider = (props) => {
    const { isLoggedIn } = useContext(AuthContext);
    const [isLoadingSession, setIsLoadingSession] = useState(false);
    const [isLoadingSessions, setIsLoadingSessions] = useState(false);
    const [isLoadedSessions, setIsLoadedSessions] = useState(false);
    const [session, setSession] = useState(null);
    const [purchasable, setPurchasable] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [movieTitle, setMovieTitle] = useState(null);
    const [venueName, setVenueName] = useState(null);
    const [sessionDate, setSessionDate] = useState(null);
    const [region, setRegion] = useState(25);
    const [confirmation, setConfirmation] = useState(false);
    const [searchParams, setSearchParams] = useState(new URLSearchParams());
    const [candyBarInventory, setCandyBarInventory] = useState(null);
    const [hasCandyBarInventory, setHasCandyBarInventory] = useState(null);
    const { lat, lng } = useContext(ApplicationContext);
    const { children } = props;

    const fetchSessions = ({
                               venueName,
                               movieTitle,
                               sessionDate,
                               region,
                           } = {}) => {
        setIsLoadingSessions(true);
        if (venueName) {
            searchParams.set("venue_name", venueName);
        } else {
            searchParams.delete("venue_name");
        }
        if (movieTitle) {
            searchParams.set("movie_title", movieTitle);
        } else {
            searchParams.delete("movie_title");
        }
        if (sessionDate) {
            searchParams.set("session_date", sessionDate);
        } else {
            searchParams.delete("session_date");
        }
        if (region && Number.isInteger(region)) {
            searchParams.set("search_distance", region);
            searchParams.delete("state");
        }
        if (
            region &&
            (typeof region === "string" || region instanceof String)
        ) {
            searchParams.set("state", region);
            searchParams.delete("search_distance");
        }

        axios
            .get(`${API_BASE_URL}/session?${searchParams.toString()}`)
            .then(function(response) {
                setIsLoadingSessions(false);
                const sessionData = response.data.data.map((item) => {
                    return {
                        ...item,
                        venue_image: item.venue_image,
                        ticket_price_cents: parseInt(item.ticket_price_cents),
                        rrp_pricing_cents: item.rrp_pricing_cents,
                    };
                });

                setSessions(sessionData);
                setSearchParams(searchParams);
            })
            .catch((error) => {
                setSessions([]);
                setIsLoadingSessions(false);
                console.log(error);
                if (isLoggedIn && error?.response?.status === 401) {
                    window.location = "/";
                }
            });
    };

    // called on movie details
    const fetchMovieSessions = ({ movie_title, lat, lng } = {}) => {
        setIsLoadingSessions(true);
        axios
            .get(
                `${API_BASE_URL}/session?movie_title=${movie_title.toString()}&search_distance=25&lat=${lat}&lng=${lng}`,
            )
            .then(function(response) {
                const sessionData = response.data.data.map((item) => {
                    return {
                        ...item,
                        ticket_price_cents: parseInt(item.ticket_price_cents),
                        rrp_pricing_cents: item.rrp_pricing_cents,
                    };
                });
                setIsLoadingSessions(false);
                setSessions(sessionData);

            })
            .catch((error) => {
                setSessions([]);
                setIsLoadingSessions(false);
                console.log(error);
                if (isLoggedIn && error?.response?.status === 401) {
                    window.location = "/";
                }
            });
    };

    const fetchPurchasable = (id) => {
        setIsLoadingSession(true);
        axios
            .get(`${API_BASE_URL}/purchasable/${id}?limit=1`)
            .then(function(response) {
                if (response.data.successUrl)
                {
                    window.open(response.data.data, "_self");
                }
                setIsLoadingSession(false);
                const sessionData = response.data.data.sessions.map((item) => {
                    return {
                        ...item,
                        cinema_group_id: item.cinema_group_id,
                        ticket_price_cents: parseInt(item.ticket_price_cents),
                        rrp_pricing_cents: item.rrp_pricing_cents,
                    };
                });
                const candyBarInventory = response.data.data.candybar.map(
                    (item) => {
                        return {
                            ...item,
                            candy_bar_price: parseInt(item.candy_bar_price),
                            candy_bar_rrpPricing: parseInt(item.candy_bar_rrpPricing),
                        };
                    },
                );
                setPurchasable([...sessionData, ...candyBarInventory]);
                setHasCandyBarInventory(candyBarInventory);
                setSession(sessionData[0]);
                setIsLoadingSession(false);
            })
            .catch(({ response }) => {
                setIsLoadingSession(false);
                setSession(null);
                setPurchasable(null);
                console.log(response);
                if (isLoggedIn && response?.status === 401) {
                    window.location = "/";
                }
            });
    };

    return (
        <SessionContext.Provider
            value={{
                confirmation,
                fetchSessions,
                fetchMovieSessions,
                fetchPurchasable,
                isLoadedSessions,
                isLoadingSession,
                setIsLoadingSession,
                setIsLoadingSessions,
                isLoadingSessions,
                candyBarInventory,
                setCandyBarInventory,
                session,
                sessions,
                purchasable,
                setConfirmation,
                setIsLoadedSessions,
                setSession,
                setPurchasable,
                setSessions,
                venueName,
                setVenueName,
                setMovieTitle,
                movieTitle,
                region,
                setRegion,
                sessionDate,
                setSessionDate,
                searchParams,
                hasCandyBarInventory,
            }}
        >
            {children}
        </SessionContext.Provider>
    );
};

export default SessionContextProvider;
