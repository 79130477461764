import React from "react";
import PropTypes from "prop-types";
import TextInput from "@components/Ui/TextInput";


const TextInputLabel = ({
                            value,
                            onChange,
                            isValueErrored,
                            disabled,
                            required,
                            autoComplete,
                            additionalClasses,
                            name,
                            id,
                            placeholder,
                            type,
                            labelName,
                        }) => {

    return (
        <div className="form__group">
            {labelName &&
                <label className="form__label" htmlFor={name}>
                    {labelName}
                </label>
            }
            <TextInput
                value={value}
                onChange={onChange}
                isValueErrored={isValueErrored}
                disabled={disabled}
                required={required}
                autoComplete={autoComplete}
                additionalClasses={additionalClasses}
                name={name}
                id={id}
                placeholder={placeholder}
                type={type}
            />
        </div>
    );
};

TextInputLabel.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    isValueErrored: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    autoComplete: PropTypes.string,
    additionalClasses: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    labelName: PropTypes.string,
};

export default TextInputLabel;
