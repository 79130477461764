import React from "react";

const JoinIcon = ({ className="" }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path d="M12.7978 28.8982C9.1604 28.4333 6.11808 26.8489 3.67082 24.145C1.22361 21.4412 0 18.2278 0 14.5049C0 10.4881 1.41328 7.06646 4.23985 4.23985C7.06646 1.41328 10.4881 0 14.5049 0C18.2278 0 21.4412 1.21432 24.145 3.64297C26.8489 6.07157 28.4333 9.10456 28.8982 12.7419L25.7629 11.8215C25.1343 9.21059 23.7898 7.07112 21.7294 5.40306C19.669 3.735 17.2608 2.90097 14.5049 2.90097C11.3138 2.90097 8.58205 4.03719 6.30962 6.30962C4.03719 8.58205 2.90097 11.3138 2.90097 14.5049C2.90097 17.2608 3.74431 19.6783 5.43099 21.7573C7.11761 23.8363 9.24777 25.1901 11.8215 25.8187L12.7978 28.8982ZM37.0042 40L23.0962 26.0363L20.4184 34.1423L14.5049 14.5049L34.1423 20.4184L26.0363 23.0962L40 37.0042L37.0042 40Z" fill="white"/>
        </svg>
    );
};

export default JoinIcon;
