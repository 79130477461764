import React, { useContext } from "react";
import { AuthContext } from "@context/AuthContext";
import CheckoutComplimentary from "@components/CheckoutComplimentary";
import Drawer from "@components/V3/Drawer";

const V3ComplimentaryCheckoutDrawer = ({ uuid, bookingSessionId, freeTicketToken }) => {
    const { drawerState, closeDrawer } = useContext(AuthContext);

    return (
        <>
            <Drawer
                isOpen={drawerState.complimentaryCheckoutDrawer}
                onClose={() => closeDrawer("complimentaryCheckoutDrawer")}
                closeButtonAlignment="right"
            >
                <CheckoutComplimentary
                    uuid={uuid}
                    bookingSessionId={bookingSessionId}
                    freeTicketToken={freeTicketToken}
                />
            </Drawer>
        </>
    );
};

export default V3ComplimentaryCheckoutDrawer;
