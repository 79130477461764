const IMAGE_WIDTH_XLARGE = 570;
const IMAGE_WIDTH_LARGE = 520;
const IMAGE_WIDTH_MEDIUM = 428;
const IMAGE_WIDTH_SMALL = 280;

const IMAGE_SIZES = [
  IMAGE_WIDTH_LARGE,
  IMAGE_WIDTH_MEDIUM,
  IMAGE_WIDTH_SMALL,
];

export {
  IMAGE_WIDTH_XLARGE,
  IMAGE_WIDTH_LARGE,
  IMAGE_WIDTH_MEDIUM,
  IMAGE_WIDTH_SMALL,
  IMAGE_SIZES
};
