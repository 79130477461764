import React, { useContext, useEffect, useState } from "react";
import { ACCOUNT_TYPE_TEMPORARY } from "@config/config";
import flattenErrorMessages from "@functions/flattenErrorMessages";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { ProfileContext } from "@context/ProfileContext";
import AlertMessage from "@components/AlertMessage";
import UserProfileIcon from "@components/Icons/UserProfileIcon";
import PasswordStrength from "@components/PasswordStrength";
import Button from "@components/Ui/Button";
import Logo from "@components/Ui/Logo";
import Spinner from "@components/Ui/Spinner";
import TextInputLabel from "@components/Ui/TextInputLabel";

const V3SignupForm = ({ switchToLogin }) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const { whiteLabel } = useContext(ApplicationContext);
    const { user, memberId, setMemberId, isLoggedIn } = useContext(AuthContext);

   

    const {
        success,
        email,
        isSignupSubmitting,
        setIsSignupSubmitting,
        signup,
        setEmail,
        errorMessages,
        setErrorMessages,
    } = useContext(ProfileContext);

    useEffect(() => {
        return () => {
            setErrorMessages({});
        };
    }, []);

    useEffect(() => {
        if (success === true) {
            const timer = setTimeout(() => {
                window.location = "/";
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [success]);

    
    const onSubmit = (e) => {
        e.preventDefault();
        setIsSignupSubmitting(true);
        setErrorMessages({});
        const data = {
            email_address: email,
            first_name: firstName,
            last_name: lastName,
            password,
            password_repeat: passwordRepeat,
            subscriber_group_code: null,
            referrer_subscriber_id: null,
            member_id: "",
        };

        if (
            user?.account_type_id === ACCOUNT_TYPE_TEMPORARY &&
            user?.subscriber_group_code
        ) {
            data.subscriber_group_code = user.subscriber_group_code;
        }
        if (user?.id) {
            data.referrer_subscriber_id = user.id;
        }

        if (memberId) {
            data.member_id = memberId;
            setMemberId(memberId);
        }

        signup(data);
    };

    const errorsFlattened = flattenErrorMessages(errorMessages);

    return (
        <>
            {!success && (
                <>
                    <div className="drawer__menu">
                        {(isLoggedIn === true && user?.account_type_id === ACCOUNT_TYPE_TEMPORARY) || isLoggedIn === false ? (
                            <div className="drawer__logo">
                                <Logo />
                            </div>
                        ) : (
                            <div className="drawer__avatar">
                                <UserProfileIcon className="drawer__avatar-icon" />
                            </div>
                        )}
                    </div>
                    <form onSubmit={onSubmit} className="form__content">
                        {whiteLabel?.show_member_no === true ? (
                            <TextInputLabel
                                id="member_id"
                                name="member_id"
                                labelName="Member Number"
                                required={true}
                                value={memberId}
                                onChange={(e) => setMemberId(e.target.value)}
                                // isValueErrored={errorMessages.member_id != ""}
                                placeholder=""
                            />
                        ) : null}

                        <TextInputLabel
                            id="Email"
                            name="Email"
                            type="email"
                            labelName="Email"
                            required={true}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            isValueErrored={errorMessages.email_address != null}
                            placeholder="Email"
                        />
                        <TextInputLabel
                            id="Firstname"
                            name="Firstname"
                            labelName="First Name"
                            required={true}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            isValueErrored={errorMessages.first_name != null}
                            placeholder="First Name"
                        />
                        <TextInputLabel
                            name="Lastname"
                            labelName="Last Name"
                            required={true}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            isValueErrored={errorMessages.last_name != null}
                            placeholder="Last Name"
                        />

                        <TextInputLabel
                            name="password"
                            type="password"
                            labelName="Password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            isValueErrored={errorMessages.password != null}
                        />
                        <PasswordStrength password={password} />
                        <TextInputLabel
                            name="passwordRepeat"
                            type="password"
                            labelName="Confirm Password"
                            additionalClasses="required mt-2 mb-2"
                            placeholder="Password Repeat"
                            value={passwordRepeat}
                            onChange={(e) => setPasswordRepeat(e.target.value)}
                        />
                        <Button additionalClasses="button button__primary">
                            {isSignupSubmitting ? (
                                <Spinner text="loading..." />
                            ) : (
                                <span>Sign Up</span>
                            )}
                        </Button>
                    </form>
                    <div className="form-footer">
                        <p className="form-footer__text">Already have an account?</p>
                        <div className="form-footer__content">
                            <Button additionalClasses="form-footer__button" onClick={switchToLogin}>
                                Login in
                            </Button>
                        </div>
                    </div>
                </>
            )}

            {success === true ? (
                <div className="body-primary">
                    <p className="paragraph">Registration completed successfully.</p>
                    <p className="paragraph">
                        Please check your email and click the verify link to activate the
                        account.
                    </p>
                </div>
            ) : null}

            {errorsFlattened.length > 0 && (
                <AlertMessage
                    message="The following errors occurred:"
                    type="error"
                    messageList={errorsFlattened}
                />
            )}
        </>
    );
};

export default V3SignupForm;
