import React, { useContext } from "react";
import { SearchContext } from "@context/SearchContext";
import SearchLensIcon from "@components/Icons/SearchLensIcon";
import SearchSelect from "@components/SearchSelect";
import "./style.scss";

const Venues = ({ venues, handleVenueClick }) => {
    const { venueName, setVenueName, searchOptions } = useContext(SearchContext);
    const venueOptionsList = venues.map((item) => ({
        ...item,
        label: item,
        value: item,
    }));

    const venueList = Array.from(new Set(searchOptions
        .filter((item) => item.type === "cinema")
        .map((item) => ({
            ...item,
            label: item.name,
        }))
    ));

    const filterOptions = (candidate, input) => {
        if (!candidate.value || typeof candidate.value !== "string") {
            return false;
        }

        const candidateStr = candidate.value.toLowerCase();
        const candidateTerms = candidateStr.split(" ");
        const searchStr = input.toLowerCase();

        return searchStr && searchStr.includes(" ")
            ? candidateStr.includes(searchStr)
            : searchStr
                ? candidateTerms.some((str) => str.startsWith(searchStr))
                : false;
    };

    const handleOnChangeVenue = (option, { action }) => {
        if (action === "clear") {
            setVenueName("");
            handleVenueClick("");
        } else if (action === "select-option") {
            setVenueName(option.label);
            handleVenueClick(option.label);
        }
    };

    const selectedVenueOption = venues.find(
        (item) => item.value === venueName,
    );

    const customNoOptionsMessage = () => (
        <span className="venue-finder__no-results">
            We did not find any venues
        </span>
    );

    return (
        <>
            <div className="venue-finder">
                <h2 className="venue-finder__title">Find a cinemas</h2>
                <div className="venue-finder__input-wrapper">
                    <span className="venue-finder__search-icon">
                        <SearchLensIcon className="venue-finder__search-svg" />
                    </span>
                    <SearchSelect
                        placeholder="type in a venue"
                        aria-label="Select a venue input"
                        value={selectedVenueOption}
                        options={venueList}
                        filterOption={filterOptions}
                        onChange={handleOnChangeVenue}
                        isClearable={true}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                        }}
                        openMenuOnClick={false}
                        noOptionsMessage={customNoOptionsMessage}
                    />
                </div>
            </div>
        </>

    );
};

export default Venues;
