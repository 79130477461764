import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { ApplicationContext } from "@context/ApplicationContext";
import "./style.scss";

const NewsPost = () => {
    const { id } = useParams();
    const { cdnUrl, newsItem } = useContext(ApplicationContext);
    const { title, image, content } = newsItem;

    const imageUrl = cdnUrl ?  cdnUrl + image : `/${ image}`;


    return (
        <div className="news-post__container">
            <h1 className="news-post__title">{title}</h1>
            <div className="news-post__image-container" style={{
                backgroundImage: `url(${imageUrl})`,
                backgroundPosition: "top center"
            }}></div>
            {/* eslint-disable-next-line react/no-danger */}
            <div className="news-post__content" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    );
};

export default NewsPost;
