import React, { useContext } from "react";
import { ApplicationContext } from "@context/ApplicationContext";
import FormWrapper from "@components/Wrappers/FormWrapper";
import Layout from "@components/Wrappers/Layout";

const NotFoundPage = () => {
    const { whiteLabel } = useContext(ApplicationContext);

    return (
        <Layout>
            <FormWrapper pageTitle="404 Page not found">
                <p className="paragraph">Sorry we were not able to find the page you requested.</p>
                <p className="paragraph">Please <a className="inline-link" href={`mailto:${whiteLabel?.support_email}`}>
                    contact support</a> if you require further assistance.
                </p>
            </FormWrapper>
        </Layout>
    );
};

export default NotFoundPage;
