import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import parse from "date-fns/parse";
import { API_BASE_URL } from "@config/config";
import { AuthContext } from "@context/AuthContext";
import { SessionContext } from "@context/SessionContext";

export const UserContext = createContext({});

const UserContextProvider = (props) => {

    const { isLoggedIn } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [purchasedTickets, setPurchasedTickets] = useState({
        current: [],
        history: [],
    });
    const [purchasedVouchers, setPurchasedVouchers] = useState([]);
    const { setIsLoadingSession } = useContext(SessionContext);
    const [purchasedTicket, setPurchasedTicket] = useState(null);
    const [bookedTicketForSession, setBookedTicketForSession] = useState([]);
    const [purchasedSession, setPurchasedSession] = useState(null);
    const [promoDetails, setPromoDetails] = useState({
        promoUniqueCode: null,
        redemptionLimit: null,
        redemptionCount: null,
        promoCategory: 0,
        promoTicketClasses: null,
        eligibility: null,
        eligible: null,
        cap_purchases: null,
    });

    const [isAdvancedPromo, setIsAdvancedPromo] = useState(false);
    const [isRegularPromo, setIsRegularPromo] = useState(false);
    const [isPromoCodeForEligibleSubscriber, setIsPromoCodeForEligibleSubscriber] = useState(null);
    const [reUseLimitReached, setReuseLimitReached] = useState(false);
    const { children } = props;


    const fetchTickets = () => {
        setIsLoading(true);
        axios
            .get(`${API_BASE_URL}/ticket/purchases`)
            .then(function(response) {
                if (response.status === 200) {
                    let tickets = response.data.data;
                    const recent = new Date();
                    recent.setDate(recent.getDate());

                    tickets = tickets.map(ticket => ({
                        ...ticket,
                        purchaseDate: parse(ticket.purchase_date, "yyyy-MM-dd HH:mm:ss", new Date()),
                        sessionDate: parse(ticket.session_date_time, "yyyy-MM-dd HH:mm:ss", new Date()),
                    }));

                    // the db query returns some duplicate values, the following line deletes the duplicates while keeping the data format
                    tickets = [...new Map(tickets.map(ticket => [ticket.ticket_id, ticket]))
                        .values()];

                    const current = tickets
                        .filter(ticket => ticket.sessionDate >= recent)
                        .sort((a, b) => a.sessionDate - b.sessionDate);

                    const history = tickets
                        .filter(ticket => ticket.sessionDate < recent)
                        .sort((a, b) => a.sessionDate - b.sessionDate);

                    setPurchasedVouchers(tickets);
                    setPurchasedTickets({
                        current,
                        history,
                    });
                }
                setIsLoading(false);
            })
            .catch(({ response }) => {
                if (isLoggedIn && response?.status === 401) {
                    window.location = "/";
                }
                setIsLoading(false);
            });
    };

    const fetchBookedTicketForSession = (id) => {
        setIsLoadingSession(true);
        axios
            .get(`${API_BASE_URL}/ticket/purchased-today/${id}`)
            .then(function(response) {
                setBookedTicketForSession(response.data.data);
                setIsLoadingSession(false);
            })
            .catch((error) => {
                setBookedTicketForSession([]);
                setIsLoadingSession(false);
                if (isLoggedIn && error?.response?.status === 401) {
                    window.location = "/";
                }
            });
    };

    const fetchApplyPromoAvailability = () => {
        setIsLoading(true);
        axios
            .get(`${API_BASE_URL}/subscriber-promo-availability`)
            .then(function(response) {
                if (response?.data?.promoDetails === null && response?.data?.promoClasses.length === 0) {
                    setIsRegularPromo(true);
                }
                if (response?.data?.promoDetails?.unique_code) {
                    const { promoDetails, promoClasses } = response.data;
                    const { unique_code, promo_reuse_count, reuse_limit, promo_category, eligibility, eligible, cap_purchases } = promoDetails;
                    setPromoDetails({
                        promoUniqueCode: unique_code,
                        redemptionLimit: parseInt(reuse_limit, 10),
                        redemptionCount: promo_reuse_count,
                        promoCategory: parseInt(promo_category, 10),
                        promoTicketClasses: promoClasses,
                        eligibility,
                        eligible,
                        cap_purchases: parseInt(cap_purchases, 10),
                    });
                    setIsLoading(false);
                    setIsAdvancedPromo(true);
                    setIsPromoCodeForEligibleSubscriber(response.data.promoDetails.eligibility);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setPromoDetails({
                    promoUniqueCode: null,
                });
            });
    };

    return (
        <UserContext.Provider
            value={{
                bookedTicketForSession,
                errorMessage,
                fetchApplyPromoAvailability,
                fetchBookedTicketForSession,
                fetchTickets,
                isLoading,
                processing,
                purchasedSession,
                purchasedTicket,
                purchasedTickets,
                purchasedVouchers,
                setBookedTicketForSession,
                setErrorMessage,
                setProcessing,
                setPurchasedSession,
                setPurchasedTicket,
                setPurchasedVouchers,
                setSuccessMessage,
                successMessage,
                promoDetails,
                isAdvancedPromo,
                setIsAdvancedPromo,
                isRegularPromo,
                setIsRegularPromo,
                isPromoCodeForEligibleSubscriber,
                reUseLimitReached,
                setReuseLimitReached,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
