import React from "react";
import "./style.scss";

const PasswordStrength = ({ password }) => {
    let score = 0;
    if (password.length >= 8) {
        score += 20;
    }
    if (password.match(/[a-z]/)) {
        score += 20;
    }
    if (password.match(/[A-Z]/)) {
        score += 20;
    }
    if (password.match(/\d+/)) {
        score += 20;
    }
    // eslint-disable-next-line
    if (password.match(/[!,@,#,$,%,\^,&,*,?,_,~]/)) {
        score += 20;
    }

    return (
        <div className="progress__container">
            <div
                role="progressbar"
                className={`progress__bar progress__${score}`}
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
            />
        </div>
    );
};

export default PasswordStrength;
