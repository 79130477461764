import React from "react";
import "./style.scss";

const RedemptionDetails = ({ redemptionCount, redemptionLimit, brandName }) => {
    return (
        <div className="redemptions__wrapper">
            <div className="redemptions__text"> {brandName}</div>
            <div className="redemptions__counter"> {redemptionCount} / {redemptionLimit} used </div>
        </div>
    );
};

export default RedemptionDetails;
