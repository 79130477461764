import React, { useContext } from "react";
import { AuthContext } from "@context/AuthContext";
import Loader from "@components/Ui/Loader";
import NowShowingTile from "@components/V3/Movies/NowShowingTile";
import "./style.scss";

const MoviesNowShowing = () => {
    const { nowShowing, isMoviesLoading } = useContext(AuthContext);

    if (isMoviesLoading) {
        return (
            <Loader isLoading={isMoviesLoading} />
        );
    }
    if (nowShowing.length === 0) {
        return (
            <div className="movie-list__movies">
                <p className="movie-list__no-movies">There was an error obtaining the Now Showing movies. Please try again.</p>
            </div>
        );
    }

    return (
        <>
            {nowShowing.length > 0 && (
                <div className="movie-list__movies">
                    {nowShowing.map((movie) => (
                        <div className="movie-list__movie-wrapper" key={movie.movie_id}>
                            <NowShowingTile movie={movie} />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default MoviesNowShowing;
