import React from "react";


const UserProfileIcon = ({ className = "" }) => {
    return (
        <svg className={className} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.9543 1 1 9.9543 1 21C1 32.0457 9.9543 41 21 41Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M20.9996 21.6661C24.436 21.6661 27.2218 18.8803 27.2218 15.4439C27.2218 12.0075 24.436 9.22168 20.9996 9.22168C17.5631 9.22168 14.7773 12.0075 14.7773 15.4439C14.7773 18.8803 17.5631 21.6661 20.9996 21.6661Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M6.55664 34.778C9.22331 30.778 14.7789 28.1113 21.0011 28.1113C27.2233 28.1113 32.5566 30.778 35.4455 34.5558" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
        </svg>

    );
};

export default UserProfileIcon;
