import React, { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { API_BASE_URL } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import Loader from "@components/Ui/Loader";
import "./style.scss";

const PrivacyCollection = () => {
    const { subscriberGroupId } = useContext(ApplicationContext);
    const [privacyCollectionNotice, setPrivacyCollectionNotice] = useState("");
    const [{ data, loading }] = useAxios(`${API_BASE_URL}/subscriber-group-data-collection/${subscriberGroupId}`);

    useEffect(() => {
        if (data) {
            setPrivacyCollectionNotice(data.data.data_collection_text);
        }
    }, [data]);

    if (loading) {
        return (
            <Loader isLoading={loading} />
        );
    }


    return (
        <div className="privacy-collection-notice__wrapper">
            {privacyCollectionNotice ? (
                // eslint-disable-next-line react/no-danger
                <div className="privacy-collection-notice__content" dangerouslySetInnerHTML={{ __html: privacyCollectionNotice }} />
            ) : (<p>Privacy collection notice content to follow</p>)}
        </div>
    );
};

export default PrivacyCollection;
