import React from "react";

const SeatIcon = ({ className="" }) => {
    return (
        <svg className={className}  width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.09093 9.77778V12.2222H18.9091V9.77778C18.9091 7.50444 20.4218 5.61 22.4546 5.06V3.66667C22.4546 1.65 20.8591 0 18.9091 0H7.09093C5.14093 0 3.54547 1.65 3.54547 3.66667V5.06C5.5782 5.61 7.09093 7.50444 7.09093 9.77778Z" fill="currentColor"/>
            <path d="M23.6364 7.3335C22.3364 7.3335 21.2727 8.4335 21.2727 9.77794V14.6668H4.72727V9.77794C4.72727 8.4335 3.66364 7.3335 2.36364 7.3335C1.06364 7.3335 0 8.4335 0 9.77794V15.8891C0 17.9057 1.59545 19.5557 3.54545 19.5557V20.7779C3.54545 21.4502 4.07727 22.0002 4.72727 22.0002C5.37727 22.0002 5.90909 21.4502 5.90909 20.7779V19.5557H20.0909V20.7779C20.0909 21.4502 20.6227 22.0002 21.2727 22.0002C21.9227 22.0002 22.4545 21.4502 22.4545 20.7779V19.5557C24.4045 19.5557 26 17.9057 26 15.8891V9.77794C26 8.4335 24.9364 7.3335 23.6364 7.3335Z" fill="currentColor"/>
        </svg>

    );
};

export default SeatIcon;
