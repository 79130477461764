import React from "react";

const EventLogo = ({ fillColour="white" }) => {
    return (
        <svg className="voucher__img" width="101" height="20" viewBox="0 0 101 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.5757 4.2471V18.5843H59.0804V15.2381H45.176V10.296H56.806V7.20721H45.176V3.3462H59.0804V0H43.0826L40.5757 4.2471ZM4.60029 10.296H16.2303V7.20721H4.60029V3.3462H18.2462L16.3595 0H0V18.5843H18.5046V15.2381H4.60029V10.296ZM75.0005 11.8662L64.1717 0H60.7344V18.5843H64.8695V6.71815L75.6725 18.5843H79.1356V0H75.0005V11.8662ZM80.7897 0V3.39769H88.8531V18.5843H92.9366V3.39769H101V0H80.7897Z" fill={fillColour} />
            <path d="M36.3114 0L29.5402 11.5573L23.0274 0H18.1945L27.1108 15.7786L29.5402 20L31.9954 15.7786L41.2218 0H36.3114Z" fill={fillColour} />
        </svg>
    );
};

export default EventLogo;
