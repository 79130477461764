import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import toSeoUrl from "@functions/toSeoUrl";
import useViewport from "@hooks/useViewport";
import { ApplicationContext } from "@context/ApplicationContext";
import PlayIcon from "@components/Icons/PlayIcon";
import TicketIcon from "@components/Icons/TicketIcon";
import TrailerButton from "@components/TrailerButton";
import "./style.scss";

const ComingSoonTile = ({ movie }) => {
    const { width } = useViewport();
    const blurbBreakpoint = 640;
    const { cdnUrl } = useContext(ApplicationContext);

    const [isActive, setIsActive] = useState(false);
    const fallbackImage = `${cdnUrl}asset/img/placeholder.jpeg`;

    const onImageError = (e) => {
        e.target.src = fallbackImage;
    };

    const handleClick = () => {
        setIsActive(!isActive);
    };

    const formattedDate = movie.release_date
        ? new Date(movie.release_date).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
        })
        : "Release date to be announced";
    
    return (
        <div className="coming-soon-tile__item">
            <Link
                className="coming-soon-tile__image-wrapper"
                to={{ pathname: `/movie/${movie.id}/${toSeoUrl(movie.title,)}` }}
            >
                <img
                    className="coming-soon-tile__image"
                    src={movie.movie_image?.[0]?.original_url || fallbackImage}
                    alt={movie.title}
                    onError={onImageError}
                />
            </Link>

            <div className="coming-soon-tile__content">
                <Link
                    className="coming-soon-tile__title"
                    to={{ pathname: `/movie/${movie.id}/${toSeoUrl(movie.title,)}` }}
                >
                    {movie.title}
                </Link>

                {movie.release_date ? (
                    <div className="coming-soon-tile__release-date">{formattedDate}</div>
                ): null}
                <div className="coming-soon-tile__meta">
                    {movie.rating ?(
                        <>
                            <span
                                className={`coming-soon-tile__movie-rating coming-soon-tile__movie-rating--${movie?.rating.replace(
                                        "+",
                                        "",
                                    )}`}
                                role="status"
                                aria-label="Movie Rating"
                                >
                                {movie?.rating}
                            </span>
                            <span> · {"  "}</span>
                        </>
                    ): null}
                    {movie.running_time ? (
                        <span>{movie.running_time}mins · {" "}</span>
                    ): null}
                    {movie.bo_genres[0] ? (
                        <span>{movie.bo_genres[0].genre_name}</span>
                    ): null}

              
                </div>
                
                
                { width >= blurbBreakpoint && (
                    <button 
                        className={`coming-soon-tile__description ${isActive ? "active" : ""}`}
                        onClick={handleClick}
                    >
                        {movie.bo_reviews[0]?.review_description}
                    </button>
                )}

                <div className="coming-soon-tile__actions">
                    {(movie.ts_sessions && movie?.ts_sessions.length > 0) && (
                        <Link
                            className="coming-soon-tile__trailer"
                            to={{ pathname: `/movie/${movie.id}/${toSeoUrl(movie.title,)}` }}
                        >
                            <TicketIcon className="coming-soon-tile__icon-svg" />
                            <span className="coming-soon-tile__trailer-text">
                                Book Now
                            </span>
                        </Link>
                    )}

                    {movie.trailer_url ? (
                        <TrailerButton
                            extraClass="coming-soon-tile__trailer"
                            type="mp4"
                            trailerLink={movie.trailer_url}
                        >
                            <PlayIcon className="coming-soon-tile__icon-svg" />

                            <span className="coming-soon-tile__trailer-text">
                                Trailer
                            </span>
                        </TrailerButton>
                    ) : null}

                </div>
            </div>
        </div>
    );
};

export default ComingSoonTile;
