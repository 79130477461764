import React from "react";
import * as Sentry from "@sentry/react";
import ReactDom from "react-dom";
import ViewportProvider from "@context/ViewportContext";
import App from "@components/App";


const {
    brandCode = "",
    subscriberGroupId = 0,
    publicSubscriberGroup = 0,
    productTypeId = 0,
    cdnUrl = null,
    gaCode = null,
    sentryDsn = null,
} = document.getElementById("root").dataset;

Sentry.init({
    dsn: "https://46c0969978134787b835ad495ca27b68@o1294733.ingest.us.sentry.io/6598565",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.2,
    tracePropagationTargets: ["localhost"],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5,
});

ReactDom.render(
    <ViewportProvider>
        <App
            brandCode={brandCode}
            subscriberGroupId={parseInt(subscriberGroupId)}
            productTypeId={parseInt(productTypeId)}
            publicSubscriberGroup={parseInt(publicSubscriberGroup)}
            cdnUrl={cdnUrl}
            gaCode={gaCode}
        />
    </ViewportProvider>,
    document.getElementById("root"),
);
