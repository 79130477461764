import React, { useContext } from "react";
import useViewport from "@hooks/useViewport";
import { ApplicationContext } from "@context/ApplicationContext";
import Footer from "@components/Footer";
import Navigation from "@components/Navs/Navigation";
import TopBanner from "@components/TopBanner";

const Layout = ({ children }) => {
    const { topBanner } = useContext(ApplicationContext);
    const { width } = useViewport();
    const desktopBreakpoint = 1220;

    return (
        <>
            <div className="main">
                <TopBanner></TopBanner>
                {topBanner && topBanner.left_side_image_url ? (
                    <>
                        {width > desktopBreakpoint ? (
                            <div style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                            }}>
                                <div className="main__side">
                                    <img
                                        key={topBanner?.id}
                                        alt={topBanner.left_side_image_url}
                                        src={topBanner.left_side_image_url}
                                        className="top-banner__image"
                                    />
                                </div>
                            </div>
                        ) : null}
                    </>
                ) : null}
                <div className="main__wrapper">
                    <div className="main__container">
                        <Navigation />
                        <div className="main__inner container">
                            {children}
                        </div>
                        <Footer />
                    </div>
                </div>
                {topBanner && topBanner.right_side_image_url ? (
                    <>
                        {width > desktopBreakpoint ? (
                            <div style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                            }}>
                                <div className="main__side">
                                    <img
                                        key={topBanner?.id}
                                        alt={topBanner.right_side_image_url}
                                        src={topBanner.right_side_image_url}
                                        className="top-banner__image"
                                    />
                                </div>
                            </div>
                        ) : null}

                    </>

                ) : null}
            </div>
        </>
    );
};

export default Layout;
