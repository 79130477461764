import React, { useContext, useEffect } from "react";
import { AUTH_JWT } from "@config/config";
import flattenErrorMessages from "@functions/flattenErrorMessages";
import { ApplicationContext } from "@context/ApplicationContext";
import { ProfileContext } from "@context/ProfileContext";
import AlertMessage from "@components/AlertMessage";
import Button from "@components/Ui/Button";
import Spinner from "@components/Ui/Spinner";
import TextInputLabel from "@components/Ui/TextInputLabel";
import "./style.scss";

const AddEmailForm = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const {
        addEmail,
        email,
        setEmail,
        successMessage,
        setSuccessMessage,
        processing,
        setProcessing,
        errorMessages,
        setErrorMessages
    } = useContext(ProfileContext);

    useEffect(() => {
        return () => {
            setErrorMessages({});
            setSuccessMessage(null);
        };
      }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setProcessing(true);
        setErrorMessages({});
        setSuccessMessage(null);
        const data = {
            email_address: email
        };
        addEmail(data);
    };

    const errorsFlattened = flattenErrorMessages(errorMessages);
    const isJwtEmail = whiteLabel?.subscriber_group?.authentication_type === AUTH_JWT;


    return (
        <div className="form__container">
            <form className="form__content" onSubmit={(e) => handleSubmit(e)}>
                <TextInputLabel
                    id="email_address"
                    name="email_address"
                    labelName="Add New Email"
                    type="email"
                    additionalClasses={`${isJwtEmail ? "email__input email__input--disabled" : "email__input"}`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isValueErrored={errorMessages.email != null}
                    disabled={processing === true || isJwtEmail}
                />
                <Button
                    disabled={isJwtEmail}
                    additionalClasses={`button button__primary ${isJwtEmail ? "button--disabled" : ""}`}
                >
                    {processing ? (
                        <Spinner text="loading..." />
                    ) : (
                        <span>Add</span>
                    )}
                </Button>
            </form>
            {successMessage && (
                <AlertMessage
                    message={successMessage}
                    type="success" />
            )}
            {errorsFlattened.length > 0 && (
                <AlertMessage
                    message="The following errors occurred:"
                    type="error"
                    messageList={errorsFlattened}
                />
            )}
        </div>
    );
};

export default AddEmailForm;
