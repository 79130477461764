import React from "react";

const SearchIcon = ({ className = "" }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="51" height="40" viewBox="0 0 51 40" fill="none">
            <path
                d="M36.0161 40H4.5769C3.27285 40 2.18403 39.5595 1.31042 38.6786C0.436806 37.7976 0 36.6996 0 35.3846V4.61536C0 3.30036 0.436806 2.20238 1.31042 1.32143C2.18403 0.440476 3.27285 0 4.5769 0H46.4231C47.7271 0 48.816 0.440476 49.6896 1.32143C50.5632 2.20238 51 3.30036 51 4.61536V35.3846C51 36.6996 50.5632 37.7976 49.6896 38.6786C48.816 39.5595 47.7271 40 46.4231 40H44.0911L30.4474 26.2418C29.5647 27.0184 28.5903 27.6008 27.5242 27.989C26.4581 28.3773 25.3111 28.5714 24.0833 28.5714C21.3408 28.5714 19.0024 27.5962 17.0681 25.6456C15.1338 23.6951 14.1667 21.337 14.1667 18.5714C14.1667 15.8059 15.1338 13.4478 17.0681 11.4972C19.0024 9.54669 21.3408 8.57143 24.0833 8.57143C26.8259 8.57143 29.1643 9.54669 31.0986 11.4972C33.0329 13.4478 34 15.8059 34 18.5714C34 19.6007 33.8892 20.5403 33.6676 21.3901C33.4461 22.24 33.0047 23.1465 32.3436 24.1099L45.268 37.1429H46.4231C46.859 37.1429 47.2585 36.9597 47.6218 36.5934C47.9851 36.2271 48.1667 35.8242 48.1667 35.3846V4.61536C48.1667 4.17583 47.9851 3.77291 47.6218 3.40657C47.2585 3.04029 46.859 2.85714 46.4231 2.85714H4.5769C4.14104 2.85714 3.74146 3.04029 3.37818 3.40657C3.01495 3.77291 2.83333 4.17583 2.83333 4.61536V35.3846C2.83333 35.8242 3.01495 36.2271 3.37818 36.5934C3.74146 36.9597 4.14104 37.1429 4.5769 37.1429H33.1827L36.0161 40ZM24.0833 25.7143C26.0667 25.7143 27.7431 25.0238 29.1125 23.6429C30.4819 22.2619 31.1667 20.5714 31.1667 18.5714C31.1667 16.5714 30.4819 14.881 29.1125 13.5C27.7431 12.119 26.0667 11.4286 24.0833 11.4286C22.1 11.4286 20.4236 12.119 19.0542 13.5C17.6847 14.881 17 16.5714 17 18.5714C17 20.5714 17.6847 22.2619 19.0542 23.6429C20.4236 25.0238 22.1 25.7143 24.0833 25.7143Z"
                fill="currentColor" />
        </svg>
    );
};

export default SearchIcon;
