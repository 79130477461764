import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "@context/UserContext";
import TicketList from "@components/Tickets/TicketList";
import Button from "@components/Ui/Button";
import Loader from "@components/Ui/Loader";
import "./style.scss";

const TicketHistory = () => {
    const { fetchTickets, purchasedTickets, isLoading } = useContext(UserContext);
    const [showCurrent, setShowCurrent] = useState(true);

    useEffect(() => {
        fetchTickets();
    }, []);

    if (isLoading === true) {
        return (
            <Loader isLoading={isLoading} />
        );
    }

    const displayTickets =  showCurrent
        ? purchasedTickets.current
        : purchasedTickets.history;

    const pageTitle = showCurrent
        ? "Current"
        : "History";

    const emptyMessage = showCurrent
        ? "You have not purchased any movies tickets yet."
        : "You do not have any purchases in your history yet.";

    const currentTicketButtonClass = showCurrent
        ? "ticket-history__button-selected"
        : "ticket-history__button";

    const historyTicketButtonClass = !showCurrent
        ? "ticket-history__button-selected"
        : "ticket-history__button";

    return (
        <div className="ticket-history">
            <div className="ticket-history__header">
                <h1 className="ticket-history__heading">
                    {pageTitle}
                </h1>
                <div className="ticket-history__buttons">
                    <Button
                        additionalClasses={`button ticket-history__button--left ${currentTicketButtonClass}`}
                        onClick={() => setShowCurrent(true)}>
                        Current
                    </Button>
                    <Button
                        additionalClasses={`button ticket-history__button--right ${historyTicketButtonClass}`}
                        onClick={() => setShowCurrent(false)}>
                        History
                    </Button>
                </div>
            </div>
            <TicketList
                tickets={displayTickets}
                emptyMessage={emptyMessage}
            />
        </div>
    );
};

export default TicketHistory;
