import React from "react";
import VenueDetails from "@components/VenueDetails";
import Layout from "@components/Wrappers/Layout";

const VenueDetailPage = () => {
    return (
        <Layout>
            <VenueDetails />
        </Layout>
    );
};

export default VenueDetailPage;
