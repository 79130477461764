import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const BookedTicketWarning = () => {
    return (
        <div className="booking__warning">
            You have already purchased ticket for this session. <br />Please
            {" "}
            <Link className="link link__primary" to="/user/current-tickets">click here</Link>
            {" "}
            to see your purchased ticket(s).
        </div>
        );
};

export default BookedTicketWarning;
