import React from "react";
import {
    VILLAGE_CINEMAS,
    EVENT_CINEMAS,
    HOYTS_CINEMAS,
    LUNA_CINEMAS,
    PALACE_CINEMAS,
    READING_CINEMAS,
    CLASS_GOLD,
    CLASS_PREMIUM,
    ORANA_CINEMAS,
} from "@config/config";
import EventLogo from "@components/CinemaGroupLogo/EventLogo";
import HoytsLogo from "@components/CinemaGroupLogo/HoytsLogo";
import LunaLogo from "@components/CinemaGroupLogo/LunaLogo";
import OranaLogo from "@components/CinemaGroupLogo/OranaLogo";
import PalaceLogo from "@components/CinemaGroupLogo/PalaceLogo";
import ReadingsLogo from "@components/CinemaGroupLogo/ReadingsLogo";
import VillageLogo from "@components/CinemaGroupLogo/VillageLogo";
import "./style.scss";

const VoucherLogo = ( voucher  ) => {
    const { item } = voucher;
    const { tile_class, intro, name, ticket_class, cinema_group_id } = item;
    let CinemaGroupLogo;

    switch (cinema_group_id) {
        case HOYTS_CINEMAS:
            CinemaGroupLogo = HoytsLogo;
            break;
        case VILLAGE_CINEMAS:
            CinemaGroupLogo = VillageLogo;
            break;
        case EVENT_CINEMAS:
            CinemaGroupLogo = EventLogo;
            break;
        case LUNA_CINEMAS:
            CinemaGroupLogo = LunaLogo;
            break;
        case PALACE_CINEMAS:
            CinemaGroupLogo = PalaceLogo;
            break;
        case READING_CINEMAS:
            CinemaGroupLogo = ReadingsLogo;
            break;
        case ORANA_CINEMAS:
            CinemaGroupLogo = OranaLogo;
            break;
        default:
            CinemaGroupLogo = () => null;
            break;
    }

    const logoFillColor =
        ticket_class === CLASS_GOLD
            ? "gold"
            : ticket_class === CLASS_PREMIUM
                ? "black"
                : "white";

    return (
        <div className={`voucher__logo-tile voucher__tile--${tile_class}`}>
            <div className="voucher__logo">
                <CinemaGroupLogo fill={logoFillColor}/>
            </div>

            <div className="voucher__title">{intro || name}</div>
        </div>
    );
};

export default VoucherLogo;
