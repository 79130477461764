import React, { useContext, useState } from "react";
import { ApplicationContext } from "@context/ApplicationContext";
import AcknowledgementOfCountry from "@components/AcknowledgementOfCountry";
import PoweredBy from "@components/PoweredBy";
import Logo from "@components/Ui/Logo";

const MinimalLayout = ({ children }) => {
    const { whiteLabel } = useContext(ApplicationContext);
    const className = ["nav__main-container", whiteLabel?.show_search_banner === true ? "nav__main-container--with-banner" : ""].join(" ");

    return (
        <>
            <div className="main">
                <div className="main__wrapper">
                    <div className="main__container">
                        <div className={className}>
                            <div className="container">
                                <nav className="layout__minimal--nav">
                                    <Logo />
                                </nav>
                            </div>
                        </div>

                        <div className="layout__minimal--main container">
                            {children}
                        </div>

                        <footer className="footer container">
                            <div className="legal__container">
                                <a className="legal__link" href={`mailto:${whiteLabel?.support_email}`}>Contact Us</a>
                            </div>
                            <PoweredBy />
                            <AcknowledgementOfCountry />
                        </footer>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MinimalLayout;
