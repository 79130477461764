import React from "react";
import { Link } from "react-router-dom";
import useViewport from "@hooks/useViewport";

const CinemaNearMeAboutUs = ({ mobileImage, desktopImage, whiteLabel }) => {
    const { width } = useViewport();
    const breakpoint = 768;
    return (
        <div className="about-us__container">
            <div className="about-us__img">
                {width < breakpoint ? (
                    <img alt="image of cinemanearme" src={mobileImage} className="about-us__col--thumbnail" />
                ) : (
                    <img alt="image of cinemanearme" src={desktopImage} className="about-us__col--thumbnail" />
                )}
            </div>
            <h1 className="main-heading">About Us</h1>
            <div className="about-us__wrapper">
                <div className="about-us__col">
                    <p className="paragraph">
                        At cinemanearme, our mission is to empower and support Australian independent cinemas by connecting them with new customers and providing movie-goers with a seamless experience in discovering and accessing quality independent cinemas, regardless of their location.
                        We strive to be the ultimate one-stop-shop platform, bridging the gap between cinema enthusiasts and the vibrant world of both blockbuster and independent films.
                    </p>
                    <p className="paragraph">We are dedicated to promoting and preserving the unique charm and cultural significance of independent cinemas across Australia.
                        By curating an extensive collection of diverse films, we aim to showcase the incredible talent and creativity that thrives within the independent film industry. Through our platform, we seek to foster a sense of community among cinema lovers, fostering an appreciation for the
                        art of storytelling and the magic of the big screen.</p>
                    <p className="paragraph">For independent cinemas, we provide a comprehensive suite of tools and services that enable them to reach a broader audience, amplify their visibility, and enhance customer retention.
                        By offering a centralised hub for cinema listings, ticket purchases, and personalised recommendations, we empower these cinemas to thrive in a competitive landscape, ensuring their continued success and growth.</p>
                </div>
                <div className="about-us__col">
                    <p className="paragraph">For movie-goers, cinemanearme is the go-to destination for discovering and accessing the best independent cinemas, wherever they may be.
                        We understand the desire for diverse cinematic experiences and the joy of stumbling upon hidden gems.
                        Our platform provides an intuitive and user-friendly interface, offering detailed information about screenings, film synopses, and user reviews.
                        Through our <Link className="about-us__link" to="/movie-club-membership">Movie Club membership</Link>, we aim to create a seamless and engaging journey for movie enthusiasts, helping them find their next cinematic adventure effortlessly.
                    </p>
                    <p className="paragraph">At cinemanearme, we are driven by our passion for cinema and our belief in the power of independent storytelling.
                        We are committed to promoting the art of film and fostering a thriving cinema ecosystem in Australia. By connecting cinemas and movie-goers, we aspire to enrich lives, inspire creativity, and create memorable experiences that celebrate the magic of the big screen.</p>
                </div>
            </div>
            <div className="about-us__link-wrapper">
                <span className="about-us__link-text"> Contact Us: </span>
                <a className="about-us__link" href={`mailto:${whiteLabel?.support_email}`}>info@cinemanearme.com</a>
            </div>
        </div>
    );
};
export default CinemaNearMeAboutUs;
