import React, { useContext } from "react";
import { ApplicationContext } from "@context/ApplicationContext";
import "./style.scss";

const Logo = () => {
    const { siteConfig, cdnUrl } = useContext(ApplicationContext);

    const logoUrl = cdnUrl && siteConfig?.logo.startsWith("data:image") === false
        ?  cdnUrl + siteConfig?.logo
        : siteConfig?.logo;

    return (
        <div className="nav-logo__container">
            <img src={logoUrl} alt={siteConfig?.logo_alt_text} className="nav-logo__img" />
        </div>
       
    );
};

export default Logo;
