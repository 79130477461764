import React, { useContext, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { PRODUCT_TYPE_MOVIE_REWARDS } from "@config/config";
import groupSessionsByVenueMovie from "@functions/groupSessionsByVenueMovie";
import { ApplicationContext } from "@context/ApplicationContext";
import { SessionContext } from "@context/SessionContext";
import { VenueContext } from "@context/VenueContext";
import AccessibleIcon from "@components/Icons/AccessibleIcon";
import MapsMarkerIcon from "@components/Icons/MapsMarkerIcon";
import MarkerIcon from "@components/Icons/MarkerIcon";
import ScreenIcon from "@components/Icons/ScreenIcon";
import SeatIcon from "@components/Icons/SeatIcon";
import SearchResults from "@components/SearchResults";
import Loader from "@components/Ui/Loader";
import "./style.scss";

const VenueDetails = () => {
    const { id, venue_name } = useParams();
    const {
        sessions,
        fetchSessions,
        setIsLoadedSessions,
        setVenueName,
    } = useContext(SessionContext);
    const { venue, fetchVenue, isLoading } = useContext(VenueContext);
    const {
        accessibility,
        address,
        audio_description,
        city,
        close_caption,
        description,
        email,
        latitude,
        longitude,
        name,
        open_caption,
        phone,
        postcode,
        screens,
        seats,
        state,
        large_image_url,
        website,
    } = venue;

    const venueAddress = `${address}, ${city}, ${state}, ${postcode}`;
    const { whiteLabel, cdnUrl, productTypeId } = useContext(ApplicationContext);
    const fallbackImage = `${cdnUrl}asset/img/placeholder.jpeg`;
    const imageUrl = venue.large_image_url ? large_image_url : fallbackImage;

    const onImageError = (e) => {
        e.target.src = fallbackImage;
    };

    const brand = whiteLabel?.brand_name;


    useEffect(() => {
        fetchVenue(id, venue_name);
    }, [id, venue_name]);

    const sessionsGrouped = sessions
        .reduce(groupSessionsByVenueMovie, [])
        .sort((a, b) => a.venue_distance - b.venue_distance);
    

    useEffect(() => {
        setVenueName(name);
        if (name) {
            setIsLoadedSessions(true);
            fetchSessions({
                venueName: name,
            });
        }
    }, [name]);


    if (isLoading === true) {
        return (
            <Loader isLoading={isLoading} />
        );
    }

    return (
        <>
            <Helmet>
                <title>{`${name} | Cheap Movie Tickets at ${brand}`}</title>
                <meta name="keywords" content={`${name} | Cheap Movie Tickets at ${brand}`} />
                <meta name="description" content={`At ${brand}, you can receive discounted
                movie tickets for the best showtimes at ${name}. Get more information
                about your local cinema today!`} />
                <link rel="canonical" />
            </Helmet>
            <div className="venue__container">
                <div className="venue__image-container">
                    {!isLoading && (
                        <img
                            className="venue__image"
                            src={imageUrl}
                            style={{
                                height: "auto",
                                width: "100%",
                                objectFit: "contain",
                            }}
                            alt={name}
                            onError={onImageError}
                        />
                    )}
                </div>
                <div className="venue__detail">
                    <h1 className="venue__title">{name}</h1>
                    <div className="venue__description">
                        {description}
                    </div>
                    <div className="venue__details">
                        {website && <div className="venue__details-item">Website: <a href={website} target="_blank"
                            rel="noopener noreferrer"
                            className="venue__details-item--field">{website}</a>
                        </div>}
                        {phone && <div className="venue__details-item">Phone: <span
                            className="venue__details-item--field">{phone}</span></div>}
                        {email && <div className="venue__details-item">Email: <span
                            className="venue__details-item--field">{email}</span></div>}
                    </div>
                </div>
                <div className="venue__information">
                    <div className="venue__map-wrap">
                        {latitude && longitude && (
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: "AIzaSyC4N1BHxxWohTt7moat_Mq3x5Gw5U6Cvqw" }}
                                className="map"
                                yesIWantToUseGoogleMapApiInternals
                                region="au"
                                zoom={11}
                                center={{ lat: Number(latitude), lng: Number(longitude) }}
                            >
                                <MapsMarkerIcon lat={latitude} lng={longitude} className="venue__marker" />
                            </GoogleMapReact>
                        )}

                    </div>
                    <div className="venue__address-wrapper">
                        <MarkerIcon className="venue__icon" />
                        <a
                            className="venue__address"
                            target="_blank"
                            rel="noreferrer"
                            href={encodeURI(
                                `https://www.google.com/maps/place/${venueAddress}`,
                            )}
                        >{venueAddress}
                        </a>
                    </div>
                    <div className="venue__items">
                        {screens ? (
                            <div className="venue__item"><ScreenIcon
                                className="venue__icon" />{`${screens > 1 ? (`${screens} Screens`) : (`${screens} Screen`)}`}
                            </div>
                        ) : null
                        }

                        {accessibility === true &&
                            <div className="venue__item"><AccessibleIcon className="venue__icon" />Accessible</div>}
                        {seats ? (
                            <div className="venue__item"><SeatIcon
                                className="venue__icon" />{`${seats > 1 ? (`${seats} Seats`) : (`${seats} Seat`)}`}</div>
                        ) : null
                        }
                        <div className="venue__item">
                            <div className="venue__sub-items">
                                {open_caption === true && <div className="venue__sub-item">OC</div>}
                                {close_caption === true && <div className="venue__sub-item">CC</div>}
                                {audio_description === true && <div className="venue__sub-item">AD</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(productTypeId === PRODUCT_TYPE_MOVIE_REWARDS && sessionsGrouped.length) ? (
                <>
                    <h2 className="venue__sub-title">Now Showing</h2>
                    <div className="venue__listings">
                        <SearchResults items={sessionsGrouped} />
                    </div>
                </>
            ) : null}

        </>
    );
};

export default VenueDetails;
