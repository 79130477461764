const reducer = (state, action) => {
    switch (action.type) {
        case "is_terms_checked": {
            return {
                ...state,
                isTermsChecked: !state.isTermsChecked,
            };
        }
        case "is_save_card_checked": {
            return {
                ...state,
                isSaveCardChecked: !state.isSaveCardChecked,
            };
        }
        case "is_use_saved_card_checked": {
            return {
                ...state,
                isUseSavedCardChecked: action.isUseSavedCardChecked,
            };
        }
        case "is_login_with_temp": {
            return {
                ...state,
                isTempLogin: !state.isTempLogin,
            };
        }
        case "is_submitting": {
            return {
                ...state,
                isSubmitting: action.isSubmitting,
            };
        }
        case "errors": {
            return {
                ...state,
                errors: action.errors,
            };
        }
        case "member_id": {
            return {
                ...state,
                member_id: action.member_id,
            };
        }

        case "credit_card_name": {
            return {
                ...state,
                name: action.name,
            };
        }
        case "credit_card_num": {
            return {
                ...state,
                number: action.number,
            };
        }
        case "credit_card_exp_mth": {
            return {
                ...state,
                expiryMonth: action.expiryMonth,
            };
        }
        case "credit_card_exp_yr": {
            return {
                ...state,
                expiryYear: action.expiryYear,
            };
        }
        case "credit_card_cvn": {
            return {
                ...state,
                cvn: action.cvn,
            };
        }
        case "credit_card_username": {
            return {
                ...state,
                username: action.username,
            };
        }
        case "credit_card_first_name": {
            return {
                ...state,
                firstName: action.firstName,
            };
        }
        case "credit_card_last_name": {
            return {
                ...state,
                lastName: action.lastName,
            };
        }
        case "eway_redirect_url": {
            return {
                ...state,
                ewayRedirectUrl: action.ewayRedirectUrl,
            };
        }
        case "credit_card_number": {
            return {
                ...state,
                cardNumber: action.cardNumber,
            };
        }
        case "eway_transaction_status": {
            return {
                ...state,
                ewayTransactionStatus: action.ewayTransactionStatus,
            };
        }
    }
    throw Error(`Unknown action: ${action.type}`);
};

export default reducer;


