import { useContext, useEffect } from "react";
import { SessionContext } from "@context/SessionContext";

const useTrackingViewItem = () => {
    const { session } = useContext(SessionContext);

    useEffect(() => {
        if (session) {
            const gaEvent = {
                event: "view_item",
                ecommerce: {
                    items: [
                        {
                            item_id: `session-id-${session.id}`,
                            item_name: `${session.venue_name} - ${session.movie_title} - ${session.session_date}`,
                            currency: "AUD",
                            price: (session.ticket_price_cents / 100).toFixed(2),
                            quantity: 1,
                        }
                    ]
                }
            };

            console.log({
                session,
                gaEvent,
            });

            // assumes window.dataLayer is initialized in usePageTracking()
            if (Array.isArray(window.dataLayer)) {
                window.dataLayer.push(gaEvent);
            }
        }
    }, [session]);
};

export default useTrackingViewItem;
