import React, { useContext } from "react";
import { ApplicationContext } from "@context/ApplicationContext";
// import Button from "@components/Ui/Button";
import "./style.scss";

const Membership = ({ memberData }) => {

    const { whiteLabel } = useContext(ApplicationContext);
    const brand = whiteLabel?.brand_name;


    if (!memberData) {
        return null;
    }

    const { membership_type, membership_expiry_date, membership_price, membership_purchase_date } = memberData;


    return (
        <div className="membership__wrapper">
            <div className="membership__content">
                <div className="membership__block">
                    <h2 className="membership__title">{brand} Movie Club</h2>
                    <div className="membership__text-wrapper">
                        <p className="membership__text">Your Limited Edition Pass to Monthly Cinematic Bliss!</p>
                    </div>

                    <p className="membership__paragraph">Purchase Date: {new Date(membership_purchase_date).toLocaleDateString()}</p>
                    <p className="membership__paragraph">Membership period: {new Date(membership_purchase_date).toLocaleDateString()} - {new Date(membership_expiry_date).toLocaleDateString()}</p>
                </div>
                <div className="membership__col">
                    <h2 className="movie-club__cta-title"> {membership_type} - ${membership_price}</h2>
                </div>
            </div>
        </div>
    );
};

export default Membership;
