import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ACCOUNT_TYPE_NORMAL, ACCOUNT_TYPE_TEMPORARY, AUTH_APPLICATION_SECURITY, AUTH_JWT, AUTH_PARTNER_INTEGRATION, PRODUCT_TYPE_MOVIE_REWARDS } from "@config/config";
import { useDetectOutsideClick } from "@hooks/useDetectOutsideClick";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { MembershipContext } from "@context/MembershipContext";
import DropdownMenu from "@components/Navs/DropdownMenu";

const DesktopMenu = () => {
    const dropDownRef = useRef(null);
    const [isOpen, setIsOpen] = useDetectOutsideClick(dropDownRef, false);
    const { publicSubscriberGroup, productTypeId, whiteLabel } = useContext(ApplicationContext);
    const { user, isLoggedIn, logout } = useContext(AuthContext);
    const { membershipOptions, fetchMembershipInformation } = useContext(MembershipContext);
    const [isNavCollapsed, setIsNavCollapsed] = useState(false);
    const partnerAuthFlag = whiteLabel?.subscriber_group?.authentication_type === AUTH_PARTNER_INTEGRATION;
    const jwtAuthFlag = whiteLabel?.subscriber_group?.authentication_type === AUTH_JWT;
    const appAuthFlag = whiteLabel?.subscriber_group?.authentication_type === AUTH_APPLICATION_SECURITY;

    useEffect(() => {
        if (user?.account_type_id === ACCOUNT_TYPE_NORMAL && user?.id) {
            fetchMembershipInformation(user.id);
        }
    }, [user?.id]);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const renderAuthenticatedMenu = () => (
        <DropdownMenu>
            <div className="dropdown-menu__container">
                {jwtAuthFlag && (
                    <>
                        <Link to="/user/email-add" className="dropdown-menu__link" onClick={() => setIsOpen(!isOpen)}>
                            Account Email
                        </Link>
                        <Link
                            to={productTypeId === PRODUCT_TYPE_MOVIE_REWARDS ? "/user/current-tickets" : "/user/current-vouchers"}
                            className="dropdown-menu__link"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            Purchases
                        </Link>
                    </>
                )}
                {!jwtAuthFlag && (
                    <>
                        <div className="dropdown-menu__name">{`${user.first_name} ${user.last_name}`}</div>
                        <Link to="/user/profile" className="dropdown-menu__link" onClick={() => setIsOpen(!isOpen)}>
                            Account Details
                        </Link>
                        {user?.membership_status === "true" && (
                            <Link to="/user/membership" className="dropdown-menu__link" onClick={() => setIsOpen(!isOpen)}>
                                Membership
                            </Link>
                        )}
                        <Link to="/user/email-add" className="dropdown-menu__link" onClick={() => setIsOpen(!isOpen)}>
                            Manage Email
                        </Link>
                        {!partnerAuthFlag && (
                            <Link to="/user/change-password" className="dropdown-menu__link" onClick={() => setIsOpen(!isOpen)}>
                                Change Password
                            </Link>
                        )}
                        <Link
                            to={productTypeId === PRODUCT_TYPE_MOVIE_REWARDS ? "/user/current-tickets" : "/user/current-vouchers"}
                            className="dropdown-menu__link"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            Purchases
                        </Link>
                        <Link to="/" className="dropdown-menu__link" onClick={logout}>
                            Logout
                        </Link>
                    </>
                )}
            </div>
        </DropdownMenu>
    );

    return (
        <div className="nav__menu">
            {(appAuthFlag || partnerAuthFlag || jwtAuthFlag) && (
                <>
                    <Link to="/" className="nav__link" onClick={handleNavCollapse}>
                        Search
                    </Link>
                    <Link to="/now-showing" className="nav__link" onClick={handleNavCollapse}>
                        Now Showing
                    </Link>
                </>
            )}
            {membershipOptions && (
                <Link to="/movie-club-membership" className="nav__link" onClick={handleNavCollapse}>
                    Movie Club
                </Link>
            )}
            {publicSubscriberGroup !== 0 && (
                <>
                    <Link to="/about" className="nav__link" onClick={handleNavCollapse}>
                        About
                    </Link>
                    <Link to="/news" className="nav__link" onClick={handleNavCollapse}>
                        News
                    </Link>
                </>
            )}
            {(!isLoggedIn || user?.account_type_id === ACCOUNT_TYPE_TEMPORARY) && (appAuthFlag || partnerAuthFlag) && (
                <>
                    <Link to="/login" className="nav__link">
                        Login
                    </Link>
                    <Link to="/signup" className="button button__primary">
                        Sign Up
                    </Link>
                </>
            )}

            {isLoggedIn && user?.account_type_id === ACCOUNT_TYPE_NORMAL && (appAuthFlag || partnerAuthFlag || jwtAuthFlag) && renderAuthenticatedMenu()}
        </div>
    );
};

export default DesktopMenu;
