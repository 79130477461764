import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ACCOUNT_TYPE_NORMAL, ACCOUNT_TYPE_TEMPORARY, AUTH_APPLICATION_SECURITY, AUTH_JWT, AUTH_PARTNER_INTEGRATION } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { MembershipContext } from "@context/MembershipContext";

const MobileMenu = () => {
    const { publicSubscriberGroup, whiteLabel, cdnUrl } = useContext(ApplicationContext);
    const { user, isLoggedIn, logout } = useContext(AuthContext);
    const { membershipOptions, fetchMembershipInformation } = useContext(MembershipContext);
    const [isNavCollapsed, setIsNavCollapsed] = useState(false);

    const partnerAuthFlag = whiteLabel?.subscriber_group?.authentication_type === AUTH_PARTNER_INTEGRATION;
    const jwtAuthFlag = whiteLabel?.subscriber_group?.authentication_type === AUTH_JWT;
    const appAuthFlag = whiteLabel?.subscriber_group?.authentication_type === AUTH_APPLICATION_SECURITY;

    useEffect(() => {
        if (user?.id) {
            fetchMembershipInformation(user?.id);
        }
    }, [user?.id]);

    const handleNavCollapse = () => {
        setIsNavCollapsed(!isNavCollapsed);
    };

    return (
        <>
            {(appAuthFlag || partnerAuthFlag || jwtAuthFlag) && (
                <button
                    className={`menu__button ${isNavCollapsed ? "open" : "closed"}`}
                    type="button"
                    data-toggle="collapse"
                    data-target="#mobilemenu"
                    aria-controls="mobilemenu"
                    aria-expanded={isNavCollapsed}
                    aria-label={isNavCollapsed ? "Close navigation" : "Open navigation"}
                    onClick={handleNavCollapse}
                    style={{
                        mask: isNavCollapsed
                            ? `url('${cdnUrl}asset/img/close.svg') no-repeat 50% 50%`
                            : `url('${cdnUrl}asset/img/hamburger.svg') no-repeat 50% 50%`,
                        WebkitMask: isNavCollapsed
                            ? `url('${cdnUrl}asset/img/close.svg') no-repeat 50% 50%`
                            : `url('${cdnUrl}asset/img/hamburger.svg') no-repeat 50% 50%`,
                    }}
                >
                    <span className="menu__icon"></span>
                </button>
            )}

            <div className={`menu__container ${isNavCollapsed ? "open" : "closed"}`} id="mobilemenu">
                <div className="nav__menu">
                    <Link to="/" onClick={handleNavCollapse} className="nav__link">
                        Search
                    </Link>
                    <Link to="/now-showing" onClick={handleNavCollapse} className="nav__link">
                        Now Showing
                    </Link>

                    {membershipOptions && (
                        <Link to="/movie-club-membership" onClick={handleNavCollapse} className="nav__link">
                            Movie Club
                        </Link>
                    )}

                    {isLoggedIn && user?.account_type_id === ACCOUNT_TYPE_NORMAL && (
                        <>
                            {(appAuthFlag || partnerAuthFlag) && (
                                <>
                                    <Link to="/user/current-tickets" onClick={handleNavCollapse} className="nav__link">
                                        Purchases
                                    </Link>
                                    <Link to="/user/profile" onClick={handleNavCollapse} className="nav__link">
                                        Account Details
                                    </Link>
                                    {user?.membership_status === "true" && (
                                        <Link to="/user/membership" onClick={handleNavCollapse} className="nav__link">
                                            Membership
                                        </Link>
                                    )}
                                    <Link to="/user/email-add" onClick={handleNavCollapse} className="nav__link">
                                        Manage Email
                                    </Link>
                                    {!partnerAuthFlag && (
                                        <Link to="/user/change-password" onClick={handleNavCollapse} className="nav__link">
                                            Change Password
                                        </Link>
                                    )}
                                    <Link to="/" onClick={logout} className="nav__link">
                                        Logout
                                    </Link>
                                </>
                            )}
                            {jwtAuthFlag && (
                                <>
                                    <Link to="/user/current-tickets" onClick={handleNavCollapse} className="nav__link">
                                        Purchases
                                    </Link>
                                    <Link to="/user/email-add" onClick={handleNavCollapse} className="nav__link">
                                        Account Email
                                    </Link>
                                </>
                            )}
                        </>
                    )}

                    {publicSubscriberGroup !== 0 && (
                        <>
                            <Link to="/about" onClick={handleNavCollapse} className="nav__link">
                                About
                            </Link>
                            <Link to="/news" onClick={handleNavCollapse} className="nav__link">
                                News
                            </Link>
                        </>
                    )}

                    {(!isLoggedIn || user?.account_type_id === ACCOUNT_TYPE_TEMPORARY) &&
                        (appAuthFlag || partnerAuthFlag) && (
                            <>
                                <Link to="/login" className="nav__link">
                                    Login
                                </Link>
                                <Link to="/signup" className="button button__primary">
                                    Sign Up
                                </Link>
                            </>
                        )}
                </div>
            </div>
        </>
    );
};

export default MobileMenu;
