import React from "react";

const MarkerIcon = ({ className="" }) => {
    return (
        <svg className={className}  width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 0C2.90643 0 0 2.9735 0 6.65C0 11.6375 6.5 19 6.5 19C6.5 19 13 11.6375 13 6.65C13 2.9735 10.0936 0 6.5 0ZM6.5 9.025C5.21857 9.025 4.17857 7.961 4.17857 6.65C4.17857 5.339 5.21857 4.275 6.5 4.275C7.78143 4.275 8.82143 5.339 8.82143 6.65C8.82143 7.961 7.78143 9.025 6.5 9.025Z" fill="currentColor"/>
        </svg>

    );
};

export default MarkerIcon;
