import React from "react";
import Select from "@components/Select";

const SearchSelect = (props) => {
    return (
        <Select {...props} />
    );
};

export default SearchSelect;
