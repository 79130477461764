import React from "react";


const FormWrapper = ({ children, pageTitle }) => {
 return (
     <div className="form__wrapper">
         {pageTitle ? <h1 className="main-heading">{pageTitle}</h1> : null}
         <div className="form__container">
             {children}
         </div>
     </div>
 );
};

export default FormWrapper;
