import React from "react";
import { Link } from "react-router-dom";
import { ApplicationContext } from "@context/ApplicationContext";
import "./style.scss";

const SelectExperienceSessions = ({ experience, setExperience }) => {
    const { cdnUrl } = React.useContext(ApplicationContext);

    const experiences = [
        { type: "STND", label: "standard", image: "STND.png" },
        { type: "PREM", label: "premium", image: "PREM.png" },
        { type: "GOLD", label: "gold", image: "GOLD.png" }
    ];

    const handleExperienceClick = (exp) => {
        setExperience(exp);
    };

    return (
        <div className="experiences__link-list">
            {experiences.map(({ type, label, image }) => (
                <Link
                    to={`/experiences/${label}`}
                    role="button"
                    key={type}
                    className={`experiences__item-button ${experience === type ? "active" : ""}`}
                    onClick={() => handleExperienceClick(type)}
                >
                    <img className="experiences__image" src={`${cdnUrl}asset/img/${image}`} alt={`${label} Experience`} />
                    <div className={`experiences__item-text experiences__item-text--${type}`}>{label}</div>
                </Link>
            ))}
        </div>
    );
};

export default SelectExperienceSessions;
