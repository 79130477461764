import React, { useState } from "react";
import Button from "@components/Ui/Button";
import "./style.scss";

const LinkTab = ({ label, activeTab, onClick }) => {
    return (
        <Button
            additionalClasses={` link-tab__link ${activeTab === label ? "link-tab__link--active" : ""}`}
            onClick={() => onClick(label)}
        >
            {label}
        </Button>
    );
};

const LinkTabs = ({ children }) => {
    const [activeTab, setActiveTab] = useState(children[0].props.label);

    const onClickTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="link-tabs">
            <div className="link-tabs__link">
                {children.map((child) => {
                    const { label } = child.props;
                    return (
                        <LinkTab
                            key={label}
                            label={label}
                            activeTab={activeTab}
                            onClick={onClickTab}
                        />
                    );
                })}
            </div>
            <div className="link-tabs__content">
                {children.map((child) => {
                    if (child.props.label !== activeTab) return undefined;
                    return child.props.children;
                })}
            </div>
        </div>
    );
};

export default LinkTabs;
