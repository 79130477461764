import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "@config/config";
import { AuthContext } from "@context/AuthContext";
import { getSessionButtonText, getSessionType } from "@utils/ticketUtils";

export const SearchContext = createContext({});

const SearchContextProvider = (props) => {
    const { isLoggedIn } = useContext(AuthContext);
    const [searchOptions, setSearchOptions] = useState([]);
    const [searchType, setSearchType] = useState(null);
    const [isSearchOptionsLoading, setIsSearchOptionsLoading] = useState(false);
    const [activeSearchItem, setActiveSearchItem] = useState(null);
    const [venue, setVenue] = useState({});
    const [movie, setMovie] = useState({});
    const [movieTitle, setMovieTitle] = useState(null);
    const [movieImage, setMovieImage] = useState(null);
    const [venueName, setVenueName] = useState("");
    const [experienceType, setExperienceType] = useState(null);
    const [selectedExperiences, setSelectedExperiences] = useState([]);
    const [sessionDate, setSessionDate] = useState(null);
    const [searchParams] = useState(new URLSearchParams());
    const [isLoadingSessions, setIsLoadingSessions] = useState(false);
    const [session, setSession] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [sessionsByVenue, setSessionsByVenue] = useState([]);
    const [participatingCinemas, setParticipatingCinemas] = useState([]);
    const [prevParams, setPrevParams] = useState({ venueName: null, movieTitle: null, sessionDate: null });
    const { children } = props;
    
    const fetchSessions = ({ venueName, movieTitle, sessionDate, lat, lng } = {}) => {
        if (prevParams.venueName === venueName && prevParams.movieTitle === movieTitle && prevParams.sessionDate === sessionDate) {
            return;
        }
        setPrevParams({ venueName, movieTitle, sessionDate });
        setIsLoadingSessions(true);
        setSessions([]);
        if (venueName) {
            searchParams.set("venue_name", venueName);
        } else {
            searchParams.delete("venue_name");
        }
        if (movieTitle) {
            searchParams.set("movie_title", movieTitle);
        } else {
            searchParams.delete("movie_title");
        }
        if (sessionDate) {
            searchParams.set("session_date", sessionDate);
        } else {
            searchParams.delete("session_date");
        }
        if (lat) {
            searchParams.set("lat", lat);
        } else {
            searchParams.delete("lat");
        }
        if (lng) {
            searchParams.set("lng", lng);
        } else {
            searchParams.delete("lng");
        }

        if (!sessionDate && !venueName && !movieTitle) {
            setIsLoadingSessions(false);
            return;
        }

        axios
            .get(`${API_BASE_URL}/session?${searchParams.toString()}`)
            .then(function(response) {
                const sessionData = response.data.data.map((item) => ({
                    ...item,
                    experience_type: getSessionType(item),
                    venue_image: item.venue_image,
                    ticket_price_cents: parseInt(item.ticket_price_cents),
                    rrp_pricing_cents: item.rrp_pricing_cents,
                }));
                setSessions(sessionData);
                setIsLoadingSessions(false);
                
            })
            .catch((error) => {
                setSessions([]);
                setIsLoadingSessions(false);
                console.log(error);
                if (isLoggedIn && error?.response?.status === 401) {
                    window.location = "/";
                }
            });
    };

    const fetchVenueSessions = ({ venue_name } = {}) => {
        setIsLoadingSessions(true);
        setSessionsByVenue([]);
        axios
            .get(`${API_BASE_URL}/session?venue_name=${venue_name.toString()}`)
            .then(function(response) {
                const sessionData = response.data.data.map((item) => ({
                    ...item,
                    experience_type: getSessionButtonText(item),
                    ticket_price_cents: parseInt(item.ticket_price_cents),
                }));
                setIsLoadingSessions(false);
                setSessionsByVenue(sessionData);
            })
            .catch((error) => {
                setIsLoadingSessions(false);
                setSessionsByVenue([]);
                console.log(error);
            });
    };

    const fetchSearchOptions = () => {
        setIsSearchOptionsLoading(true);
        axios
            .get(`${API_BASE_URL}/search`)
            .then(function(response) {
                const searchOptions = response.data.data.map((item) => ({
                    ...item,
                    label: `${item.name} (${item.type})`,
                    value: item.name,
                    url: item.link_url,
                }));
                setSearchOptions(searchOptions);
                const cinemas = response.data.data.filter(item => item.type === "cinema");
                setParticipatingCinemas(cinemas);
                setIsSearchOptionsLoading(false);
            })
            .catch(({ response }) => {
                if (isLoggedIn && response?.status === 401) {
                    window.location = "/";
                }
                setSearchOptions([]);
                setIsSearchOptionsLoading(false);
            });
    };

    const fetchVenue = (id, venue_name) => {
        axios
            .get(`${API_BASE_URL}/venue/${id}/${venue_name}`)
            .then(function(response) {
                setVenue(response.data.data[0]);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchMovie = (id, movieName) => {
        axios
            .get(`${API_BASE_URL}/movie/${id}/${movieName}`)
            .then(function(response) {
                if (response.status === 200) {
                    const movieData = response.data.data.movie;
                    setMovie(movieData);
                    setMovieTitle(movieData.title);
                    if (movieData?.images?.length > 0 && movieData.images[0]?.local_url) {
                        setMovieImage(movieData.images[0].local_url);
                    } else {
                        setMovieImage(null);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


    return (
        <SearchContext.Provider
            value={{
                fetchSessions,
                venue,
                movie,
                fetchMovie,
                venueName,
                setVenueName,
                fetchVenue,
                fetchSearchOptions,
                isSearchOptionsLoading,
                isLoadingSessions,
                setIsLoadingSessions,
                searchOptions,
                activeSearchItem,
                setActiveSearchItem,
                searchType,
                setSearchType,
                movieTitle,
                setMovieTitle,
                movieImage,
                sessionDate,
                setSessionDate,
                sessions,
                session,
                setSession,
                fetchVenueSessions,
                sessionsByVenue,
                participatingCinemas,
                experienceType,
                setExperienceType,
                selectedExperiences,
                setSelectedExperiences
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export default SearchContextProvider;
