import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ACCOUNT_TYPE_NORMAL } from "@config/config";
import { AuthContext } from "@context/AuthContext";
import { BookSessionContext } from "@context/BookSessionContext";
import AlertMessage from "@components/AlertMessage";
import Button from "@components/Ui/Button";
import Spinner from "@components/Ui/Spinner";
import TextInputLabel from "@components/Ui/TextInputLabel";
import "./style.scss";

const LoginComponent = () => {

    const location = useLocation();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const {
        user,
        loginCheckout,
        isLoginSubmitting,
        isLoggedIn,
        loginError,
        setLoginError,
        setTempAccountLogin,
        successMessage,
        setSuccessMessage,
    } = useContext(AuthContext);
    const { setIsTempAccount } = useContext(BookSessionContext);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        setLoginError(null);
        setIsTempAccount(false);
        setTempAccountLogin(true);
        loginCheckout({ username, password });
    };

    // These should be moved elsewhere, probably as actions
    const handleUsernameOnchange = (e) => {
        setUsername(e.target.value);
        setLoginError(null);
    };
    const handlePasswordOnchange = (e) => {
        setPassword(e.target.value);
        setLoginError(null);
    };

    useEffect(() => {
        setLoginError(null);
        setSuccessMessage(null);
        return () => {
            setLoginError(null);
            setSuccessMessage(null);
        };
    }, []);

    if (isLoggedIn && user.account_type_id === ACCOUNT_TYPE_NORMAL) {
        window.location = location?.state?.from ?? "/";
    }

    const showError = !!(
        loginError != null &&
        username !== "" &&
        password !== ""
    );

    return (
        <>
            <form onSubmit={handleSubmit} className="form__content">
                <TextInputLabel
                    type="email"
                    name="email"
                    labelName="Email"
                    required={true}
                    value={username}
                    onChange={handleUsernameOnchange}
                />

                <TextInputLabel
                    type="password"
                    name="password"
                    labelName="Password"
                    autoComplete="true"
                    required={true}
                    value={password}
                    onChange={handlePasswordOnchange}
                />

                <Link className="link link__primary" to="/request-password-reset">
                    Forgot password?
                </Link>

                <Button
                    additionalClasses="button button__primary"
                >
                    {isLoginSubmitting ? (
                        <Spinner text="loading..." />
                    ) : (
                        <span>Login</span>
                    )}
                </Button>
            </form>

            {successMessage && (
                <AlertMessage
                    message={successMessage}
                    type="success" />
            )}

            {showError && <AlertMessage message={loginError} type="error" />}
        </>
    );
};

export default LoginComponent;
