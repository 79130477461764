import React from "react";
import EmailVerification from "@components/EmailVerification";
import Layout from "@components/Wrappers/Layout";

const EmailVerifyPage = () => {

    return (
        <Layout>
            <EmailVerification />
        </Layout>
    );
};

export default EmailVerifyPage;
