import React, { createContext, useMemo, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "@config/config";

export const VenueContext = createContext({});

const initialVenueState = {
    accessibility: false,
    address: "",
    audio_description: false,
    city: "",
    close_caption: false,
    description: "",
    email: "",
    id: null,
    large_image_url: null,
    thumbnail_url: null,
    latitude: null,
    longitude: null,
    name: "",
    open_caption: false,
    phone: null,
    postcode: "",
    screens: 0,
    seats: 0,
    state: "",
    website: "",
    venue_image: null,
};

const VenueContextProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [venue, setVenue] = useState(initialVenueState);
    const [error, setError] = useState(null);  // New error state

    const fetchVenue = async (id, venue_name) => {
        setIsLoading(true);
        setError(null);  // Reset error state before fetching
        try {
            const response = await axios.get(`${API_BASE_URL}/venue/${id}/${venue_name}`);
            const venueData = response.data.data[0];
            setVenue({
                accessibility: venueData.accessibility,
                address: venueData.address,
                audio_description: venueData.audio_description,
                city: venueData.city,
                close_caption: venueData.close_caption,
                description: venueData.description,
                email: venueData.email,
                id: venueData.id,
                large_image_url: venueData.large_image_url,
                thumbnail_url: venueData.thumbnail_url,
                latitude: parseFloat(venueData.latitude),
                longitude: parseFloat(venueData.longitude),
                name: venueData.name,
                open_caption: venueData.open_caption,
                phone: venueData.phone,
                postcode: venueData.postcode,
                screens: venueData.screens,
                seats: venueData.seats,
                state: venueData.state.toUpperCase(),
                website: venueData.website,
                venue_image: venueData.venue_image,
            });
        } catch (err) {
            console.error(err);
            setError("Failed to fetch venue data.");  // Set error message
        } finally {
            setIsLoading(false);
        }
    };

    const value = useMemo(() => ({
        fetchVenue,
        isLoading,
        venue,
        error,  // Expose error state
    }), [venue, isLoading, error]);

    return (
        <VenueContext.Provider value={value}>
            {children}
        </VenueContext.Provider>
    );
};

export default VenueContextProvider;
