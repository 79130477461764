import React from "react";

const MovieIcon = () => {
    return (
        <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon">
            <path d="M15.2 0L16.929 3.4125C17.005 3.5625 16.891 3.75 16.72 3.75H14.839C14.478 3.75 14.1455 3.55312 13.9935 3.23437L12.35 0H10.45L12.179 3.4125C12.255 3.5625 12.141 3.75 11.97 3.75H10.089C9.728 3.75 9.3955 3.55312 9.2435 3.23437L7.6 0H5.7L7.429 3.4125C7.505 3.5625 7.391 3.75 7.22 3.75H5.339C4.978 3.75 4.6455 3.55312 4.484 3.23437L2.85 0H1.9C0.855 0 0 0.84375 0 1.875V13.125C0 14.1562 0.855 15 1.9 15H17.1C18.145 15 19 14.1562 19 13.125V0.9375C19 0.421875 18.5725 0 18.05 0H15.2Z" fill="currentColor"/>
        </svg>
    );
};

export default MovieIcon;
