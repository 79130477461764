import React from "react";
import format from "date-fns/format";
import "./style.scss";
import { getSessionTextFull } from "@utils/ticketUtils";

// re-format dates as "yyyy/mm/dd hh:mm:ss"
// to prevent safari issues
const cleanupDate = (str) => {
    // format yyyy/mm/dd instead of yyyy-mm-dd
    str = str.replace(/-/g, "/");
    // strip micro seconds
    if (str.indexOf(".") !== -1) {
        str = str.substring(0, str.indexOf("."));
    }
    return str;
};

const SessionDetails = ({ session }) => {

    const sessionDate = session?.session_date?.date
        ? cleanupDate(session.session_date.date)
        : session?.session_date;

    const formattedDate = format(new Date(sessionDate), "do MMM");
    const formattedTime = format(new Date(sessionDate), "h:mm a");
    const movieTitle = session?.movie_title ? <h2 className="session-details__heading">{session?.movie_title}</h2> : null;

    return (
        <div className="session-details">
            {movieTitle}
            <ul className="session-details__list">
                <li className="session-details__item">
                    {formattedTime}, {getSessionTextFull(session)}
                </li>
                <li className="session-details__item">
                    {formattedDate}
                </li>
                <li className="session-details__item">
                    {session?.venue_name}
                </li>
                <li className="session-details__item">
                    {session?.venue_address},<br />{session?.venue_suburb}, {session?.venue_state?.toUpperCase()}
                </li>
            </ul>
        </div>
    );
};

export default SessionDetails;
