import React, { useContext, useEffect } from "react";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import { ACCOUNT_TYPE_NORMAL, ACCOUNT_TYPE_TEMPORARY, API_BASE_URL, PRODUCT_TYPE_VOUCHER_MANAGER } from "@config/config";
import useModal from "@hooks/useModal";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { BookSessionContext } from "@context/BookSessionContext";
import { CheckoutCreditCardContext } from "@context/CheckoutCreditCardContext";
import AlertMessage from "@components/AlertMessage";
import CvnInput from "@components/CreditCardElements/CvnInput";
import ExpiryInput from "@components/CreditCardElements/ExpiryInput";
import LoginComponent from "@components/Form/LoginComponent";
import SignupComponent from "@components/Form/SignupComponent";
import Tabs from "@components/Tabs";
import Button from "@components/Ui/Button";
import Checkbox from "@components/Ui/Checkbox";
import Loader from "@components/Ui/Loader";
import Spinner from "@components/Ui/Spinner";
import TextInput from "@components/Ui/TextInput";
import TextInputLabel from "@components/Ui/TextInputLabel";
import TermsPage from "@pages/TermsPage";
import "./style.scss";

const CheckoutCreditCard = ({
                                uuid,
                                ewayAccessCode = "",
                                ewayFormActionUrl = "",
                            }) => {
    const {
        cardState,
        cardDispatch,
        fetchPaymentResult,
        processPaymentGateway,
        fetchEwayToken,
    } = useContext(CheckoutCreditCardContext);

    const { fetchReInitialiseEway, setUserAuth, setError } = useContext(BookSessionContext);
    const { whiteLabel, productTypeId } = useContext(ApplicationContext);
    const { openModalWithContent, portal } = useModal();
    const { user, isLoggedIn, isReadytoCheckout, tempAccountLogin } = useContext(AuthContext);

    useEffect(() => {
        fetchEwayToken();
    }, []);

    useEffect(() => {
        if (cardState.ewayRedirectUrl) {
            fetchPaymentResult(
                uuid,
                cardState.isSaveCardChecked,
                cardState.isUseSavedCardChecked,
                cardState.isTempLogin,
            );
        }
    }, [cardState.ewayRedirectUrl]);

    useEffect(() => {
        if (cardState.errors.length && !whiteLabel.enable_iframe_payment && isLoggedIn && user.account_type_id === ACCOUNT_TYPE_NORMAL) {
            fetchReInitialiseEway(uuid);
        }
        if (cardState.errors.length && productTypeId === PRODUCT_TYPE_VOUCHER_MANAGER && isLoggedIn && user.account_type_id === ACCOUNT_TYPE_NORMAL) {
            fetchReInitialiseEway(uuid);
        }
    }, [cardState.errors, whiteLabel.enable_iframe_payment]);

    useEffect(() => {
        if (isReadytoCheckout === true && !whiteLabel.enable_iframe_payment && isLoggedIn && user.account_type_id === ACCOUNT_TYPE_NORMAL) {
            fetchReInitialiseEway(uuid);
        }
        if (isReadytoCheckout === true && productTypeId === PRODUCT_TYPE_VOUCHER_MANAGER && isLoggedIn) {
            fetchReInitialiseEway(uuid);
        }
    }, [isReadytoCheckout, whiteLabel.enable_iframe_payment]);

    useEffect(() => {
        if (cardState.ewayTransactionStatus === true && cardState.ewayRedirectUrl) {
            const successUrl = cardState.ewayRedirectUrl.split("?");
            window.location.replace(successUrl[0] ?? cardState.ewayRedirectUrl);
        }
    }, [cardState.ewayTransactionStatus, cardState.ewayRedirectUrl]);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios
            .get(`${API_BASE_URL}/user/check-user`)
            .then(function (response) {
                if (response.data.success) {
                    cardState.isTempLogin = tempAccountLogin;
                    document.getElementById("eway_payment_form").submit();
                }
                else if (response.data.data.public_group === 1)
                    {
                        setError("Your session has been timed out, for security reasons please refresh the page");
                        setUserAuth(() => ({
                            user: null,
                            token: null,
                            isLoggedIn: false,
                        }));
                    }
                    else
                    {
                        window.location = "/";
                    }
            });

    };

    const handleChangeCardName = (e) =>
        cardDispatch({
            type: "credit_card_name",
            name: e.target.value,
        });
    const handleChangeCardNum = (e) =>
        cardDispatch({
            type: "credit_card_num",
            number: e.target.value,
        });
    const handleChangeCardExpMth = (e) =>
        cardDispatch({
            type: "credit_card_exp_mth",
            expiryMonth: e.target.value,
        });
    const handleChangeCardExpYr = (e) =>
        cardDispatch({
            type: "credit_card_exp_yr",
            expiryYear: e.target.value,
        });
    const handleChangeCardCvn = (e) =>
        cardDispatch({
            type: "credit_card_cvn",
            cvn: e.target.value,
        });

    const handleChangeCardTermsChecked = () =>
        cardDispatch({
            type: "is_terms_checked",
            isTermsChecked: !cardState.isTermsChecked,
        });
    const handleChangeSaveCardChecked = () =>
        cardDispatch({
            type: "is_save_card_checked",
            isSaveCardChecked: !cardState.isSaveCardChecked,
        });
    const handleChangeUseSavedCardChecked = () =>
        cardDispatch({
            type: "is_use_saved_card_checked",
            isUseSavedCardChecked: !cardState.isUseSavedCardChecked,
        });

    return (
        <>
            {cardState.errors.length ? (
                <div className="checkout-card__error-wrapper">
                    <AlertMessage
                        type="error"
                        message="The following errors occurred:"
                        messageList={cardState.errors}
                    />
                </div>
            ) : null}
            {(!isLoggedIn || user?.account_type_id === ACCOUNT_TYPE_TEMPORARY) && (
            <Tabs>
                <div label="Login">
                    <LoginComponent />
                </div>
                <div label="Sign Up">
                    <SignupComponent />
                </div>
            </Tabs>
            )}
            {isLoggedIn && user.account_type_id === ACCOUNT_TYPE_NORMAL && !whiteLabel.enable_iframe_payment && (
                <div className="checkout-card__wrapper">
                    <h3 className="checkout-card__title">Credit card payment</h3>
                    <div className="checkout-card__content">
                        <form
                            id="eway_payment_form"
                            method="post"
                            className="checkout-card__form"
                            action={ewayFormActionUrl}
                            onSubmit={(e) => handleSubmit(e)}
                        >
                            {ewayAccessCode && (
                                <input
                                    type="hidden"
                                    name="EWAY_ACCESSCODE"
                                    value={ewayAccessCode}
                                />
                            )}
                            <input
                                type="hidden"
                                name="EWAY_PAYMENTTYPE"
                                value="Credit Card"
                            />

                            {cardState.cardNumber &&
                            cardState.isUseSavedCardChecked === true ? (
                                <Checkbox
                                    id="savecard"
                                    checked={cardState.isUseSavedCardChecked}
                                    onChange={handleChangeUseSavedCardChecked}
                                >
                                    Use Card on File (ending in {cardState.cardNumber})
                                </Checkbox>
                            ) : (
                                <>
                                    <TextInputLabel
                                        labelName="Name on Card"
                                        id="EWAY_CARDNAME"
                                        name="EWAY_CARDNAME"
                                        onChange={handleChangeCardName}
                                        value={cardState.name}
                                        placeholder="James Bond"
                                        required
                                    />
                                    <TextInputLabel
                                        labelName="Credit card number"
                                        id="EWAY_CARDNUMBER"
                                        name="EWAY_CARDNUMBER"
                                        onChange={handleChangeCardNum}
                                        value={cardState.number}
                                        placeholder="1111222233334444"
                                        required
                                    />

                                    <div className="form__group-wrapper">
                                        <ExpiryInput>
                                            <TextInput
                                                labelName="Expiry Month"
                                                id="EWAY_CARDEXPIRYMONTH"
                                                name="EWAY_CARDEXPIRYMONTH"
                                                onChange={handleChangeCardExpMth}
                                                value={cardState.expiryMonth}
                                                placeholder="MM"
                                                required
                                                maxLength={2}
                                            />
                                            <div className="input-divider">/</div>
                                            <TextInput
                                                labelName="Expiry Year"
                                                id="EWAY_CARDEXPIRYYEAR"
                                                name="EWAY_CARDEXPIRYYEAR"
                                                onChange={handleChangeCardExpYr}
                                                value={cardState.expiryYear}
                                                placeholder="YY"
                                                required
                                                maxLength={2}
                                            />
                                        </ExpiryInput>
                                        <CvnInput>
                                            <TextInput
                                                labelName="CCV"
                                                id="EWAY_CARDCVN"
                                                name="EWAY_CARDCVN"
                                                onChange={handleChangeCardCvn}
                                                value={cardState.cvn}
                                                placeholder="352"
                                                required
                                                maxLength={3}
                                            />
                                        </CvnInput>
                                    </div>
                                    <Checkbox
                                        id="savecard"
                                        checked={cardState.isSaveCardChecked}
                                        onChange={handleChangeSaveCardChecked}
                                    >
                                        Save Credit Card Details
                                    </Checkbox>
                                </>
                            )}
                            <Checkbox
                                checked={cardState.isTermsChecked}
                                onChange={handleChangeCardTermsChecked}
                            >
                                I accept the{" "}
                                <a
                                    href="#terms"
                                    className="inline-link"
                                    onClick={() => {
                                        openModalWithContent(<TermsPage />, "medium");
                                    }}
                                >
                                    Terms &amp; Conditions
                                </a>{" "}
                                of purchase
                            </Checkbox>
                            <div
                                className="form__group"
                                data-tip={
                                    !cardState.isTermsChecked
                                        ? "Please accept Terms & Conditions"
                                        : null
                                }
                            >
                                <Button
                                    additionalClasses="button button__primary"
                                    disabled={
                                        !cardState.isTermsChecked || cardState.isSubmitting
                                            ? "disabled"
                                            : ""
                                    }
                                >
                                    {cardState.isSubmitting ? (
                                        <Spinner text="loading..." />
                                    ) : (
                                        "Purchase"
                                    )}
                                </Button>
                                <ReactTooltip className="tooltip" />
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {isLoggedIn && user.account_type_id === ACCOUNT_TYPE_NORMAL && whiteLabel.enable_iframe_payment && (
                <div className="checkout-card__wrapper">
                    <Loader isLoading={true} />
                </div>
            )}
            {portal}
        </>
    );
};

export default CheckoutCreditCard;
