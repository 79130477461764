import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "@context/UserContext";
import TicketIcon from "@components/Icons/TicketIcon";
import { getTime } from "@utils/dateUtils";
import { getSessionButtonText, setSessionClass } from "@utils/ticketUtils";
import "./style.scss";

const V3SessionButton = ({ session }) => {
    if (!session) return null;

    const { promoDetails, setIsAdvancedPromo, isAdvancedPromo } = useContext(UserContext);
    const { promoTicketClasses } = promoDetails;
    const showPromoIcon = isAdvancedPromo && promoTicketClasses?.includes(Number(session?.ticket_class_id));

    useEffect(() => {
        if (promoTicketClasses?.includes(Number(session?.ticket_class_id))) {
            setIsAdvancedPromo(true);
        }
    }, [promoTicketClasses, session?.ticket_class_id]);

    return (
        <Link
            key={session.id}
            className={`v3-sessions-button__item v3-sessions-button__item--${setSessionClass(session)}`}
            to={{ pathname: `/ticket/purchase/${session.id}` }}>
            <div className="v3-sessions-button__detail">
                <div className="v3-sessions-button__group-item time">
                    {getTime(session.session_date)}
                </div>
                <div className="v3-sessions-button__group-item type">
                    {getSessionButtonText(session)}
                </div>
                <div className="v3-sessions-button__group-item price">
                    ${(session.ticket_price_cents / 100).toFixed(2)}
                </div>
            </div>
            <div className="v3-sessions-button__pricing">
                <span className="v3-sessions-button__group-item rrp">
                    RRP: ${(session.rrp_pricing_cents / 100).toFixed(2)}
                </span>
                {showPromoIcon ? (
                    <span className="v3-sessions-button__group-item promo_icon">
                        <TicketIcon className="session-btn__promo-img" />
                    </span>
                ): null}
                <span className="v3-sessions-button__group-item save">
                    SAVE: ${(session.saving / 100).toFixed(2)}
                </span>
            </div>
        </Link>
    );
};

export default V3SessionButton;
