import React, { useContext, useEffect } from "react";
import { ApplicationContext } from "@context/ApplicationContext";
import Loader from "@components/Ui/Loader";
import "./style.scss";

const ParticipatingCinemas = () => {

    const { whiteLabel, fetchSubscriberCinemas, cinemas, isLoading } = useContext(ApplicationContext);

    useEffect(() => {
        fetchSubscriberCinemas();
    }, []);

    if (isLoading === true) {
        return (
            <Loader isLoading={isLoading} />
        );
    }

    return (
        <div className="participating-cinemas__wrapper">
            {cinemas ? (
                <>
                    <h1 className="main-heading">Participating Cinemas</h1>
                    <p className="paragraph">{whiteLabel?.participating_cinema_description}</p>
                    <ul className="participating-cinemas">
                        {cinemas.length > 0
                            ? cinemas.map((cinema, id) => <li className="participating-cinemas__item" key={id}><a href={cinema.link_url}>{cinema.name}</a></li>)
                            : <li className="participating-cinemas__item">Sorry no cinema&apos;s available</li>}
                    </ul>
                </>

            ) : (<p className="paragraph">Participating cinema content to follow</p>)}
        </div>
    );
};

export default ParticipatingCinemas;
