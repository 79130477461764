import React, { useContext, useState } from "react";
import { ApplicationContext } from "@context/ApplicationContext";
import { CheckoutComplimentaryContext } from "@context/CheckoutComplimentaryContext";
import { CheckoutCreditCardContext } from "@context/CheckoutCreditCardContext";
import { SessionContext } from "@context/SessionContext";
import Menu from "@components/Navs/Menu";
import Logo from "@components/Ui/Logo";
import "./style.scss";


const Navigation = () => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(false);
    const [redirectHome, setRedirectHome] = useState(false);
    const { whiteLabel } = useContext(ApplicationContext);
    const { cardState } = useContext(CheckoutCreditCardContext);
    const { isSubmitting } = useContext(CheckoutComplimentaryContext);
    const handleNavCollapse = () => {
        setIsNavCollapsed(!isNavCollapsed);
    };

    const {
        setVenueName,
        setMovieTitle,
        setRegion,
        setSessions,
    } = useContext(SessionContext);


    const handleNavigateHome = () => {
        setVenueName(null);
        setMovieTitle(null);
        setRegion(25);
        setSessions([]);
        setRedirectHome(true);
    };

    if (redirectHome === true) {
        window.location = "/";
    }

    const formSubmitting = cardState.isSubmitting || isSubmitting === true ? "nav__main-container--disabled" : "";
    const className = ["nav__main-container", whiteLabel?.show_search_banner === true ? "nav__main-container--with-banner" : ""].join(" ");

    return (
        <>
            <div className={className}>
                <div className="container">
                    <nav
                        className={`nav__container ${formSubmitting}`}>
                        <a className="nav__link" onClick={handleNavigateHome} role="button">
                            <Logo />
                        </a>

                        <Menu
                            handleNavCollapse={handleNavCollapse}
                            setIsNavCollapsed={setIsNavCollapsed}
                            isNavCollapsed={isNavCollapsed} />
                    </nav>
                </div>
            </div>
        </>
    );
};

export default Navigation;
