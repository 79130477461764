import React from "react";
import Ticket from "@components/Tickets/Ticket";
import "./style.scss";

const TicketList = ({ tickets, emptyMessage }) => {
    if (tickets.length === 0) {
        return (
            <div className="ticket-history__list">
                <div className="ticket-history__item">
                    <p className="ticket-history__no-results">
                        {emptyMessage}
                    </p>
                </div>
            </div>
        );
    }
    return (
        <div className="ticket-history__list">
            {tickets.map((ticket) => (
                <Ticket ticket={ticket} key={ticket.ticket_id} />
            ))}
        </div>
    );
};

export default TicketList;
