import React from "react";
import Logo from "@components/Ui/Logo";
import "./style.scss";

const LogoBar = () => {
    return (
        <div className="logo-bar__container">
            <div className="logo-bar__link">
                <Logo/>
            </div>
        </div>
    );
};

export default LogoBar;
