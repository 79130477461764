import React, { createContext, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "@config/config";

export const MovieContext = createContext({});

const MovieContextProvider = (props) => {
    const [movie, setMovie] = useState({});
    const [movieUrl, setMovieUrl] = useState(null);
    const [movieImage, setMovieImage] = useState(null);
    const [movieTitle, setMovieTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { children } = props;

    const fetchMovie = (id, movieName) => {
        setMovieImage(null);
        setIsLoading(true);
        axios
            .get(`${API_BASE_URL}/movie/${id}/${movieName}`)
            .then(function(response) {
                if (response.status === 200) {
                    setMovie(response.data.data.movie);
                    if (response.data.data?.movie?.movie_still) {
                        setMovieImage(response.data.data.movie.movie_still);
                    } else if (response.data.data.movie?.images[0]?.local_url.length) {
                        setMovieImage(response.data.data.movie.images[0].local_url);
                    }
                    setMovieTitle(response.data.data.movie.title);
                    setMovieUrl(response.data.data.movie.trailer_url);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };

    return (
        <MovieContext.Provider
            value={{
                fetchMovie,
                movieImage,
                movieTitle,
                isLoading,
                movie,
                movieUrl,
            }}
        >
            {children}
        </MovieContext.Provider>
    );
};

export default MovieContextProvider;
