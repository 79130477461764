import React from "react";
import TicketHistory from "@components/Tickets/TicketHistory";
import Layout from "@components/Wrappers/Layout";

const HistoryPage = () => {
    return (
        <Layout>
            <TicketHistory />
        </Layout>
    );
};

export default HistoryPage;
