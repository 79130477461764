import React, { useContext } from "react";
import { AuthContext } from "@context/AuthContext";
import LoginComponent from "@components/Form/LoginComponent";
import SignupComponent from "@components/Form/SignupComponent";
import Drawer from "@components/V3/Drawer";
import V3Tabs from "@components/V3/V3Tabs";
import "./style.scss";

const V2V3FastAuth = () => {
    const { drawerState, closeDrawer } = useContext(AuthContext);

    return (
        <>
            <Drawer
                isOpen={drawerState.fastAuthDrawer}
                onClose={() => closeDrawer("fastAuthDrawer")}
                closeButtonAlignment="right"
            >
                <V3Tabs>
                    <div label="Login">
                        <LoginComponent />
                    </div>
                    <div label="Sign Up">
                        <SignupComponent />
                    </div>
                </V3Tabs>
            </Drawer>
        </>
    );
};

export default V2V3FastAuth;
