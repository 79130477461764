import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "@context/AuthContext";
import { BookSessionContext } from "@context/BookSessionContext";
import { MembershipContext } from "@context/MembershipContext";
import AlertMessage from "@components/AlertMessage";
import Button from "@components/Ui/Button";
import Spinner from "@components/Ui/Spinner";
import TextInputLabel from "@components/Ui/TextInputLabel";
import "./style.scss";

const MovieClubLoginComponent = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const {
        loginMovieClub,
        isLoginSubmitting,
        loginError,
        setLoginError,
        setTempAccountLogin,
    } = useContext(AuthContext);

    const { setIsTempAccount } = useContext(BookSessionContext);
    const { selectedMembershipId } = useContext(MembershipContext);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        setLoginError(null);
        setIsTempAccount(false);
        setTempAccountLogin(true);
        const membershipId = selectedMembershipId;
        loginMovieClub({ username, password, membershipId });
    };

    const handleUsernameOnchange = (e) => {
        setUsername(e.target.value);
        setLoginError(null);
    };
    const handlePasswordOnchange = (e) => {
        setPassword(e.target.value);
        setLoginError(null);
    };

    const showError = !!(
        loginError != null &&
        username !== "" &&
        password !== ""
    );

    return (
        <>
            <form onSubmit={handleSubmit} className="form__content">
                <TextInputLabel
                    type="email"
                    name="email"
                    labelName="Email"
                    required={true}
                    value={username}
                    onChange={handleUsernameOnchange}
                />
                <TextInputLabel
                    type="password"
                    name="password"
                    labelName="Password"
                    autoComplete="true"
                    required={true}
                    value={password}
                    onChange={handlePasswordOnchange}
                />

                <Link className="link link__primary" to="/request-password-reset">
                    Forgot password?
                </Link>
                <Button
                    additionalClasses="button button__primary"
                    disabled={selectedMembershipId === 0}
                >
                    {isLoginSubmitting ? (
                        <Spinner text="loading..." />
                    ) : (
                        <span>Login</span>
                    )}
                </Button>
            </form>

            {showError && <AlertMessage message={loginError} type="error" />}
        </>
    );
};

export default MovieClubLoginComponent;
