import React from "react";
import "./style.scss";

const InlineMessage = ({ message, messageType }) => {
    return (
        <div className={`inline-message inline-message__${messageType}`}>
            {message}
        </div>
    );
};

export default InlineMessage;
