import React from "react";
import "./style.scss";

const ErrorIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            className="error-icon">
            <circle className="error-icon__a" cx="8" cy="8" r="8"/>
            <path className="error-icon__b"
                d="M1.7-4.32q-.389,0-.423-.435L.949-8.965a.786.786,0,0,1,.189-.595.7.7,0,0,1,.555-.24.721.721,0,0,1,.561.24.74.74,0,0,1,.183.595l-.32,4.21Q2.082-4.32,1.7-4.32Zm-.08,2.528a.689.689,0,0,1-.509-.177.689.689,0,0,1-.177-.509v-.194a.689.689,0,0,1,.177-.509.689.689,0,0,1,.509-.177h.149a.689.689,0,0,1,.509.177.689.689,0,0,1,.177.509v.194a.689.689,0,0,1-.177.509.689.689,0,0,1-.509.177Z"
                transform="translate(6.416 13.886)"/>
        </svg>
    );
};

export default ErrorIcon;
