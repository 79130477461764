import React, { useContext, useEffect } from "react";
import useAxios from "axios-hooks";
import { API_BASE_URL } from "@config/config";
import { AuthContext } from "@context/AuthContext";
import MoviePoster from "@components/MoviePoster";
import Loader from "@components/Ui/Loader";
import "./style.scss";


const NowShowing = () => {
    const [{ data, loading }] = useAxios(`${API_BASE_URL}/movie/now-showing`);
    const { nowShowing, setNowShowing, setComingSoon } = useContext(AuthContext);

    useEffect(() => {
        if (data) {
            setNowShowing(data.data?.nowShowing);
            setComingSoon(data.data?.comingSoon);
        }
    },[data]);

    if (loading) {
        return <Loader isLoading={loading}/>;
    }

    if (data.data.length === 0) {
        return (
            <div className="search__container">
                <p className="paragraph">Sorry there are no movies currently showing near you.</p>
            </div>
        );
    }

    return  (
        <div className="now-showing">
            <div className="now-showing__list">
                {nowShowing && nowShowing.map && nowShowing.map((movie, index) =>
                    movie.movie_title ? (
                        <MoviePoster
                            key={index}
                            movie={movie}
                            size="medium"
                            showTitle={true}
                        />
                    ) : null
                )}
            </div>
        </div>
    );
};


export default NowShowing;
