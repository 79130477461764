import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SITE_V3 } from "@config/config";
import getDateOptions from "@functions/getDateOptions";
import groupSessionsByVenueMovie from "@functions/groupSessionsByVenueMovie";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { SessionContext } from "@context/SessionContext";
import { UserContext } from "@context/UserContext";
import Byline from "@components/Byline";
import FeatureFilm from "@components/FeatureFilm";
import CalenderIcon from "@components/Icons/CalenderIcon";
import LocationIcon from "@components/Icons/LocationIcon";
import MovieIcon from "@components/Icons/MovieIcon";
import SearchResults from "@components/SearchResults";
import SearchSelect from "@components/SearchSelect";
import Button from "@components/Ui/Button";
import Loader from "@components/Ui/Loader";
import "./style.scss";


const dateOptions = getDateOptions();


const MovieSearch = () => {
    const {
        sessions,
        fetchSessions,
        isLoadingSessions,
        isLoadedSessions,
        setIsLoadedSessions,
        venueName,
        setVenueName,
        movieTitle,
        setMovieTitle,
        sessionDate,
        setSessionDate,
        region,
        setRegion,
        searchParams,
    } = useContext(SessionContext);


    const {
        isVenueOptionsLoading,
        isMovieOptionsLoading,
        movieOptions,
        venueOptions,
        fetchMovieOptions,
        fetchVenueOptions,
        user,
        siteVersion,
    } = useContext(AuthContext);


    const { whiteLabel } = useContext(ApplicationContext);
    const { fetchApplyPromoAvailability, promoDetails } = useContext(UserContext);
    const history = useHistory();
    const location = useLocation();


    useEffect(() => {
        if (siteVersion && siteVersion !== SITE_V3 && movieOptions.length === 0 && isMovieOptionsLoading === false) {
            fetchMovieOptions();
        }
        if (siteVersion && siteVersion !== SITE_V3 && venueOptions.length === 0 && isVenueOptionsLoading === false) {
            fetchVenueOptions();
        }
        if (siteVersion && siteVersion !== SITE_V3 && user?.id && promoDetails) {
            fetchApplyPromoAvailability();
        }
    }, [siteVersion]);


    useEffect(() => {
        if (searchParams?.toString() === "" && location?.search !== "") {
            const search = new URLSearchParams(location.search);
            let newRegion = null;
            if (search.get("venue_name")) {
                setVenueName(search.get("venue_name"));
            }
            if (search.get("movie_title")) {
                setMovieTitle(search.get("movie_title"));
            }
            if (search.get("session_date")) {
                setSessionDate(search.get("session_date"));
            }
            if (search.get("search_distance")) {
                newRegion = parseInt(search.get("search_distance"));
                setRegion(newRegion);
            }
            if (search.get("state")) {
                newRegion = search.get("state");
                setRegion(newRegion);
            }
            setIsLoadedSessions(true);
            fetchSessions({
                venueName: search.get("venue_name"),
                movieTitle: search.get("movie_title"),
                sessionDate: search.get("session_date"),
                region: newRegion,
            });
        }
    }, []);


    useEffect(() => {
        history.replace({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }, [searchParams.toString(), location.pathname]);


    // always re-sort by dist
    const sessionsGrouped = sessions
        .reduce(groupSessionsByVenueMovie, [])
        .sort((a, b) => a.venue_distance - b.venue_distance);


    const handleFetchSearchResults = () => {
        setIsLoadedSessions(true);
        fetchSessions({
            venueName,
            movieTitle,
            sessionDate,
            region,
        });
    };


    const handleOnChangeMovie = (option, { action }) => {
        if (action === "clear") {
            setMovieTitle("");
        } else if (action === "select-option") {
            setMovieTitle(option.value);
        }
    };


    const handleOnChangeVenue = (option, { action }) => {
        if (action === "clear") {
            setVenueName("");
        } else if (action === "select-option") {
            setVenueName(option.search_name);
        }
    };


    const handleOnChangeDate = (option, { action }) => {
        if (action === "clear") {
            setSessionDate(null);
        } else if (action === "select-option") {
            setSessionDate(option.value);
        }
    };


    const filterOptions = (candidate, input) => {
        const candidateStr = candidate.value.toLowerCase();
        const candidateTerms = candidateStr.split(" ");
        const searchStr = input.toLowerCase();


        return searchStr && searchStr.includes(" ")
            ? candidateStr.includes(searchStr)
            : searchStr
                ? candidateTerms.some((str) => str.startsWith(searchStr))
                : false;
    };


    const selectedMovieOption = movieOptions.find(
        (item) => item.value === movieTitle,
    );
    const selectedVenueOption = venueOptions.find(
        (item) => item.value === venueName,
    );
    const selectedDateOption = dateOptions.find(
        (item) => item.value === sessionDate,
    );


    const isButtonDisabled = !selectedMovieOption && !selectedVenueOption;


    const className = [
        "search",
        whiteLabel?.show_search_banner === true ? "search__banner" : "",
    ].join(" ");


    return (
        <>
            <div className={className}>
                <div className="search__title">
                    <Byline />
                </div>
                <div className="search__container">
                    <div className="search__input-wrapper">
                        <span className="search__icon search__icon--movie">
                            <MovieIcon />
                        </span>
                        <SearchSelect
                            placeholder="Type in a Movie..."
                            aria-label="Select a movie input"
                            value={selectedMovieOption}
                            options={movieOptions}
                            filterOption={filterOptions}
                            onChange={handleOnChangeMovie}
                            blurInputOnSelect={true}
                            isClearable={true}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                            }}
                            noOptionsMessage={() => null}
                        />
                    </div>
                    <div className="search__input-wrapper">
                        <span className="search__icon search__icon--location">
                            <LocationIcon />
                        </span>
                        <SearchSelect
                            placeholder="Type in a venue..."
                            aria-label="Select a venue input"
                            value={selectedVenueOption}
                            options={venueOptions}
                            filterOption={filterOptions}
                            onChange={handleOnChangeVenue}
                            isClearable={true}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                            }}
                            noOptionsMessage={() => null}
                        />
                    </div>
                    <div className="search__input-wrapper search__input-wrapper--small">
                        <span className="search__icon search__icon--calendar">
                            <CalenderIcon />
                        </span>
                        <SearchSelect
                            placeholder="Choose a Date"
                            aria-label="Select a date input"
                            value={selectedDateOption}
                            options={dateOptions}
                            onChange={handleOnChangeDate}
                            isClearable={true}
                            maxMenuHeight={400}
                        />
                    </div>
                    <div className="search__input-wrap">
                        <Button
                            disabled={isButtonDisabled}
                            additionalClasses="button button__primary button--grow"
                            onClick={handleFetchSearchResults}>
                            Search
                        </Button>
                    </div>
                </div>
            </div>
            <FeatureFilm />


            {(isLoadedSessions === true) ? (
                <SearchResults items={sessionsGrouped} />
            ) : (
                <Loader isLoading={isLoadingSessions} text="Searching" />
            )}
        </>
    );
};


export default MovieSearch;

