import React, { useContext } from "react";
import { AuthContext } from "@context/AuthContext";
import { SearchContext } from "@context/SearchContext";
import PlusIcon from "@components/Icons/PlusIcon";
import Button from "@components/Ui/Button";
import Drawer from "@components/V3/Drawer";
import Experiences from "@components/V3/Experiences";
import "./style.scss";

const ExperienceFinder = ({ experienceData, handleExperienceClick }) => {
    const { drawerState, openDrawer, closeDrawer } = useContext(AuthContext);
    const { selectedExperiences } = useContext(SearchContext);

    return (
        <>
            <Button
                additionalClasses="experience-finder__button"
                onClick={() => openDrawer("experienceDrawer")}
                aria-label={`Select movie experience. Current selection: ${selectedExperiences.length > 0 ? selectedExperiences.join(", ") : "None"}`}
                aria-expanded={drawerState.experienceDrawer}
                aria-controls="experience-drawer"
            >
                {selectedExperiences.length > 0 ? selectedExperiences.join(", ") : "Select Experience"}
                <PlusIcon className="experience-finder__icon" />
            </Button>
            <Drawer
                id="experience-drawer"
                role="dialog"
                aria-modal="true"
                aria-label="Experience Selection"
                isOpen={drawerState.experienceDrawer}
                onClose={() => closeDrawer("experienceDrawer")}
                closeButtonAlignment="right"
            >
                <Experiences
                    experienceData={experienceData}
                    handleExperienceClick={handleExperienceClick}
                />
                <Button
                    aria-label="Save experience selection and close"
                    additionalClasses="button button__primary"
                    onClick={() => closeDrawer("experienceDrawer")}
                >
                    Save and browse
                </Button>
            </Drawer>
        </>
    );
};

export default ExperienceFinder;
