import React from "react";
import "./style.scss";

const Spinner = ({ text="Loading...", center=false }) => {
    return (
        <div className={`${center === true ?  "spinner__container" : ""}`}>
            <div role="status" className="spinner">
                <span className="sr-only">{text}</span>
            </div>
        </div>
    );
};

export default Spinner;
