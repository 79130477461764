import React, { useContext, useEffect } from "react";
import { UserContext } from "@context/UserContext";
import Loader from "@components/Ui/Loader";
import Voucher from "@components/VoucherManager/VoucherPurchases/Voucher";
import "./style.scss";

const VoucherHistory = () => {
    const { fetchTickets, purchasedVouchers, isLoading } = useContext(UserContext);


    useEffect(() => {
        fetchTickets();
    }, []);

    const pageTitle = "Purchase History";

    if (isLoading === true) {
        return (
            <Loader isLoading={isLoading} />
        );
    }

    if (!purchasedVouchers) {
        return <p>You have not purchased any vouchers</p>;
    }

    return (
        <div className="voucher-history">
            <div className="voucher-history__header">
                <h1 className="voucher-history__heading">
                    {pageTitle}
                </h1>
            </div>
            <div className="voucher-history__list">
                {purchasedVouchers.map((v, i) => (
                    <Voucher voucher={v} key={i}/>
                ))}
            </div>
        </div>

    );
};

export default VoucherHistory;

