const pages = [
    {
        title: "Home Page",
        path: "/",
    },
    {
        title: "Now Showing",
        path: "/now-showing",
    },
    {
        title: "About",
        path: "/about",
    },
    {
        title: "Movie Details",
        path: "/movie/",
    },
    {
        title: "Ticket Purchase",
        path: "/ticket/purchase/",
    },
    {
        title: "Ticket Purchase Success",
        path: "/success",
    },
    {
        title: "Movie Search",
        path: "/?search_distance=",
    },
    {
        title: "Venue Search",
        path: "/?venue_name=",
    },
    {
        title: "Movie Search",
        path: "/?movie_title=",
    },
    {
        title: "Tickets Purchased",
        path: "/user/current-tickets",
    },
    {
        title: "Account Details",
        path: "/user/profile",
    },
    {
        title: "Manage Email",
        path: "/user/email-add",
    },
    {
        title: "Change Password",
        path: "/user/change-password",
    },
    {
        title: "Login",
        path: "/login",
    },
    {
        title: "Signup",
        path: "/signup",
    },
    {
        title: "Request Password reset",
        path: "/request-password-reset",
    },
];

const pageUnknown = {
    title: "Unknown",
    path: "",
};

const getPageTitleFromPath = (pathName) => {
    return pages.reduce((previous, current) => {
        return pathName.startsWith(current.path)
            ? current
            : previous;
    }, pageUnknown);
};

export default getPageTitleFromPath;
