import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { AUTH_JWT, SITE_V3 } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import { ProfileContext } from "@context/ProfileContext";
import AddEmailForm from "@components/Form/AddEmailForm";
import LayoutV3 from "@components/V3/Layout";
import Layout from "@components/Wrappers/Layout";

const AddEmailPage = () => {
    const { siteVersion } = useContext(AuthContext);
    const { whiteLabel } = useContext(ApplicationContext);
    const { updateEmailList, emailList, processing } = useContext(ProfileContext);

    const LayoutComponent = siteVersion && siteVersion === SITE_V3 ? LayoutV3 : Layout;
    useEffect(() => {
        updateEmailList();
    }, [processing]);

    const brand = whiteLabel?.brand_name;
    const isJwt = whiteLabel?.subscriber_group?.authentication_type === AUTH_JWT;
    const uniqueEmailList = emailList.filter(
        (email, index, self) => index === self.findIndex((e) => e.email === email.email)
    );

    return (
        <LayoutComponent>
            <Helmet>
                <title>{`Manage Your Email | ${brand}`}</title>
                <meta name="keywords" content={`Manage Your Email | ${brand}`} />
                <meta name="description" content={`Sign up for a ${brand}
                account with your personal email address to get access
                to the membership offers & discounts available. Sign up today!`} />
                <link rel="canonical" href={`${window.location.protocol}//${window.location.host}/user/email-add`} />
            </Helmet>
            <div className="form__wrapper">
                <h1 className="main-heading">Account Email</h1>
                <div className="form__email-please-note">
                    <p className="paragraph">Please note: The current email is always in bold and labelled &quot;default&quot;.</p>
                </div>
                <div className="email__list">
                    {uniqueEmailList.length > 0 ? (
                        uniqueEmailList.map((email) => (
                            <div className={`email__item ${email.is_default ? "email__default" : ""}`} key={email.id}>
                                {email.email}
                                {email.is_default ? <span> - default</span> : null}
                                {email.email_verified === false ? <span> - NOT VERIFIED!</span> : null}
                            </div>
                        ))
                    ) : (
                        <p className="no-results">
                            No email loaded/found.
                        </p>
                    )}
                </div>
                {!isJwt ? (
                    <AddEmailForm />
                ): null}

            </div>
        </LayoutComponent>
    );
};
export default AddEmailPage;
