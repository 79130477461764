import React from "react";
import DoubleAvatarIcon from "@components/Icons/DoubleAvatarIcon";
import FamilyAvatarIcon from "@components/Icons/FamilyAvatarIcon";
import SingleAvatarIcon from "@components/Icons/SingleAvatarIcon";

const AvatarIcon = ({ className="", type }) => {

    if (type === 1) {
        return <SingleAvatarIcon className="membership-options__icon" />;
    }
    if (type === 2) {
        return <DoubleAvatarIcon className="membership-options__icon" />;
    }

    if (type === 3) {
        return <FamilyAvatarIcon className="membership-options__icon" />;
    }

    return (
        <>
            <svg className={className} width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="Vector" d="M30 30.3335C25.875 30.3335 22.3438 28.8647 19.4062 25.9272C16.4688 22.9897 15 19.4585 15 15.3335C15 11.2085 16.4688 7.67725 19.4062 4.73975C22.3438 1.80225 25.875 0.333496 30 0.333496C34.125 0.333496 37.6562 1.80225 40.5938 4.73975C43.5312 7.67725 45 11.2085 45 15.3335C45 19.4585 43.5312 22.9897 40.5938 25.9272C37.6562 28.8647 34.125 30.3335 30 30.3335ZM0 60.3335V49.8335C0 47.7085 0.546875 45.7554 1.64062 43.9741C2.73438 42.1929 4.1875 40.8335 6 39.896C9.875 37.9585 13.8125 36.5054 17.8125 35.5366C21.8125 34.5679 25.875 34.0835 30 34.0835C34.125 34.0835 38.1875 34.5679 42.1875 35.5366C46.1875 36.5054 50.125 37.9585 54 39.896C55.8125 40.8335 57.2656 42.1929 58.3594 43.9741C59.4531 45.7554 60 47.7085 60 49.8335V60.3335H0Z" fill="white"/>
            </svg>
        </>

    );
};

export default AvatarIcon;
