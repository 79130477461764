import React from "react";

const HoytsLogo = ({ fillColour="white" }) => {
    return (
        <svg className="voucher__img" width="117" height="34" viewBox="0 0 117 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 9.8655H4.92096V14.6077H0V9.8655ZM0 0H4.92096V4.74222H0V0ZM0 19.6887H4.92096V24.4309H0V19.6887ZM0 29.2154H4.92096V33.9577H0V29.2154ZM59.8151 33.9577H68.3419V19.9851L75.2567 0.0423406H66.942L63.9725 11.9826L60.9605 0.0423406H52.6882L59.7727 19.9851C59.8151 19.9851 59.8151 33.9577 59.8151 33.9577ZM77.2505 6.9863H82.8078V33.9577H91.0377V6.9863H96.6374V0H77.2505V6.9863ZM110.425 14.6077C107.879 12.279 105.843 10.9664 105.843 8.38356V7.74844C105.843 6.94396 106.479 6.35118 107.328 6.35118H108.303C109.152 6.35118 109.788 6.94396 109.788 7.74844V10.797H116.703V6.90162C116.703 3.17559 113.437 0.0423406 109.576 0.0423406H105.334C101.474 0.0423406 98.2919 3.21793 98.2919 6.90162V9.35741C98.2919 13.7609 101.898 16.9788 104.91 19.4346C107.413 21.6787 109.449 23.3723 109.449 25.6588V26.5903C109.449 27.3948 108.813 27.9875 108.007 27.9875H106.437C105.588 27.9875 104.867 27.3948 104.867 26.5903V23.203H98.2919V27.3101C98.2919 30.9938 101.304 34 105.164 34H109.958C113.818 34 117 30.9938 117 27.3101V24.9813C117 20.3661 113.521 17.2752 110.425 14.6077ZM21.3807 14.269H17.0961V0H9.20558V33.9577H17.0961V20.9589H21.3807V33.9577H29.6106V0H21.3807V14.269ZM52.264 27.2677V6.90162C52.264 3.17559 49.0399 0.0423406 45.2219 0.0423406H39.707C35.889 0.0423406 32.8771 3.21793 32.8771 6.90162V27.2677C32.8771 30.9514 35.889 33.9577 39.707 33.9577H45.2219C49.0399 33.9577 52.264 30.9514 52.264 27.2677ZM44.3735 26.4209C44.3735 27.2254 43.5674 27.9452 42.7614 27.9452H42.1675C41.3191 27.9452 40.7676 27.2254 40.7676 26.4209V7.74844C40.7676 6.94396 41.3615 6.35118 42.1675 6.35118H42.7614C43.6099 6.35118 44.3735 6.94396 44.3735 7.74844V26.4209Z" fill={fillColour} />
        </svg>
    );
};

export default HoytsLogo;
