import React, { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { API_BASE_URL } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import Loader from "@components/Ui/Loader";
import "./style.scss";

const Faqs = () => {
    const { subscriberGroupId } = useContext(ApplicationContext);
    const [faqs, setFaqs] = useState("");
    const [{ data, loading }] = useAxios(`${API_BASE_URL}/subscriber-group-faq/${subscriberGroupId}`);

    useEffect(() => {
        if (data) {
            setFaqs(data.data.faq);
        }
    }, [data]);

    if (loading) {
        return (
            <Loader isLoading={loading} />
        );
    }

    return (
        <div className="faqs__wrapper">
            {faqs ? (
                // eslint-disable-next-line react/no-danger
                <div className="faqs__content" dangerouslySetInnerHTML={{ __html: faqs }} />
            ) : (<p>Faq content to follow</p>)}
        </div>
    );
};
export default Faqs;
