import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { SITE_V3 } from "@config/config";
import { ApplicationContext } from "@context/ApplicationContext";
import { AuthContext } from "@context/AuthContext";
import RequestPasswordResetForm from "@components/Form/RequestPasswordResetForm";
import LayoutV3 from "@components/V3/Layout";
import FormWrapper from "@components/Wrappers/FormWrapper";
import Layout from "@components/Wrappers/Layout";

const RequestPasswordResetPage = () => {
    const { whiteLabel } = useContext(ApplicationContext);
    const { siteVersion } = useContext(AuthContext);
    const brand = whiteLabel?.brand_name;
    const LayoutComponent = siteVersion && siteVersion === SITE_V3 ? LayoutV3 : Layout;

    return (
        <LayoutComponent>
            <Helmet>
                <title>{ `Request Password Reset | ${brand}` }</title>
                <meta name="keywords" content={ `Request Password Reset | ${brand}` } />
                <meta name="description" content={ `Forgot your password? Not to worry,
                simply request a password reset to re-access your ${brand} account &
                continue enjoying member benefits.` } />
                <link rel="canonical" href={`${window.location.protocol }//${ window.location.host}/request-password-reset`} />
            </Helmet>
            <FormWrapper pageTitle="Request Password Reset">
                <RequestPasswordResetForm/>
            </FormWrapper>
        </LayoutComponent>
    );
};

export default RequestPasswordResetPage;
