import React, { createContext, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "@config/config";

export const TicketContext = createContext({});
const TicketContextProvider = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [purchasedTicket, setPurchasedTicket] = useState(null);
    const [purchasedSession, setPurchasedSession] = useState(null);
    const [purchasedType, setPurchasedType] = useState(null);
    const [tempAccountCreation, setTempAccountCreation] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const { children } = props;
    
    const fetchTicket = (uuid) => {
        setIsLoading(true);
        axios
            .get(`${API_BASE_URL}/ticket/purchased/${uuid}`)
            .then(function (response) {
                setPurchasedTicket(response.data.data.ticket);
                setPurchasedType(response.data.data.ticket.ticket_type);
                setPurchasedSession(response.data.data.session);
                setTempAccountCreation(response.data.data.tempAccountCreation);
                setIsLoading(false);
            })
            .catch(({ response }) => {
                setIsLoading(false);
                if (response?.status === 401) {
                    window.location = "/";
                }
                setErrorMessage([response?.data?.message]);
            });
    };

    return (
        <TicketContext.Provider
            value={{
                isLoading,
                errorMessage,
                fetchTicket,
                purchasedTicket,
                setPurchasedTicket,
                purchasedSession,
                setPurchasedSession,
                tempAccountCreation,
                setTempAccountCreation,
                purchasedType,
            }}
        >
            {children}
        </TicketContext.Provider>
    );
};

export default TicketContextProvider;
